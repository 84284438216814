import React from "react";
import AdminRoot from "../components/frame/AdminRoot";
import StoryReplyReportTable from "../components/service/StoryReplyReportTable";

const StoryReplyReport = () => {
  return (
    <AdminRoot>
      <div>회차 댓글 신고</div>
      <StoryReplyReportTable />
    </AdminRoot>
  );
};
export default StoryReplyReport;
