import querystring from "querystring";
import axiosInstance from "./axiosInstance";

const my = "my";
const admin = "admin";

const login = "login/email";
const logout = "logout";
const user = "users";
const join = "join/email";

export const myApi = {
  // session 로그인 시에도 사용
  profile: () => axiosInstance.get(`${my}/profile`),
};

export const authApi = {
  login: (form) => axiosInstance.post(login, form),
  profile: () => axiosInstance.get(`${my}/profile`),
  logout: () => axiosInstance.get(logout),
  confirm: (form) => axiosInstance.post(`${admin}/login/confirm`, form),
};

export const userApi = {
  //list: () => axiosInstance.get(`${user}`),
  list: (pageSize, pageNum) => {
    const query = querystring.stringify({
      pageSize
      , pageNum
    });
    return axiosInstance.get(`${user}?${query}`);
  },
  withdrawalList: () => axiosInstance.get(`${user}/withdrawal`),
  adminList: () => axiosInstance.get(`${user}/admin`),
  join: (form) => axiosInstance.post(join, form),
  findUserList: () => {
    return axiosInstance.get(`${user}/userList`);
  },
};

export const blackUserApi = {
  blackUsersList: (startAt, endAt, searchFilter, inputValue) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      searchFilter,
      inputValue,
    });
    return axiosInstance.get(`blackUsers?${query}`);
  },
  blackUserAdd: (newBlackUser) => {
    return axiosInstance.post(`blackUsers`, newBlackUser);
  },
  blackUserModify: (id, detail) => {
    return axiosInstance.put(`blackUsers/${id}`, detail);
  },
  blackUserRemove: (id) => {
    return axiosInstance.delete(`blackUsers/${id}`);
  },
};

export const commonApi = {
  list: () => axiosInstance.get(),
};

export const eventPopupApi = {
  list: () => axiosInstance.get(`eventPopups`),
  add: (popupInfo) => axiosInstance.post(`eventPopups`, popupInfo),
  update: (popupEventId, popupInfo) =>
    axiosInstance.put(`eventPopups/${popupEventId}`, popupInfo),
  delete: (popupEventId) =>
    axiosInstance.delete(`eventPopups/${popupEventId}`, popupEventId),
  paymentsCodeList: (device) => {
    const query = querystring.stringify({
      device,
    });
    return axiosInstance.get(`eventPopups/code?${query}`);
  }
};
