import { createSlice } from "@reduxjs/toolkit";
import { authApi, myApi } from "../api/global";

const userSlice = createSlice({
  name: "users",
  initialState: {
    isLoggedIn: false,
    isCheckToken: false,
    userInfo: {
      id: 0,
      mail: "",
      name: "",
      admin: false,
    },
  },
  reducers: {
    logIn(_state, action) {
      return {
        isLoggedIn: true,
        isCheckToken: true,
        userInfo: {
          id: action.payload.id,
          mail: action.payload.mail,
          name: action.payload.name,
          admin: action.payload.admin,
        },
      };
    },
    logOut() {
      return {
        isLoggedIn: false,
        isCheckToken: true,
      };
    },
  },
});

export const { logIn, logOut } = userSlice.actions;

export const tokenLogin = () => async (dispatch) => {
  try {
    const { data, status } = await myApi.profile();
    if (status === 200) {
      dispatch(logIn(data));
    } else {
      dispatch(logOut());
    }
  } catch (err) {
    dispatch(logOut());
  }
};

export const userLogin = (form) => async (dispatch) => {
  try {
    const { data, status } = await authApi.login(form);

    if (status === 200) {
      dispatch(logIn(data));
    } else {
      dispatch(logOut());
    }
  } catch (err) {
    dispatch(logOut());
  }
};

export const userLogout = () => async (dispatch) => {
  const {
    data: { result },
  } = await authApi.logout();
  if (result === "success") {
    dispatch(logOut());
  }
};

export default userSlice.reducer;
