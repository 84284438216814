import React, { useEffect, useRef, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import AdminRoot from "../components/frame/AdminRoot";
import { Box } from "@mui/material";
import { userRankingApi } from "../api/tock";
import Loading from "../components/common/Loading";
import toast from "react-hot-toast";

const UserRanking = () => {
  const inputLikeRef = useRef(null);
  const inputFollowRef = useRef(null);
  const inputReplyRef = useRef(null);
  const inputViewRef = useRef(null);
  const inputRangeRef = useRef(null);
  const [seriesList, setSeriesList] = useState([]);
  const [range, setRange] = useState(0);

  const [rankingElement, setRankingElement] = useState(null);
  const [loading, setLoading] = useState(true);

  const [seriesLoading, setSeriesLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await userRankingApi.getUserRankingElement();
      if (result.data.range === "week") {
        setRange("1주일");
      } else if (result.data.range === "month1") {
        setRange("1개월");
      } else if (result.data.range === "month3") {
        setRange("3개월");
      } else if (result.data.range === "month6") {
        setRange("6개월");
      }
      setRankingElement(result.data);
      setLoading(false);
    };
    getData();
  }, []);

  const setRankRange = (event) => {
    if (event.target.name === "week") {
      setRange("1주일");
    } else if (event.target.name === "month1") {
      setRange("1개월");
    } else if (event.target.name === "month3") {
      setRange("3개월");
    } else if (event.target.name === "month6") {
      setRange("6개월");
    }
  };

  const sendRankingEleText = async () => {
    const likePoint = inputLikeRef.current.value;
    const followPoint = inputFollowRef.current.value;
    const replyPoint = inputReplyRef.current.value;
    const viewPoint = inputViewRef.current.value;
    const rangeValue = inputRangeRef.current.value;

    if (rangeValue == "0") {
      toast.error("범위를  선택해주세요");
      return;
    }

    let range;

    if (rangeValue === "1주일") {
      range = "week";
    } else if (rangeValue === "1개월") {
      range = "month1";
    } else if (rangeValue === "3개월") {
      range = "month3";
    } else if (rangeValue === "6개월") {
      range = "month6";
    }

    setSeriesLoading(true);
    const result = await userRankingApi.getUserRankingTest(
      likePoint,
      followPoint,
      replyPoint,
      viewPoint,
      range
    );
    setSeriesLoading(false);
    setSeriesList(result.data.seriesList);
  };

  const sendServerSetting = async () => {
    const likePoint = inputLikeRef.current.value;
    const followPoint = inputFollowRef.current.value;
    const replyPoint = inputReplyRef.current.value;
    const viewPoint = inputViewRef.current.value;
    const rangeValue = inputRangeRef.current.value;
    let range;

    if (rangeValue === "1주일") {
      range = "week";
    } else if (rangeValue === "1개월") {
      range = "month1";
    } else if (rangeValue === "3개월") {
      range = "month3";
    } else if (rangeValue === "6개월") {
      range = "month6";
    }

    await userRankingApi.setUserRankingEle(
      likePoint,
      followPoint,
      replyPoint,
      viewPoint,
      range
    );

    toast.success("유저랭킹 요소 반영되었습니다.");
  };

  if (loading) {
    return (
      <AdminRoot>
        <Loading />
      </AdminRoot>
    );
  }

  return (
    <AdminRoot>
      <Typography style={{ marginLeft: "20px" }}>
        유저가 뽑은 10 순위 (친구들이 뽑은 갓띵작!)
      </Typography>
      <Typography style={{ marginTop: "20px" }}>랭킹 요인 설정</Typography>

      <div style={{ display: "flex", marginTop: "10px" }}>
        <div style={{ display: "flex" }}>
          <Typography>좋아요</Typography>
          <TextField
            defaultValue={rankingElement?.likePoint}
            inputRef={inputLikeRef}
            style={{ marginLeft: "10px", width: "60px" }}
            type="number"
            min="1"
          />
        </div>
        <div style={{ display: "flex", marginLeft: "10px" }}>
          <Typography>팔로우수</Typography>
          <TextField
            defaultValue={rankingElement?.followPoint}
            inputRef={inputFollowRef}
            style={{ marginLeft: "10px", width: "60px" }}
            type="number"
            min="1"
          />
        </div>
        <div style={{ display: "flex", marginLeft: "10px" }}>
          <Typography>댓글합</Typography>
          <TextField
            defaultValue={rankingElement?.replyPoint}
            inputRef={inputReplyRef}
            style={{ marginLeft: "10px", width: "60px" }}
            type="number"
            min="1"
          />
        </div>
        <div style={{ display: "flex", marginLeft: "10px" }}>
          <Typography>조회수</Typography>
          <TextField
            defaultValue={rankingElement?.viewPoint}
            inputRef={inputViewRef}
            style={{ marginLeft: "10px", width: "60px" }}
            type="number"
            min="1"
          />
        </div>
        <div style={{ display: "flex", marginLeft: "10px" }}>
          <Typography>범위</Typography>
          <TextField
            defaultValue={rankingElement?.range}
            inputRef={inputRangeRef}
            value={range}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </div>
      </div>
      <Box sx={{ marginTop: "20px" }}>
        <Button variant="contained" onClick={setRankRange} name="week">
          1주일
        </Button>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="contained"
          onClick={setRankRange}
          name="month1"
        >
          1개월
        </Button>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="contained"
          onClick={setRankRange}
          name="month3"
        >
          3개월
        </Button>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="contained"
          onClick={setRankRange}
          name="month6"
        >
          6개월
        </Button>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Button variant="contained" onClick={sendRankingEleText}>
          랭킹 요인 테스트
        </Button>
        <Button
          sx={{ marginLeft: "20px" }}
          variant="contained"
          onClick={sendServerSetting}
        >
          서버 적용
        </Button>
      </Box>

      {seriesLoading === false &&
        seriesList.map((item, index) => (
          <div key={`item-${index}`}>
            <div
              style={{
                height: "50px",
                border: "1px solid #333333",
                borderRadius: "10px",
                margin: "10px",
                padding: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{`${item.name} (id:${item.id})`}</Typography>
            </div>
          </div>
        ))}
      {}
    </AdminRoot>
  );
};

export default UserRanking;
