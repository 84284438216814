import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import FormControl from "@mui/material/FormControl";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useLocation } from 'react-router-dom';
import { tockCommonApi } from "../../api/tock";
import toast from "react-hot-toast";
import moment from "moment";
import { useSelector } from "react-redux";

// 서비스 관리 > 공지사항 > 각 제목 클릭 시
function UpdateServiceNoticeBar() {
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.user);

  const [notice_seq, setNotcieSeq] = useState(location.state.state.notice_seq);
  const [registrant, setRegistrant] = useState(userInfo.name);  //작성자 input
  const [title, setTitle] = useState(location.state.state.title);  //제목 input
  const [contents, setContents] = useState(location.state.state.contents);  //내용 input
  const [updatedAt, setUpdatedAt] = useState(location.state.state.updatedAt);
  const [isShow, setIsShow] = useState(location.state.state.isShow);
  const [editor, setEditor] = useState(location.state.state.editor);

  const onRegistarntChange = (e) => {
    setRegistrant(e.target.value);
    setEditor(e.target.value);
  };

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const onContentsChange = (e) => {
    setContents(e.target.value);
  }

  const onIsShowChange = (e) => {
    setIsShow(e.target.value);
  }

  const onUpdateServiceNotice = async () => {
    if (window.confirm("수정하시겠습니까?") === false) {
      return;
    } else {
      const result = await tockCommonApi.updateServiceNotice("TOCK", title, isShow, contents, editor, updatedAt, notice_seq);

      if (result.status === 200) {
        // handleClose(true);
        setUpdatedAt(updatedAt);
        setRegistrant(editor);
        window.location.replace("/service/notice");
      } else {
        toast.error(result.data.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    }
  }

  const onDelete = async () => {
    if (window.confirm("삭제하시겠습니까?") === false) {
      return;
    } else {
      const result = await tockCommonApi.deleteServiceNotice(notice_seq);

      if (result.status === 200) {
        window.location.replace("/service/notice");
      } else {
        toast.error(result.data.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    }
  } 

  return (
    <SearchContainer>
      <Inner>
        <Cell1>작성일*</Cell1>
          <Cell2>
          <TextInput
                value={moment(new Date(updatedAt)).format('YYYY-MM-DD hh:mm')}
                name="registrant"
                autoComplete="off"
                required
                placeholder="작성일"
                disabled
              />
          </Cell2>

        <Cell1>작성자*</Cell1>
          <Cell2>
              <TextInput
                value={registrant}
                name="registrant"
                autoComplete="off"
                required
                placeholder="작성자"
                onChange={onRegistarntChange}
                disabled
              />
          </Cell2>
      </Inner>

      {/* <Inner>
        <Cell1>서비스*</Cell1>
          <Cell2 style={{ paddingLeft: "5px" }}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={location.state.state.serviceCode} onChange={onServiceCodeChange} />} label="TOCK" />
          </FormGroup>
          </Cell2>
      </Inner> */}

          <Inner>
            <Cell1 style={{ borderTop: 0, borderBottom: 0 }}>제목*</Cell1>
            <Cell2 style={{ borderTop: 0, borderBottom: 0 }}>
              <TextInput
                value={title}
                name="title"
                autoComplete="off"
                required
                placeholder="제목"
                onChange={onTitleChange}
              />
            </Cell2>
          </Inner>

          <Inner>
            <Cell1>게시여부</Cell1>
            <Cell2 style={{ paddingLeft: "5px" }}>
            <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={isShow}
                  onChange={onIsShowChange}
                >
                  <FormControlLabel value="Y" control={<Radio />} label="게시" />
                  <FormControlLabel value="N" control={<Radio />} label="비공개" />
                </RadioGroup>
            </FormControl>
            </Cell2>
          </Inner>

          <Inner>
            <Cell1 style={{height: "500px"}}>내용*</Cell1>
            <Cell2 style={{height: "500px",}}>
              <textarea
                value={contents}
                style={{width: "100%", height: "500px", resize: "none"}}
                placeholder="내용을 입력하세요."
                onChange={onContentsChange}
              />
            </Cell2>
          </Inner>
          
          <Inner style={{ margin: "20px 0" }}>
          </Inner>

          <Inner style={{ justifyContent: "center" }}>
            <Button
              color="orange"
              appearance="subtle"
              style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
              onClick={() => {
                window.location.replace("/service/notice");
              }}
            >
              취소{" "}
            </Button>
            <Button
              color="orange"
              appearance="subtle"
              style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
              onClick={() => {
                onDelete();
              }}
            >
              삭제{" "}
            </Button>
            <Button
              color="orange"
              appearance="subtle"
              style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
              onClick={onUpdateServiceNotice}
            >
              수정
            </Button>
          </Inner>
    </SearchContainer>
  );
}

const SearchContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});
const Inner = styled("div")({
  position: "relative",
  display: "flex",
});
const Inner2 = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Text = styled("p")({
  border: "1px solid black",
  width: "100px",
  height: "37px",
  marginRight: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#bdbdbd",
  color: "black",
});
const Cell1 = styled("div")({
  border: "1px solid gray",
  borderRight: "0",
  backgroundColor: "lightgray",
  width: "100px",
  height: "50px",
  paddingLeft: "10px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Cell2 = styled("div")({
  border: "1px solid gray",
  height: "50px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexGrow: 1,
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Ptag = styled("p")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  font: '400 16px "Roboto","Helvetica","Arial", sans-serif',
});
const Input = styled("input")({
  width: "40px",
  height: "35px",
  border: "1px solid #f2f2f2",
  borderRadius: "3px",
  backgroundColor: "white",
});
const TextInput = styled("input")({
  width: "100%",
  height: "40px",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  padding: "10px ",
  backgroundColor: "white",
});

UpdateServiceNoticeBar.propTypes = {
  getSeriesList: PropTypes.func.isRequired,
};

export default UpdateServiceNoticeBar;
