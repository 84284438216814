import React, { useState } from "react";
import AdminRoot from "../components/frame/AdminRoot";
import { styled } from "@material-ui/core";
import CpSearchBar from "../components/payments/CpSearchBar";
import { Button } from "rsuite";
import AddCpComanyModal from "../components/modal/AddCpComanyModal";
import { useEffect } from "react";
import { tockCommonApi } from "../api/tock";
import toast from "react-hot-toast";
import CpList from "../components/payments/CpList";

const ManageCp = () => {
  const [loading, setLoading] = useState(true);
  const [searchedCpList, setSearchedCpList] = useState([]);
  const [openCpRegister, setOpenCpRegister] = useState(false);

  const getCpList = async (startDate, endDate, division, keyword) => {
    try {
      setLoading(true);
      const { data } = await tockCommonApi.getCpList(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        division,
        keyword
      );
      setSearchedCpList(data);
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    const { status, data } = await tockCommonApi.getCpList(
      undefined,
      undefined,
      undefined,
      undefined
    );
    if (status === 200) {
      setSearchedCpList(data);
    } else {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <AdminRoot>
          <Title>CP/작가 관리</Title>
        </AdminRoot>
      ) : (
        <AdminRoot>
          <Title>CP/작가 관리</Title>
          <>
            <CpSearchBar getCpList={getCpList} />
            <Button
              appearance="primary"
              onClick={() => {
                setOpenCpRegister(true);
              }}
              style={{
                margin: "0 5px",
                backgroundColor: "#000000",
                textColor: "#FFFFFF",
              }}
            >
              CP/작가 등록
            </Button>
            <CpList searchedCpList={searchedCpList} getData={getData} />
          </>
          <AddCpComanyModal
            open={openCpRegister}
            handleClose={() => setOpenCpRegister(false)}
            getData={getData}
          />
        </AdminRoot>
      )}
    </>
  );
};

const Title = styled("div")({
  marginBottom: "20px",
});

export default ManageCp;
