import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFileUpload } from "use-file-upload";
import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { serviceApi } from "../../api/service";
import { alarm } from "../../redux/bottomSnackbarReducer";

const MyDiv = styled("div")`
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
  }

  td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
`;
const GuideDiv = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`;
const ButtonContainer = styled(Box)`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  & .MuiButton-root {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const ModifyMainBanner = ({ mainBannerId, onClose, onModified }) => {
  const dispatch = useDispatch();

  // "loading-banner-info"
  const [status, setStatus] = useState("loading-banner-info");

  const [isPublic, setIsPublic] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalOrderNumber, setOriginalOrderNumber] = useState(0);
  const [originalTitle, setOriginalTitle] = useState(null);

  const orderNumberRef = useRef();
  const titleRef = useRef();
  const [linkType, setLinkType] = useState(""); // 'series' or 'story'
  const [genreList, setGenreList] = useState([]);
  const [genreId, setGenreId] = useState("");
  const [seriesList, setSeriesList] = useState([]);
  const [seriesId, setSeriesId] = useState("");
  const [stories, setStories] = useState([]);
  const [storyId, setStoryId] = useState("");

  // origin, modify, delete
  const [imageStatus, setImageStatus] = useState("origin");
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, selectImageFile] = useFileUpload(null);
  // 기존 데이터 필요함.
  // 장르 리스트, 시리즈 리스트, 스토리 리스트
  // 저장 시, 두번 저장
  // 장르 변경 시, 시리즈 재조회,
  // 시리즈 변경 시, 스토리 재조회
  //
  useEffect(() => {
    const requestMainBanner = async () => {
      const { status, data } = await serviceApi.getMainBanner(mainBannerId);
      if (status === 200) {
        setIsPublic(data.isPublic === true);
        setStartDate(data.startDate);
        setEndDate(data.endDate);
        setOriginalOrderNumber(data.orderNumber);
        setOriginalTitle(data.title);
        setLinkType(data.linkType);

        if (data.story) {
          setStoryId(data.story.id);
          setSeriesId(data.story.series.id);
          setGenreId(data.story.series.genre.id);
        } else if (data.series) {
          setSeriesId(data.series.id);
          setGenreId(data.series.genre.id);
        }
        setImageUrl(data.imgUrl);
        setStatus("normal");
      } else {
        dispatch(
          alarm({ message: "장르를 얻는데 실패했습니다.", severity: "error" })
        );
      }
    };
    requestMainBanner();
  }, [dispatch, mainBannerId]);

  useEffect(() => {
    const requestGenres = async () => {
      const { status, data } = await serviceApi.getGenreList();
      if (status === 200) {
        setGenreList(data);
      } else {
        dispatch(
          alarm({ message: "장르를 얻는데 실패했습니다.", severity: "error" })
        );
      }
    };
    requestGenres();
  }, [dispatch]);
  useEffect(() => {
    const requestSeriesList = async (aGenreId) => {
      const { status, data } = await serviceApi.getSeriesListByGenreId(
        aGenreId
      );
      if (status === 200) {
        setSeriesList(data);
      } else {
        dispatch(
          alarm({ message: "시리즈를 얻는데 실패했습니다.", severity: "error" })
        );
      }
    };
    if (genreId) {
      requestSeriesList(genreId);
    }
  }, [dispatch, genreId]);

  useEffect(() => {
    const requestStories = async (aSeriesId) => {
      const { status, data } = await serviceApi.getStoriesBySeriesId(aSeriesId);
      if (status === 200) {
        setStories(data);
      } else {
        dispatch(
          alarm({ message: "회차를 얻는데 실패했습니다.", severity: "error" })
        );
      }
    };
    if (seriesId) {
      requestStories(seriesId);
    }
  }, [dispatch, seriesId]);

  if (status === "loading-banner-info") {
    return <> 로딩 중</>;
  }

  const handleChangeGenre = (event) => {
    const lGenreId = event.target.value;
    const genre = genreList.find((g) => g.id === lGenreId);
    if (genre) {
      setGenreId(genre.id);
    } else {
      setGenreId("");
    }

    setSeriesList([]);
    setSeriesId("");
    setStories([]);
    setStoryId("");
  };
  const handleChangeSeries = (event) => {
    const lSeriesId = event.target.value;
    const series = seriesList.find((g) => g.id === lSeriesId);
    if (series) {
      setSeriesId(series.id);
    } else {
      setSeriesId("");
    }
    setStories([]);
    setStoryId("");
  };

  const handleChangeStory = (event) => {
    const lStoryId = event.target.value;
    const story = stories.find((g) => g.id === lStoryId);
    if (story) {
      setStoryId(story.id);
    } else {
      setStoryId("");
    }
  };
  const handleClickSave = () => {
    const requestSave = async (info) => {
      const infoRet = await serviceApi.updateMainBannerInfo(mainBannerId, info);
      if (infoRet.status === 200) {
        // 정보 저장 완료됨.
        if (imageStatus === "modify") {
          // 파일 변경
          const fd = new FormData();
          fd.append("mainBannerId", mainBannerId);
          fd.append("mainBannerImageFile", imageFile.file);

          const { status } = await serviceApi.updateMainBannerImageFile(fd);
          if (status === 200) {
            onModified();
          } else {
            dispatch(
              alarm({
                message: "이미지 저장에 실패했습니다.",
                severity: "error",
              })
            );
          }
        } else if (imageStatus === "delete") {
          const { status } = await serviceApi.deleteMainBannerImageFile(
            mainBannerId
          );
          if (status === 200) {
            onModified();
          } else {
            dispatch(
              alarm({
                message: "이미지 삭제에 실패했습니다.",
                severity: "error",
              })
            );
          }
        } else {
          onModified();
        }
      } else {
        dispatch(alarm({ message: "저장에 실패했습니다.", severity: "error" }));
      }
    };
    const info = { isPublic, startDate, endDate, linkType, seriesId, storyId };

    if (titleRef && titleRef.current) {
      info.title = titleRef.current.value;
    }

    if (info.seriesId === "") {
      info.seriesId = null;
    }

    if (info.storyId === "") {
      info.storyId = null;
    }

    if (orderNumberRef && orderNumberRef.current) {
      let orderNumber = parseInt(orderNumberRef.current.value, 10) || null;
      if (orderNumber <= 0) {
        orderNumber = null;
      }
      info.orderNumber = orderNumber;
    }

    requestSave(info);
  };
  const handleChangeStart = (value) => {
    setStartDate(value);
  };
  const handleChangeEnd = (value) => {
    setEndDate(value);
  };
  const handleChangeLinkType = (event) => {
    setLinkType(event.target.value);
  };
  const handleClickSelectImageFile = () => {
    selectImageFile({ accept: "image/*" }, ({ source, name, size, file }) => {
      setImageStatus("modify");
    });
  };
  const handleClickImageDelete = () => {
    if (imageUrl) {
      setImageStatus("delete");
    }
  };

  const getImageComp = () => {
    if (imageStatus === "origin") {
      if (!imageUrl)
        return (
          <>
            <div>서버에 적용된 이미지가 없습니다.</div>
            <Button
              variant="contained"
              onClick={() => handleClickSelectImageFile()}
            >
              이미지 선택하기
            </Button>
          </>
        );
      return (
        <>
          <img src={imageUrl} alt="preview" style={{ maxHeight: "150px" }} />
          <Button
            variant="contained"
            onClick={() => handleClickSelectImageFile()}
          >
            이미지 선택하기
          </Button>
          <Button variant="contained" onClick={() => handleClickImageDelete()}>
            이미지 지우기
          </Button>
        </>
      );
    }
    if (imageStatus === "modify") {
      return (
        <>
          <img
            src={imageFile.source}
            alt="preview"
            style={{ maxHeight: "150px" }}
          />
          <Button
            variant="contained"
            onClick={() => handleClickSelectImageFile()}
          >
            이미지 선택하기
          </Button>
          <Button variant="contained" onClick={() => handleClickImageDelete()}>
            이미지 지우기
          </Button>
        </>
      );
    }
    if (imageStatus === "delete") {
      return (
        <>
          <Button
            variant="contained"
            onClick={() => handleClickSelectImageFile()}
          >
            이미지 선택하기
          </Button>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <MyDiv>
        <GuideDiv>배너 생성을 위한 정보를 입력해 주세요. </GuideDiv>

        <div>저장 버튼 선택 후 서버에 저장됩니다.</div>

        <table aria-label="메인 배너 수정하기">
          <tbody>
            <tr>
              <td>공개 여부</td>
              <td>
                <Checkbox
                  checked={isPublic}
                  onClick={(event) => {
                    event.preventDefault();
                    setIsPublic(!isPublic);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                공개
              </td>
            </tr>
            <tr>
              <td>노출 기간</td>
              <td>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="시작"
                      value={startDate}
                      onChange={handleChangeStart}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ marginRight: "10px" }} />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="종료"
                      value={endDate}
                      onChange={handleChangeEnd}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </td>
            </tr>
            <tr>
              <td>노출순서</td>
              <td>
                <TextField
                  type="number"
                  inputRef={orderNumberRef}
                  label="노출순서"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={originalOrderNumber}
                />
              </td>
            </tr>
            <tr>
              <td>배너 제목</td>
              <td>
                <TextField
                  id="standard-basic"
                  label="배너 제목"
                  // variant="standard"
                  multiline
                  maxRows={4}
                  fullWidth
                  inputRef={titleRef}
                  defaultValue={originalTitle}
                />
              </td>
            </tr>
            <tr>
              <td rowSpan="2">랜딩 URL</td>
              <td>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    value={linkType}
                    onChange={handleChangeLinkType}
                  >
                    <FormControlLabel
                      value="series"
                      control={<Radio />}
                      label="메인"
                    />
                    <FormControlLabel
                      value="story"
                      control={<Radio />}
                      label="작품"
                    />
                  </RadioGroup>
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      장르
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={genreId}
                      onChange={handleChangeGenre}
                      autoWidth
                      label="장르"
                    >
                      {genreList.map((genre, index) => {
                        return (
                          <MenuItem value={genre.id} key={genre.name}>
                            {genre.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      작품
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={seriesId}
                      onChange={handleChangeSeries}
                      autoWidth
                      label="작품"
                    >
                      {seriesList.map((series, index) => {
                        return (
                          <MenuItem value={series.id} key={series.name}>
                            {series.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      회차
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={storyId}
                      onChange={handleChangeStory}
                      autoWidth
                      label="회차"
                    >
                      {stories.map((story, index) => {
                        return (
                          <MenuItem value={story.id} key={story.name}>
                            {story.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </td>
            </tr>
            <tr>
              <td>이미지</td>
              <td>{getImageComp()}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr></tr>
          </tfoot>
        </table>
        <ButtonContainer>
          <Button variant="outlined" onClick={() => onClose()}>
            취소
          </Button>
          <Button variant="contained" onClick={() => handleClickSave()}>
            저장
          </Button>
        </ButtonContainer>
      </MyDiv>
    </>
  );
};

ModifyMainBanner.propTypes = {
  mainBannerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onModified: PropTypes.func.isRequired,
};

export default ModifyMainBanner;
