import React, { useState } from "react";
import { useEffect } from "react";
import { useTokensApi } from "../api/payments";
import AdminRoot from "../components/frame/AdminRoot";
import toast from "react-hot-toast";
import { styled } from "@material-ui/core";
import SettleSearchBar from "../components/payments/SettleSearchBar";
import { convertDate } from "../components/lib/utils";
import SettleList from "../components/payments/SettleList";
import ExcelDownOfSettle from "../components/Statistics/ExcelDown_Settle";

const Settle = () => {
  const [loading, setLoading] = useState(true);
  const [searchedList, setSearchedList] = useState([]);

  const getSettleList = async (date, division, keyword) => {
    try {
      setLoading(true);
      const { data } = await useTokensApi.getSettleList(
        convertDate(date).format("YYYY-MM"),
        division,
        keyword
      );
      setSearchedList(data);
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    const { status, data } = await useTokensApi.getSettleList(
      undefined,
      undefined,
      undefined
    );

    if (status === 200) {
      setSearchedList(data);
    } else {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <AdminRoot>
          <Title>정산내역</Title>
          <SettleSearchBar getSettleList={getSettleList} />
        </AdminRoot>
      ) : (
        <AdminRoot>
          <Title>정산내역</Title>
          <>
            <SettleSearchBar getSettleList={getSettleList} />
            <ExcelDownOfSettle apiResults={searchedList} />
            <SettleList searchedList={searchedList} />
          </>
        </AdminRoot>
      )}
    </>
  );
};

const Title = styled("div")({
  marginBottom: "20px",
});

export default Settle;
