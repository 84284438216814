import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { setToggle } from "../../redux/toggleSlice";
import { drawerWidth } from "../../assets/jss/config";
import { userLogout } from "../../redux/userSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
    height: "64px",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "rgba(0, 0, 0, 0.87)",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    fontFamily: "NotoSerifKR",
    flexGrow: 1,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "24px",
    fontWeight: "700",
  },
}));

const AdminAppBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onClickLogout = async () => {
    // todo logout
    dispatch(userLogout());
  };
  return (
    <AppBar position="absolute" className={classNames(classes.appBar)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          aria-label="open drawer"
          onClick={() => dispatch(setToggle())}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          noWrap
          className={classes.title}
        >
          TOCK - 관리자 페이지
        </Typography>
        <IconButton onClick={onClickLogout}>
          <ExitToApp />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AdminAppBar;
