import React from "react";
import { useState } from "react";
import AdminRoot from "../components/frame/AdminRoot";
import { styled } from "@material-ui/core";
import Loading from "../components/common/Loading";
import toast from "react-hot-toast";
import moment from "moment";
import { statisticsApi } from "../api/statistics";
import SearchBarOfReply from "../components/Statistics/SearchBar_Reply";
import ExcelDownOfReply from "../components/Statistics/ExcelDown_Reply";
import TableOfReply from "../components/Statistics/Table_Reply";

function StatisticsReply() {
  // state
  const [apiResults, setApiResults] = useState(null);
  const [loading, setLoading] = useState(false);

  // 자식 검색 컴포넌트에서 startAt, endAt, searchKey 파라미터로 넘겨받아 api 호출
  const getReply = async (startAt, endAt, searchKey) => {
    const stringifiedStartAt = moment(startAt).format("YYYY-MM-DD");
    const stringifiedEndAt = moment(endAt).format("YYYY-MM-DD");

    if (searchKey === undefined) {
      searchKey = "";
    }

    try {
      setLoading(true);
      const { status, data } = await statisticsApi.getReply(
        stringifiedStartAt,
        stringifiedEndAt,
        searchKey
      );
      if (status === 200) {
        setApiResults(data);
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const isAdminHide = () => {
    getReply(new Date("2022-01-01"), new Date(), undefined);
  };

  return (
    <AdminRoot>
      <Title>댓글 조회</Title>
      <SearchBarOfReply getReply={getReply} setApiResults={setApiResults} />
      {loading && <Loading />}
      {apiResults === null ? (
        <>
          <Empty>조회된 결과가 없습니다.</Empty>
        </>
      ) : (
        <>
          <ExcelDownOfReply apiResults={apiResults} />
          <TableOfReply apiResults={apiResults} isAdminHide={isAdminHide} />
        </>
      )}
    </AdminRoot>
  );
}

const Title = styled("div")({
  marginBottom: "20px",
});
const Empty = styled("div")({
  maxWidth: "1024px",
  height: "400px",
  border: "1px solid lightgray",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default StatisticsReply;
