import React, { useCallback, useState, useEffect } from "react";
import moment from "moment";
import { styled } from "@material-ui/core";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UseTokenCpListModal from "../modal/UseTokenCpListModal";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

function UseTokenListCp({ searchedCpTokensList, totalCnt, pageNum, rowsPerPageNum }) {
  //PAGING
  const page= useState(0);
  const rowsPerPage = useState(10);
  const dataLength = searchedCpTokensList.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;

  const [sumVal, setSumVal] = useState(100);

  const calculateSum = useCallback(
    (list) => {
      let sum = 0
      for (let i = dataLength - 1; i >= 0; i--) {
        if (
          list[i].tknClCd === "PAY" &&
          list[i].payClCd === 0
        ) {
          sum = sum + list[i].token * 120;
        }
      }
      setSumVal(sum);
    },
    [dataLength]
  );

  //modal
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [clickRow, setClickRow] = useState(null);

  useEffect(() => {
    if (searchedCpTokensList.length !== 0) {
      calculateSum(searchedCpTokensList);
    }else{
      setSumVal(0);
    }
  }, [searchedCpTokensList, calculateSum]);

  return (
    <ListContainer>
      <Inner>
        <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontSize: "15px",
              margin: "0px 12px",
              paddingRight: "20px",
              marginBottom: "12px",
              fontWeight: 500,
              letterSpacing: "-1px",
              lineHeight: "1.6",
              textAlign: "right",
            }}
          >
            전체{" "}
            <span style={{ color: "orange" }}>
              {totalCnt.toLocaleString()}
            </span>{" "}
            건
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#e0e0e0",
                  height: "60px",
                }}
              >
                <TableCell
                  sx={{ width: "3%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  No
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  CP ID
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  CP
                </TableCell>
                <TableCell
                  sx={{
                    width: "12%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  작품명
                </TableCell>
                <TableCell
                  sx={{
                    width: "12%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  회차명
                </TableCell>
                <TableCell
                  sx={{
                    width: "5%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  토큰
                </TableCell>
                <TableCell
                  sx={{
                    width: "5%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  금액
                </TableCell>
                <TableCell
                  sx={{
                    width: "12%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  차감/취소 기간
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  OS
                </TableCell>
                <TableCell
                  sx={{ width: "7%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  토큰종류
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  결제상태
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  상세내역
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(searchedCpTokensList
              ).map((data, index) => (
                <TableRow key={totalCnt - (pageNum * rowsPerPageNum) - index}>
                  <TableCell align="center">
                    {totalCnt - (pageNum * rowsPerPageNum) - index}
                  </TableCell>
                  <TableCell align="center">{data.cpId}</TableCell>
                  <TableCell align="center">{data.cpName}</TableCell>
                  <TableCell align="center">{data.seriesName}</TableCell>
                  <TableCell align="center">{data.storyName}</TableCell>
                  <TableCell align="center">{data.token}</TableCell>
                  <TableCell align="center">
                    {data.tknClCd === "PAY" ? data.amount : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {moment(data.dt).startOf("month").format("YYYY-MM-DD")} ~{" "}
                    <br />
                    {moment(data.dt).endOf("month").format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="center">
                    {data.tknClCd === "PAY" ? data.device : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {data.tknClCd === "PAY" ? "구매" : "이벤트"}
                  </TableCell>
                  <TableCell align="center">
                    {data.payClCd === 0 ? (
                      "정상"
                    ) : data.payClCd === 1 ? (
                      <span style={{ color: "red" }}>취소완료</span>
                    ) : (
                      <span style={{ color: "red" }}>취소</span>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ textDecoration: "underline" }}
                    onClick={() => {
                      if (data.payClCd === 0 || data.payClCd === 2) {
                        setOpenDetailModal(true);
                        setClickRow(data);
                      }
                    }}
                  >
                    [사용내역]
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ backgroundColor: "#e0e0e0", height: "40px" }}>
                <TableCell
                  colSpan={11}
                  align="right"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  총 금액 :
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  {sumVal.toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <UseTokenCpListModal
          open={openDetailModal}
          handleClose={() => setOpenDetailModal(false)}
          clickRow={clickRow}
        />
      </Inner>
    </ListContainer>
  );
}

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "block", //flex
  justifyContent: "center",
  alignItems: "center",
});

UseTokenListCp.propTypes = {
  searchedCpTokensList: PropTypes.array,
};

export default UseTokenListCp;
