import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { offAlarm } from "../../redux/bottomSnackbarReducer";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BottomSnackbar = () => {
  const dispatch = useDispatch();
  const bottomSnackbar = useSelector((state) => state.bottomSnackbar);

  const handleClose = (event, reason) => {
    dispatch(offAlarm());
  };
  return (
    <>
      {bottomSnackbar.open ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={bottomSnackbar.severity}
            sx={{ width: "100%" }}
          >
            {bottomSnackbar.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default BottomSnackbar;
