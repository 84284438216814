import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFileUpload } from "use-file-upload";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import TitleSimple from "../common/TitleSimple";
import SelectItems from "../common/SelectItems";
import { studioMetaApi } from "../../api/studio";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    margin: theme.spacing(3, 0, 5),
    backgroundColor: theme.palette.custom.black,
    color: theme.palette.custom.white,
  },
}));

const AddResourceModal = ({ open, handleClose, studioMenuId, items }) => {
  const classes = useStyles();
  const inputTitle = useRef(null);
  const inputEtc = useRef(null);
  const [file, selectFile] = useFileUpload(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(items[0]);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const onAdd = async (event) => {
    event.preventDefault();
    const name = inputTitle.current.value;
    if (file === null || !name) {
      toast.error("파일 선택 혹은 이름을 입력하시오.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    const fd = new FormData();
    fd.append("name", inputTitle.current.value);
    fd.append("etc", inputEtc.current.value);
    fd.append("isPublic", true);
    fd.append("file", file.file);

    const result = await studioMetaApi.addStudioResource(
      studioMenuId,
      selectedSubMenu.id,
      fd
    );
    if (result) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const onChangeSubMenu = (type) => {
    const findItem = items.find((item) => item.id === type);
    setSelectedSubMenu(findItem);
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>리소스 추가</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectItems
                items={items}
                onChange={onChangeSubMenu}
                defaultItem={items[0]?.id}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputTitle}
                id="name"
                label="이름"
                name="name"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputEtc}
                id="etc"
                label="비고"
                name="etc"
                autoFocus
              />
              <Button
                variant="contained"
                onClick={() => {
                  if (selectedSubMenu.type === 1) {
                    selectFile({ accept: "image/*" });
                  } else if (selectedSubMenu.type === 2) {
                    selectFile({ accept: "audio/*" });
                  }
                }}
              >
                파일 업로드
              </Button>
              {file ? (
                <div>
                  {selectedSubMenu.type === 1 ? (
                    <div>
                      <img
                        src={file.source}
                        alt="preview"
                        style={{ maxHeight: "150px" }}
                      />
                      <div>
                        <span> 파일 이름: {file.name} </span>
                        <span> Size: {file.size} </span>
                      </div>
                    </div>
                  ) : null}
                  {selectedSubMenu.type === 2 ? (
                    <div style={{ marginTop: "10px" }}>
                      <audio controls>
                        <source src={file.source} />
                      </audio>
                      <div>
                        <span> 파일 이름: {file.name} </span>
                        <span> Size: {file.size} </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <span> 파일 선택</span>
              )}
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} sm={6}>
              <Button
                className={classes.submit}
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                onClick={onAdd}
              >
                업로드
              </Button>
            </Grid>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddResourceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddResourceModal;
