import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
// import { userApi } from "../api/global";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import toast from "react-hot-toast";
import Loading from "../components/common/Loading";
import Popover from "@mui/material/Popover";
import { blackUserApi, userApi } from "../api/global";
import TablePaginationActions from "../components/common/TablePaginationActions";
import BlackUserRegiPopover from "./BlackUserRegiPopover";
import BlackUserModiPopover from "./BlackUserModiPopover";

function BlackUsersList({ loading, searchedBlackUsers, getBlackUsersDefault }) {
  // state 모음
  const [checkedUser, setCheckedUser] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElModify, setAnchorElModify] = React.useState(null);
  const open = Boolean(anchorEl);
  const openModify = Boolean(anchorElModify);
  const [allUser, setAllUser] = useState(null);
  const [modifyUser, setModifyUser] = useState(null);

  // console.log("allUser :", allUser);

  // '블랙리스트 등록' 팝오버 클릭시 '회원ID' 불러오기 api
  const autoUserIdCall = async () => {
    const { status, data } = await userApi.list();
    if (status === 200) {
      setAllUser(
        data.map((user) => {
          return { label: user.nickname, id: user.id };
        })
      );
    }
  };

  // '블랙리스트 등록' 버튼 클릭 Popover 이벤트
  const onBlackUserRegistry = (event) => {
    event.preventDefault();
    setAnchorEl(event.target.parentElement);
    autoUserIdCall();
  };
  // '블랙리스트 등록' Popover 닫기
  const handleClose = () => {
    setAnchorEl(null);
    setCheckedUser([]);
  };

  // 렌더링 된 리스트 체크박스로 checkedUser에 넣고 빼기 이벤트
  const onSingleCheck = (checked, idx, id, data) => {
    if (checked) {
      setCheckedUser([...checkedUser, searchedBlackUsers[idx]]);
      setModifyUser(data);
    } else {
      setCheckedUser(checkedUser.filter((obj) => obj.id !== id));
      setModifyUser(null);
    }
  };

  const alertMultiCheck = () => {
    if (checkedUser.length >= 2) {
      alert("한 명만 선택해 주십시오");
      setCheckedUser([]);
    }
  };
  useEffect(alertMultiCheck, [checkedUser]);

  // 테이블 페이지네이션 관련 내용
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - searchedBlackUsers?.length)
      : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // '블랙리스트 수정' 버튼 클릭 Popover 이벤트
  const onBlackUserModify = (event) => {
    event.preventDefault();
    setAnchorElModify(event.target.parentElement);
  };
  // '블랙리스트 수정' Popover 닫기
  const handleCloseModify = () => {
    setAnchorElModify(null);
    setCheckedUser([]);
  };

  // '블랙리스트 삭제' 버튼 클릭 핸들러
  const onRemoveBlackUser = () => {
    if (
      window.confirm(
        `${checkedUser[0].id}의 블랙리스트 등록을 삭제하시겠습니까?`
      )
    ) {
      const removeBlackUser = async () => {
        await blackUserApi.blackUserRemove(checkedUser[0].id);
      };
      removeBlackUser();
      setCheckedUser([]);
      getBlackUsersDefault();
    }
  };

  return (
    <ListContainer>
      <Inner style={{ justifyContent: "flex-end" }}>
        <Button
          style={{ backgroundColor: "black", color: "white" }}
          onClick={onBlackUserRegistry}
        >
          블랙리스트 등록
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          elevation={1}
        >
          <BlackUserRegiPopover
            handleClose={handleClose}
            getBlackUsersDefault={getBlackUsersDefault}
            allUser={allUser}
          />
        </Popover>
      </Inner>
      <Inner>
        {loading ? (
          <div style={{ height: "300px" }}>
            <Loading />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#e0e0e0", height: "60px" }}>
                  <TableCell sx={{ width: "7%" }} align="center">
                    선택
                  </TableCell>
                  <TableCell sx={{ width: "7%" }} align="center">
                    No
                  </TableCell>
                  <TableCell sx={{ width: "7%" }} align="center">
                    회원ID
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    메일
                  </TableCell>
                  <TableCell sx={{ width: "7%" }} align="center">
                    필명
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    회원타입
                  </TableCell>
                  <TableCell sx={{ width: "12%" }} align="center">
                    기능제한
                  </TableCell>
                  <TableCell
                    sx={{ width: "20%", wordBreak: "keep-all" }}
                    align="center"
                  >
                    적용기간
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    사유
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedBlackUsers.length < 1 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={9}
                      sx={{ padding: "15px 0" }}
                    >
                      조회결과가 없습니다.
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {(rowsPerPage > 0
                      ? searchedBlackUsers.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : searchedBlackUsers
                    ).map((user, idx) => (
                      <TableRow
                        key={user.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" sx={{ textAlign: "center" }}>
                          <CheckBtn
                            id={user.id}
                            name={idx}
                            type="checkbox"
                            onChange={(e) =>
                              onSingleCheck(
                                e.target.checked,
                                idx,
                                user.id,
                                user
                              )
                            }
                            checked={
                              checkedUser?.find((elm) => elm.id === user.id)
                                ? // eslint-disable-next-line no-unneeded-ternary
                                  true
                                : false
                            }
                          />
                        </TableCell>
                        <TableCell align="center">{user.id}</TableCell>
                        <TableCell align="center">{user.userId}</TableCell>
                        <TableCell align="center">
                          {user?.user?.mail ?? "None"}
                        </TableCell>
                        <TableCell align="center">
                          {user?.user?.nickname ?? "None"}
                        </TableCell>
                        <TableCell align="center">
                          {user?.user?.series.length > 0 ? "작가" : "독자"}
                        </TableCell>
                        <TableCell align="center">
                          {user.limitType === 1 && "댓글 등록"}
                          {user.limitType === 2 && "작품 등록"}
                          {user.limitType === 3 && "계정 정지"}
                        </TableCell>
                        <TableCell align="center">
                          {moment(user?.startAt).format("YYYY.MM.DD")} ~{" "}
                          {moment(user?.endAt).format("YYYY.MM.DD")}
                        </TableCell>
                        <TableCell align="center">{user.reason}</TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={9}
                    count={searchedBlackUsers?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Inner>
      <Inner style={{ justifyContent: "flex-start" }}>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onBlackUserModify}
          disabled={checkedUser?.length < 1 || checkedUser.length >= 2}
        >
          수정{" "}
        </Button>
        <Popover
          open={openModify}
          anchorEl={anchorElModify}
          onClose={handleCloseModify}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          elevation={1}
        >
          <BlackUserModiPopover
            checkedUser={checkedUser}
            handleCloseModify={handleCloseModify}
            getBlackUsersDefault={getBlackUsersDefault}
            setCheckedUser={setCheckedUser}
            user={modifyUser}
          />
        </Popover>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onRemoveBlackUser}
          disabled={checkedUser?.length < 1 || checkedUser.length >= 2}
        >
          삭제{" "}
        </Button>
      </Inner>
    </ListContainer>
  );
}

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});
const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

BlackUsersList.defaultProps = {
  searchedBlackUsers: [],
};

BlackUsersList.propTypes = {
  loading: PropTypes.bool.isRequired,
  searchedBlackUsers: PropTypes.array,
  getBlackUsersDefault: PropTypes.func.isRequired,
};

const CheckBtn = styled("input")({
  display: "inline-block",
  width: "20px",
  height: "20px",
  border: "1px solid #3EA4DD",
});

export default BlackUsersList;
