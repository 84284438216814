import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button } from "@material-ui/core";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import "../../assets/css/dateRangePicker.css";
import { userApi } from "../../api/global";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect } from "react";

function AddTokenTable({ getRegisterList, handleClose }) {
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [note, setNote] = useState("");
  const [endAt, setEndAt] = React.useState(moment());

  const onTokenChange = (e) => {
    setToken(e.target.value);
  };
  const onNoteChange = (e) => {
    setNote(e.target.value);
  };
  const handleEndAtChange = (newValue) => {
    setEndAt(newValue);
  };

  const addList = async (event) => {
    event.preventDefault();

    if (userId.length === 0) {
      toast.error("회원 ID를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (token.length === 0 || token.trim().length === 0) {
      toast.error("지급 토큰을 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (note.length === 0 || note.trim().length === 0) {
      toast.error("적요를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      //회원 ID, 지급 토큰 값 리셋
      setUserId("");
      setToken("");

      const sendData = {
        userId: userId.id,
        userNickname: userId.nickname,
        token: token,
        expDdStart: new Date(),
        expDdEnd: endAt,
        note: note,
      };

      getRegisterList(sendData);
    }
  };

  useEffect(() => {
    const getUserList = async () => {
      try {
        const { status, data } = await userApi.findUserList();
        if (status === 200) {
          setUserList(
            data.map((item) => {
              return {
                label: `${item.id}. ${item.nickname}`,
                id: item.id,
                nickname: item.nickname,
              };
            })
          );
        }
      } catch (error) {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    };
    getUserList();
  }, []);

  return (
    <>
      <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
        <Table
          sx={{
            marginTop: "30px",
            marginBottom: "30px",
            padding: "10px",
          }}
          align="center"
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                }}
                colSpan={1}
              >
                회원 ID *{" "}
              </TableCell>
              <TableCell
                sx={{ border: 1, borderColor: "grey.500" }}
                colSpan={1}
              >
                <Autocomplete
                  disablePortal
                  options={userList}
                  renderInput={(params) => (
                    <TextField {...params} label="회원ID" />
                  )}
                  sx={{ width: 195 }}
                  onChange={(event, value) => setUserId(value)}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                }}
                colSpan={1}
              >
                지급 토큰 *{" "}
              </TableCell>
              <TableCell
                sx={{ border: 1, borderColor: "grey.500" }}
                colSpan={1}
              >
                <TextField
                  variant="standard"
                  fullWidth
                  placeholder="1~999 숫자 입력 가능"
                  onChange={onTokenChange}
                  value={token}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                }}
              >
                유효기간(종료) *
              </TableCell>
              <TableCell
                sx={{ border: 1, borderColor: "grey.500" }}
                colSpan={3}
              >
                <DesktopDatePicker
                  inputFormat="yyyy-MM-DD"
                  defaultValue={endAt}
                  value={endAt}
                  onChange={handleEndAtChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                }}
                colSpan={1}
              >
                적요 *{" "}
              </TableCell>
              <TableCell
                colSpan={3}
                sx={{ border: 1, borderColor: "grey.500" }}
              >
                <TextField
                  variant="standard"
                  fullWidth
                  placeholder="내용을 등록하세요."
                  onChange={onNoteChange}
                  value={note}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Button
          variant="contained"
          style={{
            width: "100px",
            marginRight: "10px",
          }}
          onClick={handleClose}
        >
          취소
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#F0A619",
            color: "#FFF",
            width: "100px",
            marginLeft: "10px",
          }}
          onClick={addList}
        >
          추가
        </Button>
      </div>
    </>
  );
}

export default AddTokenTable;
