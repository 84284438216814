import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Button } from "rsuite";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    //border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1400px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const SettleDetailModal = ({ open, handleClose, data }) => {
  const classes = useStyles();

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  //토큰 가격
  const tokenPrice = 120;
  //수수료
  const commission = 15;

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>CP/작가 정산내역</Typography>
        <TableContainer>
          <Table sx={{ width: "1300px", marginTop: "30px" }} align="center">
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  align="left"
                >
                  정산대상기간
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                >
                  {moment(new Date(data.period))
                    .startOf("month")
                    .format("YYYY-MM-DD")}{" "}
                  ~{" "}
                  {moment(new Date(data.period))
                    .endOf("month")
                    .format("YYYY-MM-DD")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  align="left"
                >
                  CP/작가
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  align="left"
                >
                  {data.cpName}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  backgroundColor: "#e0e0e0",
                }}
              >
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  총결제금액
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  취소금액
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  월매출
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  수수료율
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  인앱수수료
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  매출금액
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  정산율
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  정산금액
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="left"
                >
                  구글 스토어
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.andUseToken * tokenPrice +
                    data.andCancelToken * tokenPrice
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(data.andCancelToken * tokenPrice)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(data.andUseToken * tokenPrice)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {commission}%
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {((data.andUseToken * tokenPrice * commission) / 100)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.andUseToken * tokenPrice -
                    (data.andUseToken * tokenPrice * commission) / 100
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {data.cpRS}%
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    ((data.andUseToken * tokenPrice -
                      (data.andUseToken * tokenPrice * commission) / 100) *
                      data.cpRS) /
                    100
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  align="left"
                >
                  애플 앱스토어
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.iosUseToken * tokenPrice +
                    data.iosCancelToken * tokenPrice
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(data.iosCancelToken * tokenPrice)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(data.iosUseToken * tokenPrice)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {commission}%
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {((data.iosUseToken * tokenPrice * commission) / 100)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.iosUseToken * tokenPrice -
                    (data.iosUseToken * tokenPrice * commission) / 100
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {data.cpRS}%
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    ((data.iosUseToken * tokenPrice -
                      (data.iosUseToken * tokenPrice * commission) / 100) *
                      data.cpRS) /
                    100
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  backgroundColor: "#e0e0e0",
                }}
              >
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  합계
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.andUseToken * tokenPrice +
                    data.andCancelToken * tokenPrice +
                    (data.iosUseToken * tokenPrice +
                      data.iosCancelToken * tokenPrice)
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.andCancelToken * tokenPrice +
                    data.iosCancelToken * tokenPrice
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.andUseToken * tokenPrice +
                    data.iosUseToken * tokenPrice
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    (data.andUseToken * tokenPrice * commission) / 100 +
                    (data.iosUseToken * tokenPrice * commission) / 100
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    data.andUseToken * tokenPrice -
                    (data.andUseToken * tokenPrice * commission) / 100 +
                    (data.iosUseToken * tokenPrice -
                      (data.iosUseToken * tokenPrice * commission) / 100)
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    fontFamily: "NotoSansKR",
                    fontSize: "16px",
                  }}
                  align="right"
                >
                  {(
                    ((data.andUseToken * tokenPrice -
                      (data.andUseToken * tokenPrice * commission) / 100) *
                      data.cpRS) /
                      100 +
                    ((data.iosUseToken * tokenPrice -
                      (data.iosUseToken * tokenPrice * commission) / 100) *
                      data.cpRS) /
                      100
                  )
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            style={{ backgroundColor: "grey", color: "white" }}
            onClick={handleCloseModal}
          >
            확인
          </Button>
        </div>
      </div>
    </Modal>
  );
};

SettleDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SettleDetailModal;
