import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, styled } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import Loading from "../common/Loading";
import { tockCommonApi } from "../../api/tock";
import { Paper, Typography, TableContainer } from "@mui/material";
import { Button } from "@material-ui/core";
import { Textarea } from "@mui/joy";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    width: "800px",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  paper: {
    //border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1300px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const UpdateContentModal = ({ open, handleClose, seriesId, storyId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [seriesData, setSeriesData] = useState([]); //작품 상세 정보
  const [genreData, setGenreData] = useState("");
  const [data, setData] = useState([]); //회차 리스트 정보
  const [searchedList, setSearchedList] = useState([]);
  const [contents, setContents] = useState("");

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const handleUpdateContent = (e) => {
    setContents(e.target.value);
  };

  useEffect(() => {
    const getContents = async () => {
      try {
        if (storyId !== 0) {
          const { status, data } = await tockCommonApi.getContents(storyId);
          if (status === 200) {
            let tmp1 = JSON.parse(data.contents[0].content);
            let tmp2 = JSON.stringify(tmp1, null, 4);
            setContents(tmp2);
          }
        }
      } catch (error) {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    };
    getContents();
  }, [storyId]);

  const saveContent = async () => {
    const result = await tockCommonApi.updateContent(
      contents,
      seriesId,
      storyId
    );

    if (result.status === 200) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const getData = async () => {
    if (seriesId !== 0) {
      setLoading(true);
      const result = await tockCommonApi.getDetailSeries(seriesId);
      if (result.status === 200) {
        setData(result.data.stories);
        setSeriesData(result.data);
        setSearchedList(result.data.stories);
        setGenreData(result.data.genre.name);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [seriesId]);

  if (loading) {
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <Loading />
    </Modal>;
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>작품 파일 수정</Typography>
        <textarea
          style={{ width: "752px", height: "450px", overflow: "auto", resize: "none" }}
          value={contents}
          onChange={handleUpdateContent}
        ></textarea>

        <ButtonContainer>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              handleClose(true);
            }}
          >
            취소
          </Button>
          <Button
            className={classes.editButton}
            onClick={() => {
              saveContent();
            }}
          >
            저장
          </Button>
        </ButtonContainer>
      </div>
    </Modal>
  );
};

const ButtonContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  height: "50px",
  border: "1px solid #f2f2f2",
  alignItems: "center",
});

UpdateContentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UpdateContentModal;
