import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { styled } from "@material-ui/core";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function UseTokenListUsers({ searchedUseTokensList, totalCnt, pageNum, rowsPerPageNum }) {

  //PAGING
  const page = useState(0);
  const rowsPerPage = useState(10);
  const dataLength = searchedUseTokensList.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;

  const [sumVal, setSumVal] = useState(100);

  const calculateSum = useCallback(
    (list) => {
      let sum = 0
      for (let i = dataLength - 1; i >= 0; i--) {
        if (
          list[i]["holdTokens.tknClCd"] === "PAY" &&
          list[i]["payments.payClCd"] === "0" &&
          list[i].useType === "STORY"
        ) {
          sum = sum + list[i].useToken * 120;
        }
      }
      setSumVal(sum);
    },
    [dataLength]
  );

  useEffect(() => {
    if (searchedUseTokensList.length !== 0) {
      calculateSum(searchedUseTokensList);
    }else{
      setSumVal(0);
    }
  }, [searchedUseTokensList, calculateSum]);

  return (
    <ListContainer>
      <Inner>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "15px",
            margin: "0px 12px",
            paddingRight: "20px",
            marginBottom: "12px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
            textAlign: "right",
          }}
        >
          전체{" "}
          <span style={{ color: "orange" }}>
            {totalCnt.toLocaleString()}
          </span>{" "}
          건
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#e0e0e0",
                  height: "60px",
                }}
              >
                <TableCell
                  sx={{ width: "3%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  No
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  주문번호
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  회원ID
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  프로필명
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  CP
                </TableCell>
                <TableCell
                  sx={{
                    width: "12%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  작품명
                </TableCell>
                <TableCell
                  sx={{
                    width: "12%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  회차명
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  토큰사용
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  사용금액
                </TableCell>
                <TableCell
                  sx={{ width: "7%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  토큰 차감일/취소일
                </TableCell>
                <TableCell
                  sx={{ width: "3%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  OS
                </TableCell>
                <TableCell
                  sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  토큰종류
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  결제상태
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  상세내역
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(searchedUseTokensList
              ).map((data, index) => (
                <TableRow key={data.id}>
                  <TableCell align="center">
                    {totalCnt - (pageNum * rowsPerPageNum) - index}
                  </TableCell>
                  <TableCell align="center">{data.id}</TableCell>
                  <TableCell align="center">{data.userId}</TableCell>
                  <TableCell align="center">
                    {data["users.nickname"] ? data["users.nickname"] : ""}
                  </TableCell>
                  <TableCell align="center">
                    {data["series.cpCompany.name"]
                      ? data["series.cpCompany.name"]
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {data["series.name"] ? data["series.name"] : ""}
                  </TableCell>
                  <TableCell align="center">
                    {data["stories.name"] ? data["stories.name"] : ""}
                  </TableCell>
                  <TableCell align="center">
                    {data["payments.payClCd"] &&
                    data["payments.payClCd"] === "0"
                      ? data.useType === "STORY"
                        ? data.useToken
                        : 0
                      : "0"}
                  </TableCell>
                  <TableCell align="center">
                    {data["payments.payClCd"] &&
                    data["payments.payClCd"] === "0"
                      ? data["holdTokens.tknClCd"] === "PAY"
                        ? data.useType === "STORY"
                          ? // ? data.useToken * data["payments.payPerAmt"]
                            data.useToken * 120
                          : 0
                        : "-"
                      : data["holdTokens.tknClCd"] === "PAY"
                      ? "0"
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {data["payments.payClCd"]
                      ? data["payments.payClCd"] === "0"
                        ? moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")
                        : moment(data["payments.updatedAt"]).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                      : ""}
                  </TableCell>
                  <TableCell align="center">
                    {data["holdTokens.tknClCd"] &&
                    data["holdTokens.tknClCd"] === "PAY" &&
                    data["payments.device"]
                      ? data["payments.device"]
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {data["holdTokens.tknClCd"]
                      ? data["holdTokens.tknClCd"] === "PAY"
                        ? "구매"
                        : "이벤트"
                      : ""}
                  </TableCell>
                  <TableCell align="center">
                    {data["payments.payClCd"] &&
                    data["payments.payClCd"] === "1" ? (
                      <span style={{ color: "red" }}>취소</span>
                    ) : data.useType === "STORY_CANCEL" ? (
                      <span style={{ color: "red" }}>취소</span>
                    ) : (
                      <>정상</>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={{
                        pathname: `/useTokens/list/${data.id}`,
                      }}
                    >
                      [사용내역]
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ backgroundColor: "#e0e0e0", height: "40px" }}>
                <TableCell
                  colSpan={12}
                  align="right"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  총 금액 :
                </TableCell>
                <TableCell
                  colSpan={2}
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  {sumVal.toLocaleString()}원
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Inner>
    </ListContainer>
  );
}

UseTokenListUsers.propTypes = {
  searchedUseTokensList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      device: PropTypes.string,
      prdCd: PropTypes.string,
      payAmt: PropTypes.number,
      userId: PropTypes.number,
      nickname: PropTypes.string,
      payId: PropTypes.string,
      createdAt: PropTypes.string,
      payClCd: PropTypes.string,
    })
  ),
};

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "block", //flex
  justifyContent: "center",
  alignItems: "center",
});

export default UseTokenListUsers;
