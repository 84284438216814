import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFileUpload } from "use-file-upload";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TitleSimple from "../common/TitleSimple";
import { studioMetaApi } from "../../api/studio";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const ChangeResourceInterActiveModal = ({
  open,
  handleClose,
  studioMenuId,
  studioSubMenuId,
  resourceId,
}) => {
  const classes = useStyles();
  const inputTitle = useRef(null);
  const inputEtc = useRef(null);
  const [imgFile, selectImgFile] = useFileUpload(null);
  const [audioFile, selectAudioFile] = useFileUpload(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isPublic, setIsPublic] = useState(true);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const sendChange = async (event) => {
    event.preventDefault();
    const name = inputTitle.current.value;
    if (!name && name.length === 0) {
      toast.error("이름을 입력하시오.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    const fd = new FormData(); //
    fd.append("name", inputTitle.current.value);
    fd.append("etc", inputEtc.current.value);
    fd.append("isPublic", isPublic);

    if (imgFile) {
      fd.append("imgFile", imgFile.file);
    }

    if (audioFile) {
      fd.append("audioFile", audioFile.file);
    }

    const result = await studioMetaApi.updateStudioInteractiveResources(
      studioMenuId,
      studioSubMenuId,
      resourceId,
      fd
    );
    if (result.status === 200) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const sendDelete = async () => {
    const result = await studioMetaApi.deleteStudioResources(
      studioMenuId,
      studioSubMenuId,
      resourceId
    );
    if (result.status === 200) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handlePublicChange = (event) => {
    setIsPublic(event.target.checked);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { status, data } = await studioMetaApi.getDetailStudioResources(
        studioMenuId,
        studioSubMenuId,
        resourceId
      );
      if (status === 200) {
        setIsPublic(data.isPublic);
        setData(data);
      }
      setLoading(false);
    };
    getData();
  }, [studioMenuId, studioSubMenuId, resourceId]);

  if (loading) {
    return (
      <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
        <div className={classes.paper}>
          <TitleSimple>리소스 수정 및 삭제</TitleSimple>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>리소스 추가</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <SelectItems items={items} onChange={onChangeSubMenu} /> */}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputTitle}
                id="name"
                label="이름"
                name="name"
                autoFocus
                defaultValue={data?.name}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputEtc}
                id="etc"
                label="비고"
                name="etc"
                autoFocus
                defaultValue={data?.etc}
              />
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={isPublic}
                      onChange={handlePublicChange}
                      name="checkedA"
                    />
                  }
                  label="공개 여부"
                />
              </div>
              {
                <>
                  <div>
                    <Typography>올라간 이미지</Typography>
                    {data?.url ? (
                      <div>
                        <img
                          src={data?.url}
                          alt="preview"
                          style={{ maxHeight: "100px" }}
                        />
                      </div>
                    ) : null}
                    {data?.url2 ? (
                      <div style={{ marginTop: "10px" }}>
                        <audio controls>
                          <source src={data?.url2} />
                        </audio>
                      </div>
                    ) : null}
                  </div>
                </>
              }
              <Button
                variant="contained"
                onClick={() => {
                  selectImgFile({ accept: "image/*" });
                }}
              >
                이미지 파일 업로드
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  selectAudioFile({ accept: "audio/*" });
                }}
              >
                사운드 파일 업로드
              </Button>
              {imgFile ? (
                <div>
                  <img
                    src={imgFile.source}
                    alt="preview"
                    style={{ maxHeight: "150px" }}
                  />
                  <div>
                    <span> 파일 이름: {imgFile.name} </span>
                    <span> Size: {imgFile.size} </span>
                  </div>
                </div>
              ) : null}
              {audioFile ? (
                <div style={{ marginTop: "10px" }}>
                  <audio controls>
                    <source src={audioFile.source} />
                  </audio>
                  <div>
                    <span> 파일 이름: {audioFile.name} </span>
                    <span> Size: {audioFile.size} </span>
                  </div>
                </div>
              ) : null}
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              className={classes.submit}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={sendChange}
            >
              {"수정"}
            </Button>
            <Button
              className={classes.submit}
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              onClick={sendDelete}
              disabled={data?.resources?.length > 0}
            >
              {"삭제"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ChangeResourceInterActiveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChangeResourceInterActiveModal;
