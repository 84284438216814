import moment from "moment";

////// 작품별 통계 엑셀 다운로드 //////
// --> 엑셀시트의 헤더 매뉴 값들
const ContentsExcelHeaders = [
  { label: "No", key: "no" },
  { label: "작품명", key: "title" },
  { label: "createdAt", key: "createdAt" },
  { label: "조회 수", key: "viewCount" },
  { label: "좋아요 수", key: "likeCount" },
  { label: "댓글 수", key: "replyCount" },
];
// --> apiResults를 data 형식으로 변환
const covertContentsExcelDataStyle = (apiResults) => {
  let tempArr;
  if (apiResults !== null) {
    tempArr = apiResults.map((series) => {
      return {
        no: series.id,
        title: series?.name?.replace(/"/g, `""`),
        createdAt: moment(series.createdAt).format("YYYY-MM-DD"),
        viewCount: series?.viewCount,
        likeCount: series?.likeCount,
        replyCount: series?.replyCount,
      };
    });
  }
  return tempArr;
};

////// 작가별 통계 엑셀 다운로드 //////
// --> 엑셀시트의 헤더 매뉴 값들
const AuthorsExcelHeaders = [
  { label: "작가id", key: "authorId" },
  { label: "createdAt", key: "authorId" },
  { label: "필명", key: "nickName" },
  { label: "작품", key: "series" },
  { label: "팔로우 수", key: "followerCount" },
];
// --> apiResults를 data 형식으로 변환
const covertAuthorsExcelDataStyle = (apiResults) => {
  let tempArr;
  if (apiResults !== null) {
    tempArr = apiResults.map((author) => {
      return {
        authorId: author.id,
        createdAt: moment(author.createdAt).format("YYYY-MM-DD"),
        nickName: author.nickname?.replace(/"/g, `""`),
        series: author?.series.map(
          (series) => `⌜${series.name?.replace(/"/g, `""`)}⌟`
        ),
        followerCount: author?.followerCount,
      };
    });
  }
  return tempArr;
};

////// 댓글 조회 통계 엑셀 다운로드 //////
// --> 엑셀시트의 헤더 매뉴 값들
const ReplyExcelHeaders = [
  { label: "No", key: "no" },
  { label: "숨김", key: "adminHide" },
  { label: "작품명", key: "title" },
  { label: "회차명", key: "storyName" },
  { label: "필명", key: "nickName" },
  { label: "댓글", key: "reply" },
  { label: "작성일", key: "createdAt" },
];
// --> apiResults를 data 형식으로 변환
const replyAuthorsExcelDataStyle = (apiResults) => {
  let tempArr;
  if (apiResults !== null) {
    tempArr = apiResults.map((author) => {
      return {
        no: author.id,
        adminHide: author.adminHide === false ? "N" : "Y",
        title: author.story?.series?.name?.replace(/"/g, `""`),
        storyName: author.story?.name?.replace(/"/g, `""`),
        nickName: author.writer?.nickname?.replace(/"/g, `""`),
        reply: author.content?.replace(/"/g, `""`),
        createdAt: moment(author.createdAt).format("YYYY-MM-DD"),
      };
    });
  }
  return tempArr;
};

////// 결제 내역 엑셀 다운로드 //////
// --> 엑셀시트의 헤더 매뉴 값들
const PaymentsExcelHeaders = [
  { label: "주문번호", key: "id" },
  { label: "구분", key: "device" },
  { label: "상품ID", key: "prdCd" },
  { label: "결제금액", key: "payAmt" },
  { label: "회원ID", key: "name" },
  { label: "프로필명", key: "nickname" },
  { label: "결제메일", key: "payUserAccount" },
  { label: "결제(환불)일시", key: "createdAt" },
  { label: "결제상태", key: "payClCd" },
];
// --> apiResults를 data 형식으로 변환
const convertPaymentsExcelDataStyle = (apiResults) => {
  let tempArr;
  if (apiResults !== null) {
    tempArr = apiResults.map((payment) => {
      return {
        id: payment.id,
        device: payment.device?.replace(/"/g, `""`),
        prdCd: payment.prdCd?.replace(/"/g, `""`),
        payAmt: payment.payClCd === "0" ? payment.payAmt : -payment.payAmt,
        name: payment.userId,
        nickname: payment.users?.nickname?.replace(/"/g, `""`),
        payUserAccount: payment.orderId?.replace(/"/g, `""`),
        createdAt: moment(payment.updatedAt).format("YYYY-MM-DD HH:mm"),
        payClCd: payment.payClCd === "0" ? "결제완료" : "취소",
      };
    });
  }
  return tempArr;
};

////// 토큰 차감 내역 엑셀 다운로드 (고객) //////
// --> 엑셀시트의 헤더 매뉴 값들
const UseTokensUsersExcelHeaders = [
  { label: "주문번호", key: "id" },
  { label: "회원ID", key: "userId" },
  { label: "프로필명", key: "userNickname" },
  { label: "CP", key: "cpName" },
  { label: "작품명", key: "seriesName" },
  { label: "회차명", key: "storyName" },
  { label: "토큰사용", key: "useToken" },
  { label: "사용금액", key: "payAmt" },
  { label: "토큰 차감일/취소일", key: "createdAt" },
  { label: "OS", key: "device" },
  { label: "토큰종류", key: "tknClCd" },
  { label: "결제상태", key: "payClCd" },
];
// --> apiResults를 data 형식으로 변환
const convertUseTokensUsersExcelDataStyle = (apiResults) => {
  let tempArr;
  if (apiResults !== null) {
    tempArr = apiResults.map((deduct) => {
      return {
        id: deduct.id,
        userId: deduct.userId,
        userNickname: deduct["users.nickname"]?.replace(/"/g, `""`),
        cpName: deduct["series.cpCompany.name"]?.replace(/"/g, `""`),
        seriesName: deduct["series.name"]?.replace(/"/g, `""`),
        storyName: deduct["stories.name"]?.replace(/"/g, `""`),
        useToken:
          deduct["payments.payClCd"] && deduct["payments.payClCd"] === "0"
            ? deduct.useType === "STORY"
              ? deduct.useToken
              : 0
            : 0,
        payAmt:
          deduct["payments.payClCd"] && deduct["payments.payClCd"] === "0"
            ? deduct["holdTokens.tknClCd"] === "PAY"
              ? deduct.useType === "STORY"
                ? // ? deduct.useToken * deduct["payments.payPerAmt"]
                  deduct.useToken * 120
                : 0
              : "-"
            : deduct["holdTokens.tknClCd"] === "PAY"
            ? 0
            : "-",
        createdAt: deduct["payments.payClCd"]
          ? deduct["payments.payClCd"] === "0"
            ? moment(deduct.createdAt).format("YYYY-MM-DD HH:mm:ss")
            : moment(deduct["payments.updatedAt"]).format("YYYY-MM-DD HH:mm:ss")
          : "",
        device:
          deduct["holdTokens.tknClCd"] &&
          deduct["holdTokens.tknClCd"] === "PAY" &&
          deduct["payments.device"]
            ? deduct["payments.device"]
            : "-",
        // deduct["payments.device"]
        //   ? deduct["payments.device"]
        //   : deduct["users.device"]
        //   ? deduct["users.device"] === "1"
        //     ? "AND"
        //     : deduct["users.device"] === "2"
        //     ? "IOS"
        //     : "-"
        //   : "-",
        tknClCd: deduct["holdTokens.tknClCd"]
          ? deduct["holdTokens.tknClCd"] === "PAY"
            ? "구매"
            : "이벤트"
          : "",
        payClCd: deduct["payments.payClCd"]
          ? deduct["payments.payClCd"] === "1"
            ? "취소"
            : deduct.useType === "STORY_CANCEL"
            ? "취소"
            : "정상"
          : "-",
      };
    });
  }
  return tempArr;
};

////// 토큰 차감 내역 엑셀 다운로드 (CP) //////
// --> 엑셀시트의 헤더 매뉴 값들
const UseTokensCpExcelHeaders = [
  { label: "CP ID", key: "cpId" },
  { label: "CP", key: "cpName" },
  { label: "작품명", key: "seriesName" },
  { label: "회차명", key: "storyName" },
  { label: "토큰", key: "token" },
  { label: "금액", key: "payAmt" },
  { label: "차감/취소 기간", key: "updatedAt" },
  { label: "OS", key: "device" },
  { label: "토큰종류", key: "tknClCd" },
  { label: "결제상태", key: "payClCd" },
];
// --> apiResults를 data 형식으로 변환
const convertUseTokensCpExcelDataStyle = (apiResults) => {
  let tempArr;
  if (apiResults !== null) {
    tempArr = apiResults.map((deduct) => {
      return {
        cpId: deduct.cpId,
        cpName: deduct.cpName?.replace(/"/g, `""`),
        seriesName: deduct.seriesName?.replace(/"/g, `""`),
        storyName: deduct.storyName?.replace(/"/g, `""`),
        token: deduct.token,
        payAmt: deduct.tknClCd === "PAY" ? deduct.amount : "-",
        updatedAt: `${moment(deduct.dt)
          .startOf("month")
          .format("YYYY-MM-DD")} ~ ${moment(deduct.dt)
          .endOf("month")
          .format("YYYY-MM-DD")}`,
        device: deduct.tknClCd === "PAY" ? deduct.device : "-",
        tknClCd: deduct.tknClCd === "PAY" ? "구매" : "이벤트",
        payClCd:
          deduct.payClCd === 0
            ? "정상"
            : deduct.payClCd === 1
            ? "취소완료"
            : "취소",
      };
    });
  }
  return tempArr;
};

////// 정산 내역 엑셀 다운로드 //////
// --> 엑셀시트의 헤더 메뉴 값들
const SettleExcelHeaders = [
  { label: "No", key: "no" },
  { label: "정산대상기간", key: "period" },
  { label: "CP/작가명", key: "cpName" },
  { label: "정산금액", key: "settleSum" },
];
// --> apiResults를 data 형식으로 변환
const convertSettleExcelDataStyle = (apiResults) => {
  let tempArr;
  if (apiResults !== null) {
    const dataLength = apiResults.length;
    tempArr = apiResults.map((data, index) => {
      return {
        no: dataLength - index,
        period: `${moment(new Date(data.period))
          .startOf("month")
          .format("YYYY-MM-DD")}
          ~
          ${moment(new Date(data.period)).endOf("month").format("YYYY-MM-DD")}`,
        cpName: data.cpName?.replace(/"/g, `""`),
        settleSum: (
          ((data.andUseToken * 120 - (data.andUseToken * 120 * 15) / 100) *
            data.cpRS) /
            100 +
          ((data.iosUseToken * 120 - (data.iosUseToken * 120 * 15) / 100) *
            data.cpRS) /
            100
        )
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
      };
    });
  }
  return tempArr;
};

export {
  ContentsExcelHeaders,
  covertContentsExcelDataStyle,
  AuthorsExcelHeaders,
  covertAuthorsExcelDataStyle,
  ReplyExcelHeaders,
  replyAuthorsExcelDataStyle,
  PaymentsExcelHeaders,
  convertPaymentsExcelDataStyle,
  UseTokensUsersExcelHeaders,
  convertUseTokensUsersExcelDataStyle,
  UseTokensCpExcelHeaders,
  convertUseTokensCpExcelDataStyle,
  SettleExcelHeaders,
  convertSettleExcelDataStyle,
};
