import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useFileUpload } from "use-file-upload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import TitleSimple from "../common/TitleSimple";
import Switch from "@material-ui/core/Switch";
import toast from "react-hot-toast";
import { tockCommonApi } from "../../api/tock";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const ChangeStoryModel = ({ open, handleClose, storyId }) => {
  const classes = useStyles();
  const inputTitle = useRef(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [isUnity, setIsUnity] = useState(false);
  const [file, selectFile] = useFileUpload(null);
  const [unityFile, selectUnityFile] = useFileUpload(null);
  const [unityIofFile, selectUnityIosFile] = useFileUpload(null);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const sendDelete = async (event) => {
    event.preventDefault();
    const result = await tockCommonApi.deleteStory(storyId);

    if (result.status === 200) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const sendChange = async (event) => {
    event.preventDefault();

    const fd = new FormData();
    fd.append("name", inputTitle.current.value);
    fd.append("isPublic", isPublic ? 1 : 0);
    fd.append("isUnity", isUnity ? 1 : 0);
    if (file) {
      fd.append("coverImg", file.file);
    }
    if (unityFile) {
      fd.append("unityfile", unityFile.file);
    }
    if (unityIofFile) {
      fd.append("unityIosfile", unityIofFile.file);
    }

    const result = await tockCommonApi.updateStory(storyId, fd);

    if (result.status === 200) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handlePublicChange = (event) => {
    setIsPublic(event.target.checked);
  };

  const handleIsUnityChange = (event) => {
    setIsUnity(event.target.checked);
  };

  useEffect(() => {
    const getData = async () => {
      if (storyId !== 0) {
        setLoading(true);
        const result = await tockCommonApi.getStory(storyId);
        if (result.status === 200) {
          setData(result.data);
          setIsPublic(result.data.isPublic);
          setIsUnity(result.data.isUnity);
        }
      }
      setLoading(false);
    };
    getData();
  }, [storyId]);

  if (loading) {
    return (
      <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
        <div className={classes.paper}>
          <TitleSimple>스토리 수정</TitleSimple>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>스토리 수정</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputTitle}
                id="title"
                label="이름"
                name="title"
                autoFocus
                defaultValue={data?.name}
              />
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    selectFile({ accept: "image/*" });
                  }}
                >
                  파일 업로드
                </Button>
                {file ? (
                  <div style={{ marginTop: "10px" }}>
                    <div>
                      <img
                        src={file.source}
                        alt="preview"
                        style={{ maxHeight: "100px" }}
                      />
                      <div>
                        <span> 파일 이름: {file.name} </span>
                        <span> Size: {file.size} </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span> 변경할 이미지 파일 선택</span>
                )}
              </div>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={isPublic}
                    onChange={handlePublicChange}
                    name="checkedA"
                  />
                }
                label="공개 여부"
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={isUnity}
                    onChange={handleIsUnityChange}
                    name="checkedA"
                  />
                }
                label="유니티 제작"
              />
            </Grid>
            {isUnity && (
              <>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      selectUnityFile();
                    }}
                  >
                    유니티 파일 업로드
                  </Button>
                  {unityFile ? (
                    <div style={{ marginTop: "10px" }}>
                      <div>
                        <div>
                          <span> 파일 이름: {unityFile.name} </span>
                          <span> Size: {unityFile.size} </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span>파일 업로드</span>
                  )}
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      selectUnityIosFile();
                    }}
                  >
                    유니티 ios 파일 업로드
                  </Button>
                  {unityIofFile ? (
                    <div style={{ marginTop: "10px" }}>
                      <div>
                        <div>
                          <span> 파일 이름: {unityIofFile.name} </span>
                          <span> Size: {unityIofFile.size} </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span>파일 업로드</span>
                  )}
                </div>
              </>
            )}
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              className={classes.submit}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={sendChange}
            >
              {"수정"}
            </Button>
            <Button
              className={classes.submit}
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              onClick={sendDelete}
            >
              {"삭제"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ChangeStoryModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChangeStoryModel;
