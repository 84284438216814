import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  ListItemText,
  ListItem,
  ListItemIcon,
  Collapse,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  enabledText: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  disabledText: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.54)',
    opacity: '0.54',
  },
  arrowDropUpIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '4px',
  },
  arrowDropDownIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '4px',
  },
  listItemIcon: {
    minWidth: 40,
  },
  listItem: {
    alignItems: 'start',
    paddingLeft: '30px',
  },
}));

const SideBarCollaspeItem = ({
  title,
  enabled,
  children,
  menuOpen,
  onClick,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick();
  };
  const textStyle = enabled ? classes.enabledText : classes.disabledText;

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.listItem}>
        <ListItemIcon className={classes.listItemIcon}>
          {menuOpen ? (
            <ArrowDropDownIcon className={classes.arrowDropUpIcon} />
          ) : (
            <ArrowRightIcon className={classes.arrowDropDownIcon} />
          )}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={title.split('\n').map((str, index) => {
            if (str.trim() === '') {
              // eslint-disable-next-line react/no-array-index-key
              return <br key={index} />;
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={index} className={textStyle}>
                {str}
              </Typography>
            );
          })}
        />
      </ListItem>
      <Collapse in={menuOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

SideBarCollaspeItem.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SideBarCollaspeItem.defaultProps = {
  children: null,
};

export default SideBarCollaspeItem;
