//import React, { useState } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import Loading from "../common/Loading";
import { Button, Modal } from "@material-ui/core";
import { Typography } from "@mui/material";
import { styled } from "@material-ui/core";
import { paymentsApi } from "../../api/payments";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "500px",
    height: "360px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
    position: "relative",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
  userName: {
    color: "#f57c00",
  },
}));

function UseTokenCancelModal({ open, handleClose, detail, isCanceled }) {
  const classes = useStyles();

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const payCancel = async () => {
    //결제 취소 로직
    const sendData = {
      id: detail.id,
      userId: detail.userId,
      paymentId: detail.paymentId,
      holdTokenId: detail.holdTokenId,
      useType: detail.useType,
      groupId: detail.groupId,
      amtDd: detail.amtDd,
      seriesId: detail.seriesId,
      storyId: detail.storyId,
      useToken: detail.useToken,
      createdAt: detail.createdAt,
    };

    const result = await paymentsApi.doUseTokenCancel(JSON.stringify(sendData));

    if (result.data.code === 0) {
      handleClose();
      isCanceled();
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>취소 처리</Typography>
        <TextContainer>
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontSize: "18px",
              fontWeight: 400,
              letterSpacing: "-1px",
              lineHeight: "1.6",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            작품명 : {detail["series.name"] ? detail["series.name"] : ""}
            <br />
            회차 : {detail["stories.name"] ? detail["stories.name"] : ""}
            <br />
            사용토큰 :{" "}
            {detail["holdTokens.tknClCd"] &&
            detail["holdTokens.tknClCd"] === "PAY"
              ? detail.useToken
              : 0}
            토큰 / 사용금액 :
            {detail["payments.payClCd"] &&
            detail["holdTokens.tknClCd"] &&
            detail["holdTokens.tknClCd"] === "PAY"
              ? // ? detail.useToken * detail["payments.payPerAmt"]
                detail.useToken * 120
              : 0}
            원을
            <br />
            취소하시겠습니까?
            <br />
            <br />※ 취소 후에는 원복되지 않습니다.
          </Typography>
        </TextContainer>
        <ButtonContainer>
          <Button className={classes.cancelButton} onClick={handleCloseModal}>
            닫기
          </Button>
          <Button className={classes.editButton} onClick={payCancel}>
            취소요청
          </Button>
        </ButtonContainer>
      </div>
    </Modal>
  );
}

const TextContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "150px",
});

const ButtonContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  height: "50px",
  alignItems: "center",
  marginTop: "80px",
});

export default UseTokenCancelModal;
