export const StudioSubMenu = {
  Character: 1,
  BGM: 2,
  EffectSound: 3,
  Interactive: 4,
  Background: 5,
  BookCover: 6,
};

export const ResourceType = {
  1: "이미지",
  2: "사운드",
  3: "인터렉티브",
};
