import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import TitleSimple from "../common/TitleSimple";
import toast from "react-hot-toast";
import { tockCommonApi } from "../../api/tock";
import { userApi } from "../../api/global";
import {
  Autocomplete
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const TransferStroyModel = ({ open, handleClose, seriesId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedGenre, setSelectedGenre] = useState(0);
  const [isNotcie, setIsNotice] = useState(false);

  const [userId, setUserId] = useState("");
  const [userList, setUserList] = useState([]);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  useEffect(() => {
    const getUserList = async () => {
      try {
        const { status, data } = await userApi.findUserList();
        if (status === 200) {
          setUserList(
            data.map((item) => {
              return {
                label: `${item.id}. ${item.nickname}`,
                id: item.id,
                nickname: item.nickname,
              };
            })
          );
        }
      } catch (error) {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    };
    getUserList();
  }, []);

  const updateWriter = async (event) => {
    event.preventDefault();

    const result = await tockCommonApi.updateWriter(userId.id, seriesId);

    if (result.status === 200) {
      handleClose(true);
      window.location.replace("/contents/list");
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (seriesId !== 0) {
        setLoading(true);
        const result = await tockCommonApi.getDetailSeries(seriesId);
        if (result.status === 200) {
          setData(result.data);
          setIsNotice(result.data.isNotice);
          setSelectedGenre(result.data.genreId);
        }
      }
      setLoading(false);
    };
    getData();
  }, [seriesId]);

  if (loading) {
    return (
      <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
        <div className={classes.paper}>
          <TitleSimple>작품 이관</TitleSimple>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>작품이관</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                style={{ paddingTop: "20px" }}
                disablePortal
                options={userList}
                renderInput={(params) => (
                  <TextField {...params} label="회원ID" />
                )}
                sx={{ width: 300 }}
                onChange={(event, value) => setUserId(value)}
              />
              <div style={{ marginTop: "20px" }} />
            </Grid>
          </Grid>
          <div style={{ justifyContent: "center", display: "flex", fontSize: "18px" }}>해당 회원에게 이관하시겠습니까?</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              className={classes.submit}
              variant="contained"
              size="large"
              style={{ backgroundColor: "white", height: "35px" }}
              onClick={() => handleClose(true)}
            >
              {"취소"}
            </Button>
            <Button
              className={classes.submit}
              variant="contained"
              style={{ backgroundColor: "orange", height: "35px" }}
              size="large"
              onClick={updateWriter}
            >
              {"확인"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

TransferStroyModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TransferStroyModel;
