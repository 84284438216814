import React, { useState } from "react";
import moment from "moment";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../push/TablePaginationActions";
import { Typography } from "@mui/material";

function PayTokenTable({ list }) {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dataLength = list.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //누적금액
  const sumArr = [];
  let sumVal = 0;

  const calculateSum = (list) => {
    for (let i = dataLength - 1; i >= 0; i--) {
      if (list[i].payClCd === "0") {
        sumVal += list[i].fstToken * list[i].payPerAmt;
      } else {
        sumVal += 0;
      }
      sumArr.push(sumVal);
    }
  };

  return (
    <>
      {calculateSum(list)}
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          fontSize: "16px",
          margin: "0px 12px",
          padding: "20px 20px",
          fontWeight: 500,
          letterSpacing: "-1px",
          lineHeight: "1.6",
        }}
      >
        <span style={{ color: "orange" }}>구매</span> 토큰 적립 내역
      </Typography>
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          fontSize: "15px",
          margin: "0px 12px",
          paddingRight: "20px",
          fontWeight: 500,
          letterSpacing: "-1px",
          lineHeight: "1.6",
          textAlign: "right",
        }}
      >
        전체 <span style={{ color: "orange" }}>{dataLength}</span> 건
      </Typography>
      <TableContainer>
        <Table
          sx={{
            width: "920px",
            marginBottom: "30px",
          }}
          align="center"
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#e0e0e0", height: "60px" }}>
              <TableCell
                align="center"
                sx={{ width: "4%", backgroundColor: "#e0e0e0" }}
              >
                No
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  width: "10%",
                  backgroundColor: "#e0e0e0",
                }}
              >
                상품ID
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#e0e0e0",
                  width: "14%",
                }}
              >
                적립(취소) 일시
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#e0e0e0",
                  width: "14%",
                }}
              >
                적립토큰
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  width: "10%",
                  backgroundColor: "#e0e0e0",
                }}
              >
                금액
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  width: "10%",
                  backgroundColor: "#e0e0e0",
                }}
              >
                토큰종류
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  width: "14%",
                  backgroundColor: "#e0e0e0",
                }}
              >
                유효기간
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  width: "10%",
                  backgroundColor: "#e0e0e0",
                }}
              >
                누적금액
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  width: "8%",
                  backgroundColor: "#e0e0e0",
                }}
              >
                비고
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : list
            ).map((data, index) => (
              <TableRow sx={{ height: "50px" }} key={data.id}>
                <TableCell align="center">
                  {dataLength - index - page * rowsPerPage}
                </TableCell>
                <TableCell align="center">{data.prdCd}</TableCell>
                <TableCell align="center">
                  {data.payClCd === "0"
                    ? moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")
                    : moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
                <TableCell align="center">
                  {data.payClCd === "0" ? data.fstToken : 0}
                </TableCell>
                <TableCell align="center">
                  {data.payClCd === "0" ? data.fstToken * data.payPerAmt : 0}
                </TableCell>
                <TableCell align="center">
                  {data.tknClCd === "PAY" ? "구매" : "이벤트"}
                </TableCell>
                <TableCell align="center">
                  {data.payClCd === "0"
                    ? moment(data.expDd).format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {sumArr.length === 0
                    ? "-"
                    : sumArr[dataLength - page * rowsPerPage - index - 1]}
                </TableCell>
                <TableCell align="center">
                  {data.payClCd === "0" ? (
                    "정상"
                  ) : (
                    <span style={{ color: "red" }}>취소</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 40 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100, { label: "All", value: -1 }]}
                colSpan={9}
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

// Payment.defaultProps = {
//   searchedPaymentList: {
//     id: "",
//     device: "",
//     prdCd: "",
//     payAmt: "",
//     userId: "",
//     nickname: "",
//     payId: "",
//     createdAt: "",
//     device: "",
//     payClCd: "",
//   },
// };

// Payment.propTypes = {
//   loading: PropTypes.bool.isRequired,
//   searchedPaymentList: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number,
//       device: PropTypes.string,
//       prdCd: PropTypes.string,
//       payAmt: PropTypes.number,
//       userId: PropTypes.number,
//       nickname: PropTypes.string,
//       payId: PropTypes.string,
//       createdAt: PropTypes.string,
//       device: PropTypes.string,
//       payClCd: PropTypes.string,
//     })
//   ),
// };

export default PayTokenTable;
