import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import "../../assets/css/dateRangePicker.css";
import AddTokenTable from "../payments/RegisterToken_AddTokenTable";
import AddTokenListTable from "../payments/RegisterToken_AddTokenListTable";
import { paymentsApi } from "../../api/payments";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "900px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  subTitle: {
    fontFamily: "NotoSansKR",
    fontSize: "20px",
    color: "black",
    margin: "0px 12px",
    padding: "20px 25px ",
    fontWeight: 800,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const AddTokenModal = ({ open, handleClose, getData }) => {
  const classes = useStyles();
  const [addData, setAddData] = useState([]);

  const handleCloseModal = (result) => {
    handleClose(result === true);
    setAddData([]);
  };

  const getRegisterList = (data) => {
    setAddData([data, ...addData]);
  };

  const deleteRegisterList = (index) => {
    const data = [];
    for (let i = 0; i < addData.length; i++) {
      if (i !== index) {
        data.push(addData[i]);
      }
    }
    setAddData(data);
    if (addData.length === 0) {
      setAddData([]);
    }
  };

  const register = async () => {
    const result = await paymentsApi.registerAdminEventToken(
      JSON.stringify(addData)
    );

    if (result.data.code === -1) {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      handleClose();
      getData();
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>토큰 등록</Typography>
        <AddTokenTable
          getRegisterList={getRegisterList}
          handleClose={handleCloseModal}
        />
        <AddTokenListTable
          addData={addData}
          deleteRegisterList={deleteRegisterList}
          register={register}
        />
      </div>
    </Modal>
  );
};

AddTokenModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddTokenModal;
