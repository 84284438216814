import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { styled } from "@material-ui/core";
import moment from "moment";
import { Button } from "rsuite";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { convertDate } from "../lib/utils";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import Loading from "../common/Loading";
import { pushApi } from "../../api/push";

const ReservedPush = ({
  pushId,
  setOpenCheckDrawer,
  onCloseCheckDrawer,
  getData,
}) => {
  const history = useHistory();
  const [pushDetail, setPushDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [android, setAndroid] = useState(false);
  const [iOs, setIOs] = useState(false);
  const [date, setDate] = useState();
  const [hour, setHour] = useState();
  const pushDate = convertDate(date, hour);
  const [pushOptions, setPushOptions] = useState({
    sendDevice: pushDetail?.sendDevice,
    process: 1,
    onlyAgreePush: true,
  });
  const [pushContents, setPushContent] = useState({
    title: pushDetail?.title,
    content: pushDetail?.content,
  });
  const pushImgInput = useRef();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  // push 내용 수정시, datepicker, pushContents에 초기값으로 pushDetail 값들 넣어주기
  useEffect(() => {
    setPushOptions({
      sendDevice: pushDetail?.sendDevice,
      process: 1,
      onlyAgreePush: true,
    });
    setDate(new Date(pushDetail?.pushDate));
    setHour(new Date(pushDetail?.pushDate));
    setPushContent({
      title: pushDetail?.title,
      content: pushDetail?.content,
    });
    setImage(pushDetail?.imgUrl);
  }, [pushDetail]);

  // eslint-disable-next-line
  const getPushDetail = async () => {
    setLoading(true);
    try {
      const { status, data } = await pushApi.detail(pushId);
      if (status === 200) {
        setPushDetail(data);
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  // eslint-disable-next-line
  useEffect(getPushDetail, []);

  // 모달창 닫기 이벤트
  const onCloseModal = () => {
    setOpenCheckDrawer(false);
  };

  // push 내용 수정하기 이벤트
  const onModifyPush = () => {
    setIsModify(true);
  };

  // push 내용 수정취소 이벤트 : 취소순간 수정중이던 항목들을 다시 최초 pushDetail값으로 초기화
  const onCancelModify = () => {
    setIsModify(false);
    setPushOptions({
      sendDevice: pushDetail.sendDevice,
      process: 1,
      onlyAgreePush: pushDetail.onlyAgreePush,
    });
    setDate(new Date(pushDetail?.pushDate));
    setHour(new Date(pushDetail?.pushDate));
    setPushContent({
      title: pushDetail?.title,
      content: pushDetail?.content,
    });
    setImage(pushDetail.imgUrl);
    setImageFile(null);
  };

  // '안드로이드/iOS' 옵션 선택,
  const onDeviceSelect = (checked, device) => {
    if (checked === true && device === "android") {
      setAndroid(true);
    } else if (checked === false && device === "android") {
      setAndroid(false);
    } else if (checked === true && device === "iOs") {
      setIOs(true);
    } else if (checked === false && device === "iOs") {
      setIOs(false);
    }
  };
  useEffect(() => {
    if (android === true && iOs === false) {
      setPushOptions({ ...pushOptions, sendDevice: 1 });
    } else if (android === false && iOs === false) {
      setPushOptions({ ...pushOptions, sendDevice: "" });
    } else if (android === false && iOs === true) {
      setPushOptions({ ...pushOptions, sendDevice: 2 });
    } else if (android === true && iOs === true) {
      setPushOptions({ ...pushOptions, sendDevice: 3 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [android, iOs]);

  //'예약발송/즉시발송' 옵션 선택
  const onPushTimingSelect = (event) => {
    setPushOptions({ ...pushOptions, process: Number(event.target.value) });
  };

  // 날짜와 시간 picker의 'x'버튼 눌렀을 때의 초기화
  const onCleanDate = () => {
    setDate();
  };
  const onCleanHour = () => {
    setHour();
  };

  // '앱푸쉬 동의자 발송' 체크 박스 선택
  const onOnlyConsenterCheck = (checked) => {
    setPushOptions({ ...pushOptions, onlyAgreePush: checked });
  };

  // '제목'과 '메시지' 입력 핸들러
  const onInputChange = (event) => {
    setPushContent({
      ...pushContents,
      [event.target.name]: event.target.value,
    });
  };

  // 이미지 삽입시 실행함수
  // 이미지 삽입위한 '이미지 등록' 버튼 클릭
  const onImgBtnClick = (event) => {
    event.preventDefault();
    pushImgInput.current.click();
  };
  // 이미지 파일 저장 및 미리 보여주기
  const onImageChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  // 마지막 '수정완료' 버튼으로 API Put
  const onCompleteModify = async () => {
    if (!pushOptions.sendDevice) {
      toast.error("발송대상자 기종을 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    if (!pushOptions.process) {
      toast.error("발송 처리를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    if (!pushContents.title || !pushContents.content) {
      toast.error("제목 및 메시지를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }

    const formData = new FormData();
    formData.append("title", pushContents.title);
    formData.append("content", pushContents.content);
    formData.append("sendDevice", pushOptions.sendDevice);
    formData.append("onlyAgreePush", pushOptions.onlyAgreePush);
    formData.append("process", pushOptions.process);
    formData.append("pushDate", pushDate);
    formData.append("image", imageFile);
    try {
      await pushApi.modify(pushId, formData);
      toast.success("push가 수정 되었습니다.");
      history.push({ pathname: "/push" });
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setIsModify(false);
      onCloseCheckDrawer(false);
      getData();
    }
  };

  return (
    <Container>
      {loading || pushDetail === null ? (
        <Loading />
      ) : (
        <Box>
          <h5 style={{ margin: "100px 0 10px" }}>Push 발송정보</h5>
          <Inner>
            <Cell1>발송대상자</Cell1>
            <Cell2>
              {isModify ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="android"
                        onChange={(event) =>
                          onDeviceSelect(
                            event.target.checked,
                            event.target.name
                          )
                        }
                      />
                    }
                    label="안드로이드"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="iOs"
                        onChange={(event) =>
                          onDeviceSelect(
                            event.target.checked,
                            event.target.name
                          )
                        }
                      />
                    }
                    label="iOs"
                  />
                </>
              ) : (
                <>
                  {pushDetail.sendDevice === 1 && "안드로이드"}
                  {pushDetail.sendDevice === 2 && "iOS"}
                  {pushDetail.sendDevice === 3 && "모두"}
                </>
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>발송처리</Cell1>
            <Cell2>
              {isModify ? (
                <>
                  <Select
                    defaultValue={pushDetail.process === 1 ? "1" : "2"}
                    onChange={onPushTimingSelect}
                  >
                    <option value="1">예약발송</option>
                    <option value="2">즉시발송</option>
                  </Select>
                </>
              ) : (
                <>
                  {pushDetail.process === 1 && `예약발송`}
                  {pushDetail.process === 2 && `즉시발송`}
                </>
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>발송시간</Cell1>
            <Cell2>
              {isModify ? (
                <>
                  <DatePicker
                    format="yyyy-MM-dd"
                    ranges={[]}
                    value={date}
                    onChange={setDate}
                    onClean={onCleanDate}
                    size="sm"
                    placeholder="Select Date"
                    style={{ width: 150, marginRight: "5px" }}
                  />
                  <DatePicker
                    format="HH:mm"
                    ranges={[]}
                    value={hour}
                    onChange={setHour}
                    onClean={onCleanHour}
                    size="sm"
                    style={{ width: 150 }}
                  />
                </>
              ) : (
                <>{moment(pushDetail.pushDate).format("YYYY-MM-DD HH:mm")}</>
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>앱푸쉬 동의</Cell1>
            <Cell2>
              {isModify ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="onlyAgreePush"
                        onChange={(event) =>
                          onOnlyConsenterCheck(event.target.checked)
                        }
                        checked={
                          pushOptions.onlyAgreePush === true ? true : false
                        }
                      />
                    }
                    label="앱푸쉬 알람 동의자에게만 발송"
                  />
                </>
              ) : (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="onlyAgreePush"
                        checked={
                          pushDetail.onlyAgreePush === true ? true : false
                        }
                      />
                    }
                    label="앱푸쉬 알람 동의자에게만 발송"
                    disabled
                  />
                </>
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>제목</Cell1>
            <Cell2>
              {isModify ? (
                <>
                  <TextInput
                    name="title"
                    value={pushContents.title}
                    maxLength="30"
                    autoComplete="off"
                    required
                    placeholder="제목을 입력하세요"
                    onChange={onInputChange}
                  />
                </>
              ) : (
                <>{pushDetail.title}</>
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>메시지</Cell1>
            <Cell2>
              {isModify ? (
                <>
                  <TextArea
                    name="content"
                    value={pushContents.content}
                    maxLength="120"
                    required
                    placeholder="내용을 입력하세요"
                    onChange={onInputChange}
                  />
                </>
              ) : (
                <>{pushDetail.content}</>
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>이미지</Cell1>
            <Cell2>
              {isModify ? (
                <>
                  <Form encType="multipart/form-data">
                    <ImageInput
                      ref={pushImgInput}
                      type="file"
                      accept="image/*"
                      onChange={onImageChange}
                    />
                    <Button onClick={onImgBtnClick}>이미지 등록</Button>
                  </Form>
                  <ImagePaint>
                    {image === null ? (
                      <></>
                    ) : (
                      <Img src={image} alt="inserted_image" />
                    )}
                  </ImagePaint>
                </>
              ) : (
                <>
                  {pushDetail.imgUrl === null ? (
                    <div>없음</div>
                  ) : (
                    <Img src={pushDetail.imgUrl} alt="inserted_image" />
                  )}
                </>
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1 style={{ borderBottom: "1px solid #ccc" }}>링크</Cell1>
            <Cell2 style={{ borderBottom: "1px solid #ccc" }}>
              <span>작품:&nbsp;</span>
              <span id={pushDetail.series?.id}>
                {pushDetail.series?.name ?? "None"}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>회차:&nbsp;</span>
              <span id={pushDetail.story?.id}>
                {pushDetail.story?.name ?? "None"}
              </span>
            </Cell2>
          </Inner>
          <Inner style={{ justifyContent: "flex-end" }}>
            <Button
              color="orange"
              appearance="subtle"
              style={{
                margin: "10px 5px 0 0",
                width: "90px",
                height: "40px",
                border: "1px solid #e0e0e0",
              }}
              onClick={isModify ? onCompleteModify : onModifyPush}
            >
              {isModify ? "수정완료" : "수정하기"}
            </Button>
            <Button
              color="orange"
              appearance="subtle"
              style={{
                margin: "10px 0",
                width: "90px",
                height: "40px",
                border: "1px solid #e0e0e0",
              }}
              onClick={isModify ? onCancelModify : onCloseModal}
            >
              {isModify ? "수정취소" : "닫기"}
            </Button>
          </Inner>
        </Box>
      )}
    </Container>
  );
};

const Container = styled("div")({
  position: "relative",
});
const Inner = styled("div")({
  position: "relative",
  height: "60px",
  display: "flex",
  justifyContent: "flex-start",
});
const Cell1 = styled("div")({
  border: "1px solid #cccccc",
  borderRight: 0,
  borderBottom: 0,
  backgroundColor: "lightgray",
  width: "100px",
  height: "100%",
  paddingLeft: "10px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Cell2 = styled("div")({
  border: "1px solid #cccccc",
  borderBottom: 0,
  height: "100%",
  paddingRight: "30px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexGrow: 1,
  paddingLeft: "30px",
  maxWidth: "377px",
  overflowX: "auto",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Select = styled("select")({
  width: "150px",
  height: "35px",
  padding: "0 10px",
  border: "1px solid #e6e6e6",
  borderRadius: "5px",
});
const TextInput = styled("input")({
  width: "100%",
  height: "40px",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  padding: "10px ",
  backgroundColor: "white",
});
const TextArea = styled("textarea")({
  width: "100%",
  height: "40px",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  padding: "10px",
  backgroundColor: "white",
  wordBreak: "keep-all",
  overflow: "auto",
});
const Img = styled("img")({
  border: "1px solid lightgray",
  height: "50px",
});
const Form = styled("form")({
  width: "50%",
});
const ImageInput = styled("input")({
  display: "none",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  backgroundColor: "white",
});
const ImagePaint = styled("div")({
  width: "50px",
  height: "50px",
});

export default ReservedPush;
