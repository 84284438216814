import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@material-ui/core";
import Box from "@mui/material/Box";
import { Button } from "rsuite";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete } from "@mui/material";
import toast from "react-hot-toast";
import { deviceTokenApi } from "../../api/push";
import { pushApi } from "../../api/push";

const ModalInner1 = styled("div")({
  position: "relative",
});
const ModalInner2 = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "flex-end",
});
const InnerBox = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "95%",
  height: "60px",
  border: "1px solid lightgray",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
const ModalInner3 = styled("div")({
  position: "absolute",
  top: "20px",
  left: "39%",
  display: "flex",
  justifyContent: "center",
});
const ModalInner4 = styled("div")({
  position: "relative",
  marginTop: "10px",
  paddingTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
});
const ModalInner5 = styled("div")({
  marginTop: "20px",
  height: "170px",
  position: "relative",
  textAlign: "center",
  overflow: "auto",
});
const ModalInner6 = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
});
const EachNumber = styled("div")({
  position: "relative",
  margin: "10px auto",
  border: "1px solid lightgray",
  width: "80%",
  backgroundColor: "#f2f2f2",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  font: '16px "Noto Sans KR"',
});

const Phone = styled("div")({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  margin: "0 15px",
});

const PushTestModal = ({
  openTestModal,
  onCloseTestModal,
  title,
  content,
  imageFile,
  seriesId,
  storyId,
}) => {
  const [testNumberGroup, setTestNumberGroup] = useState([]);
  const [testNumber, setTestNumber] = useState(null);
  const [deviceData, setDeviceData] = useState([]);
  const inputRefAutocomplte = useRef();

  useEffect(() => {
    const requestSeriesList = async () => {
      const { status, data: newData } = await deviceTokenApi.list();
      if (status === 200) {
        setDeviceData(newData);
      }
    };
    requestSeriesList();
  }, []);

  const onDeletePhone = (event) => {
    setTestNumberGroup(
      testNumberGroup.filter((item) => item.id !== Number(event.target.id))
    );
  };

  // '테스 발송 모달'에서 '테스트 발송' 버튼 클릭
  const onSubmitTestPush = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (imageFile) {
      formData.append("image", imageFile);
    }
    testNumberGroup.forEach((item) => {
      formData.append("tokenList[]", item.token);
    });

    if (seriesId) {
      formData.append("seriesId", seriesId);
    }

    if (storyId) {
      formData.append("storyId", storyId);
    }

    try {
      await pushApi.test(formData);
      toast.success("push 테스트 데이터를 보냈습니다.");
    } catch (error) {}
  };
  // '테스트 발송 모달'에서 '테스트 번호 추가' 버튼 이벤트
  const onAddTestNumber = () => {
    setTestNumberGroup([...testNumberGroup, testNumber]);
  };

  return (
    <Modal
      open={openTestModal}
      onClose={onCloseTestModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ModalInner1>
          <ModalInner2>
            <Button
              style={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
              }}
              onClick={onCloseTestModal}
            >
              <ClearIcon sx={{ color: "black" }} />
            </Button>
          </ModalInner2>
          <ModalInner3>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              테스트 발송
            </Typography>
          </ModalInner3>
          <ModalInner4>
            <InnerBox>
              <Phone>유저 닉네임</Phone>
              <Autocomplete
                ref={inputRefAutocomplte}
                sx={{ width: "200px" }}
                options={deviceData}
                getOptionLabel={(option) =>
                  `${option?.user?.nickname ? option?.user?.nickname : "없음"}`
                }
                renderInput={(props, option) => {
                  return <TextField {...props} label="닉네임" />;
                }}
                onChange={(event, value) => setTestNumber(value)}
              />
              <Button
                style={{ fontSize: "12px", margin: "0 10px" }}
                onClick={onAddTestNumber}
              >
                + 유저 추가
              </Button>
            </InnerBox>
          </ModalInner4>
          <ModalInner5>
            {testNumberGroup &&
              testNumberGroup.map((item, idx) => {
                return (
                  <EachNumber key={idx}>
                    <div>
                      <Typography>{`디바이스 넘버:${item.id}`}</Typography>
                      <Typography>{`유저 닉네임:${item?.user?.nickname}`}</Typography>
                    </div>
                    <Button
                      id={item.id}
                      style={{
                        borderRadius: "50%",
                        width: "35px",
                        height: "35px",
                        padding: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f2f2f2",
                      }}
                      onClick={onDeletePhone}
                    >
                      삭제
                    </Button>
                  </EachNumber>
                );
              })}
          </ModalInner5>
          <ModalInner6>
            <Button
              color="orange"
              appearance="subtle"
              style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
              onClick={onSubmitTestPush}
            >
              테스트 발송
            </Button>
          </ModalInner6>
        </ModalInner1>
      </Box>
    </Modal>
  );
};
export default PushTestModal;
