import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";

const SelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: "20px",
    },
  },
  input: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    border: "1px solid #E5E5E5",
    fontSize: 16,
    color: "#333333",
    // width: 800,
    height: 26,
    padding: "10px 26px 10px 12px",
    display: "flex",
    alignItems: "center",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["NotoSansKR"].join(","),
    "&:focus": {
      border: "1px solid #E5E5E5",
    },
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "7px",
  },
  formControl: {
    width: "100%",
  },
  inputLable: {
    fontSize: "18px",
    marginLeft: "16px",
    height: "21px",
    marginBottom: "8px",
  },
  text: {
    fontWeight: "400",
    fontSize: "14px",
    marginBottom: "8px",
    opacity: "0.6",
  },
  focusText: {
    color: "#F22929",
    fontSize: "14px",
    fontWeight: "700",
  },
  focusInput: {
    border: "1px solid #F22929",
  },
}));

// 컨텐츠 관리 > 작품 > 스토리 목록 > 상세 팝업 내에서 사용되는 셀렉트박스.
const SelectItems3 = ({ items, onChange, defaultItem, disabled }) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(defaultItem);
  const handleChange = (event) => {
    setSelectedItem(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          value={selectedItem}
          onChange={handleChange}
          input={<SelectInput />}
          disabled={disabled}
        >
          {items.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.id}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

SelectItems3.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

SelectItems3.defaultProps = {
  defaultItem: 1,
  disabled: false,
};

export default SelectItems3;
