import { Button, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import { paymentsApi } from "../../api/payments";
import Loading from "../common/Loading";
import { styled } from "@material-ui/core";
import PaymentCancelModal from "./PaymentCancelModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1000px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  subTitle: {
    fontFamily: "NotoSansKR",
    fontSize: "20px",
    color: "black",
    margin: "0px 12px",
    padding: "20px 25px ",
    fontWeight: 800,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

function PaymentDetailModal({ open, handleClose, paymentId, userId }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [detailData, setDetailData] = useState(null);
  const [prdData, setPrdData] = useState([]);
  const [click, setClick] = useState(0);

  const handleCloseModal = (result) => {
    handleClose(result === true);
    setDetailData(null);
    setPrdData([]);
    setClick((prev) => prev + 1);
  };

  //modal
  const [openPaymentCancel, setOpenPaymentCancel] = useState(false);

  const getIsCanceled = (value) => {
    if (value) {
      getData();
    }
  };

  const getData = async () => {
    setLoading(true);
    if (paymentId !== 0) {
      const { status, data } = await paymentsApi.detail(paymentId);
      if (status === 200) {
        setDetailData(data[0]);
        setPrdData(data[1]);
      } else {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [paymentId, click]);

  if (loading) {
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <Loading />
    </Modal>;
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>
          결제 세부 내역 - {userId}
        </Typography>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "16px",
            margin: "0px 12px",
            padding: "20px 20px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
          }}
        >
          결제자 정보
        </Typography>
        <Table
          sx={{
            width: "900px",
            marginBottom: "30px",
          }}
          align="center"
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                회원 ID
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData ? detailData.userId : ""}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                프로필명
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData && detailData.users && detailData.users.nickname
                  ? detailData.users.nickname
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                결제 메일
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData ? detailData.payUserAccount : ""}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                결제 일시
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData
                  ? moment(detailData.createdAt).format("YYYY-MM-DD HH:mm:ss")
                  : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "16px",
            margin: "0px 12px",
            padding: "20px 20px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
          }}
        >
          결제 정보
        </Typography>
        <Table
          sx={{
            width: "900px",
            marginBottom: "30px",
          }}
          align="center"
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                상품 패키지명
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData ? detailData.prdCd : ""}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                승인번호
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData ? detailData.orderId : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                구매구분
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData ? detailData.device : ""}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                구매환경
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData ? detailData.device : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                결제금액
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData ? detailData.payAmt : ""}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                결제구분
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData && detailData.payMethod === 0
                  ? "앱스토어 결제"
                  : "기타 결제"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                결제(환불) 일시
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {detailData
                  ? moment(detailData.updatedAt).format("YYYY-MM-DD HH:mm:ss")
                  : ""}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                }}
              >
                토큰환산
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                }}
              >
                {prdData.length !== 0
                  ? prdData.length === 1
                    ? `${prdData[0].applyToken} 토큰`
                    : `${prdData[0].applyToken} 토큰 (보너스 ${prdData[1].applyToken} 토큰)`
                  : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ButtonContainer>
          <Button className={classes.cancelButton} onClick={handleCloseModal}>
            목록
          </Button>
          {detailData && detailData.payClCd === "0" ? (
            <Button
              className={classes.editButton}
              onClick={() => {
                setOpenPaymentCancel(true);
              }}
            >
              결제 취소
            </Button>
          ) : (
            ""
          )}
        </ButtonContainer>
        <PaymentCancelModal
          open={openPaymentCancel}
          handleClose={() => {
            setOpenPaymentCancel(false);
          }}
          paymentId={paymentId}
          userId={userId}
          isCanceled={getIsCanceled}
        />
      </div>
    </Modal>
  );
}

const ButtonContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  height: "50px",
  alignItems: "center",
});

PaymentDetailModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  paymentId: PropTypes.number,
  userId: PropTypes.number,
};

export default PaymentDetailModal;
