import axiosInstance from "./axiosInstance";

const adminBase = "tock/admin";

const service = `${adminBase}/service`;
const previewVideo = `${service}/previewVideo`;
const genre = `${service}/genre`;
const series = `${service}/series`;
const story = `${service}/story`;
const mainBanner = `${service}/mainBanner`;
const normal = `tock`;
const normalSeries = `${normal}/series`;

const adminRanking = `${adminBase}/adminRanking`;
// 메인 작품 
const mainSeriesRanking = `${adminBase}/mainSeriesRanking`;
// 메인 작가
const mainAuthorRanking = `${adminBase}/mainAuthorRanking`;

//플랫폼 버전 관리
const platformVersion =  `${normal}/platformVersion`;

export const adminRankingApi = {
  list: () => axiosInstance.get(`${adminRanking}`),
  add: (seriesIds) => axiosInstance.post(`${adminRanking}`, { seriesIds }),
};

//메인 작품 API
export const mainSeriesRankingApi = {
  //목록
  list: (genreId) => axiosInstance.get(`${mainSeriesRanking}`, {params:{genreId}}),
  
  //랭킹 요인 테스트
  getSeriesRankingTest : (
    likePoint
    , followPoint
    , replyPoint
    , viewPoint
    , range
    , genreId
  ) => axiosInstance.get(`${mainSeriesRanking}/test`, 
    {
      params:{
        likePoint
        , followPoint
        , replyPoint
        , viewPoint
        , range
        , genreId
      }
    }
  ),
  
  //랭킹 등록
  setMainSeriesRanking: (genreId, seriesList) => axiosInstance.put(`${mainSeriesRanking}/update`, {genreId, seriesList})
}

//메인 작가 API
export const mainAuthorRankingApi = {

  //목록
  list: () => axiosInstance.get(`${mainAuthorRanking}`),

  // 전체 작가 목록
  getAuthorList: (
    startAt
    , endAt
    , division
    , keyword
  ) => axiosInstance.get(`${mainAuthorRanking}/getAuthorList`, {
      params:{
        startAt
        , endAt
        , division
        , keyword
      }
    }
  ),

  //메인 작가 등록
  setMainAuthorRanking: (authorList) => axiosInstance.put(`${mainAuthorRanking}/update`, {authorList})
};

export const serviceApi = {
  updatePreviewVideoInfo: (
    previewVideoId,
    { isPublic, orderNumber, seriesId }
  ) => {
    return axiosInstance.put(`${previewVideo}/info`, {
      previewVideoId,
      isPublic,
      orderNumber,
      seriesId,
    });
  },
  getPreviewVideoList: (limit, offset) => {
    return axiosInstance.get(`${previewVideo}/list`, {
      params: { limit, offset },
    });
  },
  updatePreviewVideoFile: (form) => {
    return axiosInstance.put(`${previewVideo}/file`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  addPreviewVideo: () => {
    return axiosInstance.post(`${previewVideo}`);
  },
  deletePreviewVideo: (previewVideoId) => {
    return axiosInstance.delete(`${previewVideo}`, {
      params: { previewVideoId },
    });
  },
  getSeriesList: (orderType, genreId) => {
    return axiosInstance.get(`${normalSeries}`, {
      params: { orderType, genreId},
    });
  },
  getGenreList: () => {
    return axiosInstance.get(`${genre}/list`);
  },
  getSeriesListByGenreId: (genreId) => {
    return axiosInstance.get(`${series}/listByGenreId`, {
      params: { genreId },
    });
  },
  getStoriesBySeriesId: (seriesId) => {
    return axiosInstance.get(`${story}/listBySeriesId`, {
      params: { seriesId },
    });
  },
  addMainBanner: (data) => {
    return axiosInstance.post(mainBanner, {
      isPublic: data.isPublic,
      startDate: data.startDate,
      endDate: data.endDate,
      orderNumber: data.orderNumber,
      title: data.title,
      linkType: data.linkType,
      seriesId: data.seriesId,
      storyId: data.storyId,
    });
  },
  deleteMainBanner: (mainBannerId) => {
    return axiosInstance.delete(mainBanner, {
      params: { mainBannerId },
    });
  },

  deleteMainBannerImageFile: (mainBannerId) => {
    return axiosInstance.delete(`${mainBanner}/imageFile`, {
      params: { mainBannerId },
    });
  },

  updateMainBannerInfo: (mainBannerId, data) => {
    return axiosInstance.put(mainBanner, {
      mainBannerId,
      ...data,
    });
  },
  updateMainBannerImageFile: (form) => {
    return axiosInstance.put(`${mainBanner}/imageFile`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getMainBannerList: (limit, offset, search) => {
    return axiosInstance.get(`${mainBanner}/list`, {
      params: { limit, offset, search },
    });
  },
  getMainBanner: (mainBannerId) => {
    return axiosInstance.get(`${mainBanner}`, {
      params: { mainBannerId },
    });
  },

  getStoryReplyReportList: (limit, offset) => {
    return axiosInstance.get(`${story}/reply/report/list`, {
      params: { limit, offset },
    });
  },

  getContentReportList: (limit, offset) => {
    return axiosInstance.get(`${service}/contents/reports`, {
      params: { limit, offset },
    });
  },

  getUserResource: () => {
    return axiosInstance.get("tock/userResources");
  },

  test: (testDate) => {
    return axiosInstance.put(`${service}/test`, {
      testDate,
    });
  },
  //플랫폼 버전 관리
  platformVersionList: () => {
    return axiosInstance.get(`${platformVersion}`);
  },

  //플랫폼 버전 관리 정보 가져오기
  platformVersionInfo: (id) => {
    return axiosInstance.get(`${platformVersion}/info`, {
      params: {id}
    });
  },

  //플랫폼 버전 추가
  addPlatformVersion: (data) => {
    return axiosInstance.put(`${platformVersion}/add`, {
      ...data
    });
  },
  
  // 플랫폼 버전 수정
  modifyPlatformVersion: (data) => {
    return axiosInstance.put(`${platformVersion}/modify`, {
      ...data
    });
  },

  //플랫폼 버전 삭제
  deletePlatformVersion:(id) => {
    return axiosInstance.delete(`${platformVersion}/delete/${id}`);
  }
};

