import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@material-ui/core";
import toast from "react-hot-toast";
import AdminRoot from "../components/frame/AdminRoot";
import SearchBar from "../components/contents/SearchBar";
import { tockCommonApi } from "../api/tock";
import ContentsList from "../components/contents/ContentList";

const Contents = () => {
  const [loading, setLoading] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [click, setClick] = useState(0);

  const getClick = () => {
    setClick((click) => click + 1);
    getData();
  };

  const getSeriesList = async (
    startDate,
    endDate,
    division,
    keyword,
    checkItems,
    isFinished
  ) => {
    try {
      setLoading(true);
      const { data } = await tockCommonApi.getSeries(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        division,
        keyword,
        checkItems,
        isFinished
      );

      setSearchedList(data);
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = useCallback(async () => {
    setLoading(true);
    const { status, data } = await tockCommonApi.getSeries(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    setLoading(false);
    if (status === 200) {
      setSearchedList(data);
    } else {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (click) {
      getData();
    }
  }, [click, getData]);

  return (
    <AdminRoot>
      <Title>작품</Title>
      <>
        <SearchBar getSeriesList={getSeriesList} />
        <ContentsList
          loading={loading}
          searchedList={searchedList}
          getClick={getClick}
        />
      </>
    </AdminRoot>
  );
};

const Title = styled("div")({
  marginBottom: "20px",
});

export default Contents;
