import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import AdminRoot from "../frame/AdminRoot";
import Box from "@mui/material/Box";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { convertDate } from "../lib/utils";
import { Link } from "react-router-dom";
import { pushApi } from "../../api/push";
import toast from "react-hot-toast";
import PushTestModal from "./PushTestModal";
import PushLink from "./PushLink_RegisterPush";

function RegisterPush() {
  const history = useHistory();
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState(new Date());
  const pushDate = convertDate(date, hour);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [android, setAndroid] = useState(false);
  const [iOs, setIOs] = useState(false);
  const [pushOptions, setPushOptions] = useState({
    sendDevice: "",
    process: "",
    onlyAgreePush: true,
  });
  const [pushContents, setPushContent] = useState({ title: "", content: "" });
  const pushImgInput = useRef();
  const [openTestModal, setOpenTestModal] = useState(false);
  const [linkValue, setLinkValue] = useState("home");
  const [pushLink, setPushLink] = useState({
    seriesId: undefined,
    storyId: undefined,
  });

  // '안드로이드/iOS' 옵션 선택,
  const onDeviceSelect = (checked, device) => {
    if (checked === true && device === "android") {
      setAndroid(true);
    } else if (checked === false && device === "android") {
      setAndroid(false);
    } else if (checked === true && device === "iOs") {
      setIOs(true);
    } else if (checked === false && device === "iOs") {
      setIOs(false);
    }
  };
  useEffect(() => {
    if (android === true && iOs === false) {
      setPushOptions({ ...pushOptions, sendDevice: 1 });
    } else if (android === false && iOs === false) {
      setPushOptions({ ...pushOptions, sendDevice: "" });
    } else if (android === false && iOs === true) {
      setPushOptions({ ...pushOptions, sendDevice: 2 });
    } else if (android === true && iOs === true) {
      setPushOptions({ ...pushOptions, sendDevice: 3 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [android, iOs]);

  //'예약발송/즉시발송' 옵션 선택
  const onPushTimingSelect = (event) => {
    setPushOptions({ ...pushOptions, process: Number(event.target.value) });
  };

  // 날짜와 시간 picker의 'x'버튼 눌렀을 때의 초기화
  const onCleanDate = () => {
    setDate(new Date());
  };
  const onCleanHour = () => {
    setDate(new Date());
  };

  // '앱푸쉬 동의자 발송' 체크 박스 선택
  const onOnlyConsenterCheck = (checked) => {
    setPushOptions({ ...pushOptions, onlyAgreePush: checked });
  };

  // '제목'과 '메시지' 입력 핸들러
  const onInputChange = (event) => {
    setPushContent({
      ...pushContents,
      [event.target.name]: event.target.value,
    });
  };

  // 이미지 삽입시 실행함수
  // 이미지 삽입위한 '이미지 등록' 버튼 클릭
  const onImgBtnClick = (event) => {
    event.preventDefault();
    pushImgInput.current.click();
  };
  // 이미지 파일 저장 및 미리 보여주기
  const onImageChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  // 모달 관련 함수
  const onOpenTestModal = () => setOpenTestModal(true);
  const onCloseTestModal = () => setOpenTestModal(false);

  // '링크' 메뉴에 대한 라디오버튼 선택 이벤트
  const onLinkValueChange = (event) => {
    setLinkValue(event.target.value);
  };

  // 마지막 '저장 및 발송' 버튼으로 API Post
  const postNewPush = async () => {
    if (!pushOptions.sendDevice) {
      toast.error("발송대상자 기종을 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    if (!pushOptions.process) {
      toast.error("발송 처리를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    if (!pushContents.title || !pushContents.content) {
      toast.error("제목 및 메시지를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    const formData = new FormData();
    formData.append("title", pushContents.title);
    formData.append("content", pushContents.content);
    formData.append("sendDevice", pushOptions.sendDevice);
    formData.append("onlyAgreePush", pushOptions.onlyAgreePush);
    formData.append("process", pushOptions.process);
    formData.append("pushDate", pushDate);
    formData.append("image", imageFile);
    if (pushLink.seriesId) {
      formData.append("seriesId", pushLink.seriesId);
    }
    if (pushLink.storyId) {
      formData.append("storyId", pushLink.storyId);
    }
    try {
      await pushApi.add(formData);
      toast.success("push 추가 되었습니다.");
      history.push({ pathname: "/push" });
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <AdminRoot>
      <Box>
        <Title>푸시발송 등록/변경</Title>
      </Box>
      <Box>
        <H3>1단계 : 푸쉬발송 대상자</H3>
        <Inner style={{ height: "60px" }}>
          <Cell1>발송대상자</Cell1>
          <Cell2>
            <div style={{ marginRight: "20px" }}>기종</div>
            <FormControlLabel
              control={
                <Checkbox
                  name="android"
                  onChange={(event) =>
                    onDeviceSelect(event.target.checked, event.target.name)
                  }
                />
              }
              label="안드로이드"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="iOs"
                  onChange={(event) =>
                    onDeviceSelect(event.target.checked, event.target.name)
                  }
                />
              }
              label="iOs"
            />
          </Cell2>
        </Inner>
      </Box>
      <Box>
        <H3>2단계 : 발송 정보</H3>
        <Inner>
          <Cell1>발송처리</Cell1>
          <Cell2>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">발송처리</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pushOptions.process}
                label="발송처리"
                onChange={onPushTimingSelect}
              >
                <MenuItem value="1">예약발송</MenuItem>
                <MenuItem value="2">즉시발송</MenuItem>
              </Select>
            </FormControl>
          </Cell2>
        </Inner>
        <Inner>
          <Cell1 style={{ borderTop: 0, borderBottom: 0 }}>예약발송</Cell1>
          <Cell2 style={{ borderTop: 0, borderBottom: 0 }}>
            <DatePicker
              format="yyyy-MM-dd"
              ranges={[]}
              value={date}
              onChange={setDate}
              onClean={onCleanDate}
              size="lg"
              placeholder="Select Date"
              style={{ width: 260 }}
              disabled={pushOptions.process === 2}
            />
            <DatePicker
              format="HH:mm"
              ranges={[]}
              value={hour}
              onChange={setHour}
              onClean={onCleanHour}
              size="lg"
              style={{ width: 260 }}
              disabled={pushOptions.process === 2}
            />
          </Cell2>
        </Inner>
        <Inner>
          <Cell1>앱푸쉬동의</Cell1>
          <Cell2>
            <FormControlLabel
              control={
                <Checkbox
                  name="onlyAgreePush"
                  onChange={(event) =>
                    onOnlyConsenterCheck(event.target.checked)
                  }
                  checked={pushOptions.onlyAgreePush === true ? true : false}
                />
              }
              label="앱푸쉬 알람 동의자에게만 발송"
            />
          </Cell2>
        </Inner>
      </Box>
      <Box>
        <H3>발송 메시지</H3>
        <Inner style={{ height: "60px" }}>
          <Cell1>제목 *</Cell1>
          <Cell2>
            <TextInput
              name="title"
              maxLength="30"
              autoComplete="off"
              required
              placeholder="제목을 입력하세요"
              onChange={onInputChange}
            />
          </Cell2>
        </Inner>
        <Inner style={{ height: "120px" }}>
          <Cell1 style={{ borderTop: 0, borderBottom: 0 }}>메시지 *</Cell1>
          <Cell2 style={{ borderTop: 0, borderBottom: 0 }}>
            <TextArea
              name="content"
              maxLength="120"
              required
              placeholder="내용을 입력하세요"
              onChange={onInputChange}
            />
          </Cell2>
        </Inner>
        <Inner style={{ height: "120px" }}>
          <Cell1>이미지</Cell1>
          <Cell2>
            <Form encType="multipart/form-data">
              <ImageInput
                ref={pushImgInput}
                type="file"
                accept="image/*"
                onChange={onImageChange}
              />
              <Button onClick={onImgBtnClick}>이미지 등록</Button>
            </Form>
            <ImagePaint>
              {image === null ? (
                <></>
              ) : (
                <Img src={image} alt="inserted_image" />
              )}
            </ImagePaint>
          </Cell2>
        </Inner>
        <Inner style={{ height: "190px" }}>
          <Cell1>링크 *</Cell1>
          <Cell2>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="home"
                name="radio-buttons-group"
                value={linkValue}
                onChange={onLinkValueChange}
              >
                <FormControlLabel
                  value="home"
                  control={<Radio size="small" />}
                  label="홈"
                  sx={{ height: "44px" }}
                />
                <FormControlLabel
                  value="notice"
                  control={<Radio size="small" />}
                  label="공지사항"
                  sx={{ height: "44px" }}
                />
                <FormControlLabel
                  value="contentsMain"
                  control={<Radio size="small" />}
                  label="작품메인"
                  sx={{ height: "44px" }}
                />
                <FormControlLabel
                  value="contentsEach"
                  control={<Radio size="small" />}
                  label="작품(회차)"
                  sx={{ height: "44px" }}
                />
              </RadioGroup>
            </FormControl>
            <PushLink linkValue={linkValue} setPushLink={setPushLink} />
          </Cell2>
        </Inner>
        <Inner
          style={{
            height: "40px",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Link to="/push">
            <Button
              color="orange"
              appearance="subtle"
              style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
            >
              취소
            </Button>
          </Link>
          <Button
            color="orange"
            appearance="subtle"
            style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
            onClick={onOpenTestModal}
          >
            테스트 발송
          </Button>
          <PushTestModal
            openTestModal={openTestModal}
            onCloseTestModal={onCloseTestModal}
            title={pushContents.title}
            content={pushContents.content}
            imageFile={imageFile}
            seriesId={pushLink.seriesId}
            storyId={pushLink.storyId}
          />
          <Button
            color="orange"
            appearance="subtle"
            style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
            onClick={postNewPush}
          >
            저장 및 발송
          </Button>
        </Inner>
      </Box>
    </AdminRoot>
  );
}

const Title = styled("div")({
  marginBottom: "20px",
});
const H3 = styled("h3")({
  font: '500 18px "Roboto","Helvetica","Arial", sans-serif',
  margin: "20px 0 10px",
});
const Inner = styled("div")({
  position: "relative",
  height: "60px",
  display: "flex",
  justifyContent: "flex-start",
});
const Cell1 = styled("div")({
  border: "1px solid gray",
  borderRight: "0",
  backgroundColor: "lightgray",
  width: "150px",
  height: "100%",
  paddingLeft: "10px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Cell2 = styled("div")({
  border: "1px solid gray",
  height: "100%",
  paddingRight: "30px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexGrow: 1,
  paddingLeft: "30px",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const TextInput = styled("input")({
  width: "100%",
  height: "40px",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  padding: "10px ",
  backgroundColor: "white",
});
const TextArea = styled("textarea")({
  width: "100%",
  height: "100px",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  padding: "10px",
  backgroundColor: "white",
  wordBreak: "keep-all",
});
const Form = styled("form")({
  width: "50%",
});
const ImageInput = styled("input")({
  display: "none",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  backgroundColor: "white",
});
const ImagePaint = styled("div")({
  width: "110px",
  height: "110px",
});
const Img = styled("img")({
  border: "1px solid lightgray",
  width: "110px",
  height: "110px",
});

export default RegisterPush;
