import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";
import { convertDate } from "../lib/utils";
import { Button, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// 서비스 관리 > 공지사항에 있는 검색부분
function ServiceSearchBar({ getNoticesList }) {
  //작품 구분
  const [checkItems, setCheckItems] = useState("0");

  const handleChange = (e) => {
    setCheckItems(e.target.value);
  };

  //검색
  const [division, setDivision] = useState("");
  const [keyword, setKeyword] = useState("");

  const onDivisionChange = (event) => {
    setDivision(event.target.value);
  };

  const onKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  //날짜 입력
  const [date, setDate] = useState([new Date("2022-01-01"), new Date()]);
  const startAt = convertDate(date[0]);
  const endAt = convertDate(date[1]);

  // '오늘', '1주일', '1개월', '3개월', '6개월', '전체' 클릭시 날짜 세팅
  const onPeriodClick = (event) => {
    const created_date = new Date();
    const thisYear = created_date.getFullYear();
    const thisMonth = created_date.getMonth() + 1;
    const today = created_date.getDate();
    if (event.target.name === "today") {
      setDate([new Date(), new Date()]);
    } else if (event.target.name === "week") {
      setDate([new Date(thisYear, thisMonth - 1, today - 6), new Date()]);
    } else if (event.target.name === "month1") {
      setDate([new Date(thisYear, thisMonth - 2, today), new Date()]);
    } else if (event.target.name === "month3") {
      setDate([new Date(thisYear, thisMonth - 4, today), new Date()]);
    } else if (event.target.name === "month6") {
      setDate([new Date(thisYear, thisMonth - 7, today), new Date()]);
    } else if (event.target.name === "whole") {
      setDate([new Date("2022-01-01"), new Date()]);
    }
  };

  // '검색'버튼 클릭시 푸시리스트 api get 요청
  const onSearchBtnClick = () => {
    if (keyword !== "" && division == "") {
      alert("구분 값을 선택하여 주세요.");
    }
    getNoticesList(startAt, endAt, division, keyword, checkItems);
  };

  // '초기화'버튼과 달력내부 'X'버튼 클릭시 초기화
  const onCleanBtnClick = () => {
    setDate([new Date("2022-01-01"), new Date()]);
    setKeyword("");
  };

  // Keyword 인풋창에서 입력 후 엔터키 누르면 검색.
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearchBtnClick();
    }
  }

  return (
    <SearchContainer>
      <Inner1>
        <Text>게시여부</Text>
        <FormControl>
              <RadioGroup 
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={checkItems} 
                onChange={handleChange}>
                <FormControlLabel value="0" control={<Radio />} label="전체" />
                <FormControlLabel value="Y" control={<Radio />} label="게시" />
                <FormControlLabel value="N" control={<Radio />} label="비공개" />
              </RadioGroup>
        </FormControl>
      </Inner1>

      <Inner1>
        <Text>기간</Text>
        <DateRangePicker
          format="yyyy. MM. dd"
          ranges={[]}
          value={date}
          onChange={setDate}
          onClean={onCleanBtnClick}
          placeholder="select date range"
        />
        <Button
          name="today"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          오늘
        </Button>
        <Button
          name="week"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          1주일
        </Button>
        <Button
          name="month1"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          1개월
        </Button>
        <Button
          name="month3"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          3개월
        </Button>
        <Button
          name="month6"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          6개월
        </Button>
        <Button
          name="whole"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          전체
        </Button>
      </Inner1>
      <Inner1>
        <Text>검색 구분</Text>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="select-search-label">Search</InputLabel>
          <Select
            labelId="select-search-label"
            id="select-search"
            label="Search"
            value={division}
            onChange={onDivisionChange}
          >
            <MenuItem value={"title"}>제목</MenuItem>
            <MenuItem value={"registrant"}>작성자</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Keyword" value={keyword} onChange={onKeywordChange} onKeyPress={handleOnKeyPress} />
      </Inner1>
      <Inner2>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onSearchBtnClick}
        >
          검색
        </Button>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onCleanBtnClick}
        >
          초기화
        </Button>
      </Inner2>
    </SearchContainer>
  );
}

const SearchContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});
const Inner1 = styled("div")({
  position: "relative",
  width: "100%",
  marginBottom: "20px",
  border: "1px solid #e0e0e0",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});
const Inner2 = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Text = styled("p")({
  border: "1px solid black",
  width: "100px",
  height: "37px",
  marginRight: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#bdbdbd",
  color: "black",
});

ServiceSearchBar.propTypes = {
  getSeriesList: PropTypes.func.isRequired,
};

export default ServiceSearchBar;
