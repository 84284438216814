import React from "react";
import "../node_modules/video-react/dist/video-react.css";
import "../node_modules/@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import store from "./redux/store";
import Router from "./components/Router";
import theme from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";

const App = () => (
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </LocalizationProvider>
  </Provider>
);

export default App;
