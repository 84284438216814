import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import toast from "react-hot-toast";
import { Button, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { modalStyle } from "../../lib/utils";
import Loading from "../../common/Loading";
import { statisticsApi } from "../../../api/statistics";

const ContentsModal = React.memo(
  ({ openContentsModal, onCloseContentsModal, contentId, contentTitle }) => {
    // state
    const [apiResults, setApiResults] = useState(null);
    const [loading, setLoading] = useState(false);

    // console.log("apiResults :", apiResults);

    // 모달창 열림과 동시에 filter, id로 Api Get 요청
    // eslint-disable-next-line
    const getUsersActivity = async () => {
      if (contentId !== undefined) {
        try {
          setLoading(true);
          const { status, data } = await statisticsApi.getSeriesStory(
            contentId
          );
          // console.log("statisticsApi", status, data);
          if (status === 200) {
            setApiResults(data);
          }
        } catch (error) {
          toast.error("네트워크 에러", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        } finally {
          setLoading(false);
        }
      }
    };
    useEffect(getUsersActivity, [contentId]);

    return (
      <Modal
        open={openContentsModal}
        onClose={onCloseContentsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {loading || contentId === undefined || apiResults === null ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              <ModalContainer>
                <TopInner>
                  <MainText>회차별 상세정보 : {contentTitle}</MainText>
                  <Button
                    sx={{
                      borderRadius: "50%",
                      width: "30px",
                      height: "64px",
                      marginRight: "5px",
                    }}
                    onClick={onCloseContentsModal}
                  >
                    <ClearIcon sx={{ color: "black" }} />
                  </Button>
                </TopInner>
                <Count>전체 {apiResults.length}건</Count>
                <TableInner>
                  <RowBox style={{ position: "sticky", top: 0 }}>
                    <Header style={{ width: "10%" }}>No</Header>
                    <Header
                      style={{ width: "51%", justifyContent: "flex-start" }}
                    >
                      회차명
                    </Header>
                    <Header style={{ width: "13%" }}>조회 수</Header>
                    <Header style={{ width: "13%" }}>좋아요 수</Header>
                    <Header style={{ width: "13%" }}>댓글 수</Header>
                  </RowBox>
                  {apiResults.map((series, idx) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <RowBox key={idx}>
                        <Cell style={{ width: "10%" }}>{idx + 1}</Cell>
                        <Cell
                          style={{ width: "51%", justifyContent: "flex-start" }}
                        >
                          {series.name}
                        </Cell>
                        <Cell style={{ width: "13%" }}>{series.viewCount}</Cell>
                        <Cell style={{ width: "13%" }}>{series.likeCount}</Cell>
                        <Cell style={{ width: "13%" }}>
                          {series.storyReplies?.length ?? 0}
                        </Cell>
                      </RowBox>
                    );
                  })}
                </TableInner>
              </ModalContainer>
            </>
          )}
        </Box>
      </Modal>
    );
  }
);

ContentsModal.defaultProps = {
  contentId: undefined,
  contentTitle: undefined,
};

ContentsModal.propTypes = {
  openContentsModal: PropTypes.bool.isRequired,
  onCloseContentsModal: PropTypes.func.isRequired,
  contentId: PropTypes.number,
  contentTitle: PropTypes.string,
};

const ModalContainer = styled("div")({
  // overflow: 'auto',
});
const MainText = styled("h1")({
  font: '700 23px "Noto Sans KR"',
  color: "#333",
  margin: "20px 0",
});
const TopInner = styled("div")({
  width: "104%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const Count = styled("div")({
  marginBottom: "10px",
  width: "100%",
  font: '700 16px "Noto Sans KR", sans-serif',
  textAlign: "right",
});
const TableInner = styled("div")({
  width: "100%",
  height: "560px",
  position: "relative",
  overflow: "auto",
});
const RowBox = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
const Header = styled("div")({
  backgroundColor: "lightgray",
  height: "50px",
  padding: "0 10px",
  font: '700 16px "Noto Sans KR", sans-serif',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Cell = styled("div")({
  height: "50px",
  padding: "0 10px",
  font: '500 16px "Noto Sans KR", sans-serif',
  borderBottom: "1px solid lightgray",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default ContentsModal;
