import React from "react";
import AdminRoot from "../components/frame/AdminRoot";
import ContentReportTable from "../components/service/ContentReportTable";

const ContentReport = () => {
  return (
    <AdminRoot>
      <div>작품 신고</div>
      <ContentReportTable />
    </AdminRoot>
  );
};
export default ContentReport;
