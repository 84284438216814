import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import toast from "react-hot-toast";
import { styled } from "@material-ui/core";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TablePaginationActions from "../push/TablePaginationActions";
import { Button } from "rsuite";
import { tockCommonApi } from "../../api/tock";
import ChangeCpComanyModal from "../modal/ChangeCpComanyModal";

function CpList({ searchedCpList, getData }) {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dataLength = searchedCpList.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal
  const [openCpChange, setOpenCpChange] = useState(false);
  const [openCpId, setOpenCpId] = useState(0);

  //Check box
  const [checkItems, setCheckItems] = useState([]);
  const [clickUpdateBtn, setClickUpdateBtn] = useState(false);

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      const startRow = page * rowsPerPage;
      const endRow = (page + 1) * rowsPerPage;
      const idArray = [];
      for (let i = startRow; i < endRow; i++) {
        idArray.push(searchedCpList[i].id);
      }
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  const updateCpCompany = async (event) => {
    event.preventDefault();

    if (checkItems.length !== 1) {
      toast.error("하나의 CP사를 선택해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      setOpenCpChange(true);
      setOpenCpId(checkItems[0]);
      setClickUpdateBtn(true);
    }
  };

  const deleteCpCompany = async (event) => {
    event.preventDefault();

    if (checkItems.length === 0) {
      toast.error("삭제할 CP사를 선택해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      const result = await tockCommonApi.deleteCpCompany(checkItems);

      if (result.status === 200) {
        getData();
      } else {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    }
  };

  return (
    <>
      <ListContainer>
        <Inner>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#e0e0e0",
                    height: "60px",
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    sx={{ width: "2%" }}
                    align="center"
                  >
                    <Checkbox
                      color="primary"
                      onChange={(e) => handleAllCheck(e.target.checked)}
                      checked={checkItems.length === rowsPerPage ? true : false}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "6%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    No
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "8%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    CP/작가
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "12%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    담당자
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "12%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    사업자등록번호
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "14%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    계좌번호
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "10%",
                      wordBreak: "keep-all",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    은행
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "14%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    전화번호
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "10%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    계약기간
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "8%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    RS 요율
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "8%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    비고
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? searchedCpList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : searchedCpList
                ).map((cp, index) => (
                  <TableRow key={cp.id}>
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        onChange={(e) =>
                          handleSingleCheck(e.target.checked, cp.id)
                        }
                        checked={checkItems.includes(cp.id) ? true : false}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {dataLength - index - page * rowsPerPage}
                    </TableCell>
                    <TableCell align="center">{cp.name}</TableCell>
                    <TableCell align="center">{cp.manager}</TableCell>
                    <TableCell align="center">
                      {cp.comRegisterNum === 0 ? "" : cp.comRegisterNum}
                    </TableCell>
                    <TableCell align="center">{cp.accountNum}</TableCell>
                    <TableCell align="center">{cp.bank}</TableCell>
                    <TableCell align="center">{cp.phoneNum}</TableCell>
                    <TableCell align="center">
                      {moment(cp.contractStart).format("YYYY-MM-DD")} ~{" "}
                      {moment(cp.contractEnd).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="center">{cp.revenueShare}</TableCell>
                    <TableCell align="center">{cp.note}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={11}
                    count={searchedCpList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Inner>
      </ListContainer>
      <InnerBtn>
        <Button
          color="green"
          appearance="ghost"
          style={{ margin: "0 5px" }}
          onClick={updateCpCompany}
        >
          수정
        </Button>
        <Button
          color="orange"
          appearance="primary"
          onClick={deleteCpCompany}
          style={{ margin: "0 5px" }}
        >
          삭제
        </Button>
      </InnerBtn>
      {clickUpdateBtn ? (
        <ChangeCpComanyModal
          open={openCpChange}
          handleClose={() => setOpenCpChange(false)}
          getData={getData}
          cpId={openCpId}
        />
      ) : (
        ""
      )}
    </>
  );
}

CpList.propTypes = {
  searchedPaymentList: PropTypes.array,
  getData: PropTypes.func,
};

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const InnerBtn = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "left",
  alignItems: "left",
});

export default CpList;
