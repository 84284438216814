import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import toast from "react-hot-toast";
import CancelIcon from "@mui/icons-material/Cancel";
import AdminRoot from "../components/frame/AdminRoot";
import { ReOrderableItem, ReOrderableList } from "react-reorderable-list";
import "react-reorderable-list/dist/index.css";
import { Button, IconButton, Typography } from "@material-ui/core";
import SeriesSelectModal from "../components/service/SeriesSelectModal";
import { adminRankingApi } from "../api/service";

const Recommended = () => {
  const [openSelectSeries, setOpenSelectSeries] = useState(false);
  const [seriesList, setSeriesList] = useState([]);

  // server upload
  const send = async () => {
    const seriesIds = seriesList.map((item) => item.seriesId);
    const { status } = await adminRankingApi.add(seriesIds);
    if (status === 200) {
      toast.success("배포 완료 되었습니다.");
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { status, data } = await adminRankingApi.list();
      if (status === 200) {
        setSeriesList(data);
      }
    };
    getData();
  }, []);

  return (
    <AdminRoot>
      <Typography style={{ marginLeft: "20px" }}>
        추천! 어머~이건 봐야해
      </Typography>
      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <Button
          style={{ margin: "20px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            setOpenSelectSeries(true);
          }}
        >
          작품 추가
        </Button>
        <Button
          style={{ margin: "20px" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={send}
        >
          작품 순서 배포
        </Button>
      </div>
      <Box sx={{ padding: "30px" }}>
        <ReOrderableList
          list={seriesList}
          onListUpdate={(newList) => setSeriesList(newList)}
        >
          {seriesList.map((item, index) => (
            <ReOrderableItem key={`item-${index}`}>
              <div
                style={{
                  height: "50px",
                  border: "1px solid #333333",
                  borderRadius: "10px",
                  margin: "10px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>{`${item.name} (id:${item.seriesId})`}</Typography>
                <IconButton
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    const newSeries = seriesList.filter(
                      (series) => series.seriesId !== item.seriesId
                    );
                    setSeriesList(newSeries);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </ReOrderableItem>
          ))}
        </ReOrderableList>
      </Box>
      <SeriesSelectModal
        open={openSelectSeries}
        onClose={() => {
          setOpenSelectSeries(false);
        }}
        onOK={(value) => {
          if (value) {
            const findSeries = seriesList.find(
              (item) => item.seriesId === value.seriesId
            );
            if (!findSeries) {
              setSeriesList([...seriesList, value]);
            }
            setOpenSelectSeries(false);
          }
        }}
        title={"시리즈"}
      />
    </AdminRoot>
  );
};
export default Recommended;
