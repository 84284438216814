import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
import { DateRangePicker } from "rsuite";
import { convertDate } from "../lib/utils";
import "rsuite/dist/rsuite.min.css";

function SearchBarOfAuthor({ title, getAuthor, setApiResults }) {
  // 날짜 입력받은 state
  const [date, setDate] = useState([new Date("2022-01-01"), new Date()]);
  const startAt = convertDate(date[0]);
  const endAt = convertDate(date[1]);
  // eslint-disable-next-line
  const [searchFilter, setSearchFilter] = useState("nickname");
  const [inputValue, setInputValue] = useState("");

  // '오늘', '1주일', '1개월', '3개월', '6개월', '전체' 클릭시 날짜 세팅
  const onPeriodClick = (event) => {
    const created_date = new Date();
    const thisYear = created_date.getFullYear();
    const thisMonth = created_date.getMonth() + 1;
    const today = created_date.getDate();
    if (event.target.name === "today") {
      setDate([new Date(), new Date()]);
    } else if (event.target.name === "week") {
      setDate([new Date(thisYear, thisMonth - 1, today - 6), new Date()]);
    } else if (event.target.name === "month1") {
      setDate([new Date(thisYear, thisMonth - 2, today), new Date()]);
    } else if (event.target.name === "month3") {
      setDate([new Date(thisYear, thisMonth - 4, today), new Date()]);
    } else if (event.target.name === "month6") {
      setDate([new Date(thisYear, thisMonth - 7, today), new Date()]);
    } else if (event.target.name === "whole") {
      setDate([new Date("2021-12-01"), new Date()]);
    }
  };

  // '검색'버튼 클릭시 통계 api get 요청
  const onSearchBtnClick = () => {
    getAuthor(startAt, endAt, searchFilter, inputValue);
  };

  // 검색카테고리 입력시 이벤트
  const onFilterChange = (event) => {
    setSearchFilter(event.target.value);
  };

  // 검색어 입력시 이벤트
  const onKeyWordInput = (event) => {
    setInputValue(event.target.value);
  };

  // '초기화'버튼과 달력내부 'X'버튼 클릭시 초기화
  const onCleanBtnClick = () => {
    setDate([new Date("2022-01-01"), new Date()]);
    setApiResults(null);
    setInputValue("");
  };

  return (
    <SearchContainer>
      <Inner1>
        <Text>검색기간</Text>
        <DateRangePicker
          format="yyyy. MM. dd"
          ranges={[]}
          value={date}
          onChange={setDate}
          onClean={onCleanBtnClick}
          placeholder="select date range"
        />
        <Button
          name="today"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          오늘
        </Button>
        <Button
          name="week"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          1주일
        </Button>
        <Button
          name="month1"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          1개월
        </Button>
        <Button
          name="month3"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          3개월
        </Button>
        <Button
          name="month6"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          6개월
        </Button>
        <Button
          name="whole"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          전체
        </Button>
      </Inner1>
      <Inner1 style={{ borderTop: 0 }}>
        <Text>검색구분</Text>
        <Select onChange={onFilterChange}>
          <option value="nickname">필명</option>
          <option value="authorId">작가id</option>
          <option value="contentTitle">작품이름</option>
        </Select>
        <Input
          value={inputValue}
          type="text"
          placeholder="Keyword"
          onChange={onKeyWordInput}
        />
      </Inner1>
      <Inner2>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onSearchBtnClick}
        >
          검색
        </Button>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onCleanBtnClick}
        >
          초기화
        </Button>
      </Inner2>
    </SearchContainer>
  );
}

SearchBarOfAuthor.propTypes = {
  getAuthor: PropTypes.func.isRequired,
  setApiResults: PropTypes.func.isRequired,
};

const SearchContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
  maxWidth: "1024px",
});
const Inner1 = styled("div")({
  position: "relative",
  width: "100%",
  border: "1px solid #e0e0e0",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});
const Inner2 = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Text = styled("p")({
  border: "1px solid black",
  width: "100px",
  height: "37px",
  marginRight: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#bdbdbd",
  color: "black",
});
const Select = styled("select")({
  display: "block",
  width: "110px",
  border: "1px solid #e0e0e0",
  height: "30px",
  borderRadius: "7px",
});
const Input = styled("input")({
  width: "350px",
  height: "30px",
  marginLeft: "10px",
  padding: "0 5px",
  border: "1px solid #E5E5E5",
  borderRadius: "4px",
  backgroundColor: "white",
});

export default SearchBarOfAuthor;
