import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { Button } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import moment from "moment";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Player } from "video-react";
import UserInfoModal from "../components/modal/UserInfoModal";
import AdminRoot from "../components/frame/AdminRoot";
import { serviceApi } from "../api/service";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const materialTableOptions = {
  search: true,
  sorting: true,
  draggable: false,
  showTitle: true,
  actionsColumnIndex: -1,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
  headerStyle: {
    backgroundColor: "#EEE",
    fontWeight: "bold",
    textAlign: "center",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  actionsCellStyle: {
    textAlign: "center",
  },
};

const UserResource = () => {
  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [openUserInfo, setOpenUserInfo] = useState(false);

  const materialTableColumns = [
    {
      title: "ID",
      field: "id",
      width: 50,
    },
    {
      title: "작가 필명",
      field: "users.nickname",
      render: (rowData) => {
        return (
          <Button
            variant="contained"
            style={{ textTransform: "none" }}
            onClick={() => {
              setUserInfo(rowData.users);
              setOpenUserInfo(true);
            }}
          >
            {rowData.users?.nickname || "미입력"}
          </Button>
        );
      },
    },
    {
      title: "리소스 타입",
      field: "type",
      render: (rowData) => {
        return (
          <div
            style={{
              width: "50px",
            }}
          >
            {rowData.type === 1 && "이미지"}
            {rowData.type === 2 && "사운드"}
            {rowData.type === 3 && "비디오"}
          </div>
        );
      },
    },
    {
      title: "리소스",
      sorting: false,
      render: (rowData) => {
        if (rowData.type === 1) {
          return (
            <img
              src={rowData?.url}
              alt="preview"
              style={{ maxHeight: "200px" }}
            />
          );
        } else if (rowData.type === 2) {
          return (
            <audio controls>
              <source src={rowData?.url} />
            </audio>
          );
        } else {
          return <Player playsInline3 src={rowData?.url} />;
        }
      },
    },
    {
      title: "생성일",
      field: "createdAt",
      width: 150,
      render: (rowData) => {
        return (
          <div
            style={{
              width: "100px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {moment(rowData.createdAt).format("YY-MM-DD  HH:mm")}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const getRoundData = async () => {
      const { status, data: newData } = await serviceApi.getUserResource();
      if (status === 200) {
        setData(newData);
      }
    };
    getRoundData();
  }, []);

  return (
    <AdminRoot>
      <UserInfoModal
        open={openUserInfo}
        handleClose={() => setOpenUserInfo(false)}
        userInfo={userInfo}
      />
      <MaterialTable
        title="유저가 올린 파일 목록"
        icons={tableIcons}
        columns={materialTableColumns}
        options={materialTableOptions}
        data={data}
      />
    </AdminRoot>
  );
};
export default UserResource;
