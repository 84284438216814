import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import TitleSimple from "../common/TitleSimple";
import SelectItems from "../common/SelectItems";
import { studioMetaApi } from "../../api/studio";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const ChangeCategoryModal = ({
  open,
  handleClose,
  studioMenuId,
  studioSubMenuId,
}) => {
  const classes = useStyles();
  const inputTitle = useRef(null);
  const [selectedResourcetype, setResouceType] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const sendDelete = async (event) => {
    event.preventDefault();
    const result = await studioMetaApi.deleteStudioSubMenu(
      studioMenuId,
      studioSubMenuId
    );

    if (result.status === 200) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const sendChange = async (event) => {
    event.preventDefault();
    const result = await studioMetaApi.updateStudioSubMenu(
      studioMenuId,
      studioSubMenuId,
      inputTitle.current.value,
      selectedResourcetype
    );

    if (result.status === 200) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const onChangeResourceType = (type) => {
    setResouceType(type);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { status, data } = await studioMetaApi.getDetailStudioSubmenu(
        studioMenuId,
        studioSubMenuId
      );
      if (status === 200) {
        setData(data);
      }
      setLoading(false);
    };
    getData();
  }, [studioMenuId, studioSubMenuId]);

  if (loading) {
    return (
      <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
        <div className={classes.paper}>
          <TitleSimple>스튜디오 카테고리 수정</TitleSimple>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>스튜디오 카테고리 수정</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputTitle}
                id="title"
                label="카테고리"
                name="title"
                autoFocus
                defaultValue={data?.name}
              />
              <SelectItems
                items={[
                  { id: 1, name: "이미지" },
                  { id: 2, name: "사운드" },
                ]}
                onChange={onChangeResourceType}
                defaultItem={data?.type}
                disabled={data?.resources?.length > 0}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              className={classes.submit}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={sendChange}
            >
              {"수정"}
            </Button>
            <Button
              className={classes.submit}
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              onClick={sendDelete}
              disabled={data?.resources?.length > 0}
            >
              {"삭제"}
            </Button>
          </div>
          <Typography style={{ marginTop: "10px" }}>
            삭제 및 리소스 타입 수정은 하위 리소스가 없어야 가능합니다.
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

ChangeCategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChangeCategoryModal;
