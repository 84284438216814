import React, { useEffect, useState } from "react";
import moment from "moment";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/TableContainer";

import { serviceApi } from "../../api/service";

const StoryReplyReportTable = () => {
  const cRowCountPerPage = 5;
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    const requestItems = async () => {
      const { status, data: newData } =
        await serviceApi.getStoryReplyReportList(
          cRowCountPerPage,
          cRowCountPerPage * pageIndex
        );
      if (status === 200) {
        setItems(newData.rows);
        setTotalCount(newData.totalCount);
      }
    };

    requestItems();
  }, [pageIndex]);

  const getEmptyRows = () => {
    const rows = [];
    const colSpan = 5;
    if (items.length < cRowCountPerPage) {
      const count = cRowCountPerPage - items.length;
      for (let i = 0; i < count; i++) {
        rows.push(
          <TableRow key={`empty-row-${i}`}>
            <TableCell colSpan={colSpan}>&nbsp;</TableCell>
          </TableRow>
        );
      }
    }
    return rows;
  };
  const handleChangePage = (_event, newPage) => {
    setPageIndex(newPage);
  };

  const getReporter = (item) => {
    return `${item.id} ${
      item.reporter?.nickname ? `: ${item.reporter?.nickname}` : ""
    }`;
  };

  const getDateText = (date) => {
    const moDate = new moment(date);
    if (moDate.isValid()) {
      return moDate.format("YYYY-MM-DD HH:mm");
    } else {
      return "";
    }
  };

  return (
    <div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-label="회차 댓글 신고 테이블">
            <TableHead>
              <TableRow>
                <TableCell align="center">번호</TableCell>
                <TableCell align="center">댓글 내용</TableCell>
                <TableCell align="center">신고자 정보 </TableCell>
                <TableCell align="center">신고 사유</TableCell>
                <TableCell align="center">신고 시간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => {
                return (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {item.id}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={item.storyReply?.content}>
                        {item.storyReply?.content}
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{getReporter(item)}</TableCell>
                    <TableCell align="center">{item.content}</TableCell>
                    <TableCell align="center">
                      {getDateText(item.createdAt)}
                    </TableCell>
                  </TableRow>
                );
              })}
              {getEmptyRows()}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[cRowCountPerPage]}
          component="div"
          count={totalCount}
          rowsPerPage={cRowCountPerPage}
          page={pageIndex}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
};

export default StoryReplyReportTable;
