import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { styled } from "@material-ui/core";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../push/TablePaginationActions";
import PaymentDetailModal from "../modal/PaymentDetailModal";
import { Typography } from "@mui/material";

function Payment({ searchedPaymentList }) {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dataLength = searchedPaymentList.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //modal
  const [openPaymentDetail, setOpenPaymentDetail] = useState(false);
  const [paymentId, setPaymentId] = useState(0);
  const [userId, setUserId] = useState(0);

  return (
    <ListContainer>
      <Inner>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "15px",
            margin: "0px 12px",
            paddingRight: "20px",
            marginBottom: "12px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
            textAlign: "right",
          }}
        >
          전체 <span style={{ color: "orange" }}>{dataLength}</span> 건
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#e0e0e0",
                  height: "60px",
                }}
              >
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  번호
                </TableCell>
                <TableCell
                  sx={{ width: "7%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  구분
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  상품 ID
                </TableCell>
                <TableCell
                  sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  결제 금액
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  회원 ID
                </TableCell>
                <TableCell
                  sx={{ width: "7%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  프로필명
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    wordBreak: "keep-all",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  승인번호
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "purple",
                  }}
                  align="center"
                >
                  결제(환불) 일시
                </TableCell>
                <TableCell
                  sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  결제 상태
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? searchedPaymentList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : searchedPaymentList
              ).map((payment, index) => (
                <TableRow
                  key={payment.id}
                  onClick={() => {
                    setPaymentId(payment.id);
                    setUserId(payment.userId);
                    setOpenPaymentDetail(true);
                  }}
                >
                  <TableCell align="center">
                    {dataLength - index - page * rowsPerPage}
                  </TableCell>
                  <TableCell align="center">{payment.device}</TableCell>
                  <TableCell align="center">{payment.prdCd}</TableCell>
                  <TableCell align="center">
                    {payment.payClCd === "0" ? payment.payAmt : -payment.payAmt}
                  </TableCell>
                  <TableCell align="center">{payment.userId}</TableCell>
                  <TableCell align="center">
                    {payment.users ? payment.users.nickname : ""}
                  </TableCell>
                  <TableCell align="center">{payment.orderId}</TableCell>
                  <TableCell align="center">
                    {moment(payment.updatedAt).format("YY-MM-DD HH:mm")}
                  </TableCell>
                  {payment.payClCd === "0" ? (
                    <TableCell align="center">결제완료</TableCell>
                  ) : (
                    <TableCell align="center" sx={{ color: "red" }}>
                      취소
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ backgroundColor: "#e0e0e0", height: "40px" }}>
                <TableCell
                  colSpan={8}
                  align="right"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  총 금액 :
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  {searchedPaymentList
                    .map(({ payAmt, payClCd }) =>
                      payClCd === "0" ? payAmt : -payAmt
                    )
                    .reduce((sum, i) => sum + i, 0)}
                  원
                </TableCell>
              </TableRow>
              <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={10}
                  count={searchedPaymentList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <PaymentDetailModal
          open={openPaymentDetail}
          handleClose={() => setOpenPaymentDetail(false)}
          paymentId={paymentId}
          userId={userId}
        />
      </Inner>
    </ListContainer>
  );
}

Payment.defaultProps = {
  searchedPaymentList: {
    id: "",
    device: "",
    prdCd: "",
    payAmt: "",
    userId: "",
    nickname: "",
    payId: "",
    createdAt: "",
    payClCd: "",
  },
};

Payment.propTypes = {
  searchedPaymentList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      device: PropTypes.string,
      prdCd: PropTypes.string,
      payAmt: PropTypes.number,
      userId: PropTypes.number,
      nickname: PropTypes.string,
      payId: PropTypes.string,
      createdAt: PropTypes.string,
      payClCd: PropTypes.string,
    })
  ),
};

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "block",
  justifyContent: "center",
  alignItems: "center",
});

export default Payment;
