import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";
import { convertDate } from "../lib/utils";
import { Button, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";

function UseTokenSearchBar({ getUseTokenList, pageSize, setPage, setSearchParams}){
    const [checkDeviceItems, setCheckDeviceItems] = useState([]); //기기
    const onCheckedDeviceChange = (checked, device) => {
        if (checked) {
            setCheckDeviceItems((prev) => [...prev, device]);
        } else {
            setCheckDeviceItems(checkDeviceItems.filter((el) => el !== device));
        }

        if (checked && checkTknClCd.includes("BOUNS")) {
            setCheckTknClCd([]);
        }
    };

    const [checkTknClCd, setCheckTknClCd] = useState([]); //토큰 종류
    const onCheckedTknClCd = (checked, tknClCd) => {
        if (checked) {
          setCheckTknClCd((prev) => [...prev, tknClCd]);
        } else {
          setCheckTknClCd(checkTknClCd.filter((el) => el !== tknClCd));
        }

        if (tknClCd === "BOUNS" && checked) {
          setCheckDeviceItems([]);
        }
    };

    //검색
    const [division, setDivision] = useState("");
    const [keyword, setKeyword] = useState("");

    const onDivisionChange = (event) => {
        setDivision(event.target.value);
    };

    const onKeywordChange = (event) => {
        setKeyword(event.target.value);
    };

    //날짜 입력 
    const [date, setDate] = useState([new Date("2022-01-01"), new Date()]);
    const startAt = convertDate(date[0]);
    const endAt = convertDate(date[1]);
    
    // '오늘', '1주일', '1개월', '3개월', '6개월', '전체' 클릭시 날짜 세팅
    const onPeriodClick = (event) => {
        const created_date = new Date();
        const thisYear = created_date.getFullYear();
        const thisMonth = created_date.getMonth() + 1;
        const today = created_date.getDate();   

        if (event.target.name === "today") {
            setDate([new Date(), new Date()]);
        } else if (event.target.name === "week") {
            setDate([new Date(thisYear, thisMonth - 1, today - 6), new Date()]);
        } else if (event.target.name === "month1") {
            setDate([new Date(thisYear, thisMonth - 2, today), new Date()]);
        } else if (event.target.name === "month3") {
            setDate([new Date(thisYear, thisMonth - 4, today), new Date()]);
        } else if (event.target.name === "month6") {
            setDate([new Date(thisYear, thisMonth - 7, today), new Date()]);
        } else if (event.target.name === "whole") {
            setDate([new Date("2022-01-01"), new Date()]);
        }
    };

    // '검색'버튼 클릭시 푸시리스트 api get 요청
    const  onSearchBtnClick = async () => {
        await setPage(0);
        await getUseTokenList (
            startAt
            , endAt
            , checkDeviceItems
            , checkTknClCd
            , division 
            , keyword
            , 0
            , pageSize
        );
    };
    
    // '초기화'버튼과 달력내부 'X'버튼 클릭시 초기화 (검색 초기화되게)
    const onCleanBtnClick = async(e) => {
        e.preventDefault();
        setDate([new Date("2022-01-01"), new Date()]);
        setCheckDeviceItems([]);
        setCheckTknClCd([]);
        setKeyword("");

        let searcnParams = [];
        searcnParams.push("startAt", new Date("2022-01-01"));
        searcnParams.push("endAt", new Date());
        searcnParams.push("checkDeviceItems", []);
        searcnParams.push("checkTknClCd", []);
        searcnParams.push("division", "");
        searcnParams.push("keyword", "");
        searcnParams.push("page", 0);
        searcnParams.push("pageSize", 10);

        setSearchParams(searcnParams);
    };

    return (
        <SearchContainer>
            <Inner1>
                <Text>기간</Text>
                <DateRangePicker
                    format = "yyyy.MM.dd"
                    ranges = {[]}
                    value = { date }
                    onChange = { setDate }
                    onClean = { onCleanBtnClick }
                    placeholder = "select date range"
                />
                <Button
                    name = "today"
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin : "0 5px" }}
                    onClick = { onPeriodClick }
                >
                    오늘
                </Button>
                <Button
                    name = "week"
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                    onClick = { onPeriodClick }
                >
                    1주일
                </Button>
                <Button
                    name = "month1"
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                    onClick = { onPeriodClick } 
                >
                    1개월
                </Button>
                <Button
                    name = "month3"
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                    onClick = { onPeriodClick }
                >
                    3개월
                </Button>
                <Button
                    name = "month6"
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                    onClick = { onPeriodClick }
                >
                    6개월
                </Button>
                <Button
                    name = "whole"
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                    onClick = { onPeriodClick }
                >
                    전체
                </Button>
            </Inner1>
            <Inner1>
                <InnerDiv>
                    <Text>검색 구분</Text>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-search-label">Search</InputLabel>
                        <Select
                            labelId="select-search-label"
                            id="select-search"
                            label="Search"
                            value={division}
                            onChange={onDivisionChange}
                        >
                            <MenuItem value={"userName"}>회원ID</MenuItem>
                            <MenuItem value={"nickname"}>프로필명</MenuItem>
                            <MenuItem value={"cpName"}>CP사</MenuItem>
                            <MenuItem value={"seriesName"}>작품명</MenuItem>
                            <MenuItem value={"payClCd"}>결제상태</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Keyword"
                        value={keyword}
                        onChange={onKeywordChange}
                    />
                </InnerDiv>
                <InnerDiv>
                    <Text>기기</Text>
                    <FormControl variant="standard">
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={checkDeviceItems.includes("AND") ? true : false}
                                    onChange={(e) =>
                                    onCheckedDeviceChange(e.target.checked, "AND")
                                    }
                                    name="AND"
                                />
                                }
                                label="Android"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={checkDeviceItems.includes("IOS") ? true : false}
                                    onChange={(e) =>
                                    onCheckedDeviceChange(e.target.checked, "IOS")
                                    }
                                    name="IOS"
                                />
                                }
                                label="IOS"
                            />
                        </FormGroup>
                    </FormControl>
                </InnerDiv>
                <InnerDiv>
                    <Text>토큰 종류</Text>
                    <FormControl variant="standard">
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={checkTknClCd.includes("PAY") ? true : false}
                                    onChange={(e) => onCheckedTknClCd(e.target.checked, "PAY")}
                                    name="PAY"
                                />
                                }
                                label="구매"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={checkTknClCd.includes("BOUNS") ? true : false}
                                    onChange={(e) =>
                                    onCheckedTknClCd(e.target.checked, "BOUNS")
                                    }
                                    name="BOUNS"
                                />
                                }
                                label="이벤트"
                            />
                        </FormGroup>
                    </FormControl>
                </InnerDiv>
            </Inner1>
            <Inner2>
                <Button
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                    onClick = { onSearchBtnClick }
                >
                    검색
                </Button>
                <Button
                    color = "orange"
                    appearance = "subtle"
                    style = {{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                    onClick = { onCleanBtnClick }
                >
                    초기화
                </Button>
            </Inner2>
        </SearchContainer>
    );
}

const SearchContainer = styled("div")({
    position: "relative",
    marginBottom: "20px",
});

const Inner1 = styled("div")({
    position: "relative",
    width: "100%",
    marginBottom: "20px",
    border: "1px solid #e0e0e0",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
});

const InnerDiv = styled("div")({
    position: "relative",
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
});

const Inner2 = styled("div")({
    position: "relative",
    width: "100%",
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const Text = styled("p")({
    border: "1px solid black",
    width: "100px",
    height: "37px",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#bdbdbd",
    color: "black",
});

UseTokenSearchBar.propTypes = {
    getUseTokenList: PropTypes.func,
};
  
export default UseTokenSearchBar;