import React from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/sidebarStyles";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { setClose } from "../../redux/toggleSlice";
import {
  // setToggleMenu01,
  setToggleMenu02,
  setToggleMenu03,
  setToggleMenu04,
  setToggleMenu05,
  setToggleMenu06,
  // setToggleMenu06,
  setToggleMenu07,
  setToggleMenu08,
} from "../../redux/sidebarMenuSlice";
import SideBarItem from "./SideBarItem";
import SideBarCollaspeItem from "./SideBarCollaspeItem";

const useStyles = makeStyles(styles);

const getPathPage = (pathname) => {
  if (pathname.startsWith("/user")) {
    return "/user";
  }
  if (pathname.startsWith("/idea")) {
    return "/idea";
  }
  return pathname;
};

const SideBar = withRouter(({ location: { pathname } }) => {
  const dispatch = useDispatch();
  const path = getPathPage(pathname);
  const open = useSelector((state) => state.toggle);
  const {
    // openMenu01,
    openMenu02,
    openMenu03,
    openMenu04,
    openMenu05,
    openMenu06,
    openMenu07,
    openMenu08
  } = useSelector((state) => state.mainMenu);
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        ),
      }}
      open
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => dispatch(setClose())}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <SideBarCollaspeItem
          title="회원 관리"
          enabled
          menuOpen={openMenu02}
          onClick={() => dispatch(setToggleMenu02())}
        >
          <SideBarItem
            active={path === "/users/admin"}
            title="관리자 조회"
            to="/users/admin"
          />
          <SideBarItem
            active={path === "/users"}
            title="회원 조회"
            to="/users"
          />
          <SideBarItem
            active={path === "/device"}
            title="디바이스 조회"
            to="/device"
          />
          <SideBarItem
            active={path === "/users/withdrawal"}
            title="탈퇴 회원 조회"
            to="/users/withdrawal"
          />
          <SideBarItem
            active={path === "/users/blackusers"}
            title="블랙리스트"
            to="/users/blackusers"
          />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title="컨텐츠 관리"
          enabled
          menuOpen={openMenu03}
          onClick={() => dispatch(setToggleMenu03())}
        >
          {/* <SideBarItem
            active={path === "/contents/normal"}
            title="일반 작품"
            to="/contents/normal"
          /> */}
          <SideBarItem
            active={path === "/contents/list"}
            title="작품"
            to="/contents/list"
          />
          <SideBarItem
            active={path === "/contents/normal"}
            title="삭제된 일반 작품"
            to="/contents/normal/deleted"
          />
          <SideBarItem
            active={path === "/contents/notice"}
            title="공지 사항"
            to="/contents/notice"
          />
          <SideBarItem
            active={path === "/eventPopup"}
            title="이벤트 팝업"
            to="/eventPopup"
          />
          <SideBarItem
            active={path === "/service/notice"}
            title="서비스 점검"
            to="/service/notice"
          />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title="메타 관리"
          enabled
          menuOpen={openMenu04}
          onClick={() => dispatch(setToggleMenu04())}
        >
          <SideBarItem
            active={path === "/contents/meta/character"}
            title="인물"
            to="/contents/meta/character"
          />
          <SideBarItem
            active={path === "/contents/meta/bgm"}
            title="BGM"
            to="/contents/meta/bgm"
          />
          <SideBarItem
            active={path === "/contents/meta/sound"}
            title="효과음"
            to="/contents/meta/sound"
          />
          <SideBarItem
            active={path === "/contents/meta/interactive"}
            title="인터렉티브"
            to="/contents/meta/interactive"
          />
          <SideBarItem
            active={path === "/contents/meta/background"}
            title="배경전환"
            to="/contents/meta/background"
          />
          <SideBarItem
            active={path === "/contents/meta/bookCover"}
            title="작품 표지"
            to="/contents/meta/bookCover"
          />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title="서비스 관리"
          enabled
          menuOpen={openMenu05}
          onClick={() => dispatch(setToggleMenu05())}
        >
          <SideBarItem
            active={path === "/exposur/recommended"}
            title="추천!어머~이건 봐야해"
            to="/exposur/recommended"
          />
          <SideBarItem
            active={path === "/userRanking"}
            title="유저가 뽑은 10 순위"
            to="/userRanking"
          />
          <SideBarItem
            active={path === "/mainSeries"}
            title="메인 작품 전시"
            to="/mainSeries"
          />
          <SideBarItem
            active={path === "/mainAuthor"}
            title="메인 작가 전시"
            to="/mainAuthor"
          />
          <SideBarItem
            active={path === "/service/preview-video"}
            title="미리보기영상관리"
            to="/service/preview-video"
          />
          <SideBarItem
            active={path === "/main/banner"}
            title="메인빅배너"
            to="/main/banner"
          />
          <SideBarItem
            active={path === "/story/reply/report"}
            title="회차 댓글 신고"
            to="/story/reply/report"
          />
          <SideBarItem active={path === "/push"} title="푸쉬관리" to="/push" />
          <SideBarItem
            active={path === "/contents/report"}
            title="작품 신고"
            to="/contents/report"
          />
          <SideBarItem
            active={path === "/userResource"}
            title="유저가 올린 파일목록"
            to="/userResource"
          />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title="통계 관리"
          enabled
          menuOpen={openMenu06}
          onClick={() => dispatch(setToggleMenu06())}
        >
          <SideBarItem
            active={path === "/statistics/contents"}
            title="작품별 통계"
            to="/statistics/contents"
          />
          <SideBarItem
            active={path === "/statistics/authors"}
            title="작가별 통계"
            to="/statistics/authors"
          />
          <SideBarItem
            active={path === "/statistics/reply"}
            title="댓글 조회"
            to="/statistics/reply"
          />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title="결제 및 매출 관리"
          enabled
          menuOpen={openMenu07}
          onClick={() => dispatch(setToggleMenu07())}
        >
          <SideBarItem
            active={path === "/payments/list"}
            title="결제 관리"
            to="/payments/list"
          />
          <SideBarItem
            active={path === "/useTokens/list"}
            title="토큰 차감"
            to="/useTokens/list"
          />
          <SideBarItem
            active={path === "/payments/registerTokens"}
            title="토큰 지급"
            to="/payments/registerTokens"
          />
          <SideBarItem
            active={path === "/payments/manageCp"}
            title="CP/작가 관리"
            to="/payments/manageCp"
          />
          <SideBarItem
            active={path === "/useTokens/settle"}
            title="정산내역"
            to="/useTokens/settle"
          />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title = "시스템 관리"
          enabled
          menuOpen = {openMenu08}
          onClick={() => dispatch(setToggleMenu08())}
        >
            <SideBarItem
            active={path === "/platformVersion/list"}
            title="플랫폼 버전 관리"
            to="/platformVersion"
          />

        </SideBarCollaspeItem>
        {/* <SideBarCollaspeItem
          title="서비스 관리"
          enabled
          menuOpen={openMenu04}
          onClick={() => dispatch(setToggleMenu04())}
        >
          <SideBarItem
            active={path === "/report/complain"}
            title="신고접수"
            to="/report/complain"
          />
          <SideBarItem active={path === "/push"} title="푸쉬관리" to="/push" />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title="고객 센터"
          enabled
          menuOpen={openMenu05}
          onClick={() => dispatch(setToggleMenu05())}
        >
          <SideBarItem
            active={path === "/qna/service"}
            title="서비스 문의"
            to="/qna/service"
          />
          <SideBarItem
            active={path === "/faq"}
            title="자주 묻는 질문"
            to="/faq"
          />
          <SideBarItem
            active={path === "/notice"}
            title="NOTICE"
            to="/notice"
          />
          <SideBarItem active={path === "/popup"} title="팝업" to="/popup" />
        </SideBarCollaspeItem>
        <SideBarCollaspeItem
          title="결제 및 매출"
          enabled
          menuOpen={openMenu06}
          onClick={() => dispatch(setToggleMenu06())}
        >
          <SideBarItem
            active={path === "/payment"}
            title="결제 관리"
            to="/payment"
          />
          <SideBarItem
            active={path === "/point"}
            title="포인트 관리"
            to="/point"
          />
        </SideBarCollaspeItem> */}
      </List>
    </Drawer>
  );
});

export default SideBar;
