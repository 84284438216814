import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import Loading from "../common/Loading";
import PropTypes from "prop-types";
import { useState } from "react";
import EventPointListModal from "../modal/EventPointListModal";
import PayPointListModal from "../modal/PayPointListModal";

function PayDetail({ loading, detail }) {
  const payDetail = detail;

  //Modal
  const [openEventPointList, setOpenEventPointList] = useState(false);
  const [openPayPointList, setOpenPayPointList] = useState(false);
  const [userId, setUserId] = useState(0);

  return (
    <>
      {loading ? (
        <div style={{ height: "300px" }}>
          <Loading />
        </div>
      ) : (
        <>
          <div>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontSize: "16px",
                margin: "0px 12px",
                padding: "20px 20px",
                fontWeight: 500,
                letterSpacing: "-1px",
                lineHeight: "1.6",
              }}
            >
              결제 정보
            </Typography>
            <TableContainer>
              <Table
                sx={{
                  width: "1200px",
                  marginBottom: "30px",
                }}
                align="center"
                size="small"
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                        lineHeight: "2.45",
                      }}
                    >
                      회원ID
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "30%",
                        lineHeight: "2.45",
                      }}
                      colSpan={2}
                    >
                      {payDetail ? payDetail.useTokenDetail.userId : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                        lineHeight: "2.45",
                      }}
                    >
                      프로필명
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "30%",
                        lineHeight: "2.45",
                      }}
                      colSpan={2}
                    >
                      {payDetail.useTokenDetail &&
                      payDetail.useTokenDetail.users
                        ? payDetail.useTokenDetail.users.nickname
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                      }}
                    >
                      누적이벤트토큰
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "15%",
                      }}
                    >
                      {payDetail.sumEventToken ? payDetail.sumEventToken : "0"}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "15%",
                      }}
                      align="center"
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#e0e0e0",
                          color: "black",
                          width: "150px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          setOpenEventPointList(true);
                          setUserId(payDetail.useTokenDetail.userId);
                        }}
                      >
                        이벤트 적립 내역
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                      }}
                    >
                      최종적립일
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "30%",
                      }}
                      colSpan={2}
                    >
                      {payDetail.lastEventPoint !== 0
                        ? moment(payDetail.lastEventPoint).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                      }}
                    >
                      누적구매금액
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "15%",
                      }}
                    >
                      {payDetail.sumPayToken
                        ? payDetail.sumPayToken[0].sumPayToken === null
                          ? "0"
                          : payDetail.sumPayToken[0].sumPayToken
                        : "0"}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "15%",
                      }}
                      align="center"
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#e0e0e0",
                          color: "black",
                          width: "150px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          setOpenPayPointList(true);
                          setUserId(payDetail.useTokenDetail.userId);
                        }}
                      >
                        구매 적립 내역
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                      }}
                    >
                      최종결제일
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "30%",
                      }}
                      colSpan={2}
                    >
                      {payDetail.lastPayPoint === 0
                        ? ""
                        : moment(payDetail.lastPayPoint).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                        lineHeight: "2.45",
                      }}
                    >
                      잔여토큰
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "30%",
                        lineHeight: "2.45",
                      }}
                      colSpan={2}
                    >
                      {payDetail.remainToken < 0 ? 0 : payDetail.remainToken}
                      토큰
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "20%",
                        lineHeight: "2.45",
                      }}
                    >
                      결제메일
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontFamily: "NotoSansKR",
                        width: "30%",
                        lineHeight: "2.45",
                      }}
                      colSpan={2}
                    >
                      {payDetail
                        ? payDetail.useTokenDetail.payments.payUserAccount
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <EventPointListModal
            open={openEventPointList}
            handleClose={() => setOpenEventPointList(false)}
            userId={userId}
          />
          <PayPointListModal
            open={openPayPointList}
            handleClose={() => setOpenPayPointList(false)}
            userId={userId}
          />
        </>
      )}
    </>
  );
}

PayDetail.propTypes = {
  loading: PropTypes.bool.isRequired,
  detail: PropTypes.object,
};

export default PayDetail;
