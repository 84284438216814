import React, { useEffect, useState } from "react";
import AdminRoot from "../components/frame/AdminRoot";
import { styled } from "@material-ui/core";
import { blackUserApi } from "../api/global";
import toast from "react-hot-toast";
import BlackUsersSearch from "./BlackUsersSearch";
import BlackUsersList from "./BlackUserList";
import moment from "moment";

function BlackUsers() {
  const [loading, setLoading] = useState(false);
  const [searchedBlackUsers, setSearchedBlackUsers] = useState([]);
  const [saveDates, setSaveDates] = useState({
    startAt: new Date("2022-01-01"),
    endAt: new Date(),
    filter: "",
    keyword: "",
  });

  // 검색 기간 설정하여  블랙리스트 받기
  const getBlackUsers = async (
    startDate,
    endDate,
    searchFilter,
    inputValue
  ) => {
    setSaveDates({
      startAt: startDate,
      endAt: endDate,
      filter: searchFilter,
      keyword: inputValue,
    });
    try {
      setLoading(true);
      const { status, data } = await blackUserApi.blackUsersList(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        searchFilter,
        inputValue
      );
      if (status === 200) {
        setSearchedBlackUsers(data);
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // '검색'에 의해서가 아닌, '등록/수정/삭제' 후 해당기간에 대한 자동 렌더링
  const getBlackUsersDefault = async () => {
    try {
      setLoading(true);
      const { status, data } = await blackUserApi.blackUsersList(
        moment(saveDates.startAt).format("YYYY-MM-DD"),
        moment(saveDates.endAt).format("YYYY-MM-DD"),
        saveDates.filter,
        saveDates.keyword
      );
      if (status === 200) {
        setSearchedBlackUsers(data);
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  // useEffect(getBlakUsersDefault, []);
  useEffect(() => {
    // const blakUsersDefault = async () => {
    //   console.log("blakUsersDefault");
    //   try {
    //     setLoading(true);
    //     const { status, data } = await blackUserApi.blackUsersList(
    //       moment(saveDates.startAt).format("YYYY-MM-DD"),
    //       moment(saveDates.endAt).format("YYYY-MM-DD"),
    //       undefined,
    //       undefined
    //     );
    //     if (status === 200) {
    //       setSearchedBlackUsers(data);
    //     }
    //   } catch (error) {
    //     toast.error("네트워크 에러", {
    //       style: {
    //         borderRadius: "10px",
    //         background: "#333",
    //         color: "#fff",
    //       },
    //     });
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    // blakUsersDefault();
    getBlackUsersDefault();
  }, []);

  return (
    <AdminRoot>
      <Title>블랙리스트</Title>
      <BlackUsersSearch getBlackUsers={getBlackUsers} />
      <BlackUsersList
        loading={loading}
        searchedBlackUsers={searchedBlackUsers}
        getBlackUsersDefault={getBlackUsersDefault}
      />
    </AdminRoot>
  );
}

const Title = styled("div")({
  marginBottom: "20px",
});

export default BlackUsers;
