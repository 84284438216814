import React, { useState } from "react";
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "rsuite";
import toast from "react-hot-toast";
import { blackUserApi } from "../api/global";
import Loading from "../components/common/Loading";

function BlackUserRegiPopover({ handleClose, getBlackUsersDefault, allUser }) {
  // 날짜 입력받은 state
  const [inputValues, setInputValues] = useState({
    user: "",
    email: "",
    nickName: "",
    reason: "",
  });
  const [dates, setDates] = useState({
    startAt: moment(new Date()).format("YYYY-MM-DD"),
    endAt: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [limitType, setLimitType] = useState(1);

  // '메일, 필명, 사유' 입력 이벤트
  const onInputChange = (event) => {
    if (event.target.name === "reason") {
      setInputValues({ ...inputValues, reason: event.target.value });
    }
  };

  //'기능제한' 옵션 선택
  const onAuthLimit = (event) => {
    setLimitType(Number(event.target.value));
  };

  // '기간' change event
  const onDateChange = (event) => {
    if (event.target.name === "startAt") {
      setDates({ ...dates, startAt: event.target.value });
    } else if (event.target.name === "endAt") {
      setDates({ ...dates, endAt: event.target.value });
    }
  };

  // '블랙리스트 등록' 버튼 이벤트
  const onAddNewBlackUser = async () => {
    try {
      const { status, data } = await blackUserApi.blackUserAdd({
        userId: inputValues.user.id,
        limitType: limitType,
        reason: inputValues.reason,
        startAt: dates.startAt,
        endAt: dates.endAt,
      });
      if (status === 200) {
        getBlackUsersDefault();
      } else {
        toast.error(data.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Container>
      <h4 style={{ marginBottom: "30px" }}>블랙리스트 등록</h4>
      {allUser === null ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Inner>
            <Cell1>회원ID*</Cell1>
            <Cell2>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allUser}
                sx={{ width: "100%", marginRight: "10px" }}
                renderInput={(params) => (
                  <TextField {...params} label="회원 ID 값 입력" />
                )}
                size="small"
                getOptionLabel={(option) =>  option.label || "" }
                onChange={(event, value) =>
                  setInputValues({ ...dates, user: value })
                }
                // disabled
              />
            </Cell2>
            {/* <Cell1>메일</Cell1>
        <Cell2>
          <TextInput
            value={inputValues.email}
            name="email"
            maxLength="30"
            autoComplete="off"
            required
            placeholder="메일정보입력"
            onChange={onInputChange}
          />
        </Cell2> */}
          </Inner>
          {/* <Inner>
        <Cell1>필명*</Cell1>
        <Cell2>
          <TextInput
            value={inputValues.nickName}
            name="nickName"
            maxLength="30"
            autoComplete="off"
            required
            placeholder="필명을 입력하세요."
            onChange={onInputChange}
          />
        </Cell2>
      </Inner> */}
          <Inner>
            <Cell1 style={{ borderTop: 0, borderBottom: 0 }}>적용기간*</Cell1>
            <Cell2 style={{ borderTop: 0, borderBottom: 0 }}>
              <div
                style={{
                  paddingLeft: "10px",
                  position: "relative",
                  zIndex: "10000 !important",
                }}
              >
                <Input
                  type="date"
                  name="startAt"
                  style={{ width: "110px" }}
                  value={dates.startAt}
                  onChange={onDateChange}
                />
                <span>&nbsp;&nbsp;~&nbsp;&nbsp;</span>
                <Input
                  type="date"
                  name="endAt"
                  style={{ width: "110px" }}
                  value={dates.endAt}
                  onChange={onDateChange}
                />
              </div>
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>기능제한</Cell1>
            <Cell2 style={{ paddingLeft: "1px" }}>
              <FormControl sx={{ minWidth: 120 }} size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={limitType}
                  onChange={onAuthLimit}
                >
                  <MenuItem value="1">댓글 등록</MenuItem>
                  <MenuItem value="2">작품 등록</MenuItem>
                  <MenuItem value="3">계정 정지</MenuItem>
                </Select>
              </FormControl>
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>사유</Cell1>
            <Cell2>
              <TextInput
                value={inputValues.reason}
                name="reason"
                maxLength="30"
                autoComplete="off"
                required
                placeholder="사유를 입력하세요."
                onChange={onInputChange}
              />
            </Cell2>
          </Inner>
          <Inner style={{ margin: "20px 0" }}>
            <Ptag>
              <span style={{ color: "orange" }}>선택한 필명</span>의 기능을
              제한하시겠습니까?
            </Ptag>
          </Inner>
          <Inner style={{ justifyContent: "center" }}>
            <Button
              color="orange"
              appearance="subtle"
              style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
              onClick={() => {
                handleClose();
              }}
            >
              취소{" "}
            </Button>
            <Button
              color="orange"
              appearance="subtle"
              style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
              onClick={onAddNewBlackUser}
              disabled={inputValues.userId === ""}
            >
              등록
            </Button>
          </Inner>
        </>
      )}
    </Container>
  );
}

BlackUserRegiPopover.defaultProps = {
  allUser: null,
};

BlackUserRegiPopover.propTypes = {
  handleClose: PropTypes.func.isRequired,
  getBlackUsersDefault: PropTypes.func.isRequired,
  allUser: PropTypes.array,
};

const Container = styled("div")({
  position: "relative",
  padding: "20px",
  width: "600px",
  height: "470px",
});
const Inner = styled("div")({
  position: "relative",
  display: "flex",
});
const Cell1 = styled("div")({
  border: "1px solid gray",
  borderRight: "0",
  backgroundColor: "lightgray",
  width: "100px",
  height: "50px",
  paddingLeft: "10px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Cell2 = styled("div")({
  border: "1px solid gray",
  height: "50px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexGrow: 1,
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const TextInput = styled("input")({
  width: "100%",
  height: "40px",
  border: "1px solid #E2E2E3",
  borderRadius: "3px",
  padding: "10px ",
  backgroundColor: "white",
});
const Input = styled("input")({
  width: "40px",
  height: "35px",
  border: "1px solid #f2f2f2",
  borderRadius: "3px",
  backgroundColor: "white",
});
const Ptag = styled("p")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  font: '400 16px "Roboto","Helvetica","Arial", sans-serif',
});

export default BlackUserRegiPopover;
