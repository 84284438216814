import React, { forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import moment from "moment";
import { useSelector } from "react-redux";
import AdminRoot from "../components/frame/AdminRoot";
import { userApi } from "../api/global";
import AddUserModal from "../components/modal/AddUserModal";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const materialTableColumns = [ 
  {
    title: "no",
    field: "id",
  },
  {
    title: "디바이스",
    field: "id",
    render: (rowData) => (
      <div>
        {rowData.device === 1 && "안드로이드"}
        {rowData.device === 2 && "ios"}
      </div>
    ),
  },
  {
    title: "소셜 로그인 타입",
    field: "provider",
  },
  {
    title: "snsId",
    field: "snsId",
  },
  {
    title: "메일",
    field: "mail",
  },
  {
    title: "이름",
    field: "name",
  },
  {
    title: "필명",
    field: "nickname",
  },
  {
    title: "회원 타입",
    render: (rowData) => <> {rowData.series.length > 0 ? "작가" : "독자"}</>,
  },
  { title: `성별`, field: "gender" },
  { title: `생일`, field: "birthAt" },
  {
    title: `블랙`,
    render: (rowData) => <>{rowData.black ? "블랙" : ""}</>,
  },
  {
    title: `가입일`,
    field: "createdAt",
    render: (rowData) => (
      <>
        {rowData.createdAt
          ? moment(rowData.createdAt).format("YY-MM-DD  HH:mm")
          : ""}
      </>
    ),
  },
  {
    title: `최종로그인`,
    field: "black",
    render: (rowData) => (
      <>
        {rowData.lastLoginAt
          ? moment(rowData.lastLoginAt).format("YY-MM-DD  HH:mm")
          : ""}
      </>
    ),
  },
];

const materialTableOptions = {
  search: true,
  sorting: true,
  draggable: false,
  showTitle: true,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
  headerStyle: {
    backgroundColor: "#EEE",
    fontWeight: "bold",
    textAlign: "center",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  actionsCellStyle: {
    textAlign: "center",
  },
};

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "100%",
  },
}));

const Users = () => {
  const classes = useStyles();

  const { userInfo } = useSelector((state) => state.user);

  const [data, setData] = useState([]);
  const [openUserAdd, setOpenUserAdd] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);

  useEffect(() => {
    const getRoundData = async () => {
      const { status, data: newData } = await userApi.list();
      if (status === 200) {
        setData(newData);
      }
    };
    getRoundData();
  }, [reloadCount]);

  return (
    <AdminRoot>
      <div className={classes.root}>
        <MaterialTable
          title="회원 관리"
          icons={tableIcons}
          data={data}
          columns={materialTableColumns}
          options={materialTableOptions}
          actions={[
            {
              icon: AddBox,
              tooltip: "관리자 추가",
              isFreeAction: true,
              onClick: () => {
                if (userInfo.id === 1) {
                  setOpenUserAdd(true);
                }
              },
            },
          ]}
        />
        <AddUserModal
          open={openUserAdd}
          handleClose={(result) => {
            setOpenUserAdd(false);
            if (result) {
              setReloadCount(reloadCount + 1);
            }
          }}
        />
      </div>
    </AdminRoot>
  );
};
export default Users;
