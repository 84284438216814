import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import TitleSimple from "../common/TitleSimple";
import toast from "react-hot-toast";
import { userApi } from "../../api/global";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    margin: theme.spacing(3, 0, 5),
    backgroundColor: theme.palette.custom.black,
    color: theme.palette.custom.white,
  },
}));

const AddUserModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const inputEmail = useRef(null);
  const inputPasswrod = useRef(null);
  const inputName = useRef(null);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const onAdd = async (event) => {
    event.preventDefault();
    const result = await userApi.join({
      name: inputName.current.value,
      mail: inputEmail.current.value,
      password: inputPasswrod.current.value,
    });
    if (result) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>유저 추가</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputEmail}
                id="email"
                label="email"
                name="email"
                autoFocus
                type="email"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputName}
                id="이름"
                label="이름"
                name="이름"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputPasswrod}
                id="패스워드"
                label="패스워드"
                name="패스워드"
                autoFocus
                type="password"
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} sm={6}>
              <Button
                className={classes.submit}
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                onClick={onAdd}
              >
                {"확인"}
              </Button>
            </Grid>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddUserModal;
