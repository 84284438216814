import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Modal } from "@material-ui/core";
import { Typography } from "@mui/material";
import { styled } from "@material-ui/core";
import { paymentsApi } from "../../api/payments";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "500px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
  userName: {
    color: "#f57c00",
  },
}));

function PaymentCancelModal({
  open,
  handleClose,
  paymentId,
  userId,
  isCanceled,
}) {
  const classes = useStyles();

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const payCancel = async () => {
    //결제 취소 로직
    const sendData = {
      pPaymentId: paymentId,
      pUserId: userId,
    };

    const result = await paymentsApi.doPayCancel(JSON.stringify(sendData));

    if (result.data === "success") {
      isCanceled(true);
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>결제 취소 확인</Typography>
        <TextContainer>
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontSize: "20px",
              fontWeight: 400,
              letterSpacing: "-1px",
              lineHeight: "1.6",
              textAlign: "center",
            }}
          >
            <span className={classes.userName}>{userId}(회원 ID)</span>의
            <br /> 결제를 취소하시겠습니까?
          </Typography>
        </TextContainer>
        <ButtonContainer>
          <Button className={classes.cancelButton} onClick={handleCloseModal}>
            닫기
          </Button>
          <Button className={classes.editButton} onClick={payCancel}>
            취소
          </Button>
        </ButtonContainer>
      </div>
    </Modal>
  );
}

const TextContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "150px",
});

const ButtonContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  height: "50px",
  alignItems: "center",
});

PaymentCancelModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  paymentId: PropTypes.number,
  userId: PropTypes.number,
  isCanceled: PropTypes.func,
};

export default PaymentCancelModal;
