import React from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

function SeriesDetailTable({ seriesData, genreData }) {
  return (
    <>
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          fontSize: "16px",
          margin: "0px 12px",
          padding: "0px 20px 10px 20px",
          fontWeight: 500,
          letterSpacing: "-1px",
          lineHeight: "1.6",
        }}
      >
        작품 상세
      </Typography>
      <Table
        sx={{
          width: "900px",
          marginBottom: "30px",
        }}
        align="center"
        size="small"
      >
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: "#e0e0e0",
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              작품명
            </TableCell>
            <TableCell
              sx={{
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              {seriesData.name}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#e0e0e0",
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              작가/CP
            </TableCell>
            <TableCell
              sx={{
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              {seriesData.cpCompany ? seriesData.cpCompany.name : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: "#e0e0e0",
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              구분
            </TableCell>
            <TableCell
              sx={{
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              {seriesData.type}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#e0e0e0",
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              장르
            </TableCell>
            <TableCell
              sx={{
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              {genreData}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: "#e0e0e0",
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              최초 등록일
            </TableCell>
            <TableCell
              sx={{
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              {moment(seriesData.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#e0e0e0",
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              최종 등록일
            </TableCell>
            <TableCell
              sx={{
                border: 1,
                borderColor: "grey.500",
                fontFamily: "NotoSansKR",
              }}
            >
              {moment(seriesData.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

export default SeriesDetailTable;
