import React, { useState } from "react";
import { Button } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import "../../assets/css/dateRangePicker.css";
import moment from "moment";
import TablePaginationActions from "../push/TablePaginationActions";

function AddTokenListTable({ addData, deleteRegisterList, register }) {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dataLength = addData ? addData.length : 0;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteData = (index) => {
    deleteRegisterList(index);
  };

  const registerData = (event) => {
    event.preventDefault();

    register();
  };

  return (
    <>
      <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "15px",
            margin: "0px 12px",
            paddingRight: "20px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
            textAlign: "right",
          }}
        >
          전체 <span style={{ color: "orange" }}>{addData.length}</span> 건
        </Typography>
        <Table
          sx={{
            marginTop: "10px",
            marginBottom: "30px",
            padding: "10px",
          }}
          align="center"
          size="small"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#e0e0e0", height: "60px" }}>
              <TableCell
                align="center"
                sx={{
                  width: "12%",
                  backgroundColor: "#e0e0e0",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                회원ID
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  width: "12%",
                  backgroundColor: "#e0e0e0",
                }}
              >
                프로필명
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#e0e0e0",
                  width: "12%",
                }}
              >
                지급 토큰
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#e0e0e0",
                  width: "17%",
                }}
              >
                유효기간(시작)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#e0e0e0",
                  width: "17%",
                }}
              >
                유효기간(종료)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#e0e0e0",
                  width: "20%",
                }}
              >
                적요
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#e0e0e0",
                  width: "10%",
                }}
              >
                삭제
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? addData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : addData
            ).map((data, index) => (
              <TableRow sx={{ height: "50px" }} key={index}>
                <TableCell align="center">{data.userId}</TableCell>
                <TableCell align="center">{data.userNickname}</TableCell>
                <TableCell align="center">{data.token}</TableCell>
                <TableCell align="center">
                  {moment(data.expDdStart).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell align="center">
                  {moment(data.expDdEnd).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell align="center">{data.note}</TableCell>
                <TableCell
                  align="center"
                  sx={{ textDecoration: "underline" }}
                  onClick={() => deleteData(index)}
                >
                  [삭제]
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 40 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={9}
                count={addData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "#F0A619",
            color: "#FFF",
            width: "200px",
            marginLeft: "10px",
          }}
          onClick={registerData}
        >
          등록
        </Button>
      </div>
    </>
  );
}

export default AddTokenListTable;
