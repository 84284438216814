import { combineReducers } from "redux";
import toggleReducer from "./toggleSlice";
import userReducer from "./userSlice";
import sidebarMenuReducer from "./sidebarMenuSlice";
import bottomSnackbarReducer from "./bottomSnackbarReducer";

export default combineReducers({
  toggle: toggleReducer,
  user: userReducer,
  mainMenu: sidebarMenuReducer,
  bottomSnackbar: bottomSnackbarReducer,
});
