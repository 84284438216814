import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { 
    makeStyles 
    , Modal
    , Grid 
    , TextField 
    , Button
} from "@material-ui/core";
import TitleSimple from "../common/TitleSimple";
import { serviceApi } from "../../api/service";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    paper: {
      border: "1px solid rgba(0, 0, 0, 0.38)",
      backgroundColor: theme.palette.background.paper,
      width: "700px",
      padding: "24px",
      outline: "none",
    },
    deleteBtn: {
      margin: theme.spacing(3, 0, 5),
    },
    submit: {
      margin: theme.spacing(3, 0, 5),
      backgroundColor: theme.palette.custom.black,
      color: theme.palette.custom.white,
    },
}));

// 플랫폼 버전 추가 및 수정
const ChangePlatformVersion = ({open, handleClose, platformId}) => {
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const inputGubun = useRef(null);
    const inputVersion = useRef(null);

    //모달 닫기
    const handleCloseModal = (result) => {
        handleClose(result === true);
    };

    //플랫폼 버전 추가
    const addPlatform = async() => {

        if(inputGubun.current.value === undefined || inputGubun.current.value === null || inputGubun.current.value === ""){
            alert("플랫폼명을 입력해주세요.");
            return false;
        }

        if(inputVersion.current.value === undefined || inputVersion.current.value === null || inputVersion.current.value === ""){
            alert("버전을 입력해주세요.");
            return false;
        }

        const result = await serviceApi.addPlatformVersion({
            gubun: inputGubun.current.value
            , version: inputVersion.current.value
        });

        if(result){
            handleClose(true);
        }else{
            toast.error(result.data.message, {
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
            });  
        }
    };

    //플랫폼 정보 수정
    const modifyPlatform = async() => {
        
        if(inputGubun.current.value === undefined || inputGubun.current.value === null || inputGubun.current.value === ""){
            alert("플랫폼명을 입력해주세요.");
            return false;
        }

        if(inputVersion.current.value === undefined || inputVersion.current.value === null || inputVersion.current.value === ""){
            alert("버전을 입력해주세요.");
            return false;
        }

        const result = await serviceApi.modifyPlatformVersion({
            gubun: inputGubun.current.value
            , version: inputVersion.current.value
            , id: platformId
        });

        if(result){
            handleClose(true);
            getData();
        }else{
            toast.error(result.data.message, {
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
            });  
        }
    };

    //팝업 제목
    const [title, setTitle] = useState();

    // 버튼 액션 
    const [btnAction, setBtnAction] = useState();
    
    //플랫폼 정보 데이터 가져오기
    const [data, setData] = useState();
    const getData = async () => {
        const { status, data: newData } = await serviceApi.platformVersionInfo(platformId);

        if (status === 200) {
            setData(newData);
        }
        setTitle('수정');
        setBtnAction('modify');
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        
        if(platformId !== null && platformId !== undefined && platformId !== ""){
            getData();
        }else{
            setData(null);
            setTitle('추가');
            setBtnAction('add');
            setLoading(false);
        }

    }, [platformId]);

    if (loading) {
        return (
            <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
            <div className={classes.paper}>
                <TitleSimple>플랫폼 {title} </TitleSimple>
            </div>
            </Modal>
        );
    }

    return(
        <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
            <div className={classes.paper}>
                <TitleSimple>플랫폼 {title}</TitleSimple>
                
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                inputRef={inputGubun}
                                id="gubun"
                                label="플랫폼명"
                                name="gubun"
                                autoFocus
                                defaultValue={data?.gubun}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                inputRef={inputVersion}
                                id="version"
                                label="버전"
                                name="version"
                                autoFocus
                                defaultValue={data?.version}
                            />
                        </Grid>
                    </Grid>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                className={classes.submit}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick = {btnAction === 'add' ? addPlatform : modifyPlatform}
                            >
                                {title}
                            </Button>
                        </Grid>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ChangePlatformVersion.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default ChangePlatformVersion;