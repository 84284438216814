import React, { useEffect, useState } from "react";
import { useFileUpload } from "use-file-upload";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import { serviceApi } from "../api/service";
import AdminRoot from "../components/frame/AdminRoot";

import NumberInputModal from "../components/service/NumberInputModal";
import SeriesSelectModal from "../components/service/SeriesSelectModal";
import VideoPlayModal from "../components/service/VideoPlayModal";

const PreviewVideo = () => {
  const cRowCountPerPage = 5;

  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [openOrderNumber, setOpenOrderNumber] = useState(false);
  const [previewVideoId, setPreviewVideoId] = useState(null);
  const [seriesId, setSeriesId] = useState(null);
  const [, selectVideoFile] = useFileUpload(null);
  const [reload, setReload] = useState(0);

  const [openSelectSeries, setOpenSelectSeries] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);
  const requestPreviewVideoInfo = async (
    id,
    { isPublic, orderNumber, seriesId }
  ) => {
    const { status, data: newData } = await serviceApi.updatePreviewVideoInfo(
      id,
      { isPublic, orderNumber, seriesId }
    );
    if (status === 200) {
      const newItems = items.map((item) => {
        if (item.id === id) {
          return newData;
        }
        return item;
      });
      setItems(newItems);

      if (openOrderNumber) {
        setOpenOrderNumber(false);
      }
      if (openSelectSeries) {
        setOpenSelectSeries(false);
      }
    }
  };

  const handleClickPublic = (id, toIsPublic) => {
    requestPreviewVideoInfo(id, { isPublic: toIsPublic });
  };
  const handleOrderNumber = (id, orderNumber) => {
    requestPreviewVideoInfo(id, { orderNumber });
  };

  const handleSeriedId = (id, seriesId) => {
    requestPreviewVideoInfo(id, { seriesId });
  };
  useEffect(() => {
    const requestItems = async () => {
      const { status, data: newData } = await serviceApi.getPreviewVideoList(
        cRowCountPerPage,
        cRowCountPerPage * pageIndex
      );
      if (status === 200) {
        setItems(newData.rows);
        setTotalCount(newData.totalCount);
      }
    };
    requestItems();
  }, [reload, pageIndex]);

  const requestForm = async (id, file) => {
    const fd = new FormData();
    fd.append("previewVideoId", id);
    fd.append("previewVideo", file);
    const { status, data: newData } = await serviceApi.updatePreviewVideoFile(
      fd
    );
    if (status === 200) {
      const newItems = items.map((item) => {
        if (item.id === id) {
          return newData;
        }
        return item;
      });
      setItems(newItems);
    }
  };

  const requestAdd = async () => {
    const { status } = await serviceApi.addPreviewVideo();
    if (status === 200) {
      setReload(reload + 1);
    }
  };

  const requestDelete = async (id) => {
    const { status } = await serviceApi.deletePreviewVideo(id);
    if (status === 200) {
      setReload(reload + 1);
    }
  };
  const getEmptyRows = () => {
    const rows = [];
    const colSpan = editMode ? 6 : 3;
    if (items.length < cRowCountPerPage) {
      const count = cRowCountPerPage - items.length;
      for (let i = 0; i < count; i++) {
        rows.push(
          <TableRow key={`empty-row-${i}`}>
            <TableCell colSpan={colSpan}>&nbsp;</TableCell>
          </TableRow>
        );
      }
    }
    return rows;
  };
  const handleChangePage = (_event, newPage) => {
    setPageIndex(newPage);
  };

  return (
    <AdminRoot>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" gutterBottom component="div">
          미리보기 영상 관리
        </Typography>
        {editMode ? (
          <Box sx={{ display: "inline" }}>
            <Button
              variant="contained"
              onClick={() => {
                requestAdd();
              }}
              sx={{ margin: "5px" }}
            >
              새 항목 추가
            </Button>
            <Button variant="contained" onClick={() => setEditMode(false)}>
              편집 종료
            </Button>
          </Box>
        ) : (
          <Button onClick={() => setEditMode(true)}>편집하기</Button>
        )}
      </Box>

      <Paper sx={{ width: "100%" }}>
        {!editMode ? (
          <TableContainer>
            <Table sx={{ minWidth: 500 }} aria-label="미리보기 영상 관리">
              <TableHead>
                <TableRow>
                  <TableCell align="center">공개여부</TableCell>
                  <TableCell align="center">원본 파일 이름</TableCell>
                  <TableCell align="center">노출순서</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => {
                  return (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {item.isPublic ? "공개" : "비공개"}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="text"
                          onClick={() => {
                            if (item.url) {
                              setVideoUrl(item.url);
                              setOpenPlayer(true);
                            }
                          }}
                        >
                          {item.originalname}
                        </Button>
                      </TableCell>
                      <TableCell align="center">{item.orderNumber}</TableCell>
                    </TableRow>
                  );
                })}
                {getEmptyRows()}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 500 }} aria-label="미리보기 영상 관리">
              <TableHead>
                <TableRow>
                  <TableCell align="center">공개여부</TableCell>
                  <TableCell align="center" colSpan={2}>
                    원본 파일 이름
                  </TableCell>
                  <TableCell align="center">노출순서</TableCell>
                  <TableCell align="center">시리즈</TableCell>
                  <TableCell align="center">삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => {
                  return (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        <Checkbox
                          checked={item.isPublic}
                          onClick={(event) => {
                            event.preventDefault();
                            handleClickPublic(item.id, !item.isPublic);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        공개
                      </TableCell>
                      <TableCell>{item.originalname}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            selectVideoFile(
                              { accept: "video/*" },
                              ({ source, name, size, file }) => {
                                requestForm(item.id, file);
                              }
                            );
                          }}
                        >
                          파일 변경
                        </Button>
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setPreviewVideoId(item.id);
                            setOpenOrderNumber(true);
                          }}
                        >
                          {!item.orderNumber ? "입력" : item.orderNumber}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            setPreviewVideoId(item.id);
                            setSeriesId(item.seriesId);
                            setOpenSelectSeries(true);
                          }}
                        >
                          {!item.seriesId ? "입력" : item.seriesName}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => {
                            requestDelete(item.id);
                          }}
                        >
                          삭제
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {getEmptyRows()}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[cRowCountPerPage]}
          component="div"
          count={totalCount}
          rowsPerPage={cRowCountPerPage}
          page={pageIndex}
          onPageChange={handleChangePage}
        />
      </Paper>
      {
        <NumberInputModal
          open={openOrderNumber}
          onClose={() => {
            setPreviewVideoId(null);
            setOpenOrderNumber(false);
          }}
          onOK={(value) => {
            handleOrderNumber(previewVideoId, value);
          }}
          title={"노출 순서"}
        />
      }
      {
        <SeriesSelectModal
          open={openSelectSeries}
          onClose={() => {
            setOpenSelectSeries(false);
          }}
          onOK={(value) => {
            if (value === null) {
              handleSeriedId(previewVideoId, null);
            } else {
              handleSeriedId(previewVideoId, value.seriesId);
            }
          }}
          selectedSeriesId={seriesId}
          title={"시리즈"}
        />
      }
      {openPlayer ? (
        <VideoPlayModal
          open={openPlayer}
          onClose={() => {
            setVideoUrl(null);
            setOpenPlayer(false);
          }}
          url={videoUrl}
        />
      ) : null}
    </AdminRoot>
  );
};
export default PreviewVideo;
