import React, { useRef, useState, useEffect } from "react";
import {
  Autocomplete,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TablePaginationActions from "../push/TablePaginationActions";
import moment from "moment";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core";
import SelectItems3 from "../common/SelectItems3";
import { useFileUpload } from "use-file-upload";
import toast from "react-hot-toast";
import { tockCommonApi } from "../../api/tock";
import SelectItems2 from "../common/SelectItems2";
import UpdateContentModal from "../modal/UpdateContentModal";
import RegisterContentModal from "../modal/RegisterContentModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1000px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  subTitle: {
    fontFamily: "NotoSansKR",
    fontSize: "20px",
    color: "black",
    margin: "0px 12px",
    padding: "20px 25px ",
    fontWeight: 800,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  addButton: {
    border: "1px solid #BFB9B6",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

function StoryListTable({ seriesId, storyData, handleClose, getData }) {
  const classes = useStyles();
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - storyData.length) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [storyId, setStoryId] = useState(0);
  const [openUpdateContent, setOpenUpdateContent] = useState(false);
  const [openRegisterContent, setOpenRegisterContent] = useState(false);

  //checkbox_single
  const [checkItem, setCheckItem] = useState(0);
  const handleCheckItem = (checked, id) => {
    if (checked === false && id === checkItem) {
      setCheckItem(0);
      setEditIsPublic(null);
      setEditIsCharged(null);
      setEditAndToken(-1);
      setEditIosToken(-1);
      resetFileInfo();
    } else {
      setCheckItem(id);
    }
  };

  const onChangeIsChargedAll = (event) => {
    if (event === true) {
      if (window.confirm("모든 회차의 유료여부를 Y로 변경하시겠습니까?")) {
        changeIsCharedAll(true);
      }
    } else if (event === false) {
      if (window.confirm("모든 회차의 유료여부를 N으로 변경하시겠습니까?")) {
        changeIsCharedAll(false);
      }
    }
  };
  const changeIsCharedAll = async (value) => {
    const idArray = [];
    for (let i = 0; i < storyData.length; i++) {
      idArray.push(storyData[i].id);
    }

    const sendData = {
      items: idArray,
      value: value,
    };
    const result = await tockCommonApi.updateStoryIsCharged(
      seriesId,
      JSON.stringify(sendData)
    );
    if (result.status === 200) {
      getData();
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const onChangeSeriesIdAll = (event) => {
    const tmp = seriesIdArr.find((item) => item.id === event);
    const name = tmp ? tmp.name : null;

    if (event !== "시리즈ID") {
      if (
        window.confirm(
          `모든 회차의 시리즈ID를 ${event} ${name}으로 변경하시겠습니까?`
        )
      ) {
        changeSeriesIdAll(event);
      }
    }
  };

  const changeSeriesIdAll = async (event) => {
    const idArray = [];
    for (let i = 0; i < storyData.length; i++) {
      idArray.push(storyData[i].seriesId);
    }

    const sendData = {
      items: idArray,
      value: event,
    };
    const result = await tockCommonApi.updateSeriesId(
      seriesId,
      JSON.stringify(sendData)
    );
    if (result.status === 200) {
      getData();
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const onChangeAndTokenAll = (event) => {
    if (event !== "-1") {
      if (
        window.confirm(
          `모든 회차의 AOS 토큰을 ${event}토큰으로 변경하시겠습니까?`
        )
      ) {
        changeAndTokenAll(event);
      }
    }
  };
  const changeAndTokenAll = async (event) => {
    const idArray = [];
    for (let i = 0; i < storyData.length; i++) {
      idArray.push(storyData[i].id);
    }

    const sendData = {
      items: idArray,
      value: event,
    };
    const result = await tockCommonApi.updateStoryAndToken(
      seriesId,
      JSON.stringify(sendData)
    );
    if (result.status === 200) {
      getData();
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const onChangeIosTokenAll = (event) => {
    if (event !== "-1") {
      if (
        window.confirm(
          `모든 회차의 IOS 토큰을 ${event}토큰으로 변경하시겠습니까?`
        )
      ) {
        changeIosTokenAll(event);
      }
    }
  };
  const changeIosTokenAll = async (event) => {
    const idArray = [];
    for (let i = 0; i < storyData.length; i++) {
      idArray.push(storyData[i].id);
    }

    const sendData = {
      items: idArray,
      value: event,
    };
    const result = await tockCommonApi.updateStoryIosToken(
      seriesId,
      JSON.stringify(sendData)
    );
    if (result.status === 200) {
      getData();
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const [seriesIds, setSeriesIds] = useState("");
  const [searchedList, setSearchedList] = useState([]);
  const [seriesIdArr, setSeriesIdArr] = useState([
    { id: "시리즈ID", name: "시리즈ID" },
  ]);

  useEffect(() => {
    const getSeriesList = async () => {
      try {
        const { status, data } = await tockCommonApi.getSeries();
        if (status === 200) {
          setSearchedList(
            data.map((item) => {
              return {
                label: `${item.id}. ${item.name}`,
                id: item.id,
                name: item.name,
              };
            })
          );

          let tmp = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
          tmp.unshift({ id: "시리즈ID", name: "시리즈ID" });
          setSeriesIdArr(tmp);
        }
      } catch (error) {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    };
    getSeriesList();
  }, []);

  // const addTableRows = () => {
  //   for (let i = 0; i < storyData.length; i++) {
  //     const dummy = storyData[i].id;
  //     if (dummy === null) {
  //       storyData.splice(i, 1);
  //     }
  //   }

  //   if (addrow === 0) {
  //     storyData.unshift({
  //       andToken: 0,
  //       coverImgUrl: null,
  //       createdAt: null,
  //       draft: false,
  //       id: null,
  //       iosToken: 0,
  //       isCharged: false,
  //       isPublic: false,
  //       isUnity: false,
  //       name: null,
  //       seriesId: seriesId,
  //       unityIosfileUrl: null,
  //       unityfileUrl: null,
  //       updatedAt: null,
  //       viewCount: null,
  //     });
  //   }
  // };
  const deleteTableRows = (index) => {
    storyData.deleteTableRows();
  };

  const [coverImgfile, selectCoverImgFile] = useFileUpload(null);
  const [unityFile, selectUnityFile] = useFileUpload(null);
  const [unityIosFile, selectUnityIosFile] = useFileUpload(null);
  const inputName = useRef(null);
  const [editIsPublic, setEditIsPublic] = useState(null);
  const [editIsCharged, setEditIsCharged] = useState(null);
  const [editAndToken, setEditAndToken] = useState(-1);
  const [editIosToken, setEditIosToken] = useState(-1);

  const onChangeSeriesId = (event, value) => {
    console.log(value.id);
    setSeriesIds(value.id);
  }

  const onChangeIsPublic = (event) => {
    setEditIsPublic(event);
  };
  const onChangeIsCharged = (event) => {
    setEditIsCharged(event);
  };
  const onChangeAndToken = (event) => {
    setEditAndToken(event);
  };
  const onChangeIosToken = (event) => {
    setEditIosToken(event);
  };

  const sendChange = async (event) => {
    event.preventDefault();

    const fd = new FormData();
    fd.append("seriesId", seriesIds !== "" ? seriesIds : seriesId);
    fd.append("name", inputName.current.value);
    fd.append("isPublic", editIsPublic ? 1 : 0);
    fd.append("isCharged", editIsCharged ? 1 : 0);
    fd.append("andToken", editAndToken);
    fd.append("iosToken", editIosToken);

    if (coverImgfile && coverImgfile.source !== undefined) {
      fd.append("coverImg", coverImgfile.file);
    }
    if (unityFile && unityFile.source !== undefined) {
      fd.append("unityfile", unityFile.file);
    }
    if (unityIosFile && unityIosFile.source !== undefined) {
      fd.append("unityIosfile", unityIosFile.file);
    }

    const result = await tockCommonApi.updateStory(checkItem, fd);
    // console.log(fd);
    // console.log(result);

    if (result.status === 200) {
      getData();
      setCheckItem(0);
      setEditIsPublic(null);
      setEditIsCharged(null);
      setEditAndToken(-1);
      setEditIosToken(-1);
      resetFileInfo();
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const resetFileInfo = () => {
    //이미지나 파일들 정보 초기화
    if (coverImgfile) {
      coverImgfile.source = undefined;
      coverImgfile.name = undefined;
      coverImgfile.size = undefined;
      coverImgfile.file = undefined;
    }
    if (unityFile) {
      unityFile.source = undefined;
      unityFile.name = undefined;
      unityFile.size = undefined;
      unityFile.file = undefined;
    }
    if (unityIosFile) {
      unityIosFile.source = undefined;
      unityIosFile.name = undefined;
      unityIosFile.size = undefined;
      unityIosFile.file = undefined;
    }
  };

  useEffect(() => {
    if (checkItem !== 0) {
      const checkedData = storyData.filter((story) => {
        return story.id === checkItem;
      });

      if (editIsPublic === null) {
        setEditIsPublic(checkedData[0].isPublic);
      }
      if (editIsCharged === null) {
        setEditIsCharged(checkedData[0].isCharged);
      }
      if (editAndToken === -1) {
        setEditAndToken(checkedData[0].andToken);
      }
      if (editIosToken === -1) {
        setEditIosToken(checkedData[0].iosToken);
      }

      resetFileInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkItem]);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "16px",
            margin: "0px 12px",
            padding: "20px 20px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
          }}
        >
          리스트
        </Typography>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "15px",
            margin: "0px 12px",
            paddingRight: "20px",
            marginBottom: "12px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
            textAlign: "left",
          }}
        >
          {/* <button onClick={addTableRows}>추가</button> */}
        </Typography>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "15px",
            margin: "0px 12px",
            paddingRight: "20px",
            marginBottom: "12px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
            textAlign: "right",
          }}
        >
          전체 <span style={{ color: "orange" }}>{storyData.length}</span> 건
        </Typography>
        <TableContainer sx={{ maxHeight: 340 }}>
          <Table
            sx={{
              width: "1400px",
              marginBottom: "30px",
            }}
            align="center"
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#e0e0e0", height: "60px" }}>
                <TableCell
                  padding="checkbox"
                  align="center"
                  sx={{ width: "2%", backgroundColor: "#e0e0e0" }}
                >
                  <Checkbox color="primary" />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "6%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  {storyData && storyData.length > 0 ? (
                    <SelectItems3
                      items={seriesIdArr}
                      defaultItem={seriesIdArr[0].id}
                      onChange={onChangeSeriesIdAll}
                    />
                  ) : (
                    "시리즈ID"
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "6%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  회차ID
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "15%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  회차명
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    backgroundColor: "#e0e0e0",
                    width: "14%",
                  }}
                >
                  커버
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "10%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  공개여부
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "10%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  제작방식
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "14%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  작품등록일
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "8%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  {storyData && storyData.length > 0 ? (
                    <SelectItems2
                      items={[
                        { id: "-1", name: "유료여부" },
                        { id: true, name: "Y" },
                        { id: false, name: "N" },
                      ]}
                      defaultItem={"-1"}
                      onChange={onChangeIsChargedAll}
                    />
                  ) : (
                    "유료여부"
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "5%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  {storyData && storyData.length > 0 ? (
                    <SelectItems2
                      items={[
                        { id: "-1", name: "AOS" },
                        { id: "0", name: "0" },
                        { id: "1", name: "1" },
                        { id: "2", name: "2" },
                        { id: "3", name: "3" },
                        { id: "4", name: "4" },
                        { id: "5", name: "5" },
                        { id: "6", name: "6" },
                        { id: "7", name: "7" },
                        { id: "8", name: "8" },
                        { id: "9", name: "9" },
                        { id: "10", name: "10" },
                      ]}
                      defaultItem={"-1"}
                      onChange={onChangeAndTokenAll}
                    />
                  ) : (
                    "AOS"
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "5%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  {storyData && storyData.length > 0 ? (
                    <SelectItems2
                      items={[
                        { id: "-1", name: "IOS" },
                        { id: "0", name: "0" },
                        { id: "1", name: "1" },
                        { id: "2", name: "2" },
                        { id: "3", name: "3" },
                        { id: "4", name: "4" },
                        { id: "5", name: "5" },
                        { id: "6", name: "6" },
                        { id: "7", name: "7" },
                        { id: "8", name: "8" },
                        { id: "9", name: "9" },
                        { id: "10", name: "10" },
                      ]}
                      defaultItem={"-1"}
                      onChange={onChangeIosTokenAll}
                    />
                  ) : (
                    "IOS"
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    width: "6%",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  작품파일
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? storyData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : storyData
              ).map((story) => (
                <TableRow sx={{ height: "50px" }} key={story.id}>
                  <TableCell align="center">
                    <Checkbox
                      color="primary"
                      onChange={(e) =>
                        handleCheckItem(e.target.checked, story.id)
                      }
                      checked={checkItem === story.id ? true : false}
                    />
                  </TableCell>
                  {/* --------  시리즈ID 추가 필요  --------- */}
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <Autocomplete
                        disablePortal
                        options={searchedList}
                        renderInput={(params) => (
                          <TextField {...params} label="시리즈ID" />
                        )}
                        sx={{ width: 110 }}
                        onChange={onChangeSeriesId}
                      />
                    ) : (
                      story.seriesId
                    )}
                  </TableCell>
                  {/* ---------------------------------------- */}
                  <TableCell align="center">{story.id}</TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <TextField
                        variant="outlined"
                        defaultValue={story.name}
                        inputRef={inputName}
                        style={{ width: "150px" }}
                      />
                    ) : (
                      story.name
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => {
                            selectCoverImgFile({ accept: "image/*" });
                          }}
                        >
                          업로드
                        </Button>
                        {coverImgfile ? (
                          <div style={{ marginTop: "10px" }}>
                            <div>
                              <img
                                src={coverImgfile.source}
                                alt="preview"
                                style={{ maxHeight: "100px" }}
                              />
                              <div>
                                <span> 파일 이름: {coverImgfile.name} </span>
                                <span> Size: {coverImgfile.size} </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div> 변경할 파일 선택</div>
                        )}
                      </>
                    ) : (
                      <img
                        src={story.coverImgUrl}
                        alt="preview"
                        style={{ maxHeight: "50px" }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <SelectItems2
                        items={[
                          { id: true, name: "공개" },
                          { id: false, name: "비공개" },
                        ]}
                        defaultItem={story.isPublic}
                        onChange={onChangeIsPublic}
                      />
                    ) : story.isPublic ? (
                      "공개"
                    ) : (
                      "비공개"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      story.isUnity ? (
                        <>
                          <div>
                            <Button
                              onClick={() => {
                                selectUnityFile();
                              }}
                            >
                              유니티 파일 업로드
                            </Button>
                            {unityFile ? (
                              <div style={{ marginTop: "10px" }}>
                                <div>
                                  <div>
                                    <span> 파일 이름: {unityFile.name} </span>
                                    <span> Size: {unityFile.size} </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <span>파일 업로드</span>
                            )}
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                selectUnityIosFile();
                              }}
                            >
                              유니티 ios 파일 업로드
                            </Button>
                            {unityIosFile ? (
                              <div style={{ marginTop: "10px" }}>
                                <div>
                                  <div>
                                    <span>
                                      {" "}
                                      파일 이름: {unityIosFile.name}{" "}
                                    </span>
                                    <span> Size: {unityIosFile.size} </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <span>파일 업로드</span>
                            )}
                          </div>
                        </>
                      ) : (
                        `톡크 에디터`
                      )
                    ) : story.isUnity ? (
                      "유니티"
                    ) : (
                      "톡크 에디터"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {story.createdAt
                      ? moment(story.createdAt).format("YYYY-MM-DD HH:mm:ss")
                      : ""}
                  </TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <SelectItems2
                        items={[
                          { id: true, name: "Y" },
                          { id: false, name: "N" },
                        ]}
                        defaultItem={story.isCharged.toString()}
                        onChange={onChangeIsCharged}
                      />
                    ) : story.isCharged ? (
                      "Y"
                    ) : (
                      "N"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <SelectItems2
                        items={[
                          { id: "0", name: "0" },
                          { id: "1", name: "1" },
                          { id: "2", name: "2" },
                          { id: "3", name: "3" },
                          { id: "4", name: "4" },
                          { id: "5", name: "5" },
                          { id: "6", name: "6" },
                          { id: "7", name: "7" },
                          { id: "8", name: "8" },
                          { id: "9", name: "9" },
                          { id: "10", name: "10" },
                        ]}
                        defaultItem={story.andToken}
                        onChange={onChangeAndToken}
                      />
                    ) : (
                      story.andToken
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <SelectItems2
                        items={[
                          { id: "0", name: "0" },
                          { id: "1", name: "1" },
                          { id: "2", name: "2" },
                          { id: "3", name: "3" },
                          { id: "4", name: "4" },
                          { id: "5", name: "5" },
                          { id: "6", name: "6" },
                          { id: "7", name: "7" },
                          { id: "8", name: "8" },
                          { id: "9", name: "9" },
                          { id: "10", name: "10" },
                        ]}
                        defaultItem={story.iosToken}
                        onChange={onChangeIosToken}
                      />
                    ) : (
                      story.iosToken
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {checkItem === story.id ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setStoryId(story.id);
                          setOpenRegisterContent(true);
                        }}
                      >
                        등록
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          setStoryId(story.id);
                          setOpenUpdateContent(true);
                        }}
                      >
                        수정
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    25,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  colSpan={12}
                  count={storyData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <UpdateContentModal
          open={openUpdateContent}
          seriesId={seriesId}
          storyId={storyId}
          handleClose={() => setOpenUpdateContent(false)}
        />

        <RegisterContentModal
          open={openRegisterContent}
          seriesId={seriesId}
          handleClose={() => setOpenRegisterContent(false)}
        />

        <ButtonContainer>
          {/* <Button
            className={classes.addButton}
            onClick={checkItem !== 0 ? sendAdd : null}
          >
            등록
          </Button> */}
          <Button
            className={classes.cancelButton}
            onClick={(result) => {
              handleClose(result === true);
            }}
          >
            취소
          </Button>
          <Button
            className={classes.editButton}
            onClick={checkItem !== 0 ? sendChange : null}
          >
            수정
          </Button>
        </ButtonContainer>
      </Paper>
    </>
  );
}

const ButtonContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  height: "50px",
  border: "1px solid #f2f2f2",
  alignItems: "center",
});

export default StoryListTable;
