import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { serviceApi } from "../../api/service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};


const SeriesSelectModal = ({
  open,
  onClose,
  onOK,
  selectedSeriesId,
  title,
  context,
  genreId,
}) => {
  
  const [seriesList, setSeriesList] = useState([]);
  const [seriesId, setSeriesId] = useState(selectedSeriesId);
  const [openSelect, setOpenSelect] = useState(false);
  
  useEffect(() => {
    const requestSeriesList = async () => {
      const { status, data: newData } = await serviceApi.getSeriesList("name", genreId);
      if (status === 200) {
        setSeriesList(newData);
      }
    };

    if(open){
      requestSeriesList();
    }
  }, [open]);

  useEffect(() => {
    setSeriesId(selectedSeriesId);
  }, [selectedSeriesId]);

  const handleChange = (event) => {
    setSeriesId(event.target.value);
  };

  const handleClose = () => {
    setOpenSelect(false);
  };

  const handleOpen = () => {
    setOpenSelect(true);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style, width: 400 }}>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography> */}

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-controlled-open-select-label">
            {title}
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={openSelect}
            onClose={handleClose}
            onOpen={handleOpen}
            value={seriesId || ""}
            label="Series"
            onChange={handleChange}
          >
            {/* <MenuItem value={""}>
              <em>None</em>
            </MenuItem> */}
            {seriesList.map((series) => {
              return (
                <MenuItem
                  value={series.id}
                  key={series.id}
                >{`${series.name}(id:${series.id})`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={() => {
              onClose();
            }}
          >
            취소
          </Button>
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={() => {
              if (seriesId === "") onOK(null);
              else {
                const found = seriesList.find((el) => seriesId === el.id);
                onOK({ seriesId, name: found.name });
              }
            }}
          >
            적용
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

SeriesSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired,
  selectedSeriesId: PropTypes.number,
  title: PropTypes.string,
  context: PropTypes.any,
  genreId: PropTypes.any,
};

SeriesSelectModal.defaultProps = {
  selectedSeriesId: null,
  title: null,
  context: null,
  genreId: null,
};

export default SeriesSelectModal;
