import React, {forwardRef, useState, useEffect } from "react";
import AdminRoot from "../components/frame/AdminRoot";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { serviceApi } from "../api/service";
import ChangePlatformVersion from "../components/modal/ChangePlatformVersion";
import MaterialTable from "material-table";
import {
    AddBox
    , ArrowDownward
    , Check 
    , ChevronLeft
    , ChevronRight
    , Clear 
    , DeleteOutline
    , Edit
    , FilterList
    , FirstPage
    , LastPage
    , Remove
    , SaveAlt
    , Search
    , ViewColumn
} from "@material-ui/icons"
import { Button } from "@mui/material";
import toast from "react-hot-toast";

// 플랫폼 별 버전 관리
const PlatformVersion= () => {
    //스타일 
    const useStyles = makeStyles(() => ({
        root: {},
    }));
    
    const classes = useStyles();
    
    //테이블 아이콘 사용
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => (
          <ChevronRight {...props} ref={ref} />
        )),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => (
          <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    const materialTableOptions = {
        search: true,
        sorting: true,
        draggable: false,
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
        headerStyle: {
          backgroundColor: "#EEE",
          fontWeight: "bold",
          textAlign: "center",
          textOverflow: "clip",
          whiteSpace: "nowrap",
        },
        cellStyle: {
          whiteSpace: "nowrap",
          textAlign: "center",
        },
        actionsCellStyle: {
          textAlign: "center",
        },
    };

    const materialTableColumns = [
        {
          title: "번호",
          field: "id",
        },
        {
            title: "플랫폼",
            field: "gubun",
        },
        {
            title: "버전",
            field: "version",
        },
        {
            title: "사용여부",
            field: "useYn",
        },
        {
            title: "등록일",
            field: "createdAt",
            render: (rowData) => (
                <>
                  {rowData.createdAt
                    ? moment(rowData.createdAt).format("YY-MM-DD  HH:mm")
                    : ""}
                </>
            ),
        },
        {
            title: "수정",
            field: "id",
            render: (rowData) => {
                return (
                    <>
                    <Button
                        variant="contained"
                        onClick={async() => {
                            setPlatformId(rowData.id);
                            setOpenPlatform(true);
                        }}
                    >
                        수정
                    </Button>
                    <Button
                        sx={{marginLeft: "20px"}}
                        variant="outlined"
                        onClick={() => {
                            onDelete(rowData.id);
                        }}
                        >
                        삭제
                    </Button>
                    </>
                );
              },
        },
    ];

    // 삭제
    const onDelete = async(id) => {
        if (window.confirm("삭제하시겠습니까?") === false) {
            return;

        } else {
            const result = await serviceApi.deletePlatformVersion(id);

            if (result.status === 200) {
                window.location.reload();

            } else {
                toast.error(result.data.message, {
                    style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                        },
                    }
                );
            }
        }
    };

    //플랫폼 버전 가져오기
    const [data, setData] = useState([]);
    const getPlatformVersionList = async () => {
        const { status, data: newData } = await serviceApi.platformVersionList();

        if (status === 200) {
            setData(newData);
        }
    };

    


    // 플랫폼 추가 팝업
    const [openPlatform, setOpenPlatform] = useState(false);
    const [reloadCount, setReloadCount] = useState(0);
    const [platformId, setPlatformId] = useState(null);

    useEffect(() => {
        getPlatformVersionList();

    }, [reloadCount]);

    return (
        <AdminRoot>
            <div className={classes.root}>
                <MaterialTable
                    title = "플랫폼 버전 관리"
                    icons = {tableIcons}
                    data={data}
                    columns={materialTableColumns}
                    options={materialTableOptions}
                    actions={[
                        {
                            icon: AddBox
                            , tooltip: "플랫폼 추가"
                            , isFreeAction: true
                            , onClick: () => {
                                setPlatformId(null);
                                setOpenPlatform(true);
                            }
                        }
                    ]}
                />
                <ChangePlatformVersion
                    open = {openPlatform}
                    handleClose = { (result) => {
                            setOpenPlatform(false);
                            if(result){
                                setReloadCount(reloadCount + 1)
                            }
                        }
                    }
                    platformId = { platformId }
                    
                />
            </div>
        </AdminRoot>
    );
};
export default PlatformVersion;