import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const VideoPlayModal = ({ open, onClose, url }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style, width: 400 }}>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography> */}

        <video controls width="100%">
          <source src={url} />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={() => {
              onClose();
            }}
          >
            취소
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

VideoPlayModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default VideoPlayModal;
