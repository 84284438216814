import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { styled } from "@material-ui/core";
import AdminRoot from "../components/frame/AdminRoot";
import { useTokensApi } from "../api/payments";
import Loading from "../components/common/Loading";
import PayDetail from "../components/payments/UseTokenDetail_PayDetail";
import UseTokenList from "../components/payments/UseTokenDetail_UseTokenList";

const UseTokenDetail = ({ match }) => {
  const { id } = match.params;

  const [loading, setLoading] = useState(false);
  const [payDetail, setPayDetail] = useState("");
  const [useTokenList, setUseTokenList] = useState([]);

  const getData = async () => {
    setLoading(true);
    const { status, data } = await useTokensApi.detail(id);
    if (status === 200) {
      setPayDetail(data);
      setUseTokenList(data.useTokenList);
    } else {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    setLoading(false);
  };

  const isCanceled = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <AdminRoot>
      <Title>토큰 차감(상세)</Title>
      {loading ? (
        <div style={{ height: "300px" }}>
          <Loading />
        </div>
      ) : (
        <>
          <PayDetail loading={loading} detail={payDetail} />
          <UseTokenList
            loading={loading}
            list={useTokenList}
            isCanceled={isCanceled}
          />
        </>
      )}
    </AdminRoot>
  );
};

const Title = styled("div")({
  marginBottom: "20px",
});

export default UseTokenDetail;
