import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loading from "../common/Loading";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    //border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "870px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const UsersCancelDetailModal = ({ open, handleClose, detail }) => {
  const classes = useStyles();

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      {!open ? (
        <Loading />
      ) : (
        <div className={classes.paper}>
          <Typography className={classes.title}>취소 정보</Typography>
          <div style={{ padding: "20px 20px" }}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontSize: "16px",
                margin: "0px 12px",
                fontWeight: 500,
                letterSpacing: "-1px",
                lineHeight: "1.6",
              }}
            >
              취소주문 정보
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: 750, marginTop: "10px" }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#e0e0e0",
                      height: "50px",
                    }}
                  >
                    <TableCell
                      sx={{
                        width: "17%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      CP
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "14%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      작품명
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "14%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      회차
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "12%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      토큰사용
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "13%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      사용금액
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "12%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      토큰종류
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "18%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      취소일시
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell align="center">
                    {detail["series.cpCompany.name"]
                      ? detail["series.cpCompany.name"]
                      : ""}
                  </TableCell>
                  <TableCell align="center">{detail["series.name"]}</TableCell>
                  <TableCell align="center">{detail["stories.name"]}</TableCell>
                  <TableCell align="center">{-detail.useToken}</TableCell>
                  <TableCell align="center">
                    {/* {-detail.useToken * detail["payments.payPerAmt"]} */}
                    {-detail.useToken * 120}
                  </TableCell>
                  <TableCell align="center">
                    {detail["holdTokens.tknClCd"] === "PAY" ? "구매" : "이벤트"}
                  </TableCell>
                  <TableCell align="center">
                    {detail["payments.payClCd"] === "1"
                      ? moment(detail["payments.updatedAt"]).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : moment(detail.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ padding: "20px 20px", marginTop: "30px" }}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontSize: "16px",
                margin: "0px 12px",
                fontWeight: 500,
                letterSpacing: "-1px",
                lineHeight: "1.6",
              }}
            >
              원주문 정보
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: 750, marginTop: "10px" }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#e0e0e0",
                      height: "50px",
                    }}
                  >
                    <TableCell
                      sx={{
                        width: "17%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      CP
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "14%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      작품명
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "14%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      회차
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "12%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      토큰사용
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "13%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      사용금액
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "12%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      토큰종류
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "18%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      차감일시
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell align="center">
                    {detail["series.cpCompany.name"]
                      ? detail["series.cpCompany.name"]
                      : ""}
                  </TableCell>
                  <TableCell align="center">{detail["series.name"]}</TableCell>
                  <TableCell align="center">{detail["stories.name"]}</TableCell>
                  <TableCell align="center">{detail.useToken}</TableCell>
                  <TableCell align="center">
                    {/* {detail.useToken * detail["payments.payPerAmt"]} */}
                    {detail.useToken * 120}
                  </TableCell>
                  <TableCell align="center">
                    {detail["holdTokens.tknClCd"] === "PAY" ? "구매" : "이벤트"}
                  </TableCell>
                  <TableCell align="center">
                    {moment(detail.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </Modal>
  );
};

UsersCancelDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UsersCancelDetailModal;
