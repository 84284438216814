import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import TablePaginationActions from "../push/TablePaginationActions";
import PropTypes from "prop-types";

function CpUseTokenList({ list }) {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dataLength = list ? list.length : 0;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //누적금액
  const sumArr = [];
  let sumVal = 0;

  const calculateSum = (list) => {
    for (let i = dataLength - 1; i >= 0; i--) {
      if (list[i].tknClCd === "PAY") {
        const tkn = list[i].payClCd === "0" ? list[i].useToken : 0;
        sumVal += tkn * 120;
        sumArr.push(sumVal);
      } else {
        sumArr.push("-");
      }
    }
  };

  return (
    <>
      {calculateSum(list)}
      <div>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "16px",
            margin: "0px 12px",
            padding: "20px 20px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
          }}
        >
          사용 내역
        </Typography>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontSize: "15px",
            margin: "0px 12px",
            paddingRight: "20px",
            fontWeight: 500,
            letterSpacing: "-1px",
            lineHeight: "1.6",
            textAlign: "right",
            marginBottom: "12px",
          }}
        >
          전체 <span style={{ color: "orange" }}>{list.length}</span> 건
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#e0e0e0",
                  height: "60px",
                }}
              >
                <TableCell
                  sx={{ width: "5%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  NO
                </TableCell>
                <TableCell
                  sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  회원ID
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  필명
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  회차
                </TableCell>
                <TableCell
                  sx={{
                    width: "6%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  토큰
                </TableCell>
                <TableCell
                  sx={{
                    width: "8%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  금액
                </TableCell>
                <TableCell
                  sx={{ width: "6%", fontWeight: "bold", fontSize: "15px" }}
                  align="center"
                >
                  종류
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    wordBreak: "keep-all",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  차감/취소일자
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  누적금액
                </TableCell>
                <TableCell
                  sx={{
                    width: "6%",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  비고
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? list.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : list
              ).map((data, index) => (
                <TableRow key={dataLength - index - page * rowsPerPage}>
                  <TableCell align="center">
                    {dataLength - index - page * rowsPerPage}
                  </TableCell>
                  <TableCell align="center">{data.id}</TableCell>
                  <TableCell align="center">{data.nickname}</TableCell>
                  <TableCell align="center">{data.storyName}</TableCell>
                  <TableCell align="center">
                    {data.payClCd === "0" ? data.useToken : 0}
                  </TableCell>
                  <TableCell align="center">
                    {/* {data.payClCd === "0" ? data.useToken * data.payPerAmt : 0} */}
                    {data.payClCd === "0" ? data.useToken * 120 : 0}
                  </TableCell>
                  <TableCell align="center">
                    {data.tknClCd === "PAY" ? "구매" : "이벤트"}
                  </TableCell>
                  <TableCell align="center">
                    {moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell align="center">
                    {sumArr[dataLength - index - page * rowsPerPage - 1]}
                  </TableCell>
                  <TableCell align="center">
                    {data.payClCd === "0" ? "정상" : "취소"}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={10}
                  count={list.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

CpUseTokenList.propTypes = {
  list: PropTypes.array,
};

export default CpUseTokenList;
