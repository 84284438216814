import React, { useEffect, useState, useRef }from "react";
import { mainAuthorRankingApi } from "../api/service";
import AuthorSelectModal from "../components/service/AuthorSelectModal";
import { convertDate } from "../components/lib/utils";
import AdminRoot from "../components/frame/AdminRoot";
import { ReOrderableItem, ReOrderableList } from "react-reorderable-list";
import { styled } from "@material-ui/core";
import { 
    Typography
    , FormControl
    , InputLabel
    , Select
    , MenuItem
    , TextField
    , Box
    , IconButton
} from "@mui/material";
import { 
    DateRangePicker
    , Button
} from "rsuite";
import CancelIcon from "@mui/icons-material/Cancel";
import toast from "react-hot-toast";

const MainAuthor= () => {

    //날짜 검색
    const [date, setDate] = useState([new Date("2022-01-01"), new Date()]);
    const startAt = convertDate(date[0]);
    const endAt = convertDate(date[1]);

    // '오늘', '1주일', '1개월', '3개월', '6개월', '전체' 클릭시 날짜 세팅
    const onPeriodClick = (event) => {
        const created_date = new Date();
        const thisYear = created_date.getFullYear();
        const thisMonth = created_date.getMonth() + 1;
        const today = created_date.getDate();

        if (event.target.name === "today") {
            setDate([new Date(), new Date()]);
        } else if (event.target.name === "week") {
            setDate([new Date(thisYear, thisMonth - 1, today - 6), new Date()]);
        } else if (event.target.name === "month1") {
            setDate([new Date(thisYear, thisMonth - 2, today), new Date()]);
        } else if (event.target.name === "month3") {
            setDate([new Date(thisYear, thisMonth - 4, today), new Date()]);
        } else if (event.target.name === "month6") {
            setDate([new Date(thisYear, thisMonth - 7, today), new Date()]);
        } else if (event.target.name === "whole") {
            setDate([new Date("2022-01-01"), new Date()]);
        }
    };

    // 검색 키워드
    const [keyword, setKeyword] = useState("");
    const onKeywordChange = (event) => {
        setKeyword(event.target.value);
    };

    // Keyword 인풋창에서 입력 후 엔터키 누르면 검색.
    const handleOnKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearchBtnClick();
        }
    }

    //검색 구분
    const [division, setDivision] = useState("");
    const onDivisionChange = (event) => {
        setDivision(event.target.value);
    };

    // '초기화'버튼과 달력내부 'X'버튼 클릭시 초기화
    const onCleanBtnClick = () => {
        setDate([new Date("2022-01-01"), new Date()]);
        setDivision("");
        setKeyword("");
        getAuthorList();
    };


    // 검색
    const onSearchBtnClick = async() => {

        const {status, data} = await mainAuthorRankingApi.getAuthorList(
            convertDate(date[0]).format("YYYY-MM-DD")
            , convertDate(date[1]).format("YYYY-MM-DD")
            , division
            , keyword
        );

        if(status === 200){
            setAuthorList(data);
        }
    };

    //작가 목록
    const [authorList, setAuthorList] = useState([]);

    //메인 작가 가져오기(기존에 등록된)
    const getAuthorList = async () => {
        const { status, data } = await mainAuthorRankingApi.list();

        if (status === 200) {
            setAuthorList(data);
        }
    };

    // 작가 등록 팝업
    const [openSelectAuthor, setOpenSelectAuthor] = useState(false);
    
    //메인 작가 등록
    const setMainAuthorRanking = async() => {

        await mainAuthorRankingApi.setMainAuthorRanking(authorList);
        toast.success("메인 작가 랭킹 반영되었습니다.");

    };

    useEffect(() => {
        getAuthorList();

    }, []);

    return (
        <AdminRoot>
            <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginBottom: "20px"}}
            >
                메인 작가 전시
            </Typography>

            {/* 검색 영역 */}
            <SearchContainer> 
                <Inner1>
                    <Text>검색 기간</Text>
                    <DateRangePicker
                        format="yyyy. MM. dd"
                        ranges={[]}
                        value={date}
                        onChange={setDate}
                        onClean={onCleanBtnClick}
                        placeholder="select date range"
                    />
                    <Button
                        name="today"
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onPeriodClick}
                    >
                        오늘
                    </Button>
                    <Button
                        name="week"
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onPeriodClick}
                    >
                        1주일
                    </Button>
                    <Button
                        name="month1"
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onPeriodClick}
                    >
                        1개월
                    </Button>
                    <Button
                        name="month3"
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onPeriodClick}
                    >
                        3개월
                    </Button>
                    <Button
                        name="month6"
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onPeriodClick}
                    >
                        6개월
                    </Button>
                    <Button
                        name="whole"
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onPeriodClick}
                    >
                        전체
                    </Button>
                </Inner1>
                <Inner1>
                <Text>검색 구분</Text>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-search-label">Search</InputLabel>
                        <Select
                            labelId="select-search-label"
                            id="select-search"
                            label="Search"
                            value={division}
                            onChange={onDivisionChange}
                        >
                            <MenuItem value={"nickname"}>필명</MenuItem>
                            <MenuItem value={"id"}>작가 ID</MenuItem>
                            <MenuItem value={"seriesName"}>작품 이름</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField 
                        label="Keyword" 
                        value={keyword} 
                        onChange={onKeywordChange} 
                        onKeyPress={handleOnKeyPress} 
                    />
                </Inner1>
                <Inner2>
                    <Button
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onSearchBtnClick}
                    >
                        검색
                    </Button>
                    <Button
                        color="orange"
                        appearance="subtle"
                        style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
                        onClick={onCleanBtnClick}
                    >
                        초기화
                    </Button>
                </Inner2>
            </SearchContainer>

            <Box style= {{marginTop: "20px", textAlign:"right"}}>
                <Button
                    variant= "contained"
                    size= "large"
                    style= {{margin: "10px", backgroundColor: "#505050", width:"150px", color:"white"}}
                    onClick= {() => {
                        setOpenSelectAuthor(true);
                    }}
                >
                    작가 등록
                </Button>
                <Button
                    variant= "contained"
                    size= "large"
                    style= {{margin: "10px", backgroundColor: "#505050", width:"150px", color:"white"}}
                    onClick={() => {
                        setMainAuthorRanking();
                    }}
                >
                    서버 적용
                </Button>
            </Box>

            {/* 메인 작가 목록 */}
            <Box>
                <ReOrderableList
                    list={authorList}
                    onListUpdate={(newList) => setAuthorList(newList)}
                >
                    {authorList.map((item, index) => (
                        <ReOrderableItem key={`item-${index}`}>
                        <div
                            style={{
                            height: "50px",
                            border: "1px solid #333333",
                            borderRadius: "10px",
                            margin: "10px",
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            }}
                        >
                            <Typography>{`${item.nickname} (id:${item.id}) 팔로우 수 (${item.followCount})`}</Typography>
                            <IconButton
                                style={{ marginLeft: "auto" }}
                                onClick={() => {
                                    const newAuthors = authorList.filter(
                                    (author) => author.id !== item.id
                                    );
                                    setAuthorList(newAuthors);
                            }}
                            >
                            <CancelIcon />
                            </IconButton>
                        </div>
                        </ReOrderableItem>
                    ))}
                </ReOrderableList>
            </Box>

             {/* 작가 등록 팝업 */}
             <AuthorSelectModal
                open={openSelectAuthor}
                onClose={() => {
                    setOpenSelectAuthor(false);
                }}
                onOK={(value) => {
                if (value) {
                    const findAuthor = authorList.find(
                        (item) => item.id === value.Id
                    );

                    if (!findAuthor) {
                        let newValue = {};
                        if(value != undefined && value != '' && value != null){
                            newValue.id = value.authorId;
                            newValue.nickname = value.nickname;
                            newValue.followCount = value.followCount;
                        }
                        setAuthorList([...authorList, newValue]);
                    }
                    setOpenSelectAuthor(false);
                }
                }}
                title= {"작가"}
            /> 
        </AdminRoot>
    );
};

const SearchContainer = styled("div")({
    position: "relative",
    marginBottom: "20px",
});

const Inner1 = styled("div")({
    position: "relative",
    width: "100%",
    marginBottom: "20px",
    border: "1px solid #e0e0e0",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
});

const Inner2 = styled("div")({
    position: "relative",
    width: "100%",
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const Text = styled("p")({
    border: "1px solid black",
    width: "100px",
    height: "37px",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#bdbdbd",
    color: "black",
});
  

export default MainAuthor;
