import React, { useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import { userLogin } from "../redux/userSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#F22929",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.custom.secondary,
  },
  title: {
    color: theme.palette.custom.secondary,
    fontFamily: "NotoSerifKR",
    fontWeight: "500",
  },
}));

export default function Login() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const inputEmail = useRef(null);
  const inputPassword = useRef(null);

  const onSubmitLogin = (event) => {
    event.preventDefault();

    dispatch(
      userLogin({
        mail: inputEmail.current.value,
        password: inputPassword.current.value,
      })
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon className={classes.lockOutlinedIcon} />
        </Avatar>
        <Typography component="h1" variant="h4" className={classes.title}>
          {"교원-TOCK"}
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmitLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={inputEmail}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={inputPassword}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            로그인
          </Button>
        </form>
      </div>
    </Container>
  );
}
