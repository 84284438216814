import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { styled } from "@material-ui/core";
import AdminRoot from "../components/frame/AdminRoot";
import { useTokensApi } from "../api/payments";
import UseTokenSearchBar from "../components/contents/UseTokenSearchBar";
import ExcelDownOfUseTokensUsers from "../components/Statistics/ExcelDown_UseTokensUsers";
import ExcelDownOfUseTokensCp from "../components/Statistics/ExcelDown_UseTokensCp";
import { Button } from "rsuite";
import UseTokenListUsers from "../components/payments/UseTokenList_Users";
import UseTokenListCp from "../components/payments/UseTokenList_Cp";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../components/common/TablePaginationActions";

const Title = styled("div")({
    marginBottom: "20px",
});

const UseTokens = () => {
    const [loading, setLoading] = useState(true);
    const [cpMode, setCpMode] = useState(false);
    const [searchedUsersTokensList, setSearchedUsersTokensList] = useState([]);
    const [searchedCpTokensList, setSearchedCpTokensList] = useState([]);

    //페이징
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageSize, setPageSize] = useState(10);
    const [totalCnt, setTotalCnt] = useState(0);
    
    //검색
    const [searchParams, setSearchParams] = useState([]);
    
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
        setPageSize(pageSize);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value));
        setPage(0);
        setRowsPerPage(parseInt(event.target.value));
    };

    function isEmpty(val){
       return val === null || val === undefined || val === "";
    }

    let { startAt, endAt, device, tknClCd, division, keyword } = useState("");
    function getParam(key){
        return key === "page" ? (page + 1) : eval(key);
    }
    
    function getSearchParams (){
        if(!isEmpty(searchParams)){
            for (const key of searchParams){
                switch(key[0]){
                    case "startAt":
                        startAt = key[1];
                        break;
                    case "endAt":
                        endAt = key[1];
                        break;
                    case "device":
                        device = key[1];
                        break;
                    case "tknClCd":
                        tknClCd = key[1];
                        break;
                    case "division" :
                        division = key[1];
                        break;
                    case "keyword":
                        keyword = key[1];
                        break;
                    default:
                        break;
                }
            }
        }
        return startAt, endAt, device, tknClCd, division, keyword ;
    }

    const getUsersData = async (
        startAt
        , endAt
        , device
        , tknClCd
        , division
        , keyword
        , page
        , pageSize 
    ) => {
        setLoading(true);
        getSearchParams();

        const { status, data } = await useTokensApi.listUsers(
            isEmpty(startAt) ? getParam("startAt") : startAt.format("YYYY-MM-DD")
            , isEmpty(endAt) ? getParam("endAt") : endAt.format("YYYY-MM-DD")
            , isEmpty(device) ? getParam("device") : device
            , isEmpty(tknClCd) ? getParam("tknClCd") : tknClCd
            , isEmpty(division) ? getParam("division") : division
            , isEmpty(keyword) ? getParam("keyword") : keyword
            , isEmpty(page) ? getParam("page") : (page + 1)
            , isEmpty(pageSize) ? getParam("pageSize") : pageSize
        );

        setLoading(false);
        if(status === 200){
            setSearchedUsersTokensList(data.useTokens);
            setTotalCnt(parseInt(data.totalCount));
            setSearchParams(data.searchParams);

        }else{
            toast.error("서버와 통신이 원활하지 않습니다", {
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        }
    };

    const getCpData= async (
        startAt
        , endAt
        , device
        , tknClCd
        , division
        , keyword
        , page
        , pageSize 
    ) => {
        setLoading(true);
        await getSearchParams();

        const { status, data } = await useTokensApi.listCp(
            isEmpty(startAt) ? getParam("startAt") : startAt.format("YYYY-MM-DD")
            , isEmpty(endAt) ? getParam("endAt") : endAt.format("YYYY-MM-DD")
            , isEmpty(device) ? getParam("device") : device
            , isEmpty(tknClCd) ? getParam("tknClCd") : tknClCd
            , isEmpty(division) ? getParam("division") : division
            , isEmpty(keyword) ? getParam("keyword") : keyword
            , isEmpty(page) ? getParam("page") : (page + 1)
            , isEmpty(pageSize) ? getParam("pageSize") : pageSize
        );
    
        setLoading(false);
        if(status === 200){
            setSearchedCpTokensList(data.finalResult);
            setTotalCnt(parseInt(data.totalCount[0].count));
            setSearchParams(data.searchParams);
        }else{
            toast.error("서버와 통신이 원활하지 않습니다", {
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        }
    };

    useEffect(() => {
        cpMode === true ?  getCpData() :  getUsersData();
    }, [page, pageSize]);

    return (
        <AdminRoot>
            <Title>토큰 차감</Title>
            <>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Button 
                        color="orange"
                        appearance= {cpMode ? "subtle" : "primary"} 
                        style={{
                            border: "1px solid #e0e0e0",
                            margin: "0 5px",
                            width: "100px",
                            height: "40px",
                            fontSize: "17px",
                        }}
                        onClick={() => {
                            setCpMode(false);
                            setPage(0);
                            getUsersData();
                        }}
                    >
                        고객
                    </Button>

                    <Button
                        color="orange"
                        appearance={cpMode ? "primary" : "subtle"} 
                        style={{
                            border: "1px solid #e0e0e0",
                            margin: "0 5px",
                            width: "100px",
                            height: "40px",
                            fontSize: "17px",
                        }}
                        onClick={() => {
                            setCpMode(true);
                            setPage(0);
                            getCpData();
                        }}
                    >
                        CP사
                    </Button>
                </div>
            </>
            <>
                <UseTokenSearchBar 
                    getUseTokenList={ cpMode ? getCpData : getUsersData} 
                    pageSize = { pageSize }
                    setPage = { setPage }
                    setSearchParams = { setSearchParams }
                />
            </>
            {cpMode ? (
                <>
                    <ExcelDownOfUseTokensCp 
                        getSearchParams = { getSearchParams }
                        getParam = { getParam }
                        isEmpty = { isEmpty }
                    />
                    <UseTokenListCp searchedCpTokensList={ searchedCpTokensList }
                        totalCnt = { totalCnt }
                        pageNum = { page }
                        rowsPerPageNum = { pageSize }
                    />
                </>
            ) : (
                <>
                    <ExcelDownOfUseTokensUsers 
                        getSearchParams = { getSearchParams }
                        getParam = { getParam }
                        isEmpty = { isEmpty }
                    />
                    <UseTokenListUsers
                        searchedUseTokensList = { searchedUsersTokensList }
                        totalCnt = { totalCnt }
                        pageNum = { page }
                        rowsPerPageNum = { pageSize }
                    />
                </>
            )
     
        }
        <table style = {{float:"right"}}>
            <tbody>
                <tr>
                    <TablePagination
                        count = { totalCnt }
                        rowsPerPage = { rowsPerPage }
                        page = { page }
                        onPageChange = { handleChangePage }
                        onRowsPerPageChange = { handleChangeRowsPerPage }
                        ActionsComponent = { TablePaginationActions }
                    />
                </tr>
            </tbody>
        </table> 
        </AdminRoot>
    );
};

export default UseTokens;

