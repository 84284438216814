import querystring from "querystring";
import axiosInstance from "./axiosInstance";

const statistics = "statistics";

export const statisticsApi = {
  // 작품별 통계
  getSeries: (startAt, endAt, searchKey) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      searchKey,
    });
    return axiosInstance.get(`${statistics}/series?${query}`);
  },
  // 작품별 개별 시리즈 통계
  getSeriesStory: (id) => {
    return axiosInstance.get(`${statistics}/series/${id}/stories`);
  },
  // 작가별 통계
  getAuthor: (startAt, endAt, searchFilter, searchKey) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      searchFilter,
      searchKey,
    });
    return axiosInstance.get(`${statistics}/users?${query}`);
  },
  // 댓글 조회
  getReply: (startAt, endAt, searchKey) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      searchKey,
    });
    return axiosInstance.get(`${statistics}/replies?${query}`);
  },
  // 댓글 숨김
  doAdminHide: (form) => {
    return axiosInstance.post(`${statistics}/replies/adminHide`, form);
  },
  //댓글 숨김 해제
  doAdminHideCancel: (form) => {
    return axiosInstance.post(`${statistics}/replies/adminHideCancel`, form);
  },
};
