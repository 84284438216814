import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItemText, ListItem, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '74px',
  },
  unSelectedText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#838383',
  },
  selectedText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#6AD1F2',
  },
}));

const SideBarItem = withRouter(({ title, to, location: { pathname } }) => {
  const classes = useStyles();
  const current = pathname === to;
  const textStyle = current ? classes.selectedText : classes.unSelectedText;
  return (
    <ListItem button component={Link} to={to} style={{ paddingLeft: '75px' }}>
      <ListItemText
        primary={<Typography className={textStyle}>{title}</Typography>}
      />
    </ListItem>
  );
});

SideBarItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default SideBarItem;
