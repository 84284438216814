import * as React from "react";
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css"; // rsuite 라이브러리 디자인 적용을 위해 반드시 임포트 요망!
import {
  ContentsExcelHeaders,
  covertContentsExcelDataStyle,
} from "../lib/excelDown";

function ExcelDownOfContents({ apiResults }) {
  // apiResults => 엑셀데이터 형식으로 변환
  const finalData = covertContentsExcelDataStyle(apiResults);

  return (
    <Container>
      <CSVLink
        headers={ContentsExcelHeaders}
        data={finalData}
        filename="CSV 데이터"
        style={{ color: "white" }}
      >
        <Button appearance="primary" color="green">
          엑셀다운로드
        </Button>
      </CSVLink>
    </Container>
  );
}

ExcelDownOfContents.defaultProps = {
  apiResults: [],
};

ExcelDownOfContents.propTypes = {
  apiResults: PropTypes.array,
};

const Container = styled("div")({
  margin: "0 0 10px 0",
});

export default ExcelDownOfContents;
