import axiosInstance from "./axiosInstance";
import querystring from "querystring";

const tock = "tock";
const series = "series";

export const tockCommonApi = {
  getGenres: () => axiosInstance.get(`${tock}/${series}/genres`),
  getTags: () => axiosInstance.get(`${tock}/${series}/tags`),
  getClientSeries: () => axiosInstance.get(`${tock}/${series}/nameAsc`),
  //getSeries: () => axiosInstance.get(`${tock}/${series}/admin/list`),
  getServiceNotices: () => axiosInstance.get(`${tock}/admin/emergency/service/notice/list`),
  getServiceNoticesAdmin: (startAt, endAt, division, keyword, checkItems) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      division,
      keyword,
      checkItems,
    });
    return axiosInstance.get(`${tock}/admin/emergency/service/notice/adminList?${query}`)
  },
  getDetailServiceNotices: (notice_seq) => axiosInstance.get(`${tock}/admin/emergency/service/notice/detail/${notice_seq}`),
  serviceNoticeAdd: (NewServiceNotices) => axiosInstance.post(`${tock}/admin/emergency/service/regist/notice`, NewServiceNotices),
  updateServiceNotice: (serviceCode, title, isShow, contents, editor, updatedAt, notice_seq) => {
    return axiosInstance.put(`${tock}/admin/emergency/service/update/notice`, {
      serviceCode, title, isShow, contents, editor, updatedAt, notice_seq
    });
  },
  deleteServiceNotice: (notice_seq) => {
    return axiosInstance.delete(`${tock}/admin/emergency/service/delete/notice/${notice_seq}`);
  },
  getSeries: (startAt, endAt, division, keyword, checkItems, isFinished) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      division,
      keyword,
      checkItems,
      isFinished
    });
    return axiosInstance.get(`${tock}/${series}/admin/list?${query}`);
  },
  getCpNames: () => axiosInstance.get(`${tock}/${series}/cpNames`),
  updateSeriesCtgr: (seriesCtgr, cpId, id) => {
    return axiosInstance.put(`${tock}/${series}/update`, {
      seriesCtgr,
      cpId,
      id,
    });
  },
  updateWriter: (userId, id) => {
    return axiosInstance.put(`${tock}/${series}/update/writer`, {
      userId,
      id,
    });
  },
  getDeletedSeries: () =>
    axiosInstance.get(`${tock}/${series}/admin/list/deleted`),
  getNotices: () => axiosInstance.get(`${tock}/notice`),
  changeSeries: (seriesId, info) =>
    axiosInstance.put(`${tock}/${series}/${seriesId}`, info),
  changeSeriesCover: (seriesId, form) =>
    axiosInstance.put(`${tock}/${series}/${seriesId}/coverImg`, form),
  updateStory: (storyId, form) =>
    axiosInstance.put(`${tock}/${series}/story/admin/${storyId}`, form),
  addStory: (seriesId, form) =>
    axiosInstance.post(`${tock}/${series}/${seriesId}/AddStory`, form),
  updateSeriesId: (seriesId, form) =>
    axiosInstance.put(`${tock}/${series}/${seriesId}/update/seriesId`, form),
  updateStoryIsCharged: (seriesId, form) =>
    axiosInstance.put(`${tock}/${series}/${seriesId}/update/isCharged`, form),
  updateStoryAndToken: (seriesId, form) =>
    axiosInstance.put(`${tock}/${series}/${seriesId}/update/andToken`, form),
  updateStoryIosToken: (seriesId, form) =>
    axiosInstance.put(`${tock}/${series}/${seriesId}/update/iosToken`, form),
  getStory: (storyId) =>
    axiosInstance.get(`${tock}/${series}/story/${storyId}`),
  deleteStory: (storyId) =>
    axiosInstance.delete(`${tock}/${series}/story/${storyId}`),
  getDetailSeries: (seriesId) =>
    axiosInstance.get(`${tock}/${series}/${seriesId}/admin`),
  getContents: (storyId) =>
    axiosInstance.get(`${tock}/${series}/${storyId}/content`),
  updateContent: (content, seriesId, storyId) => {
    return axiosInstance.put(`${tock}/${series}/update/content`, {
      content,
      seriesId,
      storyId,
    });
  },
  insertCpCompany: (form) => {
    return axiosInstance.post(`${tock}/${series}/cpCompany/insert`, form);
  },
  updateCpCompany: (form) => {
    return axiosInstance.put(`${tock}/${series}/cpCompany/update`, form);
  },
  deleteCpCompany: (items) => {
    return axiosInstance.delete(`${tock}/${series}/cpCompany/delete`, {
      params: { items },
    });
  },
  getCpList: (startAt, endAt, division, keyword) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      division,
      keyword,
    });
    return axiosInstance.get(`${tock}/${series}/cpList?${query}`);
  },
  getCpDetail: (cpId) => {
    return axiosInstance.get(`${tock}/${series}/cpCompany/detail`, {
      params: { cpId },
    });
  },
};

export const userRankingApi = {
  getUserRankingElement: (genreId) =>
    axiosInstance.get(`${tock}/admin/adminRanking/userRankingElement`,{
      params: {genreId},
    }),

  getUserRankingTest: (
    likePoint,
    followPoint,
    replyPoint,
    viewPoint,
    range
  ) => {
    const query = querystring.stringify({
      likePoint,
      followPoint,
      replyPoint,
      viewPoint,
      range,
    });
    return axiosInstance.get(
      `${tock}/admin/adminRanking/userRankingElement/test?${query}`
    );
  },

  setUserRankingEle: (likePoint, followPoint, replyPoint, viewPoint, range, genreId) => {
    return axiosInstance.put(`${tock}/admin/adminRanking/userRankingElement`, {
      likePoint,
      followPoint,
      replyPoint,
      viewPoint,
      range,
      genreId,
    });
  },
};
