import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import { serviceApi } from "../../api/service";

const SearchRow = styled("div")`
  display: flex;
  align-items: center;
`;

const FirstRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SButton = styled(Button)({
  marginLeft: "5px",
  marginRight: "5px",
});

const MainBannerTable = ({ show, reload, onModify, onClickCreate }) => {
  const cRowCountPerPage = 5;
  const searchInputRef = useRef(null);
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [reload2, setReload2] = useState(0);
  // const [doing, setDoing] = useState(Doing.nothing);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const requestItems = async () => {
      const { status, data: newData } = await serviceApi.getMainBannerList(
        cRowCountPerPage,
        cRowCountPerPage * pageIndex,
        searchText
      );
      if (status === 200) {
        setItems(newData.rows);
        setTotalCount(newData.totalCount);
      }
    };

    requestItems();
  }, [pageIndex, reload, reload2, searchText]);

  const getEmptyRows = () => {
    const rows = [];
    const colSpan = 5;
    if (items.length < cRowCountPerPage) {
      const count = cRowCountPerPage - items.length;
      for (let i = 0; i < count; i++) {
        rows.push(
          <TableRow key={`empty-row-${i}`}>
            <TableCell colSpan={colSpan}>&nbsp;</TableCell>
          </TableRow>
        );
      }
    }
    return rows;
  };
  const handleChangePage = (_event, newPage) => {
    setPageIndex(newPage);
  };
  const handleRemove = (mainBannerId) => {
    const requestRemove = async (id) => {
      const { status } = await serviceApi.deleteMainBanner(id);
      if (status === 200) {
        setReload2(reload2 + 1);
      }
    };
    requestRemove(mainBannerId);
  };
  const getDateText = (startDate, endDate) => {
    const moStartDate = new moment(startDate);
    const moEndDate = new moment(endDate);
    if (moStartDate.isValid()) {
      const sYmd = moStartDate.format("YYYY-MM-DD");
      if (moEndDate.isValid()) {
        const eYmd = moEndDate.format("YYYY-MM-DD");
        return `${sYmd} ~ ${eYmd}`;
      } else {
        return `${sYmd} ~ `;
      }
    } else if (moEndDate.isValid()) {
      const eYmd = moEndDate.format("YYYY-MM-DD");
      return ` ~ ${eYmd}`;
    } else {
      return " ~ ";
    }
  };

  const handleSearchEnterKey = () => {
    onClickSearch();
  };

  const handleClickSearch = () => {
    onClickSearch();
  };
  const onClickSearch = () => {
    if (searchInputRef && searchInputRef.current) {
      const searchValue = searchInputRef.current.value;
      if (searchValue && searchValue.length > 0) {
        doSearch(searchValue);
      }
    }
  };

  const doSearch = (value) => {
    setSearchText(value);
    setPageIndex(0);
  };
  if (!show) {
    return null;
  }
  return (
    <div>
      <FirstRow>
        <SearchRow>
          <div style={{ marginLeft: "30px", marginRight: "30px" }}>
            검색 하기{" "}
          </div>
          <TextField
            id="search-input"
            variant="standard"
            placeholder="제목 검색"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearchEnterKey();
              }
            }}
            inputRef={searchInputRef}
          />
          <Button
            variant="contained"
            sx={{ margin: "5px" }}
            onClick={handleClickSearch}
            size="small"
          >
            검색
          </Button>
        </SearchRow>
        <Button
          variant="contained"
          onClick={() => {
            onClickCreate();
          }}
          sx={{ margin: "5px" }}
        >
          새 항목 추가
        </Button>
      </FirstRow>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-label="미리보기 영상 관리">
            <TableHead>
              <TableRow>
                <TableCell align="center">번호</TableCell>
                <TableCell align="center">제목</TableCell>
                <TableCell align="center">공개</TableCell>
                <TableCell align="center">노출순서</TableCell>
                <TableCell align="center">노출기간</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => {
                return (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {item.id}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="text"
                        onClick={() => {
                          if (item.url) {
                            //   setVideoUrl(item.url);
                            //   setOpenPlayer(true);
                          }
                        }}
                      >
                        {item.title}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      {item.isPublic ? "Y" : "N"}
                    </TableCell>
                    <TableCell align="center">{item.orderNumber}</TableCell>
                    <TableCell align="center">
                      {getDateText(item.startDate, item.endDate)}
                    </TableCell>
                    <TableCell align="center">
                      <SButton
                        variant="contained"
                        onClick={() => {
                          onModify(item.id);
                        }}
                        size="small"
                      >
                        수정
                      </SButton>
                      <SButton
                        variant="contained"
                        onClick={() => {
                          handleRemove(item.id);
                        }}
                        size="small"
                      >
                        삭제
                      </SButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {getEmptyRows()}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[cRowCountPerPage]}
          component="div"
          count={totalCount}
          rowsPerPage={cRowCountPerPage}
          page={pageIndex}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
};

MainBannerTable.propTypes = {
  show: PropTypes.bool.isRequired,
  reload: PropTypes.number.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};

export default MainBannerTable;
