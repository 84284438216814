import { Box } from "@mui/material";
import { Button } from "@mui/material";
import MaterialTable from "material-table";
import React, { forwardRef, useState, useEffect } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AdminRoot from "../components/frame/AdminRoot";
import AddEventPopupModal from "../components/modal/AddEventPopupModal";
import { eventPopupApi } from "../api/global";
import moment from "moment";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const materialTableOptions = {
  search: true,
  sorting: true,
  draggable: false,
  showTitle: true,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
  headerStyle: {
    backgroundColor: "#EEE",
    fontWeight: "bold",
    textAlign: "center",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  actionsCellStyle: {
    textAlign: "center",
  },
};

const EventPopup = () => {
  const [openAddEventPopup, setOpenAddEventPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [data, setData] = useState([]);
  const [reloadCount, setReloadCount] = useState(0);

  const materialTableColumns = [
    {
      title: "no",
      field: "id",
      width: "60px",
    },
    {
      title: "제목",
      field: "title",
    },
    {
      title: "구분",
      render: (rowData) => {
        return (
          <div
            style={{
              width: "50px",
            }}
          >
            {rowData.type === "normal" && "일반팝업"}
            {rowData.type === "point" && "포인트 지급 팝업"}
            {rowData.type === "point2" && "포인트 지급 팝업 (중복불가)"}
            {rowData.type === "sale" && "할인팝업"}
          </div>
        );
      },
    },
    {
      title: "게시기간",
      render: (rowData) => {
        return (
          <div
            style={{
              width: "50px",
            }}
          >
            {`${moment(rowData.startAt).format("yyyy-MM-DD")} ~ ${moment(
              rowData.endAt
            ).format("yyyy-MM-DD")}`}
          </div>
        );
      },
      width: "200px",
    },
    {
      title: "토큰 사용기간",
      render: (rowData) => {
        if (!rowData.tokenValidateStartAt || !rowData.tokenValidateEndAt) {
          return (
            <div
              style={{
                width: "50px",
              }}
            ></div>
          );
        }
        return (
          <div
            style={{
              width: "50px",
            }}
          >
            {`${moment(rowData.tokenValidateStartAt).format(
              "yyyy-MM-DD"
            )} ~ ${moment(rowData.tokenValidateEndAt).format("yyyy-MM-DD")}`}
          </div>
        );
      },
      width: "200px",
    },
    { title: `노출순서`, field: "order" },
    {
      title: "수정",
      render: (rowData) => {
        return (
          <Button
            onClick={() => {
              setPopupData(rowData);
              setOpenAddEventPopup(true);
            }}
            variant="contained"
          >
            수정
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    const getRoundData = async () => {
      const { status, data: newData } = await eventPopupApi.list();
      if (status === 200) {
        setData(newData);
      }
    };
    getRoundData();
  }, [reloadCount]);
  return (
    <AdminRoot>
      <Box>
        <MaterialTable
          title="이벤트 팝업"
          data={data}
          icons={tableIcons}
          columns={materialTableColumns}
          options={materialTableOptions}
          actions={[
            {
              icon: AddBox,
              tooltip: "이벤트 팝업 추가",
              isFreeAction: true,
              onClick: () => {
                setPopupData(null);
                setOpenAddEventPopup(true);
              },
            },
          ]}
        />
      </Box>
      <AddEventPopupModal
        key={openAddEventPopup}
        open={openAddEventPopup}
        close={(result) => {
          if (result) {
            setReloadCount(reloadCount + 1);
          }
          setOpenAddEventPopup(false);
        }}
        data={popupData}
      />
    </AdminRoot>
  );
};

export default EventPopup;
