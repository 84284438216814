import React, { useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import { useState } from "react";
import ChangeStoryModel from "./ChangeStoryModel";
import Loading from "../common/Loading";
import { tockCommonApi } from "../../api/tock";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const materialTableOptions = {
  search: true,
  sorting: true,
  draggable: false,
  showTitle: true,
  actionsColumnIndex: -1,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
  headerStyle: {
    backgroundColor: "#EEE",
    fontWeight: "bold",
    textAlign: "center",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  actionsCellStyle: {
    textAlign: "center",
  },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1000px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    margin: theme.spacing(3, 0, 5),
    backgroundColor: theme.palette.custom.black,
    color: theme.palette.custom.white,
  },
}));

const StoryListModal = ({ open, handleClose, seriesId, deletedContent }) => {
  const classes = useStyles();
  const [selectedStoryId, setSelectedStoryId] = useState(0);
  const [openChangeStoryModel, setOpenChangeStoryModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [reloadCount, setReloadCount] = useState(0);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  useEffect(() => {
    const getData = async () => {
      if (seriesId !== 0) {
        setLoading(true);
        const result = await tockCommonApi.getDetailSeries(seriesId);
        if (result.status === 200) {
          setData(result.data.stories);
        }
      }
      setLoading(false);
    };
    getData();
  }, [seriesId, reloadCount]);

  const materialTableColumns = [
    {
      title: "ID",
      field: "id",
    },
    {
      title: "이름",
      field: "name",
    },
    {
      title: `커버이미지`,
      sorting: false,
      render: (rowData) => {
        if (rowData?.coverImgUrl) {
          return (
            <img
              src={rowData?.coverImgUrl}
              alt="preview"
              style={{ maxHeight: "50px" }}
            />
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: "공개 여부",
      field: "isPublic",
      render: (rowData) => {
        return <span>{rowData.isPublic ? "공개" : "비공개"}</span>;
      },
    },
    {
      title: "제작 방식",
      field: "isUnity",
      render: (rowData) => {
        return <span>{rowData.isUnity ? "유니티" : "톡크 에디터"}</span>;
      },
    },
    {
      title: "유니티 에셋 다운로드",
      field: "isUnity",
      render: (rowData) => {
        return (
          <div>
            {rowData.unityfileUrl || rowData.unityIosfileUrl ? (
              <div>
                <div>
                  <a href={rowData.unityfileUrl} download="filename">
                    android 에셋
                  </a>
                </div>
                <div>
                  <a href={rowData.unityIosfileUrl} download="filename">
                    ios 에셋
                  </a>
                </div>
              </div>
            ) : (
              "없음"
            )}
          </div>
        );
      },
    },
    {
      title: "등록일",
      field: "createdAt",
      render: (rowData) => (
        <>
          {rowData.createdAt
            ? moment(rowData.createdAt).format("YY-MM-DD")
            : ""}
        </>
      ),
    },
    {
      title: "수정",
      sorting: false,
      render: (rowData) => (
        <>
          <Button
            variant="contained"
            disabled={deletedContent}
            onClick={() => {
              setSelectedStoryId(rowData.id);
              setOpenChangeStoryModel(true);
            }}
          >
            수정
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <Loading />
    </Modal>;
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <MaterialTable
          title="스토리 리스트"
          icons={tableIcons}
          columns={materialTableColumns}
          options={materialTableOptions}
          data={data}
        />
        <ChangeStoryModel
          open={openChangeStoryModel}
          handleClose={(result) => {
            setOpenChangeStoryModel(false);
            if (result) {
              setReloadCount(reloadCount + 1);
            }
          }}
          storyId={selectedStoryId}
        />
      </div>
    </Modal>
  );
};

StoryListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  deletedContent: PropTypes.bool,
};

StoryListModal.defaultProps = {
  deletedContent: false,
};

export default StoryListModal;
