import axiosInstance from "./axiosInstance";

const tock = "tock";
const studioMenu = "studioMenu";
const subMenu = "subMenu";
const resources = "resources";

export const studioMetaApi = {
  // 스튜디오 메타 메뉴 리스트
  getListStudioMenu: () => axiosInstance.get(`${tock}/${studioMenu}`),

  // 스튜디오 메타 메뉴 추가
  addStudioMenu: (name) =>
    axiosInstance.post(`${tock}/${studioMenu}`, { name }),

  // 스튜디오 메타 메뉴 디테일
  getDetailStudioMenu: (studioMenuId) =>
    axiosInstance.get(`${tock}/${studioMenu}/${studioMenuId}`),

  // 스튜디오 메타 서브 메뉴 리스트
  getListStudioSubMenu: (studioMenuId) =>
    axiosInstance.get(`${tock}/${studioMenu}/${studioMenuId}/${subMenu}`),

  // 스튜디오 메타 서브 메뉴 추가
  addStudioSubMenu: (name, studioMenuId, resourcetype) =>
    axiosInstance.post(`${tock}/${studioMenu}/${studioMenuId}/${subMenu}`, {
      name,
      resourcetype,
    }),

  getDetailStudioSubmenu: (studioMenuId, subMenuId) =>
    axiosInstance.get(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}`
    ),

  // 스튜디오 메타 서브 메뉴 업데이트
  updateStudioSubMenu: (studioMenuId, subMenuId, name, resourcetype) =>
    axiosInstance.put(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}`,
      {
        name,
        resourcetype,
      }
    ),

  // 스튜디오 메타 서브 메뉴 삭제
  deleteStudioSubMenu: (studioMenuId, subMenuId) =>
    axiosInstance.delete(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}`
    ),

  // 스튜디오 메타 리소스 리스트
  getListStudioResources: (studioMenuId, subMenuId) =>
    axiosInstance.get(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}/${resources}`
    ),

  // 스튜디오 메타 리소스 추가
  addStudioResource: (studioMenuId, studioSubMenuId, form) =>
    axiosInstance.post(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${studioSubMenuId}/${resources}`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ),

  // 스튜디오 메타 인터렉티브 추가
  addStudioInteractiveResource: (studioMenuId, studioSubMenuId, form) =>
    axiosInstance.post(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${studioSubMenuId}/${resources}/interactive`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ),

  // 스튜디오 메타 리소스 상세
  getDetailStudioResources: (studioMenuId, subMenuId, resourceId) =>
    axiosInstance.get(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}/${resources}/${resourceId}`
    ),

  // 스튜디오 메타 리소스 수정
  updateStudioInteractiveResources: (
    studioMenuId,
    subMenuId,
    resourceId,
    form
  ) =>
    axiosInstance.put(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}/${resources}/${resourceId}/Interactive`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ),

  // 스튜디오 메타 인터렉티브 리소스 수정
  updateStudioResources: (studioMenuId, subMenuId, resourceId, form) =>
    axiosInstance.put(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}/${resources}/${resourceId}`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ),

  // 스튜디오 메타 리소스 삭제
  deleteStudioResources: (studioMenuId, subMenuId, resourceId) =>
    axiosInstance.delete(
      `${tock}/${studioMenu}/${studioMenuId}/${subMenu}/${subMenuId}/${resources}/${resourceId}`
    ),
};
