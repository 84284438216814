import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@material-ui/core";
import toast from "react-hot-toast";
import AdminRoot from "../components/frame/AdminRoot";
import { tockCommonApi } from "../api/tock";
import AddServiceNoticeBar from "../components/contents/AddServiceNoticeBar";

const AddServiceNotice = () => {
  const [loading, setLoading] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [click, setClick] = useState(0);

  const getClick = () => {
    setClick((click) => click + 1);
    getData();
  };

  const getNoticesList = async () => {
    try {
      setLoading(true);
      const { data } = await tockCommonApi.getServiceNotices();
      // console.log(data);
      setSearchedList(data);
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = useCallback(async () => {
    setLoading(true);
    const { status, data } = await tockCommonApi.getSeries(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    setLoading(false);
    if (status === 200) {
      setSearchedList(data);
    } else {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (click) {
      getData();
    }
  }, [click, getData]);

  return (
    <AdminRoot>
      <Title>공지사항 등록</Title>
      <>
        <AddServiceNoticeBar getNoticesList={getNoticesList} />
      </>
    </AdminRoot>
  );
};

const Title = styled("div")({
  marginBottom: "20px",
});

export default AddServiceNotice;
