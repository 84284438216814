import querystring from "querystring";
import axiosInstance from "./axiosInstance";

const push = "push";

export const deviceTokenApi = {
  list: () => axiosInstance.get(`${push}/token`),
};

export const pushApi = {
  list: (startAt, endAt) => {
    const query = querystring.stringify({
      startAt,
      endAt,
    });
    return axiosInstance.get(`${push}/sendPush?${query}`);
  },
  // push 등록
  add: (pushInfo) => {
    // form data 로 들어가야함
    // sendDevice, onlyAgreePush, process, pushDate, title, content
    // sendDevice : 1 Android , 2 iOs, 3 All device
    // process : 1 예약 발송 , 2 즉시 발송
    return axiosInstance.post(`${push}/sendPush`, pushInfo);
  },
  test: (pushInfo) => {
    return axiosInstance.post(`${push}/sendPush/test`, pushInfo);
  },
  // push 삭제
  remove: (pushId) => {
    return axiosInstance.delete(`${push}/sendPush/${pushId}`);
  },
  // push 상세
  detail: (pushId) => {
    return axiosInstance.get(`${push}/sendPush/${pushId}`);
  },
  // push 수정
  modify: (pushId, pushInfo) => {
    return axiosInstance.put(`${push}/sendPush/${pushId}`, pushInfo);
  },
};
