import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../common/Loading";
import { Button, styled } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import TablePaginationActions from "../push/TablePaginationActions";
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";
import { convertDate } from "../lib/utils";

const ServiceNoticeList = ({ loading, searchedList, getNoticesList }) => {
  const history = useHistory();

  const { userInfo } = useSelector((state) => state.user);

  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchedList.length) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    setCheckItems([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCheckItems([]);
  };

  useEffect(() => {
    getNoticesList(convertDate(new Date("2022-01-01")), convertDate(new Date()), "", "", 0);
  }, [])

  //Check box
  const [checkItems, setCheckItems] = useState([]);

  const goToPage = ({ series }) => {
    history.push('/service/detail/notice', {
      state: {
        notice_seq: `${series.notice_seq}`,
        title: `${series.title}`,
        contents: `${series.contents}`,
        createdAt: `${series.createdAt}`,
        editor: `${series.editor}`,
        isShow: `${series.isShow}`,
        registrant: `${series.registrant}`,
        updatedAt: `${series.updatedAt}`,
        serviceCode: `${series.serviceCode}`
      }
    })
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      const startRow = page * rowsPerPage;
      const endRow = (page + 1) * rowsPerPage;
      const idArray = [];
      for (let i = startRow; i < endRow; i++) {
        idArray.push(searchedList[i].id);
      }
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  return (
    <ListContainer>
      <Inner>
        {loading ? (
          <div style={{ height: "300px" }}>
            <Loading />
          </div>
        ) : (
          <>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontSize: "15px",
                margin: "0px 12px",
                paddingRight: "20px",
                marginBottom: "12px",
                fontWeight: 500,
                letterSpacing: "-1px",
                lineHeight: "1.6",
                textAlign: "right",
              }}
            >
              전체{" "}
              <span style={{ color: "orange" }}>{searchedList.length}</span> 건
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#e0e0e0",
                      height: "60px",
                    }}
                  >
                    {/* <TableCell
                      padding="checkbox"
                      sx={{ width: "2%" }}
                      align="center"
                    >
                      <Checkbox
                        color="primary"
                        onChange={(e) => handleAllCheck(e.target.checked)}
                        checked={
                          checkItems.length === rowsPerPage ? true : false
                        }
                      />
                    </TableCell> */}
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "5%",
                      }}
                      align="center"
                    >
                      No
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "40%" }}
                      align="center"
                    >
                      제목
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "15%",
                      }}
                      align="center"
                    >
                      작성자
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "12%",
                      }}
                      align="center"
                    >
                      작성일
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "12%",
                      }}
                      align="center"
                    >
                      수정일
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "12%" }}
                      align="center"
                    >
                      게시여부
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? searchedList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : searchedList
                  ).map((series) => (
                    <TableRow key={series.notice_seq} sx={{ height: "50px" }}>
                      {/* <TableCell align="center">
                        <Checkbox
                          color="primary"
                          onChange={(e) =>
                            handleSingleCheck(e.target.checked, series.notice_seq)
                          }
                          checked={
                            checkItems.includes(series.notice_seq) ? true : false
                          }
                        />
                      </TableCell> */}
                      <TableCell align="center">
                        {series.notice_seq}
                      </TableCell>
                      {/* <TableCell align="center">{series.serviceCode}</TableCell> */}
                      <TableCell align="center" sx={{ maxWidth: "500px",whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}><a onClick={() => goToPage({ series })}>{series.title}</a></TableCell>
                      <TableCell align="center">
                        {/* {series.registrant} */}
                        {userInfo.name}
                      </TableCell>
                      <TableCell align="center">{moment(new Date(series.createdAt)).format('YYYY-MM-DD hh:mm')}</TableCell>
                      <TableCell align="center">{moment(new Date(series.updatedAt)).format('YYYY-MM-DD hh:mm')}</TableCell>
                      <TableCell align="center">{series.isShow === "Y" ? "공개" : "비공개"}</TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                    <TableCell colSpan={3}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          window.location.replace("/service/regist/notice") } }
                      >
                        등록
                      </Button>
                    </TableCell>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={10}
                      count={searchedList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
      </Inner>
    </ListContainer>
  );
};

ServiceNoticeList.defaultProps = {
  searchedList: {
    id: "",
    name: "",
    introduce: "",
    cpName: "",
    nickname: "",
    genre: "",
    coverImgUrl: "",
    type: "",
    createdAt: "",
  },
};

ServiceNoticeList.propTypes = {
  loading: PropTypes.bool.isRequired,
  searchedList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      introduce: PropTypes.string,
      cpName: PropTypes.string,
      nickname: PropTypes.string,
      coverImgUrl: PropTypes.string,
      type: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
  getClick: PropTypes.func.isRequired,
};

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "block",
  justifyContent: "center",
  alignItems: "center",
});

export default ServiceNoticeList;
