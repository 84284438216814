import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Modal } from "@material-ui/core";
import { Typography } from "@mui/material";
import { styled } from "@material-ui/core";
import { statisticsApi } from "../../../api/statistics";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "500px",
    height: "300px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
    position: "relative",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
  userName: {
    color: "#f57c00",
  },
}));

function ReplyAdminHideModal({
  open,
  handleClose,
  checkItems,
  isAdminHide,
  resetCheckItems,
}) {
  const classes = useStyles();

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const adminHide = async () => {
    const sendData = checkItems;

    const result = await statisticsApi.doAdminHide(JSON.stringify(sendData));

    if (result.data.code === 0) {
      handleClose();
      isAdminHide();
      resetCheckItems();
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>관리자 숨김 처리</Typography>
        <TextContainer>
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontSize: "18px",
              fontWeight: 400,
              letterSpacing: "-1px",
              lineHeight: "1.6",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            선택한 댓글을 숨김 처리 하시겠습니까?
          </Typography>
        </TextContainer>
        <ButtonContainer>
          <Button className={classes.cancelButton} onClick={handleCloseModal}>
            닫기
          </Button>
          <Button className={classes.editButton} onClick={adminHide}>
            확인
          </Button>
        </ButtonContainer>
      </div>
    </Modal>
  );
}

const TextContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "150px",
});

const ButtonContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  height: "50px",
  alignItems: "center",
  marginTop: "20px",
});

export default ReplyAdminHideModal;
