import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: "55%",
    top: "50%",
    width: "150px",
    height: "150px",
    margin: "-75px 0 0 -75px",
  },
  sidebarOpen: {
    left: "60%",
  },
}));

const Loading = () => {
  const classes = useStyles();
  const open = useSelector((state) => state.toggle);

  return (
    <div className={classNames(classes.root, open && classes.sidebarOpen)}>
      <CircularProgress size={100} thickness={4} />
    </div>
  );
};

export default Loading;
