import querystring from "querystring";
import axiosInstance from "./axiosInstance";

const payments = "payments";
const useTokens = "useTokens";

export const paymentsApi = {
  list: (startAt, endAt, division, keyword) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      division,
      keyword,
    });
    return axiosInstance.get(`${payments}/list?${query}`);
  },
  detail: (paymentId) => {
    return axiosInstance.get(`${payments}/list/${paymentId}`);
  },
  doPayCancel: (form) => axiosInstance.post(`${payments}/doPayCancel`, form),
  doUseTokenCancel: (form) =>
    axiosInstance.post(`${payments}/doUseTokenCancel`, form),
  registerAdminEventToken: (form) =>
    axiosInstance.post(`${payments}/registerAdminEventToken`, form),
  getAdminTokenList: (startAt, endAt, division, keyword) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      division,
      keyword,
    });
    return axiosInstance.get(`${payments}/registerTokens/list?${query}`);
  },
};

export const useTokensApi = {
  listUsers: (startAt, endAt, device, tknClCd, division, keyword, page, pageSize) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      device,
      tknClCd,
      division,
      keyword,
      page,
      pageSize
    });
    return axiosInstance.get(`${useTokens}/listUsers?${query}`);
  },
  listCp: (startAt, endAt, device, tknClCd, division, keyword, page, pageSize) => {
    const query = querystring.stringify({
      startAt,
      endAt,
      device,
      tknClCd,
      division,
      keyword,
      page,
      pageSize
    });
    return axiosInstance.get(`${useTokens}/listCp?${query}`);
  },
  detail: (id) => {
    return axiosInstance.get(`${useTokens}/list/${id}`);
  },
  eventTokenList: (id) => {
    return axiosInstance.get(`${useTokens}/list/eventTokenList/${id}`);
  },
  payTokenList: (id) => {
    return axiosInstance.get(`${useTokens}/list/payTokenList/${id}`);
  },
  getUseTokenList: (form) => {
    return axiosInstance.post(`${useTokens}/cpUseToken`, form);
  },
  getUseTokenCancelList: (form) => {
    return axiosInstance.post(`${useTokens}/cpUseTokenCancel`, form);
  },
  getStoryCancelData: (id) => {
    return axiosInstance.get(`${useTokens}/cpStoryCancel/${id}`);
  },
  getSettleList: (month, division, keyword) => {
    const query = querystring.stringify({
      month,
      division,
      keyword,
    });
    return axiosInstance.get(`${useTokens}/settle/list?${query}`);
  },
};
