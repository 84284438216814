import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import Loading from "../common/Loading";
import toast from "react-hot-toast";
import { Typography, Button } from "@mui/material";
import { useTokensApi } from "../../api/payments";
import EventTokenTable from "../payments/UseToken_EventTokenTable";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    //border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1200px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const EventPointListModal = ({ open, handleClose, userId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  useEffect(() => {
    const getData = async () => {
      if (userId !== 0) {
        setLoading(true);
        const result = await useTokensApi.eventTokenList(userId);
        if (result.status === 200) {
          setData(result.data);
        } else {
          toast.error("네트워크 에러", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
        setLoading(false);
      }
    };
    getData();
  }, [userId]);

  if (loading) {
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <Loading />
    </Modal>;
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>이벤트 토큰 적립 내역</Typography>
        <EventTokenTable list={data} />
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              display: "inline-block",
              backgroundColor: "#e0e0e0",
              color: "black",
              fontWeight: "bold",
            }}
            onClick={handleCloseModal}
          >
            확인
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EventPointListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EventPointListModal;
