import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../push/TablePaginationActions";
import SettleDetailModal from "../modal/SettleDetailModal";

function SettleList({ searchedList }) {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dataLength = searchedList.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //토큰 가격
  const tokenPrice = 120;
  //수수료
  const commission = 15;

  const calculateSum = (searchedList) => {
    let sum = 0;
    for (let i = 0; i < searchedList.length; i++) {
      sum =
        sum +
        ((searchedList[i].andUseToken * tokenPrice * 0.85 +
          searchedList[i].iosUseToken * tokenPrice * 0.85) *
          searchedList[i].cpRS) /
          100;
    }
    return sum;
  };

  //Modal
  const [openSettleModal, setOpenSettleModal] = useState(false);
  const [openSettleData, setOpenSettleData] = useState({});

  return (
    <>
      <ListContainer>
        <Inner>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#e0e0e0",
                    height: "60px",
                  }}
                >
                  <TableCell
                    sx={{
                      width: "10%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    No
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    정산대상기간
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "30%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    CP/작가명
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    정산금액
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    정산내역
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? searchedList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : searchedList
                ).map((cp, index) => (
                  <TableRow key={index} sx={{ height: "45px" }}>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 1,
                        borderColor: "grey.500",
                        fontSize: "15px",
                      }}
                    >
                      {dataLength - index - page * rowsPerPage}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontSize: "15px",
                      }}
                    >
                      {cp.period}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontSize: "15px",
                      }}
                    >
                      {cp.cpName}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        border: 1,
                        borderColor: "grey.500",
                        fontSize: "15px",
                      }}
                    >
                      {(
                        ((cp.andUseToken * tokenPrice -
                          (cp.andUseToken * tokenPrice * commission) / 100) *
                          cp.cpRS) /
                          100 +
                        ((cp.iosUseToken * tokenPrice -
                          (cp.iosUseToken * tokenPrice * commission) / 100) *
                          cp.cpRS) /
                          100
                      )
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        textDecoration: "underline",
                        borderLeft: 1,
                        borderColor: "grey.500",
                      }}
                      onClick={() => {
                        setOpenSettleData(cp);
                        setOpenSettleModal(true);
                      }}
                    >
                      [정산내역]
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ backgroundColor: "#e0e0e0", height: "40px" }}>
                  <TableCell
                    colSpan={3}
                    align="right"
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    총 금액 :
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="right"
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    {calculateSum(searchedList)}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="right"
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={5}
                    count={searchedList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <SettleDetailModal
            open={openSettleModal}
            handleClose={() => setOpenSettleModal(false)}
            data={openSettleData}
          />
        </Inner>
      </ListContainer>
    </>
  );
}

SettleList.propTypes = {
  searchedList: PropTypes.array,
};

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default SettleList;
