import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
import { DateRangePicker } from "rsuite";
import { convertDate } from "../lib/utils";
import "rsuite/dist/rsuite.min.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

function RegisterTokenSearchBar({ getList }) {
  //검색
  const [division, setDivision] = useState("");
  const [keyword, setKeyword] = useState("");

  const onDivisionChange = (event) => {
    setDivision(event.target.value);
  };

  const onKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  // 날짜 입력받은 state
  const [date, setDate] = useState([
    new Date("2022-01-01"),
    new Date("2023-12-31"),
  ]);
  const startAt = convertDate(date[0]);
  const endAt = convertDate(date[1]);

  // '오늘', '1주일', '1개월', '3개월', '6개월', '전체' 클릭시 날짜 세팅
  const onPeriodClick = (event) => {
    const created_date = new Date();
    const thisYear = created_date.getFullYear();
    const thisMonth = created_date.getMonth() + 1;
    const today = created_date.getDate();
    if (event.target.name === "today") {
      setDate([new Date(), new Date()]);
    } else if (event.target.name === "week") {
      setDate([new Date(), new Date(thisYear, thisMonth - 1, today + 6)]);
    } else if (event.target.name === "month1") {
      setDate([new Date(), new Date(thisYear, thisMonth, today)]);
    } else if (event.target.name === "month3") {
      setDate([new Date(), new Date(thisYear, thisMonth + 2, today)]);
    } else if (event.target.name === "month6") {
      setDate([new Date(), new Date(thisYear, thisMonth + 5, today)]);
    } else if (event.target.name === "whole") {
      setDate([new Date("2022-01-01"), new Date("2023-12-31")]);
    }
  };

  // '검색'버튼 클릭시 푸시리스트 api get 요청
  const onSearchBtnClick = () => {
    getList(startAt, endAt, division, keyword);
  };

  // '초기화'버튼과 달력내부 'X'버튼 클릭시 초기화
  const onCleanBtnClick = () => {
    setDate([new Date("2022-01-01"), new Date("2023-12-31")]);
    setKeyword("");
  };

  return (
    <SearchContainer>
      <Inner1>
        <Text>토큰 유효 기간</Text>
        <DateRangePicker
          format="yyyy. MM. dd"
          ranges={[]}
          value={date}
          onChange={setDate}
          onClean={onCleanBtnClick}
          placeholder="select date range"
        />
        <Button
          name="today"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          오늘
        </Button>
        <Button
          name="week"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          1주일
        </Button>
        <Button
          name="month1"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          1개월
        </Button>
        <Button
          name="month3"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          3개월
        </Button>
        <Button
          name="month6"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          6개월
        </Button>
        <Button
          name="whole"
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onPeriodClick}
        >
          전체
        </Button>
      </Inner1>
      <Inner1>
        <Text>검색 구분</Text>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="select-search-label">Search</InputLabel>
          <Select
            labelId="select-search-label"
            id="select-search"
            label="Search"
            value={division}
            onChange={onDivisionChange}
          >
            <MenuItem value={"userId"}>회원ID</MenuItem>
            <MenuItem value={"nickname"}>프로필명</MenuItem>
            <MenuItem value={"eventDesc"}>적요</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Keyword" value={keyword} onChange={onKeywordChange} />
      </Inner1>
      <Inner2>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onSearchBtnClick}
        >
          검색
        </Button>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onCleanBtnClick}
        >
          초기화
        </Button>
      </Inner2>
    </SearchContainer>
  );
}

const SearchContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});
const Inner1 = styled("div")({
  position: "relative",
  width: "100%",
  marginBottom: "20px",
  border: "1px solid #e0e0e0",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});
const Inner2 = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Text = styled("p")({
  border: "1px solid black",
  width: "100px",
  height: "37px",
  marginRight: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#bdbdbd",
  color: "black",
});

RegisterTokenSearchBar.propTypes = {
  getList: PropTypes.func.isRequired,
};

export default RegisterTokenSearchBar;
