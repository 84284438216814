import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import TitleSimple from "../common/TitleSimple";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    margin: theme.spacing(3, 0, 5),
    backgroundColor: theme.palette.custom.black,
    color: theme.palette.custom.white,
  },
}));

const UserInfoModal = ({ open, handleClose, userInfo }) => {
  const classes = useStyles();

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>회원 정보</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                label="회원 번호"
                fullWidth
                autoFocus
                defaultValue={userInfo?.id}
                disabled
              />
              {userInfo?.photoUrl ? (
                <img
                  src={userInfo?.photoUrl}
                  alt="preview"
                  style={{ maxHeight: "50px" }}
                />
              ) : (
                <></>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                label="작가 이름"
                fullWidth
                autoFocus
                defaultValue={userInfo?.name || "미입력"}
                disabled
              />
              <TextField
                variant="outlined"
                margin="normal"
                label="작가 닉네임"
                fullWidth
                autoFocus
                defaultValue={userInfo?.nickname || "미입력"}
                disabled
              />
              <TextField
                variant="outlined"
                margin="normal"
                label="블랙 회원"
                fullWidth
                autoFocus
                defaultValue={userInfo?.black ? "블랙" : "일반"}
                disabled
              />
              <TextField
                variant="outlined"
                margin="normal"
                label="소셜 로그인 공급업체"
                fullWidth
                autoFocus
                defaultValue={userInfo?.provider || "미입력"}
                disabled
              />
              <TextField
                variant="outlined"
                margin="normal"
                label="소셜 id"
                fullWidth
                autoFocus
                defaultValue={userInfo?.snsId || "미입력"}
                disabled
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} sm={6}>
              {/* <Button
                className={classes.submit}
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                onClick={onAdd}
              >
                {"확인"}
              </Button> */}
            </Grid>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UserInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserInfoModal;
