import React, { useState } from "react";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import { convertDate } from "../lib/utils";
import { DateRangePicker } from "rsuite";
import "../../assets/css/dateRangePicker.css";
import { tockCommonApi } from "../../api/tock";
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "900px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  subTitle: {
    fontFamily: "NotoSansKR",
    fontSize: "20px",
    color: "black",
    margin: "0px 12px",
    padding: "20px 25px ",
    fontWeight: 800,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const ChangeCpComanyModal = ({ open, handleClose, getData, cpId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const inputName = useRef(null);
  const inputManager = useRef(null);
  const inputComRegisterNum = useRef(null);
  const inputAccountNum = useRef(null);
  const inputBank = useRef(null);
  const inputPhoneNum = useRef(null);
  const inputRevenueShare = useRef(null);
  const inputNote = useRef(null);

  // 날짜 입력받은 state
  const [date, setDate] = useState([new Date("2022-01-01"), new Date()]);
  const startAt = convertDate(date[0]);
  const endAt = convertDate(date[1]);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const update = async (event) => {
    event.preventDefault();

    if (
      inputName.current.value.length === 0 ||
      inputName.current.value.trim().length === 0
    ) {
      toast.error("CP/작가를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (
      inputManager.current.value.length === 0 ||
      inputManager.current.value.trim().length === 0
    ) {
      toast.error("담당자를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (
      inputAccountNum.current.value.length === 0 ||
      inputAccountNum.current.value.trim().length === 0
    ) {
      toast.error("계좌번호를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (
      inputBank.current.value.length === 0 ||
      inputBank.current.value.trim().length === 0
    ) {
      toast.error("은행을 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (
      inputRevenueShare.current.value.length === 0 ||
      inputRevenueShare.current.value.trim().length === 0
    ) {
      toast.error("RS를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (
      (inputComRegisterNum.current.value.length !== 0 ||
        inputComRegisterNum.current.value.trim().length !== 0) &&
      inputComRegisterNum.current.value.match(/^[0-9]+$/) === null
    ) {
      toast.error("사업자등록번호 숫자만 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      const sendData = {
        id: cpId,
        name: inputName.current.value,
        manager: inputManager.current.value,
        comRegisterNum: inputComRegisterNum.current.value,
        accountNum: inputAccountNum.current.value,
        bank: inputBank.current.value,
        phoneNum: inputPhoneNum.current.value,
        revenueShare: inputRevenueShare.current.value,
        contractStart: startAt,
        contractEnd: endAt,
        note: inputNote.current.value,
      };
      const result = await tockCommonApi.updateCpCompany(sendData);
      if (result) {
        handleClose(true);
        getData();
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (cpId !== 0) {
        setLoading(true);
        const result = await tockCommonApi.getCpDetail(cpId);
        if (result.status === 200) {
          setData(result.data);
        }
      }
      setLoading(false);
    };
    getData();
  }, [cpId]);

  return (
    <>
      {loading ? (
        <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
          <div className={classes.paper}>
            <Typography className={classes.title}>CP | 작가 수정</Typography>
          </div>
        </Modal>
      ) : (
        <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
          <div className={classes.paper}>
            <Typography className={classes.title}>CP | 작가 수정</Typography>
            <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
              <Table
                sx={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  padding: "10px",
                }}
                align="center"
                size="small"
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      CP/작가 *{" "}
                    </TableCell>
                    <TableCell
                      colSpan={7}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        placeholder="내용을 입력하세요."
                        defaultValue={data.name}
                        inputRef={inputName}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      담당자 *{" "}
                    </TableCell>
                    <TableCell
                      colSpan={7}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        placeholder="내용을 입력하세요."
                        defaultValue={data.manager}
                        inputRef={inputManager}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      사업자등록번호{" "}
                    </TableCell>
                    <TableCell
                      colSpan={7}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        placeholder="내용을 입력하세요. (숫자만 입력하세요.)"
                        defaultValue={data.comRegisterNum}
                        inputRef={inputComRegisterNum}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      계좌번호 *{" "}
                    </TableCell>
                    <TableCell
                      colSpan={5}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        placeholder="내용을 입력하세요."
                        defaultValue={data.accountNum}
                        inputRef={inputAccountNum}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      은행*{" "}
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        placeholder="내용을 입력하세요."
                        defaultValue={data.bank}
                        inputRef={inputBank}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      전화번호
                    </TableCell>
                    <TableCell
                      colSpan={7}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        placeholder="내용을 입력하세요."
                        defaultValue={data.phoneNum}
                        inputRef={inputPhoneNum}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      계약기간 *
                    </TableCell>
                    <TableCell
                      colSpan={5}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <DateRangePicker
                        style={{ zIndex: 10000000000 }}
                        format="yyyy. MM. dd"
                        ranges={[]}
                        onChange={setDate}
                        placeholder="select date range"
                        defaultValue={[
                          new Date(data.contractStart.substring(0, 10)),
                          new Date(data.contractEnd.substring(0, 10)),
                        ]}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      RS *{" "}
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        placeholder="숫자만 입력"
                        defaultValue={data.revenueShare}
                        inputRef={inputRevenueShare}
                      />
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        border: 1,
                        borderColor: "grey.500",
                      }}
                      colSpan={1}
                    >
                      비고{" "}
                    </TableCell>
                    <TableCell
                      colSpan={7}
                      sx={{ border: 1, borderColor: "grey.500" }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        placeholder="내용을 입력하세요."
                        defaultValue={data.note}
                        inputRef={inputNote}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#F0A619",
                  color: "#FFF",
                  width: "100px",
                }}
                onClick={update}
              >
                수정
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

ChangeCpComanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChangeCpComanyModal;
