import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { Button } from "rsuite";
import { styled } from "@mui/material/styles";
import "rsuite/dist/rsuite.min.css"; // rsuite 라이브러리 디자인 적용을 위해 반드시 임포트 요망!
import {
  UseTokensCpExcelHeaders,
  convertUseTokensCpExcelDataStyle,
} from "../lib/excelDown";
import { useTokensApi } from "../../api/payments";

function ExcelDownOfUseTokensCp({ getSearchParams, getParam, isEmpty }) {

  let apiResults = [];
  const [finalData, setFinalData]= useState(false);
  const csvLink = useRef();

  const getCpData= async (
    startAt
    , endAt
    , device
    , tknClCd
    , division
    , keyword
    , page
    , pageSize 
  ) => {
      getSearchParams();

      const { status, data } = await useTokensApi.listCp(
        isEmpty(startAt) ? getParam("startAt") : undefined
        , isEmpty(endAt) ? getParam("endAt") : undefined
        , isEmpty(device) ? getParam("device") : undefined 
        , isEmpty(tknClCd) ? getParam("tknClCd") : undefined 
        , isEmpty(division) ? getParam("division") : undefined
        , isEmpty(keyword) ? getParam("keyword") : undefined
        , 1
        , 999999999
      );

      if(status === 200){
        apiResults = data.finalResult;
      }
  };


  const onExcelBtnClick = async () => {
    await getCpData();
    
    let timer = setInterval(() => {
      if(!isEmpty(apiResults) && apiResults.length > 0){
        setFinalData(convertUseTokensCpExcelDataStyle(apiResults));
        clearInterval(timer);
      }
    }, 1000);
  }

  useEffect(() => {
    if(finalData && csvLink.current && csvLink.current.link){
      setTimeout(() => {
        csvLink.current.link.click();
        setFinalData(false);
      });
    }

  }, [finalData]);

  return (
    <Container>
      <Button 
        appearance = { "primary" } 
        color = "green"
        onClick = { onExcelBtnClick }
      >
        엑셀다운로드
      </Button>
      {finalData.length > 0 ? 
        <CSVLink
          headers={UseTokensCpExcelHeaders}
          data={finalData}
          filename="토큰 차감 데이터_CP사"
          style={{ color: "white" }}
          ref = { csvLink }
          data-interception = "off"
        />
      :undefined }
    </Container>
  );
}

const Container = styled("div")({
  margin: "0 0 10px 0",
});

ExcelDownOfUseTokensCp.propTypes = {
  getSearchParams:PropTypes.func,
  getParam: PropTypes.func,
  isEmpty: PropTypes.func,
};

export default ExcelDownOfUseTokensCp;
