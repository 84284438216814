import { createSlice } from '@reduxjs/toolkit';

// 사이드바 open close 에 관한 정보
const slice = createSlice({
  name: 'open',
  initialState: true,
  reducers: {
    setOpen: () => {
      return true;
    },
    setClose: () => {
      return false;
    },
    setToggle: state => {
      return !state;
    },
  },
});

export const { setOpen, setClose, setToggle } = slice.actions;

export default slice.reducer;
