import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import toast from "react-hot-toast";
import { tockCommonApi } from "../../api/tock";

// x버튼 눌렀을 때의 처리, 홈일 때는? api post하는 것만 하면 끝

function PushLink({ linkValue, setPushLink, defaltSeriesId, defaltStoryId }) {
  // state
  const [notices, setNotices] = useState([]);
  const [tockSeries, setTockSeries] = useState([]);
  const [tockDetail, setTockDetial] = useState([]);
  const [seriesId, setSeriedId] = useState(
    defaltSeriesId ? defaltSeriesId : ""
  );
  const [storyId, setStoryId] = useState(defaltStoryId ? defaltStoryId : "");

  // 푸시링크 seriesId와 storyId를 부모 'RegisterPush'로 보내는 함수
  useEffect(() => {
    setPushLink({ seriesId: seriesId?.id, storyId: storyId?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesId, storyId]);

  // 공지사항 api get
  useEffect(() => {
    const getNotices = async () => {
      try {
        const { status, data } = await tockCommonApi.getNotices();
        if (status === 200) {
          setNotices(
            data.map((noti) => {
              return { label: noti.name, id: noti.id };
            })
          );
        }
      } catch (error) {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    };
    getNotices();
  }, []);

  // 작품메인 api get
  useEffect(() => {
    const getTockSeries = async () => {
      try {
        const { status, data } = await tockCommonApi.getClientSeries();
        if (status === 200) {
          setTockSeries(
            data.map((item) => {
              return {
                label: `${item.id}. ${item?.name}` ?? item.introduce,
                id: item.id,
              };
            })
          );
        }
      } catch (error) {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    };
    getTockSeries();
  }, []);

  // 작품(회차) api get
  useEffect(() => {
    const getTockDetailSeries = async () => {
      if (seriesId !== "" && linkValue === "contentsEach") {
        try {
          const { status, data } = await tockCommonApi.getDetailSeries(
            seriesId.id
          );
          if (status === 200) {
            setTockDetial(
              data.stories.map((story) => {
                return {
                  label: story?.name ?? "none",
                  id: story?.id ?? "none",
                };
              })
            );
          }
        } catch (error) {
          toast.error("네트워크 에러", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      }
    };
    getTockDetailSeries();
  }, [linkValue, seriesId]);

  // '작품회차'가 선택되지 않았으 ㄹ때는 storyId를 초기화 시켜주기
  useEffect(() => {
    if (linkValue !== "contentsEach") {
      setStoryId("");
    }
  }, [linkValue]);

  return (
    <div>
      <DropdownGroup>
        <Inner>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={notices}
            sx={{ width: 400 }}
            renderInput={(params) => (
              <TextField {...params} label="선택하세요" />
            )}
            size="small"
            disabled={linkValue === "notice" ? false : true}
            onChange={(event, value) => setSeriedId(value)}
          />
        </Inner>
        <Inner>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={notices} // 임시로 notice 넣어둠
            sx={{ width: 195, marginRight: "10px" }}
            renderInput={(params) => <TextField {...params} label="카테고리" />}
            size="small"
            disabled
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={tockSeries}
            sx={{ width: 195 }}
            renderInput={(params) => <TextField {...params} label="작품" />}
            size="small"
            disabled={linkValue === "contentsMain" ? false : true}
            onChange={(event, value) => setSeriedId(value)}
          />
        </Inner>
        <Inner>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={notices} // 임시로 notice 넣어둠
            sx={{ width: 195, marginRight: "10px" }}
            renderInput={(params) => <TextField {...params} label="카테고리" />}
            size="small"
            disabled
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={tockSeries}
            sx={{ width: 195, marginRight: "10px" }}
            renderInput={(params) => <TextField {...params} label="작품" />}
            size="small"
            disabled={linkValue === "contentsEach" ? false : true}
            onChange={(event, value) => setSeriedId(value)}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={tockDetail}
            sx={{ width: 195 }}
            renderInput={(params) => <TextField {...params} label="회차" />}
            size="small"
            disabled={linkValue === "contentsEach" ? false : true}
            onChange={(event, value) => setStoryId(value)}
          />
        </Inner>
      </DropdownGroup>
    </div>
  );
}

PushLink.propTypes = {
  linkValue: PropTypes.string.isRequired,
  setPushLink: PropTypes.func.isRequired,
};

const DropdownGroup = styled("div")({
  paddingTop: "45px",
  position: "relative",
});
const Inner = styled("div")({
  margin: "5px 0",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

export default PushLink;
