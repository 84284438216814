import React, { forwardRef, useEffect, useState } from "react";
import AdminRoot from "../components/frame/AdminRoot";

import moment from "moment";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import _ from "lodash";
import { tockCommonApi } from "../api/tock";
import { Button } from "@material-ui/core";
import UserInfoModal from "../components/modal/UserInfoModal";
import StoryListModal from "../components/modal/StoryListModal";
import ChangeSeriesModel from "../components/modal/ChangeSeriesModel";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const materialTableOptions = {
  search: true,
  sorting: true,
  draggable: false,
  showTitle: true,
  actionsColumnIndex: -1,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
  headerStyle: {
    backgroundColor: "#EEE",
    fontWeight: "bold",
    textAlign: "center",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  actionsCellStyle: {
    textAlign: "center",
  },
};

const NormalDeletedContents = () => {
  const [data, setData] = useState([]);
  const [genreList, setGenreList] = useState([]);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [openStoryList, setOpenStoryList] = useState(false);
  const [openChangeContent, setOpenChangeContent] = useState(false);
  const [seriesId, setSeriesId] = useState(0);
  const [reloadCount, setReloadCount] = useState(0);

  const materialTableColumns = [
    {
      title: "ID",
      field: "id",
      width: 50,
    },
    {
      title: "작품 이름",
      field: "name",
      render: (rowData) => {
        return (
          <div
            style={{
              width: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {rowData.name}
          </div>
        );
      },
    },
    {
      title: "작품 소개",
      field: "introduce",
      render: (rowData) => {
        return (
          <div
            style={{
              width: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {rowData.introduce}
          </div>
        );
      },
    },
    {
      title: "스토리 목록",
      sorting: false,
      render: (rowData) => {
        return (
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => {
              setSeriesId(rowData.id);
              setOpenStoryList(true);
            }}
          >
            상세
          </Button>
        );
      },
    },
    {
      title: "작가",
      width: 200,
      field: "userId",
      render: (rowData) => {
        return (
          <Button
            variant="contained"
            style={{ textTransform: "none" }}
            onClick={() => {
              setUserInfo(rowData.users);
              setOpenUserInfo(true);
            }}
          >
            {rowData.userId}
          </Button>
        );
      },
    },
    {
      title: "장르",
      field: "genreId",
      width: 150,
      render: (rowData) => {
        const genre = _.find(genreList, { id: rowData.genreId });
        return <span>{genre?.name} </span>;
      },
    },
    {
      title: `커버이미지`,
      sorting: false,
      render: (rowData) => {
        return (
          <img
            src={rowData?.coverImgUrl}
            alt="preview"
            style={{ maxHeight: "50px" }}
          />
        );
      },
    },
    {
      title: "작품타입",
      field: "type",
      width: 150,
    },
    {
      title: "등록일",
      width: 150,
      field: "createdAt",
      render: (rowData) => (
        <>
          {rowData.createdAt
            ? moment(rowData.createdAt).format("YY-MM-DD")
            : ""}
        </>
      ),
    },
  ];

  useEffect(() => {
    const getRoundData = async () => {
      const result = await tockCommonApi.getGenres();
      if (result.status === 200) {
        setGenreList(result.data);
      }

      const { status, data: newData } = await tockCommonApi.getDeletedSeries();
      if (status === 200) {
        setData(newData);
      }
    };
    getRoundData();
  }, [reloadCount]);

  return (
    <AdminRoot>
      <MaterialTable
        title="일반 작품"
        icons={tableIcons}
        columns={materialTableColumns}
        options={materialTableOptions}
        data={data}
      />
      <UserInfoModal
        open={openUserInfo}
        handleClose={() => setOpenUserInfo(false)}
        userInfo={userInfo}
      />
      <StoryListModal
        open={openStoryList}
        handleClose={() => setOpenStoryList(false)}
        seriesId={seriesId}
        deletedContent={true}
      />
      <ChangeSeriesModel
        key={openChangeContent}
        open={openChangeContent}
        handleClose={(result) => {
          if (result) {
            setReloadCount(reloadCount + 1);
          }
          setOpenChangeContent(false);
        }}
        seriesId={seriesId}
      />
    </AdminRoot>
  );
};
export default NormalDeletedContents;
