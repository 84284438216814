import React, { useEffect, useState, useRef }from "react";
import AdminRoot from "../components/frame/AdminRoot";
import { mainSeriesRankingApi } from "../api/service";
import { userRankingApi } from "../api/tock";
import SeriesSelectModal from "../components/service/SeriesSelectModal";
import { ReOrderableItem, ReOrderableList } from "react-reorderable-list";
import { 
    Box
    , Tabs
    , Tab
    , Typography
    , TextField
    , InputLabel
    , MenuItem
    , FormControl
    , Select
    , Button 
    , IconButton
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import toast from "react-hot-toast";

const MainSeries= () => {

    const [tabValue, setTabValue]= useState(0);
    const [dateRange, setDateRange]= useState();
    const [rankingElement, setRankingElement] = useState(null);

    const [openSelectSeries, setOpenSelectSeries] = useState(false);
    const [seriesList, setSeriesList] = useState([]);
    const [genreId, setGenreId] = useState(1);
    const likePoint = useRef(null);
    const followPoint = useRef(null);
    const replyPoint = useRef(null);
    const viewPoint = useRef(null);

    //탭 변경
    const handleTabChange= (event, newValue)=> {
        setTabValue(newValue);
        setGenreId(newValue + 1);
    };

    //랭킹 요인 기간 변경
    const handleDateRange = (event) => {
        if (event.target.name === "week") {
            setDateRange("1주일");
        } else if (event.target.name === "month1") {
            setDateRange("1개월");
        } else if (event.target.name === "month3") {
            setDateRange("3개월");
        } else if (event.target.name === "month6") {
            setDateRange("6개월");
        }
    };

    //장르 변경
    const handleGenre = (event) => {
        setTabValue(event.target.value -1);
        setGenreId(event.target.value);
    };

    //링캥 요인 포인트 변경
    const handleRankingEle = (event) => {
        setRankingElement({[event.target.id]: event.target.value});
    };

    //메인 랭킹 시리즈 정보 가져오기(기존에 등록된)
    const getSeriesList = async () => {
        const { status, data } = await mainSeriesRankingApi.list( genreId );

        if (status === 200) {
            setSeriesList(data);
        }
    };

    //랭킹 요인 가져오기
    const getRankingEle = async() => {
        const {status, data} = await userRankingApi.getUserRankingElement(genreId);

        if (status === 200) {
            if (data.range === "week") {
                setDateRange("1주일");
            } else if (data.range === "month1") {
                setDateRange("1개월");
            } else if (data.range === "month3") {
                setDateRange("3개월");
            } else if (data.range === "month6") {
                setDateRange("6개월");
            }

            setGenreId(data.genre);
            setRankingElement(data);
        }
    };

    //랭킹 요인 테스트
    const getRankingEleTest = async() => {

        let range;
        if (dateRange === "1주일") {
            range = "week";
        } else if (dateRange === "1개월") {
            range = "month1";
        } else if (dateRange === "3개월") {
            range = "month3";
        } else if (dateRange === "6개월") {
            range = "month6";
        }

        const {status, data} = await mainSeriesRankingApi.getSeriesRankingTest(
            likePoint.current.value
            , followPoint.current.value
            , replyPoint.current.value
            , viewPoint.current.value
            , range
            , genreId
        );

        if(status === 200){
            setSeriesList(data);
        }
    };

    //메인 시리즈 랭킹 서버 등록
    const setRankingEle = async() => {
        let range;
        if (dateRange === "1주일") {
            range = "week";
        } else if (dateRange === "1개월") {
            range = "month1";
        } else if (dateRange === "3개월") {
            range = "month3";
        } else if (dateRange === "6개월") {
            range = "month6";
        }

        //랭킹 요인 등록
        await userRankingApi.setUserRankingEle(
            likePoint.current.value
            , followPoint.current.value
            , replyPoint.current.value
            , viewPoint.current.value
            , range
            , genreId
        );

        //메인 시리즈 등록
        await mainSeriesRankingApi.setMainSeriesRanking(genreId, seriesList);
        toast.success("메인 작품 랭킹 요소 반영되었습니다.");
    };

    useEffect(() => {
        getSeriesList();
        getRankingEle();

    }, [tabValue]);

    return (
        <AdminRoot>
            <Typography
                variant="h5"
                style={{ fontWeight: "bold"}}
            >
                메인 작품 전시
            </Typography>
            
            <Box style= {{ width: "100%", bgcolor: "Background.paper"}}>
                <Tabs value= {tabValue} onChange= {handleTabChange} centered>
                    <Tab label= "달달 + 설렘 로맨스"  style= {{fontWeight: "bold", fontSize: "20px"}}/>     
                    <Tab label= "공상 + 초자연 판타지"  style= {{fontWeight: "bold", fontSize: "20px"}}/>   
                    <Tab label= "으스스 공포! 스릴러"  style= {{fontWeight: "bold", fontSize: "20px"}}/>   
                    <Tab label= "재미 보장! 스토리"  style= {{fontWeight: "bold", fontSize: "20px"}}/>         
                </Tabs>
            </Box>

            <Box style= {{marginTop: "20px", border: "1px solid #a0a0a0"}}>
                <Typography 
                    style= {{margin: "10px", fontWeight: "bold"}}
                >
                    랭킹 요인 설정
                </Typography>
                <Box style= {{margin: "10px"}}>
                    <TextField
                        id = "likePoint"
                        label = "좋아요"
                        type = "number"
                        inputRef = {likePoint}
                        value = {rankingElement?.likePoint}
                        InputLabelProps = {{
                            shrink: true,
                        }}
                        InputProps = {{ 
                            inputProps: {min: 0}
                        }}
                        style = {{width: "100px", marginRight: "20px", marginTop: "30px"}}
                        onChange = {handleRankingEle}
                    />
                    <TextField
                        id= "followPoint"
                        label= "팔로우수"
                        type= "number"
                        inputRef = {followPoint}
                        value={rankingElement?.followPoint}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ 
                            inputProps: {min: 0}
                        }}
                        style={{width: "100px", marginRight: "20px", marginTop: "30px"}}
                        onChange = {handleRankingEle}
                    />
                    <TextField
                        id= "replyPoint"
                        label= "댓글합"
                        type= "number"
                        inputRef = {replyPoint}
                        value={rankingElement?.replyPoint}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ 
                            inputProps: {min: 0}
                        }}
                        style={{width: "100px", marginRight: "20px", marginTop: "30px"}}
                        onChange = {handleRankingEle}
                    />
                    <TextField
                        id= "viewPoint"
                        label= "조회수"
                        type= "number"
                        inputRef = {viewPoint}
                        value={rankingElement?.viewPoint}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ 
                            inputProps: {min: 0}
                        }}
                        style={{width: "100px", marginRight: "20px", marginTop: "30px"}}
                        onChange = {handleRankingEle}
                    />
                     <TextField
                        id= "dateRange"
                        value={dateRange}
                        label= "범위"
                        defaultValue= "범위"
                        InputProps={{ 
                            readOnly: true,
                        }}
                        style={{width: "100px", marginRight: "20px", marginTop: "30px"}}
                    />
                    <FormControl style= {{width: "300px", marginTop: "30px", marginRight: "30px"}}>
                        <InputLabel id="genreGubun">장르구분</InputLabel>
                        <Select
                            labelId="genreGubun"
                            label="genreGubun"
                            onChange={handleGenre}
                            value={genreId}
                        >
                            <MenuItem value= {1} selected>달달 + 설렘 로맨스</MenuItem>
                            <MenuItem value= {2}>공상 + 초자연 판타지</MenuItem>
                            <MenuItem value= {3}>으스스 공포! 스릴러</MenuItem>
                            <MenuItem value= {4}>재미 보장! 스토리</MenuItem>
                        </Select>
                    </FormControl>

                    <Button 
                        variant= "contained"
                        size= "large"
                        style= {{marginTop: "30px", height: "60px", backgroundColor: "#a0a0a0"}}
                        onClick={getRankingEleTest}
                    >
                        랭킹 요인 테스트
                    </Button>
                </Box>
                <Box style= {{margin: "10px"}}>
                    <Button 
                        variant= "contained" 
                        size= "large" 
                        style= {{marginTop: "10px", marginRight:"10px"}}
                        onClick= {handleDateRange}
                        name= "week"
                    >
                        1주일
                    </Button>
                    <Button 
                        variant= "contained" 
                        size= "large" 
                        style= {{marginTop: "10px", marginRight:"10px"}}
                        onClick= {handleDateRange}
                        name= "month1"
                    >
                        1개월
                    </Button>
                    <Button 
                        variant= "contained" 
                        size= "large" 
                        style= {{marginTop: "10px",marginRight:"10px"}}
                        onClick= {handleDateRange}
                        name= "month3"
                    >
                        3개월
                    </Button>
                    <Button 
                        variant= "contained" 
                        size= "large" 
                        style= {{marginTop: "10px",marginRight:"10px"}}
                        onClick= {handleDateRange}
                        name= "month6"
                    >
                        6개월
                    </Button>
                </Box>
            </Box>

            <Box style= {{marginTop: "20px", textAlign:"right"}}>
                <Button
                    variant= "contained"
                    size= "large"
                    style= {{margin: "10px", backgroundColor: "#505050", width:"150px"}}
                    onClick= {() => {
                        setOpenSelectSeries(true);
                    }}
                >
                    작품 추가
                </Button>
                <Button
                    variant= "contained"
                    size= "large"
                    style= {{margin: "10px", backgroundColor: "#505050", width:"150px"}}
                    onClick={() => {
                        setRankingEle();
                    }}
                >
                    서버 적용
                </Button>
            </Box>

            {/* 메인 시리즈 목록 */}
            <Box>
                <ReOrderableList
                    list={seriesList}
                    onListUpdate={(newList) => setSeriesList(newList)}
                >
                    {seriesList.map((item, index) => (
                        <ReOrderableItem key={`item-${index}`}>
                        <div
                            style={{
                            height: "50px",
                            border: "1px solid #333333",
                            borderRadius: "10px",
                            margin: "10px",
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            }}
                        >
                            <Typography>{`${item.name} (id:${item.id})`}</Typography>
                            <IconButton
                                style={{ marginLeft: "auto" }}
                                onClick={() => {
                                    const newSeries = seriesList.filter(
                                    (series) => series.id !== item.id
                                    );
                                    setSeriesList(newSeries);
                            }}
                            >
                            <CancelIcon />
                            </IconButton>
                        </div>
                        </ReOrderableItem>
                    ))}
                </ReOrderableList>
            </Box>

            {/* 작품 추가 팝업 */}
            <SeriesSelectModal
                open={openSelectSeries}
                onClose={() => {
                    setOpenSelectSeries(false);
                }}
                onOK={(value) => {
                if (value) {
                    const findSeries = seriesList.find(
                        (item) => item.id === value.seriesId
                    );

                    if (!findSeries) {
                        let newValue = {};
                        if(value != undefined && value != '' && value != null){
                            newValue.id = value.seriesId;
                            newValue.name = value.name;
                        }
                        setSeriesList([...seriesList, newValue]);
                    }
                    setOpenSelectSeries(false);
                }
                }}
                title= {"시리즈"}
                genreId= { genreId }
            /> 
        </AdminRoot>
    );
};


export default MainSeries;