import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal
  , Box
  , Button
  , InputLabel
  , FormControl
  , MenuItem
  , Select
}
from "@mui/material";
import { mainAuthorRankingApi } from "../../api/service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const AuthorSelectModal = ({
  open,
  onClose,
  onOK,
  selectedAuthorId,
  title,
}) => {
  
  const [authorList, setAuthorList] = useState([]);
  const [authorId, setAuthorId] = useState(selectedAuthorId);
  const [openSelect, setOpenSelect] = useState(false);
  
  useEffect(() => {
    //작가 목록 가져오기
    const requestAuthorList = async () => {
      const { status, data: newData } = await mainAuthorRankingApi.getAuthorList();
      if (status === 200) {
        setAuthorList(newData);
      }
    };

    if(open){
      requestAuthorList();
    }
  }, [open]);

  useEffect(() => {
    setAuthorId(selectedAuthorId);
  }, [selectedAuthorId]);

  //작가 변경
  const handleChange = (event) => {
    setAuthorId(event.target.value);
  };

  //닫음
  const handleClose = () => {
    setOpenSelect(false);
  };

  //열림
  const handleOpen = () => {
    setOpenSelect(true);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style, width: 400 }}>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="select-search-label">
            {title}
          </InputLabel>
          <Select
            labelId="select-search-label"
            id="select-search-label"
            open={openSelect}
            onClose={handleClose}
            onOpen={handleOpen}
            value={authorId || ""}
            label="Search"
            onChange={handleChange}
          >
            {authorList.map((author) => {
              return (
                <MenuItem
                  value={author.id}
                  key={author.id}
                >{`${author.nickname}(id:${author.id}) 팔로우 수 (${author.followCount})`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={() => {
              onClose();
            }}
          >
            취소
          </Button>
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={() => {
              if (authorId === "") onOK(null);
              else {
                const found = authorList.find((el) => authorId === el.id);
                onOK({ authorId, nickname: found.nickname, followCount: found.followCount });
              }
            }}
          >
            적용
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

AuthorSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired,
  selectedAuthorId: PropTypes.number,
  title: PropTypes.string,
  context: PropTypes.any,
  genreId: PropTypes.any,
};

AuthorSelectModal.defaultProps = {
  selectedAuthorId: null,
  title: null,
  context: null,
  genreId: null,
};

export default AuthorSelectModal;
