import * as React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { Button } from "rsuite";
import { styled } from "@mui/material/styles";
import "rsuite/dist/rsuite.min.css"; // rsuite 라이브러리 디자인 적용을 위해 반드시 임포트 요망!
import {
  SettleExcelHeaders,
  convertSettleExcelDataStyle,
} from "../lib/excelDown";

function ExcelDownOfSettle({ apiResults }) {
  const finalData = convertSettleExcelDataStyle(apiResults);

  return (
    <Container>
      <CSVLink
        headers={SettleExcelHeaders}
        data={finalData}
        filename="정산 내역 데이터"
        style={{ color: "white" }}
      >
        <Button appearance="primary" color="green">
          엑셀다운로드
        </Button>
      </CSVLink>
    </Container>
  );
}

ExcelDownOfSettle.defaultProps = {
  apiResults: [],
};

ExcelDownOfSettle.propTypes = {
  apiResults: PropTypes.array,
};

const Container = styled("div")({
  margin: "0 0 10px 0",
});

export default ExcelDownOfSettle;
