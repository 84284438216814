import React, { useState } from "react";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import { convertDate } from "../lib/utils";
import { DateRangePicker } from "rsuite";
import "../../assets/css/dateRangePicker.css";
import { tockCommonApi } from "../../api/tock";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "900px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  subTitle: {
    fontFamily: "NotoSansKR",
    fontSize: "20px",
    color: "black",
    margin: "0px 12px",
    padding: "20px 25px ",
    fontWeight: 800,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const AddCpComanyModal = ({ open, handleClose, getData }) => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [manager, setManager] = useState("");
  const [comRegisterNum, setComRegisterNum] = useState(0);
  const [accountNum, setAccountNum] = useState("");
  const [bank, setBank] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [revenueShare, setRevenueShare] = useState("");
  const [note, setNote] = useState("");

  // 날짜 입력받은 state
  const [date, setDate] = useState([new Date(), new Date()]);
  const startAt = convertDate(date[0]);
  const endAt = convertDate(date[1]);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onManagerChange = (e) => {
    setManager(e.target.value);
  };
  const onComRegisterNumChange = (e) => {
    setComRegisterNum(e.target.value);
  };
  const onAccountNumChange = (e) => {
    setAccountNum(e.target.value);
  };
  const onBankChange = (e) => {
    setBank(e.target.value);
  };
  const onPhoneNumChange = (e) => {
    setPhoneNum(e.target.value);
  };
  const onRevenueShareChange = (e) => {
    setRevenueShare(e.target.value);
  };
  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  const register = async (event) => {
    event.preventDefault();

    if (name.length === 0 || name.trim().length === 0) {
      toast.error("CP/작가를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (manager.length === 0 || manager.trim().length === 0) {
      toast.error("담당자를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (accountNum.length === 0 || accountNum.trim().length === 0) {
      toast.error("계좌번호를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (bank.length === 0 || bank.trim().length === 0) {
      toast.error("은행을 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (revenueShare.length === 0 || revenueShare.trim().length === 0) {
      toast.error("RS를 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else if (
      comRegisterNum.length > 0 &&
      comRegisterNum.match(/^[0-9]+$/) === null
    ) {
      toast.error("사업자등록번호 숫자만 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      const sendData = {
        name: name,
        manager: manager,
        comRegisterNum: comRegisterNum,
        accountNum: accountNum,
        bank: bank,
        phoneNum: phoneNum,
        revenueShare: revenueShare,
        contractStart: startAt,
        contractEnd: endAt,
        note: note,
      };
      const result = await tockCommonApi.insertCpCompany(sendData);
      if (result) {
        handleClose(true);
        getData();
      }
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>CP | 작가 등록 / 수정</Typography>
        <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
          <Table
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              padding: "10px",
            }}
            align="center"
            size="small"
          >
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  CP/작가 *{" "}
                </TableCell>
                <TableCell
                  colSpan={7}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="내용을 입력하세요."
                    onChange={onNameChange}
                    name="name"
                    id="name"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  담당자 *{" "}
                </TableCell>
                <TableCell
                  colSpan={7}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="내용을 입력하세요."
                    onChange={onManagerChange}
                    name="manager"
                    id="manager"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  사업자등록번호{" "}
                </TableCell>
                <TableCell
                  colSpan={7}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="내용을 입력하세요. (숫자만 입력하세요.)"
                    onChange={onComRegisterNumChange}
                    name="comRegisterNum"
                    id="comRegisterNum"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  계좌번호 *{" "}
                </TableCell>
                <TableCell
                  colSpan={5}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="내용을 입력하세요."
                    onChange={onAccountNumChange}
                    name="accountNum"
                    id="accountNum"
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  은행*{" "}
                </TableCell>
                <TableCell
                  colSpan={1}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="내용을 입력하세요."
                    onChange={onBankChange}
                    id="bank"
                    name="bank"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  전화번호
                </TableCell>
                <TableCell
                  colSpan={7}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="내용을 입력하세요."
                    onChange={onPhoneNumChange}
                    id="phoneNum"
                    name="phoneNum"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  계약기간 *
                </TableCell>
                <TableCell
                  colSpan={5}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <DateRangePicker
                    style={{ zIndex: 10000000000 }}
                    format="yyyy. MM. dd"
                    ranges={[]}
                    value={date}
                    onChange={setDate}
                    //onClean={onCleanBtnClick}
                    placeholder="select date range"
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  RS *{" "}
                </TableCell>
                <TableCell
                  colSpan={1}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    placeholder="숫자만 입력"
                    onChange={onRevenueShareChange}
                  />
                  %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    backgroundColor: "#e0e0e0",
                    border: 1,
                    borderColor: "grey.500",
                  }}
                  colSpan={1}
                >
                  비고{" "}
                </TableCell>
                <TableCell
                  colSpan={7}
                  sx={{ border: 1, borderColor: "grey.500" }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="내용을 입력하세요."
                    onChange={onNoteChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F0A619",
              color: "#FFF",
              width: "100px",
            }}
            onClick={register}
          >
            등록
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AddCpComanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddCpComanyModal;
