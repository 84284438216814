import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import TitleSimple from "../common/TitleSimple";
import { studioMetaApi } from "../../api/studio";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "700px",
    padding: "24px",
    outline: "none",
  },
  deleteBtn: {
    margin: theme.spacing(3, 0, 5),
  },
  submit: {
    margin: theme.spacing(3, 0, 5),
    backgroundColor: theme.palette.custom.black,
    color: theme.palette.custom.white,
  },
}));

const AddCategoryInterActiveModal = ({ open, handleClose, studioMenuId }) => {
  const classes = useStyles();
  const inputTitle = useRef(null);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const onAdd = async (event) => {
    event.preventDefault();
    const result = await studioMetaApi.addStudioSubMenu(
      inputTitle.current.value,
      studioMenuId
    );
    if (result) {
      handleClose(true);
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>카테고리 추가</TitleSimple>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={inputTitle}
                id="title"
                label="카테고리"
                name="title"
                autoFocus
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} sm={6}>
              <Button
                className={classes.submit}
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                onClick={onAdd}
              >
                {"확인"}
              </Button>
            </Grid>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddCategoryInterActiveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddCategoryInterActiveModal;
