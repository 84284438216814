import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AdminRoot from "../components/frame/AdminRoot";
import CreateMainBanner from "../components/service/CreateMainBanner";
import MainBannerTable from "../components/service/MainBannerTable";
import ModifyMainBanner from "../components/service/ModityMainBanner";

const MainBanner = () => {
  // normal, create, modify

  const [status, setStatus] = useState("normal");
  const [modifyMainBannerId, setModifyMainBannerId] = useState(null);
  const [reloadList, setReloadList] = useState(0);
  const handleModify = (mainBannerId) => {
    setModifyMainBannerId(mainBannerId);
    setStatus("modify");
  };
  const handleModified = () => {
    setReloadList(reloadList + 1);
    setStatus("normal");
  };

  return (
    <AdminRoot>
      <Box>
        <Typography variant="h4" gutterBottom component="div">
          메인빅배너
        </Typography>
        {status === "create" ? (
          <Box>
            <CreateMainBanner onClose={() => setStatus("normal")} />
          </Box>
        ) : null}
        {status === "modify" ? (
          <Box>
            <ModifyMainBanner
              mainBannerId={modifyMainBannerId}
              onClose={() => setStatus("normal")}
              onModified={() => handleModified()}
            />
          </Box>
        ) : null}
        <MainBannerTable
          show={status === "normal"}
          reload={reloadList}
          onModify={handleModify}
          onClickCreate={() => setStatus("create")}
        />
      </Box>
    </AdminRoot>
  );
};
export default MainBanner;
