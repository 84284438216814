import { createTheme } from "@material-ui/core/styles";
import NotoSansKRLight from "./assets/fonts/NotoSansKR-Light.otf";
import NotoSansKRRegular from "./assets/fonts/NotoSansKR-Regular.otf";
import NotoSansKRMedium from "./assets/fonts/NotoSansKR-Medium.otf";
import NotoSansKRBold from "./assets/fonts/NotoSansKR-Bold.otf";
import NotoSerifKRLight from "./assets/fonts/NotoSerifKR-Light.otf";
import NotoSerifKRMedium from "./assets/fonts/NotoSerifKR-Medium.otf";
import NotoSerifKRRegular from "./assets/fonts/NotoSerifKR-Regular.otf";
import NotoSerifKRBold from "./assets/fonts/NotoSerifKR-Bold.otf";

const notoSerifKRLight = {
  fontFamily: "NotoSerifKR",
  fontWeight: "300",
  src: `url(${NotoSerifKRLight}) format('opentype')`,
};

const notoSerifKRegular = {
  fontFamily: "NotoSerifKR",
  fontWeight: "400",
  src: `url(${NotoSerifKRRegular}) format('opentype')`,
};

const notoSerifKRMedium = {
  fontFamily: "NotoSerifKR",
  fontWeight: "500",
  src: `url(${NotoSerifKRMedium}) format('opentype')`,
};

const notoSerifKRBold = {
  fontFamily: "NotoSerifKR",
  fontWeight: "700",
  src: `url(${NotoSerifKRBold}) format('opentype')`,
};

const notoSansKRLight = {
  fontFamily: "NotoSansKR",
  fontWeight: "300",
  src: `url(${NotoSansKRLight}) format('opentype')`,
};

const notoSansKRRegular = {
  fontFamily: "NotoSansKR",
  fontWeight: "400",
  src: `url(${NotoSansKRRegular}) format('opentype')`,
};

const notoSansKRMedium = {
  fontFamily: "NotoSansKR",
  fontWeight: "500",
  src: `url(${NotoSansKRMedium}) format('opentype')`,
};

const notoSansKRBold = {
  fontFamily: "NotoSansKR",
  fontWeight: "700",
  src: `url(${NotoSansKRBold}) format('opentype')`,
};

const statusChip = {
  preOpen: "#C4C4C4",
  targetingOngoing: "#F1C85F",
  feedbackOngoing: "#99E3B7",
  reportCompleted: "#8CC4F8",
  myReport: "#6AD1F2",
};

const theme = createTheme({
  palette: {
    custom: {
      white: "#FFFFFF",
      black: "#000000",
      selectedText: "#00A8E0",
      secondary: "#00A8E0",
      gray: "gray",
      alarmRed: "#EA3D2F",
    },
  },
  eval: {
    background: "#F9F9F9",
    blue: "#6AD1F2",
    blueButton: "#3EA4DD",
    white: "#FFFFFF",
    disabledBtn: "#D8D9E0",
    disabledText: "#EEEEEE",
    chip: { ...statusChip },
  },
  size: {
    sideBarWidth: "260px",
    modalReplyWidth: "450px",
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["NotoSansKR"].join(","),
    h2: {
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: -1,
      color: "#333333",
    },
    h3: {
      fontSize: 22,
      fontWeight: 700,
      letterSpacing: -1,
      color: "#333333",
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: -1,
      color: "#333333",
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: -1,
      color: "#333333",
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: -1,
      color: "#333333",
    },
    body1: {
      color: "#333333",
      fontSize: 16,
      letterSpacing: "0.25px",
      fontWeight: 500,
    },
    body2: {
      color: "#333333",
      fontSize: 14,
      letterSpacing: "0.25px",
      fontWeight: 500,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          notoSansKRBold,
          notoSansKRRegular,
          notoSansKRLight,
          notoSansKRMedium,
          notoSerifKRLight,
          notoSerifKRegular,
          notoSerifKRMedium,
          notoSerifKRBold,
        ],
      },
    },
  },
});

export default theme;
