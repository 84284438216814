import React, { useEffect } from "react";
import { useState } from "react";
import AdminRoot from "../components/frame/AdminRoot";
import { styled } from "@material-ui/core";
import SearchBar from "../components/push/SearchBar_Push";
import PushList from "../components/push/PushList_Push";
import { pushApi } from "../api/push";
import toast from "react-hot-toast";

function Push() {
  const [loading, setLoading] = useState(false);
  const [searchedPushList, setSearchedPushList] = useState([]);

  // 검색 기간 설정하여  푸시 리스트 받기
  const getPushList = async (startDate, endDate) => {
    try {
      setLoading(true);
      const { data } = await pushApi.list(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      setSearchedPushList(data);
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  //
  // eslint-disable-next-line
  const getData = async () => {
    setLoading(true);
    const { status, data } = await pushApi.list(undefined, undefined);
    setLoading(false);
    if (status === 200) {
      setSearchedPushList(data);
    } else {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };
  useEffect(getData, []);

  return (
    <AdminRoot>
      <Title>푸시 관리</Title>
      <>
        <SearchBar getPushList={getPushList} />
        <PushList
          loading={loading}
          searchedPushList={searchedPushList}
          getData={getData}
        />
      </>
    </AdminRoot>
  );
}

const Title = styled("div")({
  marginBottom: "20px",
});

export default Push;
