import { createSlice } from "@reduxjs/toolkit";

// 사이드바 안의 메뉴들을 펼칠껀지 아닌지에 대한 정보
const slice = createSlice({
  name: "open",
  initialState: {
    openMenu01: true,
    openMenu02: false,
    openMenu03: false,
    openMenu04: false,
    openMenu05: false,
    openMenu06: false,
    openMenu07: false,
    openMenu08: false,
  },
  reducers: {
    setToggleMenu01: (state) => {
      return {
        openMenu01: !state.openMenu01,
        openMenu02: state.openMenu02,
        openMenu03: state.openMenu03,
        openMenu04: state.openMenu04,
        openMenu05: state.openMenu05,
        openMenu06: state.openMenu06,
        openMenu07: state.openMenu07,
        openMenu08: state.openMenu08,
      };
    },
    setToggleMenu02: (state) => {
      return {
        openMenu01: state.openMenu01,
        openMenu02: !state.openMenu02,
        openMenu03: state.openMenu03,
        openMenu04: state.openMenu04,
        openMenu05: state.openMenu05,
        openMenu06: state.openMenu06,
        openMenu07: state.openMenu07,
        openMenu08: state.openMenu08,
      };
    },
    setToggleMenu03: (state) => {
      return {
        openMenu01: state.openMenu01,
        openMenu02: state.openMenu02,
        openMenu03: !state.openMenu03,
        openMenu04: state.openMenu04,
        openMenu05: state.openMenu05,
        openMenu06: state.openMenu06,
        openMenu07: state.openMenu07,
        openMenu08: state.openMenu08,
      };
    },
    setToggleMenu04: (state) => {
      return {
        openMenu01: state.openMenu01,
        openMenu02: state.openMenu02,
        openMenu03: state.openMenu03,
        openMenu04: !state.openMenu04,
        openMenu05: state.openMenu05,
        openMenu06: state.openMenu06,
        openMenu07: state.openMenu07,
        openMenu08: state.openMenu08,
      };
    },
    setToggleMenu05: (state) => {
      return {
        openMenu01: state.openMenu01,
        openMenu02: state.openMenu02,
        openMenu03: state.openMenu03,
        openMenu04: state.openMenu04,
        openMenu05: !state.openMenu05,
        openMenu06: state.openMenu06,
        openMenu07: state.openMenu07,
        openMenu08: state.openMenu08,
      };
    },
    setToggleMenu06: (state) => {
      return {
        openMenu01: state.openMenu01,
        openMenu02: state.openMenu02,
        openMenu03: state.openMenu03,
        openMenu04: state.openMenu04,
        openMenu05: state.openMenu05,
        openMenu06: !state.openMenu06,
        openMenu07: state.openMenu07,
        openMenu08: state.openMenu08,
      };
    },
    setToggleMenu07: (state) => {
      return {
        openMenu01: state.openMenu01,
        openMenu02: state.openMenu02,
        openMenu03: state.openMenu03,
        openMenu04: state.openMenu04,
        openMenu05: state.openMenu05,
        openMenu06: state.openMenu06,
        openMenu07: !state.openMenu07,
        openMenu08: state.openMenu08,
      };
    },
    setToggleMenu08: (state) => {
      return {
        openMenu01: state.openMenu01,
        openMenu02: state.openMenu02,
        openMenu03: state.openMenu03,
        openMenu04: state.openMenu04,
        openMenu05: state.openMenu05,
        openMenu06: state.openMenu06,
        openMenu07: state.openMenu07,
        openMenu08: !state.openMenu08,
      };
    },
  },
});

export const {
  setToggleMenu01,
  setToggleMenu02,
  setToggleMenu03,
  setToggleMenu04,
  setToggleMenu05,
  setToggleMenu06,
  setToggleMenu07,
  setToggleMenu08,
} = slice.actions;

export default slice.reducer;
