import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFileUpload } from "use-file-upload";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { serviceApi } from "../../api/service";
import { alarm } from "../../redux/bottomSnackbarReducer";

const Step1 = styled("div")`
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
  }

  td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
`;

const BannerStepper = styled(Stepper)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const GuideDiv = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const ButtonContainer = styled(Box)`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  & .MuiButton-root {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const steps = ["정보 입력", "이미지 입력", "결과"];

const CreateMainBanner = ({ onClose }) => {
  const [isPublic, setIsPublic] = useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const orderNumberRef = useRef();
  const titleRef = useRef();
  const [linkType, setLinkType] = useState(""); // 'series' or 'story'
  const [genreList, setGenreList] = useState([]);
  const [genreId, setGenreId] = useState("");
  const [seriesList, setSeriesList] = useState([]);
  const [seriesId, setSeriesId] = useState("");
  const [stories, setStories] = useState([]);
  const [storyId, setStoryId] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const [imageFile, selectImageFile] = useFileUpload(null);
  const [mainBannerId, setMainBannerId] = useState(null);
  const [mainBanner, setMainBanner] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const requestGenres = async () => {
      const { status, data } = await serviceApi.getGenreList();
      if (status === 200) {
        setGenreList(data);
      } else {
        dispatch(
          alarm({ message: "장르를 얻는데 실패했습니다.", severity: "error" })
        );
      }
    };
    requestGenres();
  }, [dispatch]);
  useEffect(() => {
    const requestSeriesList = async (aGenreId) => {
      const { status, data } = await serviceApi.getSeriesListByGenreId(
        aGenreId
      );
      if (status === 200) {
        setSeriesList(data);
      } else {
        dispatch(
          alarm({ message: "시리즈를 얻는데 실패했습니다.", severity: "error" })
        );
      }
    };
    if (genreId) {
      requestSeriesList(genreId);
    }
  }, [dispatch, genreId]);

  useEffect(() => {
    const requestStories = async (aSeriesId) => {
      const { status, data } = await serviceApi.getStoriesBySeriesId(aSeriesId);
      if (status === 200) {
        setStories(data);
      } else {
        dispatch(
          alarm({ message: "회차를 얻는데 실패했습니다.", severity: "error" })
        );
      }
    };
    if (seriesId) {
      requestStories(seriesId);
    }
  }, [dispatch, seriesId]);
  const handleClickPublic = (toIsPublic) => {
    setIsPublic(toIsPublic);
  };
  const handleChangeStart = (value) => {
    setStart(value);
  };
  const handleChangeEnd = (value) => {
    setEnd(value);
  };
  const handleChangeLinkType = (event) => {
    setLinkType(event.target.value);
  };
  const handleChangeGenre = (event) => {
    const lGenreId = event.target.value;
    const genre = genreList.find((g) => g.id === lGenreId);
    if (genre) {
      setGenreId(genre.id);
    } else {
      setGenreId("");
    }

    setSeriesList([]);
    setSeriesId("");
    setStories([]);
    setStoryId("");
  };
  const handleChangeSeries = (event) => {
    const lSeriesId = event.target.value;
    const series = seriesList.find((g) => g.id === lSeriesId);
    if (series) {
      setSeriesId(series.id);
    } else {
      setSeriesId("");
    }
    setStories([]);
    setStoryId("");
  };

  const handleChangeStory = (event) => {
    const lStoryId = event.target.value;
    const story = stories.find((g) => g.id === lStoryId);
    if (story) {
      setStoryId(story.id);
    } else {
      setStoryId("");
    }
  };

  let orderNumber = null;
  if (orderNumberRef && orderNumberRef.current) {
    orderNumber = parseInt(orderNumberRef.current.value, 10) || null;
  }
  if (orderNumber === 0) {
    orderNumber = null;
  }

  let title = null;
  if (titleRef && titleRef.current) {
    title = titleRef.current.value;
  }

  const handleClickSaveStep1 = () => {
    const requestAddMainBanner = async (objParam) => {
      const { status, data } = await serviceApi.addMainBanner(objParam);
      if (status === 200) {
        setMainBannerId(data.id);
        setMainBanner(data);
        setActiveStep(1);
      } else {
        dispatch(
          alarm({ message: "저장하는데 실패했습니다.", severity: "error" })
        );
      }
    };
    const obj = { isPublic };
    if (start) {
      obj.startDate = start;
    }
    if (end) {
      obj.endDate = end;
    }
    let orderNumber = null;
    if (orderNumberRef && orderNumberRef.current) {
      orderNumber = parseInt(orderNumberRef.current.value, 10) || null;
    }
    if (orderNumber > 0) {
      obj.orderNumber = orderNumber;
    }
    if (title) {
      obj.title = title;
    }
    if (linkType) {
      obj.linkType = linkType;
    }

    if (typeof seriesId === "number") {
      obj.seriesId = seriesId;
    }
    if (typeof storyId === "number") {
      obj.storyId = storyId;
    }

    requestAddMainBanner(obj);
  };

  const handleClickSelectImageFile = () => {
    selectImageFile({ accept: "image/*" });
  };

  const handleClickUploadImageFile = () => {
    const requestForm = async (id, file) => {
      const fd = new FormData();
      fd.append("mainBannerId", mainBannerId);
      fd.append("mainBannerImageFile", file);
      const { status, data: newData } =
        await serviceApi.updateMainBannerImageFile(fd);
      if (status === 200) {
        setMainBanner(newData);
        setActiveStep(2);
      }
    };

    if (imageFile && imageFile.file) {
      requestForm(mainBannerId, imageFile.file);
    }
  };
  const getLinkTypeText = () => {
    if (mainBanner.linkType === "series") {
      return "메인";
    }
    if (mainBanner.linkType === "story") {
      return "작품";
    }
    return "";
  };

  const getLandingUrlText = () => {
    if (mainBanner.series) {
      if (mainBanner.series.genre) {
        if (mainBanner.story) {
          return `${mainBanner.series.genre.name} -  ${mainBanner.series.name} - ${mainBanner.story.name}`;
        } else {
          return `${mainBanner.series.genre.name} -  ${mainBanner.series.name}`;
        }
      }
    }

    return "";
  };

  return (
    <>
      <BannerStepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </BannerStepper>
      {activeStep === 0 ? (
        <Step1>
          <GuideDiv>배너 생성을 위한 정보를 입력해 주세요</GuideDiv>
          <table aria-label="메인 배너 생성하기">
            <tbody>
              <tr>
                <td>공개 여부</td>
                <td>
                  <Checkbox
                    checked={isPublic}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClickPublic(!isPublic);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  공개
                </td>
              </tr>
              <tr>
                <td>노출 기간</td>
                <td>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="시작"
                        value={start}
                        onChange={handleChangeStart}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ marginRight: "10px" }} />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="종료"
                        value={end}
                        onChange={handleChangeEnd}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </td>
              </tr>
              <tr>
                <td>노출순서</td>
                <td>
                  <TextField
                    type="number"
                    inputRef={orderNumberRef}
                    label="노출순서"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>배너 제목</td>
                <td>
                  <TextField
                    id="standard-basic"
                    label="배너 제목"
                    // variant="standard"
                    multiline
                    maxRows={4}
                    fullWidth
                    inputRef={titleRef}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="2">랜딩 URL</td>
                <td>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={linkType}
                      onChange={handleChangeLinkType}
                    >
                      <FormControlLabel
                        value="series"
                        control={<Radio />}
                        label="메인"
                      />
                      <FormControlLabel
                        value="story"
                        control={<Radio />}
                        label="작품"
                      />
                    </RadioGroup>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        장르
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={genreId}
                        onChange={handleChangeGenre}
                        autoWidth
                        label="장르"
                      >
                        {genreList.map((genre, index) => {
                          return (
                            <MenuItem value={genre.id} key={genre.name}>
                              {genre.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        작품
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={seriesId}
                        onChange={handleChangeSeries}
                        autoWidth
                        label="작품"
                      >
                        {seriesList.map((series, index) => {
                          return (
                            <MenuItem value={series.id} key={series.name}>
                              {series.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        회차
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={storyId}
                        onChange={handleChangeStory}
                        autoWidth
                        label="회차"
                      >
                        {stories.map((story, index) => {
                          return (
                            <MenuItem value={story.id} key={story.name}>
                              {story.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr></tr>
            </tfoot>
          </table>
          <ButtonContainer>
            <Button variant="outlined" onClick={() => onClose()}>
              취소
            </Button>
            <Button variant="contained" onClick={() => handleClickSaveStep1()}>
              저장
            </Button>
          </ButtonContainer>
        </Step1>
      ) : null}
      {activeStep === 1 ? (
        <>
          <GuideDiv>배너에 이미지를 적용해 주세요.</GuideDiv>
          <Button
            variant="contained"
            onClick={() => handleClickSelectImageFile()}
          >
            이미지 선택하기
          </Button>
          {imageFile ? (
            <div>
              <img
                src={imageFile.source}
                alt="preview"
                style={{ maxHeight: "150px" }}
              />
              <div>
                <span> 파일 이름: {imageFile.name} </span>
                <span> Size: {imageFile.size} </span>
              </div>
            </div>
          ) : null}
          <ButtonContainer>
            <Button variant="outlined" onClick={() => setActiveStep(2)}>
              건너뛰기
            </Button>
            <Button
              variant="contained"
              onClick={() => handleClickUploadImageFile()}
            >
              등록
            </Button>
          </ButtonContainer>
        </>
      ) : null}
      {activeStep === 2 ? (
        <Step1>
          <GuideDiv>서버에 등록된 정보입니다.</GuideDiv>
          <table>
            <tbody>
              <tr>
                <td>공개여부</td>
                <td>{mainBanner.isPublic ? "공개" : "비공개"}</td>
              </tr>
              <tr>
                <td>노출기간</td>
                <td>
                  {mainBanner.startDate ? mainBanner.startDate : null} ~{" "}
                  {mainBanner.endDate ? mainBanner.endDate : null}
                </td>
              </tr>
              <tr>
                <td>노출순서</td>
                <td>
                  {mainBanner.orderNumber ? mainBanner.orderNumber : null}
                </td>
              </tr>
              <tr>
                <td>제목</td>
                <td>{mainBanner.name}</td>
              </tr>
              <tr>
                <td>랜딩 URL</td>
                <td>
                  <span>
                    {getLinkTypeText()}&nbsp;:&nbsp;{getLandingUrlText()}
                  </span>
                </td>
              </tr>
              <tr>
                <td>이미지</td>
                <td>
                  {mainBanner.imgOriginalName ? (
                    <div>
                      <div>{mainBanner.imgOriginalName}</div>
                      <div>
                        <img
                          src={mainBanner.imgUrl}
                          alt="preview"
                          style={{ maxHeight: "150px" }}
                        />
                      </div>
                    </div>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
          <ButtonContainer>
            <Button variant="outlined" onClick={() => onClose()}>
              닫기
            </Button>
          </ButtonContainer>
        </Step1>
      ) : null}
    </>
  );
};
CreateMainBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreateMainBanner;
