import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import { Button, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TitleSimple from "../common/TitleSimple";
import {
  FormControl,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { tockCommonApi } from "../../api/tock";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  paper: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "530px",
    padding: "24px",
    outline: "none",
  },
  commentText: {
    marginTop: "15px",
    fontSize: "18px",
    textAlign: "center",
  },
}));

const ChangeSeriesCtgrModal = ({
  open,
  handleClose,
  checkItems,
  getClick,
  resetCheckItems,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const [openCpName, setOpenCpName] = useState(false);
  const [cpNames, setCpNames] = useState([]);
  const [cpId, setCpId] = useState(0);
  const [ctgr, setCtgr] = useState(-1);

  const onCtgrChange = (event) => {
    setCtgr(event.target.value);
    if (event.target.value === "0") {
      setOpenCpName(false);
      setCpId(0);
    } else if (event.target.value === "1") {
      setOpenCpName(true);
    } else if (event.target.value === "2") {
      setOpenCpName(true);
    }
  };

  const onCpNameChange = (event) => {
    setCpId(event.target.value);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await tockCommonApi.getCpNames();
      setCpNames(result.data);
      setLoading(false);
    };
    getData();
    return () => setLoading(false);
  }, []);

  const sendChange = async (event) => {
    event.preventDefault();

    let result;
    for (const val of checkItems) {
      result = await tockCommonApi.updateSeriesCtgr(ctgr, cpId, val);
    }
    if (result.status === 200) {
      handleClose(true);
      getClick();
      resetCheckItems();
    } else {
      toast.error(result.data.message, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  if (loading) {
    return (
      <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
        <div className={classes.paper}>
          <TitleSimple>작품 이동</TitleSimple>
        </div>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <TitleSimple>작품 이동</TitleSimple>
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontSize: "16px" }}>작품구분*</TableCell>
                <TableCell>
                  <FormControl variant="standard">
                    <RadioGroup row value={ctgr} onChange={onCtgrChange}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="일반 작품"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="계약 작품"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="오리지널"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
              {openCpName ? (
                <TableRow>
                  <TableCell sx={{ fontSize: "16px" }}>CP/작가*</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select value={cpId} onChange={onCpNameChange}>
                        {cpNames.map((data) => (
                          <MenuItem
                            key={data.id}
                            value={data.id}
                            sx={{ margin: "auto", justifyContent: "center" }}
                          >
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </div>
        <Typography
          style={{
            marginTop: "30px",
            fontSize: "16px",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          해당 작품을 이동하시겠습니까?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F0A619",
              color: "#FFF",
              width: "100px",
            }}
            onClick={sendChange}
          >
            수정
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ChangeSeriesCtgrModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  checkItems: PropTypes.array.isRequired,
  getClick: PropTypes.func.isRequired,
  resetCheckItems: PropTypes.func.isRequired,
};

export default ChangeSeriesCtgrModal;
