import { createSlice } from "@reduxjs/toolkit";

// severity : error (red), warning (oringe), info (blue), success (green)
const bottomSnackbarReducer = createSlice({
  name: "bottomSnackbar",
  initialState: {
    open: false,
    message: "",
    severity: "info",
  },
  reducers: {
    alarm: (state, action) => {
      const severity =
        action.payload.severity === undefined
          ? "info"
          : action.payload.severity;

      return {
        open: true,
        message: action.payload.message,
        severity,
      };
    },
    offAlarm: (state) => {
      return {
        open: false,
        message: "",
        severity: "info",
      };
    },
  },
});

export const { alarm, offAlarm } = bottomSnackbarReducer.actions;

export default bottomSnackbarReducer.reducer;
