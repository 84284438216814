import React from "react";
import { Drawer } from "rsuite";
import ReservedPush from "./ReservedPush";
import PromptPush from "./PromptPush";

const PushCheckDrawer = ({
  pushId,
  pushStatus,
  openDrawer,
  setOpenDrawer,
  onCloseCheckDrawer,
  getData,
}) => {
  return (
    <>
      <Drawer
        size="sm"
        open={openDrawer}
        onClose={onCloseCheckDrawer}
        backdrop="static"
      >
        <Drawer.Body>
          {pushStatus === 1 ? (
            <ReservedPush
              pushId={pushId}
              setOpenCheckDrawer={setOpenDrawer}
              onCloseCheckDrawer={onCloseCheckDrawer}
              getData={getData}
            />
          ) : (
            <PromptPush pushId={pushId} setOpenCheckDrawer={setOpenDrawer} />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default PushCheckDrawer;
