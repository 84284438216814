import React, { useEffect } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import Loading from "../common/Loading";
import { Typography } from "@mui/material";
import CpPayDetail from "../payments/UseTokenDetail_CpPayDetail";
import { useTokensApi } from "../../api/payments";
import CpUseTokenList from "../payments/UseTokenDetail_CpUseTokenList";
import { Button } from "rsuite";
import CpUseTokenCancelList from "../payments/UseTokenDetail_CpUseTokenCancelList";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    //border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1000px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const UseTokenCpListModal = ({ open, handleClose, clickRow }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [useTokenData, setUseTokenData] = useState([]); //결제상태->정상
  const [useTokenCancelData, setUseTokenCancelData] = useState([]); //결제상태->취소

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  useEffect(() => {
    const getData = async () => {
      if (clickRow.cpId !== 0 && clickRow.payClCd === 0) {
        setLoading(true);
        const { data, status } = await useTokensApi.getUseTokenList(
          JSON.stringify(clickRow)
        );
        if (status === 200) {
          setUseTokenData(data);
        } else {
          toast.error("네트워크 에러", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
        setLoading(false);
      } else if (
        clickRow.cpId !== 0 &&
        (clickRow.payClCd === 1 || clickRow.payClCd === 2)
      ) {
        setLoading(true);
        const { data, status } = await useTokensApi.getUseTokenCancelList(
          JSON.stringify(clickRow)
        );
        if (status === 200) {
          setUseTokenCancelData(data);
        } else {
          toast.error("네트워크 에러", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
        setLoading(false);
      }
    };
    if (open) {
      getData();
    }
  }, [clickRow, open]);

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.paper}>
          <Typography className={classes.title}>
            상세 내역 - {clickRow ? clickRow.cpName : ""}
          </Typography>
          <CpPayDetail data={clickRow} />
          {clickRow && clickRow.payClCd === 0 ? (
            <CpUseTokenList list={useTokenData} />
          ) : (
            <CpUseTokenCancelList list={useTokenCancelData} />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              style={{ backgroundColor: "grey", color: "white" }}
              onClick={handleCloseModal}
            >
              확인
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

UseTokenCpListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  clickRow: PropTypes.object,
};

export default UseTokenCpListModal;
