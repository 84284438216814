import React, { useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { Box, Checkbox, FormGroup, styled } from "@material-ui/core";
import { useFileUpload } from "use-file-upload";
import toast from "react-hot-toast";
import TitleSimple from "../common/TitleSimple";
import { Button, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PushLink from "../push/PushLink_RegisterPush";
import { eventPopupApi } from "../../api/global";
import { useEffect } from "react";
import SelectItems3 from "../common/SelectItems3";

const AddEventPopupModal = ({ open, close, data }) => {
  const inputTitle = useRef(null);
  const inputOrder = useRef(null);
  const inputPoint = useRef(null);
  const [startAt, setStartAt] = React.useState(
    data ? moment(data.startAt) : moment()
  );
  const [endAt, setEndAt] = React.useState(
    data ? moment(data.endAt) : moment()
  );

  const [tokenValidateStartAt, setTokenValidateStartAt] = useState(
    data ? moment(data.tokenValidateStartAt) : moment()
  );

  const [tokenValidateEndAt, setTokenValidateEndAt] = useState(
    data ? moment(data.tokenValidateEndAt) : moment()
  );

  const [linkValue, setLinkValue] = useState(data ? data.linkType : "home");

  const [popupTypeValue, setPopupTypeValue] = useState(
    data?.type ? data.type : "normal"
  );
  const handleChange = (event) => {
    if (event.target.value === "normal") {
    }
    if (event.target.value === "point") {
    }
    setPopupTypeValue(event.target.value);
  };
  const [pushLink, setPushLink] = useState({
    seriesId: data?.seriesId,
    storyId: data?.storyId,
  });
  // '링크' 메뉴에 대한 라디오버튼 선택 이벤트
  const onLinkValueChange = (event) => {
    setLinkValue(event.target.value);
  };

  const handleStartAtChange = (newValue) => {
    setStartAt(newValue);
  };
  const handleEndAtChange = (newValue) => {
    setEndAt(newValue);
  };

  const handlePaymentIosChange = (newValue) => {
    setPaymentIosValue(newValue);
  };

  const handlePaymentAndChange = (newValue) => {
    setPaymentAndValue(newValue);
  };

  const onCheckedChange = (newValue) => {
    if(newValue === true){
      setDoublePayYn("N");
    }else{
      setDoublePayYn("Y");
    }
  };

  const [imgUrl, setImgUrl] = useState(""); //기존 이미지 파일
  const [imgFile, selectImgFile] = useFileUpload(null); //수정 이미지 파일

  const [doublePayYn, setDoublePayYn] = useState("Y");
  const [paymentIosValue, setPaymentIosValue] = useState(data?.iosPrdCd);
  const [paymentAndValue, setPaymentAndValue] = useState(data?.andPrdCd);
  const [paymentsIosCodeList, setPaymentsIosCodeList] = useState(
    [{ prdCd: data?.iosPrdCd, device: "IOS" }],
  );

  const [paymentsAndCodeList, setPaymentsAndCodeList] = useState(
    [{ prdCd: data?.andPrdCd, device: "AND"}],
  );

  const paymentsCodeList = async (device) => {
    const { status, data: newData } = await eventPopupApi.paymentsCodeList(device);

    if (status === 200) {
      if(device === "IOS"){
        setPaymentsIosCodeList(newData);
      }else if(device === "AND"){
        setPaymentsAndCodeList(newData);
      }
    }
  };

  const onSend = async () => {
    if (!inputTitle.current.value) {
      toast.error("제목을 입력해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }

    if (!inputOrder.current.value) {
      toast.error("순서를 기입해주세요", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    if (popupTypeValue === "point" || popupTypeValue === "point2") {
      if (!inputPoint.current.value) {
        toast.error("포인트를 기입해주세요.", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return;
      }
    }

    const fd = new FormData();
    fd.append("title", inputTitle.current.value);
    fd.append("type", popupTypeValue);
    fd.append("linkType", linkValue);
    if (pushLink.seriesId) {
      fd.append("seriesId", pushLink.seriesId);
    }
    if (pushLink.storyId) {
      fd.append("storyId", pushLink.storyId);
    }
    fd.append("startAt", startAt.format("yyyy-MM-DD"));
    fd.append("endAt", endAt.format("yyyy-MM-DD"));
    fd.append("order", inputOrder.current.value);
    
    if (popupTypeValue === "point" || popupTypeValue === "point2") {
      fd.append("point", inputPoint.current.value);
      fd.append(
        "tokenValidateStartAt",
        tokenValidateStartAt.format("yyyy-MM-DD")
      );
      fd.append("tokenValidateEndAt", tokenValidateEndAt.format("yyyy-MM-DD"));

    }else if(popupTypeValue === "sale"){
      fd.append("tokenValidateStartAt", startAt.format("yyyy-MM-DD"));
      fd.append("tokenValidateEndAt", endAt.format("yyyy-MM-DD"));
    }

    if (imgFile) {
      fd.append("img", imgFile.file);
    }

    if(paymentIosValue){
      fd.append("iosPrdCd", paymentIosValue);
    }

    if(paymentAndValue){
      fd.append("andPrdCd", paymentAndValue);
    }
    if (doublePayYn) {
      fd.append("doublePayYn", doublePayYn);
    }

    if (data) {
      const result = await eventPopupApi.update(data.id, fd);
      if (result.status !== 200) {
        console.log("에러");
      }
    } else {
      const result = await eventPopupApi.add(fd);
      if (result.status !== 200) {
        console.log("에러");
      }
    }

    close(true);
  };

  const onDelete = async (id) => {
    await eventPopupApi.delete(id);
    close(true);
  };

  useEffect(() => {
    if (open) {
      if (data) {
        setImgUrl(data.url);
      }

      if (imgFile && imgFile.source != undefined) {
        imgFile.source = undefined;
        imgFile.name = undefined;
        imgFile.size = undefined;
        imgFile.file = undefined;
      }

      paymentsCodeList("IOS");
      paymentsCodeList("AND");
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.38)",
          backgroundColor: "white",
          width: "700px",
          maxHeight: "100%",
          overflow: "auto",
          padding: "24px",
          outline: "none",
        }}
      >
        <TitleSimple>이벤트 팝업 추가</TitleSimple>
        <TextField
          id="title"
          label="title"
          fullWidth
          inputRef={inputTitle}
          defaultValue={data?.title}
        />
        <Box sx={{ display: "flex" , marginTop:"20px"}}>
          <Box sx={{ marginRight: "30px" }}>
            <Button
              variant="contained"
              component="label"
              onClick={() => {
                selectImgFile({ accept: "image/*" });
              }}
            >
              이미지 등록
            </Button>
          </Box>
          {imgUrl && (!imgFile || (imgFile && imgFile.source == undefined)) && (
            <div>
              <img src={imgUrl} alt="preview" style={{ maxHeight: "150px" }} />
            </div>
          )}
          {imgFile && imgFile.source != undefined && (
            <div>
              <img
                src={imgFile.source}
                alt="preview"
                style={{ maxHeight: "150px" }}
              />
              <div>
                <span> 파일 이름: {imgFile.name} </span>
                <span> Size: {imgFile.size} </span>
              </div>
            </div>
          )}
        </Box>
        <Box sx = {{marginTop:"20px"}}> 
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">팝업 구분</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={popupTypeValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label="일반팝업"
              />
              <FormControlLabel
                value="point"
                control={<Radio />}
                label="포인트지급팝업"
              />
              <FormControlLabel
                value="point2"
                control={<Radio />}
                label="포인트지급팝업(중복불가)"
              />
              <FormControlLabel
                value="sale"
                control={<Radio />}
                label="할인팝업"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {(popupTypeValue === "sale") && (
          <Box sx = {{marginTop:"20px"}}> 
            <Typography>결제코드(IOS)</Typography>
            <SelectItems3
              items={paymentsIosCodeList}
              defaultItem={paymentsIosCodeList[0].prdCd}
              onChange={handlePaymentIosChange}
            />
          </Box>
        )}
        {(popupTypeValue === "sale") && (
          <Box sx = {{marginTop:"20px"}}> 
            <Typography>결제코드(AND)</Typography>
            <SelectItems3
              items={paymentsAndCodeList}
              defaultItem={paymentsAndCodeList[0].prdCd}
              onChange={handlePaymentAndChange}
            />
          </Box>
        )}
        {(popupTypeValue === "sale") && (
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox 
                  style={{color:"#1976d2"}}
                  onChange={(e) => onCheckedChange(e.target.checked)}
                  defaultChecked = {data?.doublePayYn === "N" ? true : false}
                />
              }
              label="중복결제 허용하지 않음"
            />
          </FormControl>
        )}
        
        <Box sx={{ display: "flex", marginTop:"20px"}}>
          {(popupTypeValue === "point" || popupTypeValue === "point2") && (
            <Box sx = {{marginRight:"20px"}}>
              <Typography>포인트</Typography>
              <TextField
                type="number"
                inputRef={inputPoint}
                defaultValue={data?.point}
              />
            </Box>
          )}
          <Box>
            <Typography>노출 순서</Typography>
            <TextField
              type="number"
              inputRef={inputOrder}
              defaultValue={data?.order}
            />
          </Box>
        </Box>
        {(popupTypeValue === "point" || popupTypeValue === "point2") && (
          <Box sx = {{marginTop:"20px"}}>
            <Typography>토큰 사용기간</Typography>
            <Box sx={{ marginTop: "10px" }}>
              <DesktopDatePicker
                inputFormat="yyyy-MM-DD"
                defaultValue={data?.tokenValidateStartAt}
                value={tokenValidateStartAt}
                onChange={(newValue) => {
                  setTokenValidateStartAt(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <DesktopDatePicker
                inputFormat="yyyy-MM-DD"
                defaultValue={data?.tokenValidateEndAt}
                value={tokenValidateEndAt}
                onChange={(newValue) => {
                  setTokenValidateEndAt(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ marginTop: "20px" }}>
          <Typography>게시기간</Typography>
          <Box>
            <DesktopDatePicker
              inputFormat="yyyy-MM-DD"
              defaultValue={data?.startAt}
              value={startAt}
              onChange={handleStartAtChange}
              renderInput={(params) => <TextField {...params} />}
            /> 
            <DesktopDatePicker
              inputFormat="yyyy-MM-DD"
              defaultValue={data?.endAt}
              value={endAt}
              onChange={handleEndAtChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>
        <Box marginTop="20px">
          <Typography>링크</Typography>
          <Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="home"
                name="radio-buttons-group"
                value={linkValue}
                onChange={onLinkValueChange}
              >
                <FormControlLabel
                  value="home"
                  control={<Radio size="small" />}
                  label="홈"
                  sx={{ height: "44px" }}
                />
                <FormControlLabel
                  value="notice"
                  control={<Radio size="small" />}
                  label="공지사항"
                  sx={{ height: "44px" }}
                />
                <FormControlLabel
                  value="contentsMain"
                  control={<Radio size="small" />}
                  label="작품메인"
                  sx={{ height: "44px" }}
                />
                <FormControlLabel
                  value="contentsEach"
                  control={<Radio size="small" />}
                  label="작품(회차)"
                  sx={{ height: "44px" }}
                />
              </RadioGroup>
            </FormControl>
            {(linkValue !== "home") && (
              <PushLink
                linkValue={linkValue}
                setPushLink={setPushLink}
                defaltSeriesId={data?.seriesId}
                defaltStoryId={data?.storyId}
              />
            )}
          </Box>
        </Box>
        <Button variant="contained" component="label" onClick={onSend}>
          {data && "수정"}
          {data === null && "확인"}
        </Button>
        {data && (
          <Button
            variant="contained"
            component="label"
            onClick={() => {
              onDelete(data.id);
            }}
            color="error"
            sx={{ marginLeft: "10px" }}
          >
            삭제
          </Button>
        )}
      </Box>
    </Modal>
  );
};

const Select = styled("select")({
  display: "block",
  width: "100%",
  border: "1px solid #e0e0e0",
  height: "60px",
  borderRadius: "5px",
});

export default AddEventPopupModal;
