const blackColor = '#000';
const whiteColor = '#FFF';
const defaultFont = {
  fontFamily: 'Roboto',
  fontWeight: '300',
  lineHeight: '1.5em',
  color: whiteColor,
};
const drawerWidth = 274;

export { blackColor, whiteColor, defaultFont, drawerWidth };
