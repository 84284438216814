import React, { useState, useRef } from "react";
import { styled } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "rsuite";
import toast from "react-hot-toast";
import { blackUserApi } from "../api/global";
import { TextField } from "@mui/material";

function BlackUserModiPopover({
  checkedUser,
  handleCloseModify,
  getBlackUsersDefault,
  setCheckedUser,
  user,
}) {
  // 날짜 입력받은 state
  const [dates, setDates] = useState({
    startAt: moment(user.startAt).format("YYYY-MM-DD"),
    endAt: moment(user.endAt).format("YYYY-MM-DD"),
  });
  const inputReason = useRef(null);
  const inputLimitType = useRef(null);

  // '기간' change event
  const onDateChange = (event) => {
    if (event.target.name === "startAt") {
      setDates({ ...dates, startAt: event.target.value });
    } else if (event.target.name === "endAt") {
      setDates({ ...dates, endAt: event.target.value });
    }
  };

  // '블랙리스트 수정' 버튼 이벤트
  const onModifyBlackUser = async (event) => {
    event.preventDefault();

    try {
      const { status } = await blackUserApi.blackUserModify(checkedUser[0].id, {
        limitType: inputLimitType.current.value,
        reason: inputReason.current.value,
        startAt: dates.startAt,
        endAt: dates.endAt,
      });
      if (status === 200) {
        getBlackUsersDefault();
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      handleCloseModify();
      setCheckedUser([]);
    }
  };

  return (
    <Container>
      <h4 style={{ marginBottom: "30px" }}>블랙리스트 등록 수정</h4>
      <Inner>
        <Cell1 style={{ borderTop: 0, borderBottom: 0 }}>적용기간*</Cell1>
        <Cell2 style={{ borderTop: 0, borderBottom: 0 }}>
          <div
            style={{
              paddingLeft: "10px",
              position: "relative",
              zIndex: "10000 !important",
            }}
          >
            <Input
              type="date"
              name="startAt"
              style={{ width: "110px" }}
              value={dates.startAt}
              onChange={onDateChange}
            />
            <span>&nbsp;&nbsp;~&nbsp;&nbsp;</span>
            <Input
              type="date"
              name="endAt"
              style={{ width: "110px" }}
              value={dates.endAt}
              onChange={onDateChange}
            />
          </div>
        </Cell2>
      </Inner>
      <Inner>
        <Cell1>기능제한</Cell1>
        <Cell2 style={{ paddingLeft: "1px" }}>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={user.limitType}
              inputRef={inputLimitType}
            >
              <MenuItem value="1">댓글 등록</MenuItem>
              <MenuItem value="2">작품 등록</MenuItem>
              <MenuItem value="3">계정 정지</MenuItem>
            </Select>
          </FormControl>
        </Cell2>
      </Inner>
      <Inner>
        <Cell1>사유</Cell1>
        <Cell2>
          <TextField
            defaultValue={user.reason}
            name="reason"
            autoComplete="off"
            required
            placeholder="사유를 입력하세요."
            inputRef={inputReason}
          />
        </Cell2>
      </Inner>
      <Inner style={{ margin: "20px 0" }}>
        <Ptag>
          <span style={{ color: "orange" }}>
            {checkedUser.map((elm) => {
              return <span key={elm.id}>{elm.id}&nbsp;</span>;
            })}
          </span>
          의 블랙리스트 등록을 수정하시겠습니까?
        </Ptag>
      </Inner>
      <Inner style={{ justifyContent: "center" }}>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={() => {
            handleCloseModify();
          }}
        >
          취소{" "}
        </Button>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onModifyBlackUser}
        >
          수정{" "}
        </Button>
      </Inner>
    </Container>
  );
}

BlackUserModiPopover.propTypes = {
  checkedUser: PropTypes.array,
  handleCloseModify: PropTypes.func.isRequired,
  getBlackUsersDefault: PropTypes.func.isRequired,
  setCheckedUser: PropTypes.func.isRequired,
};

const Container = styled("div")({
  position: "relative",
  padding: "20px",
  width: "600px",
  height: "400px",
});
const Inner = styled("div")({
  position: "relative",
  display: "flex",
});
const Cell1 = styled("div")({
  border: "1px solid gray",
  borderRight: "0",
  backgroundColor: "lightgray",
  width: "100px",
  height: "50px",
  paddingLeft: "10px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Cell2 = styled("div")({
  border: "1px solid gray",
  height: "50px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexGrow: 1,
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Input = styled("input")({
  width: "40px",
  height: "35px",
  border: "1px solid #f2f2f2",
  borderRadius: "3px",
  backgroundColor: "white",
});
const Ptag = styled("p")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  font: '400 16px "Roboto","Helvetica","Arial", sans-serif',
});

export default BlackUserModiPopover;
