import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import toast from "react-hot-toast";
import Loading from "../common/Loading";
import { Button, styled } from "@material-ui/core";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import TablePaginationActions from "../push/TablePaginationActions";
import ChangeSeriesModel from "../modal/ChangeSeriesModel";
import ChangeSeriesCtgrModal from "../modal/ChangeSeriesCtgrModal";
import StoryListModalEdit from "../modal/StoryListModalEdit";
import UserInfoModal2 from "../modal/UserInfoModal2";

const ContentsList = ({ loading, searchedList, getClick }) => {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchedList.length) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    setCheckItems([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCheckItems([]);
  };

  //Modal
  const [openStoryList, setOpenStoryList] = useState(false);
  const [seriesId, setSeriesId] = useState(0);
  const [seriesId2, setSeriesId2] = useState(0);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [openChangeContent, setOpenChangeContent] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);
  const [openChangeCtgr, setOpenChangeCtgr] = useState(false);

  //Check box
  const [checkItems, setCheckItems] = useState([]);

  const resetCheckItems = () => {
    setCheckItems([]);
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      const startRow = page * rowsPerPage;
      const endRow = (page + 1) * rowsPerPage;
      const idArray = [];
      for (let i = startRow; i < endRow; i++) {
        idArray.push(searchedList[i].id);
      }
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  return (
    <ListContainer>
      <Inner>
        {loading ? (
          <div style={{ height: "300px" }}>
            <Loading />
          </div>
        ) : (
          <>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontSize: "15px",
                margin: "0px 12px",
                paddingRight: "20px",
                marginBottom: "12px",
                fontWeight: 500,
                letterSpacing: "-1px",
                lineHeight: "1.6",
                textAlign: "right",
              }}
            >
              전체{" "}
              <span style={{ color: "orange" }}>{searchedList.length}</span> 건
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#e0e0e0",
                      height: "60px",
                    }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{ width: "2%" }}
                      align="center"
                    >
                      <Checkbox
                        color="primary"
                        onChange={(e) => handleAllCheck(e.target.checked)}
                        checked={
                          checkItems.length === rowsPerPage ? true : false
                        }
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "10%",
                      }}
                      align="center"
                    >
                      작품구분
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "10%",
                      }}
                      align="center"
                    >
                      CP/작가명
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "4%" }}
                      align="center"
                    >
                      ID
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "10%",
                      }}
                      align="center"
                    >
                      작품이름
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "12%",
                      }}
                      align="center"
                    >
                      작품소개
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "9%" }}
                      align="center"
                    >
                      스토리목록
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "9%" }}
                      align="center"
                    >
                      작가필명
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "7%" }}
                      align="center"
                    >
                      장르
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        width: "10%",
                      }}
                      align="center"
                    >
                      커버이미지
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "9%" }}
                      align="center"
                    >
                      작품타입
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "8%" }}
                      align="center"
                    >
                      등록일
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "15px", width: "8%" }}
                      align="center"
                    >
                      수정
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? searchedList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : searchedList
                  ).map((series) => (
                    <TableRow key={series.id} sx={{ height: "50px" }}>
                      <TableCell align="center">
                        <Checkbox
                          color="primary"
                          onChange={(e) =>
                            handleSingleCheck(e.target.checked, series.id)
                          }
                          checked={
                            checkItems.includes(series.id) ? true : false
                          }
                        />
                      </TableCell>
                      {series.seriesCtgr === 0 ? (
                        <TableCell align="center">일반 작품</TableCell>
                      ) : series.seriesCtgr === 1 ? (
                        <TableCell align="center">계약 작품</TableCell>
                      ) : (
                        <TableCell align="center">교원 오리지널</TableCell>
                      )}
                      <TableCell align="center">
                        {(series.seriesCtgr === 1 || series.seriesCtgr === 2)
                          && series.cpCompany !== null && series.cpCompany !== undefined && series.cpCompany !== ''
                          ? series.cpCompany.name 
                          : "-"}
                      </TableCell>
                      <TableCell align="center">{series.id}</TableCell>
                      <TableCell align="center">{series.name}</TableCell>
                      <TableCell align="center">
                        {/* <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      > */}
                        {series.introduce}
                        {/* </div> */}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textTransform: "none" }}
                          onClick={() => {
                            setSeriesId2(series.id);
                            setOpenStoryList(true);
                          }}
                        >
                          상세
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => {
                            setUserInfo(series.users);
                            setSeriesId(series.id);
                            setOpenUserInfo(true);
                          }}
                        >
                          {series.users.nickname}
                        </Button>
                      </TableCell>
                      <TableCell align="center">{series.genre.name}</TableCell>
                      <TableCell align="center">
                        <img
                          src={series.coverImgUrl}
                          alt="preview"
                          style={{ maxHeight: "50px" }}
                        />
                      </TableCell>
                      <TableCell align="center">{series.type}</TableCell>
                      <TableCell align="center">
                        {moment(series.createdAt).format("YY-MM-DD")}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSeriesId(series.id);
                            setOpenChangeContent(true);
                          }}
                        >
                          수정
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                    <TableCell colSpan={3}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (checkItems.length === 0) {
                            toast.error("작품을 선택해주세요", {
                              style: {
                                borderRadius: "10px",
                                background: "#333",
                                color: "#fff",
                              },
                            });
                          } else {
                            setOpenChangeCtgr(true);
                          }
                        }}
                      >
                        작품이동
                      </Button>
                    </TableCell>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={10}
                      count={searchedList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <StoryListModalEdit
              open={openStoryList}
              handleClose={() => setOpenStoryList(false)}
              seriesId={seriesId2}
            />
            <UserInfoModal2
              open={openUserInfo}
              handleClose={() => setOpenUserInfo(false)}
              userInfo={userInfo}
              seriesId={seriesId}
            />
            <ChangeSeriesModel
              key={openChangeContent}
              open={openChangeContent}
              handleClose={(result) => {
                if (result) {
                  setReloadCount(reloadCount + 1);
                }
                setOpenChangeContent(false);
              }}
              seriesId={seriesId}
            />
            <ChangeSeriesCtgrModal
              open={openChangeCtgr}
              checkItems={checkItems}
              handleClose={(result) => {
                setOpenChangeCtgr(false);
                if (result) {
                  setReloadCount(reloadCount + 1);
                }
              }}
              getClick={getClick}
              resetCheckItems={resetCheckItems}
            />
          </>
        )}
      </Inner>
    </ListContainer>
  );
};

ContentsList.defaultProps = {
  searchedList: {
    id: "",
    name: "",
    introduce: "",
    cpName: "",
    nickname: "",
    genre: "",
    coverImgUrl: "",
    type: "",
    createdAt: "",
  },
};

ContentsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  searchedList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      introduce: PropTypes.string,
      cpName: PropTypes.string,
      nickname: PropTypes.string,
      coverImgUrl: PropTypes.string,
      type: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
  getClick: PropTypes.func.isRequired,
};

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "block",
  justifyContent: "center",
  alignItems: "center",
});

export default ContentsList;
