import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import Loading from "../common/Loading";
import { tockCommonApi } from "../../api/tock";
import { Paper, Typography, TableContainer } from "@mui/material";
import SeriesDetailTable from "../contents/SeriesDetailTable";
import StoryListTable from "../contents/StoryListTable";
import StorySearchBar from "../contents/StorySearchBar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflow: "auto",
  },
  paper: {
    //border: "1px solid rgba(0, 0, 0, 0.38)",
    backgroundColor: theme.palette.background.paper,
    width: "1500px",
    padding: "24px",
    outline: "none",
  },
  title: {
    backgroundColor: "#5F5D5Eff",
    fontSize: "20px",
    color: "white",
    margin: "0px 12px",
    padding: "15px 15px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    fontFamily: "NotoSansKR",
  },
  cancelButton: {
    border: "1px solid #5F5D5Eff",
    width: "80px",
    height: "40px",
    marginRight: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
  },
  editButton: {
    backgroundColor: "#f57c00",
    width: "80px",
    height: "40px",
    marginLeft: "10px",
    fontFamily: "NotoSansKR",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "-1px",
    lineHeight: "1.6",
    color: "white",
  },
}));

const StoryListModalEdit = ({ open, handleClose, seriesId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [seriesData, setSeriesData] = useState([]); //작품 상세 정보
  const [genreData, setGenreData] = useState("");
  const [data, setData] = useState([]); //회차 리스트 정보
  const [searchedList, setSearchedList] = useState([]);

  const handleCloseModal = (result) => {
    handleClose(result === true);
  };

  const getStoryList = async (keyword) => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const name = data[i].name;
      const flag = name.includes(keyword);
      if (flag === true) {
        result.push(data[i]);
      }
    }
    setSearchedList(result);
  };

  const getData = async () => {
    if (seriesId !== 0) {
      setLoading(true);
      const result = await tockCommonApi.getDetailSeries(seriesId);
      if (result.status === 200) {
        console.log(result.data)
        setData(result.data.stories);
        setSeriesData(result.data);
        setSearchedList(result.data.stories);
        setGenreData(result.data.genre.name);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [seriesId]);

  if (loading) {
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <Loading />
    </Modal>;
  }

  return (
    <Modal open={open} onClose={handleCloseModal} className={classes.modal}>
      <div className={classes.paper}>
        <Typography className={classes.title}>
          스토리 목록 - {seriesData.name}({seriesData.id})
        </Typography>
        <StorySearchBar getStoryList={getStoryList} />
        <TableContainer component={Paper}>
          <SeriesDetailTable seriesData={seriesData} genreData={genreData} />
        </TableContainer>
        <StoryListTable
          seriesId={seriesId}
          storyData={searchedList}
          handleClose={handleClose}
          getData={getData}
        />
      </div>
    </Modal>
  );
};

StoryListModalEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default StoryListModalEdit;
