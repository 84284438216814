import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Users from "../screens/Users";
import NormalDeletedContents from "../screens/NormalDeletedContents";
import Meta from "../screens/Meta";
import MetaCharacter from "../screens/MetaCharacter";
import MetaEffectSound from "../screens/MetaEffectSound";
import MetaBackground from "../screens/MetaBackground";
import MetaBGM from "../screens/MetaBGM";
import MetaInteractive from "../screens/MetaInteractive";
import Recommended from "../screens/Recommended";
import MainBanner from "../screens/MainBanner";
import StoryReplyReport from "../screens/StoryReplyReport";
import ContentReport from "../screens/ContentReport";
import UserRanking from "../screens/UserRanking";
import Point from "../screens/Point";
import NoticeContents from "../screens/NoticeContents";
import UserResource from "../screens/UserResource";
import Push from "../screens/Push";
import Login from "../screens/Login";
import PreviewVideo from "../screens/PreviewVideo";
import { tokenLogin } from "../redux/userSlice";
import Loading from "./common/Loading";
import UsersAdmin from "../screens/UsersAdmin";
import RegisterPush from "./push/RegisterPush";
import Devices from "../screens/Devices";
import BlackUsers from "../screens/BlackUsers";
import WithdrawalUsers from "../screens/WithdrawalUsers";
import StatisticsContents from "../screens/StatisticsContents";
import StatisticsAuthor from "../screens/StatisticsAuthor";
import StatisticsReply from "../screens/StatisticsReply";
import MetaBookCover from "../screens/MetaBookCover";
import PaymentList from "../screens/PaymentList";
import Contents from "../screens/Contents";
import UseTokens from "../screens/UseTokens";
import UseTokenDetail from "../screens/UseTokenDetail";
import ManageCp from "../screens/ManageCp";
import EventPopup from "../screens/EventPopup";
import RegisterTokens from "../screens/RegisterTokens";
import Settle from "../screens/Settle";
import ServiceNotice from "../screens/ServiceNotice";
import AddServiceNotice from "../screens/AddServiceNotice";
import UpdateServiceNotice from "../screens/UpdateServiceNotice";
import MainSeries from "../screens/MainSeries";
import MainAuthor from "../screens/MainAuthor";
import PlatformVersion from "../screens/PlatformVersion";

const LoggedInRoutes = () => (
  <Switch>
    <Route path="/users/admin" exact component={UsersAdmin} />
    <Route path="/users/withdrawal" exact component={WithdrawalUsers} />
    <Route path="/device" exact component={Devices} />
    <Route path="/users" exact component={Users} />
    <Route path="/users/blackusers" exact component={BlackUsers} />
    {/* <Route path="/contents/normal" exact component={NormalContents} /> */}
    <Route
      path="/contents/normal/deleted"
      exact
      component={NormalDeletedContents}
    />
    <Route path="/contents/notice" exact component={NoticeContents} />
    <Route path="/contents/list" exact component={Contents} />
    <Route path="/contents/meta" exact component={Meta} />
    <Route path="/contents/meta/character" exact component={MetaCharacter} />
    <Route path="/contents/meta/bgm" exact component={MetaBGM} />
    <Route path="/contents/meta/sound" exact component={MetaEffectSound} />
    <Route path="/contents/meta/background" exact component={MetaBackground} />
    <Route path="/contents/meta/bookCover" exact component={MetaBookCover} />
    <Route
      path="/contents/meta/interactive"
      exact
      component={MetaInteractive}
    />
    <Route path="/service/notice" exact component={ServiceNotice} />
    <Route path="/service/regist/notice" exact component={AddServiceNotice} />
    <Route path="/service/detail/notice" exact component={UpdateServiceNotice} />
    <Route path="/contents/report" exact component={ContentReport} />
    <Route path="/service/preview-video" exact component={PreviewVideo} />
    <Route path="/exposur/recommended" exact component={Recommended} />
    <Route path="/main/banner" exact component={MainBanner} />
    <Route path="/story/reply/report" exact component={StoryReplyReport} />
    <Route path="/userResource" exact component={UserResource} />
    <Route path="/userRanking" exact component={UserRanking} />
    <Route path="/eventPopup" exact component={EventPopup} />
    <Route path="/point" exact component={Point} />
    <Route path="/push" exact component={Push} />
    <Route path="/push/register" exact component={RegisterPush} />
    <Route path="/statistics/contents" exact component={StatisticsContents} />
    <Route path="/statistics/authors" exact component={StatisticsAuthor} />
    <Route path="/payments/list" exact component={PaymentList} />
    <Route path="/useTokens/list" exact component={UseTokens} />
    <Route path="/useTokens/list/:id" exact component={UseTokenDetail} />
    <Route path="/payments/registerTokens" exact component={RegisterTokens} />
    <Route path="/payments/manageCp" exact component={ManageCp} />
    <Route path="/statistics/reply" exact component={StatisticsReply} />
    <Route path="/useTokens/settle" exact component={Settle} />
    <Route path="/mainSeries" exact component={MainSeries} />
    <Route path="/mainAuthor" exact component={MainAuthor} />
    <Route path="/PlatformVersion" exact component={PlatformVersion} />
    <Redirect from="*" to="/users" />
  </Switch>
);

const LoggedOutRoutes = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Redirect from="*" to="/login" />
  </Switch>
);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { isLoggedIn, isCheckToken } = useSelector((state) => state.user);
  const isTokenLogin = isLoggedIn === false && isCheckToken === false;
  const dispatch = useDispatch();
  const ComponentDidMount = () => {
    if (isTokenLogin) {
      dispatch(tokenLogin());
    }
  };

  useEffect(ComponentDidMount, [dispatch, isTokenLogin]);

  return (
    <>
      {isTokenLogin ? (
        <Loading />
      ) : (
        <Router>{isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}</Router>
      )}
    </>
  );
};
