import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "48px",
  },
  titleText: {
    margin: "0px 12px",
    fontSize: "20px",
    fontWeight: 700,
    letterSpacing: "0.15px",
    lineHeight: "32px",
    textOverflow: "clip",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
}));

function TitleSimple({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.titleText}>{children}</Typography>
    </div>
  );
}

TitleSimple.propTypes = {
  children: PropTypes.string.isRequired,
};

export default TitleSimple;
