import React, { useRef } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const NumberInputModal = ({
  open,
  onClose,
  onOK,
  defaultNumber,
  title,
  context,
}) => {
  const numberRef = useRef();
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title">
      <Box sx={{ ...style, width: 400 }}>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography> */}

        <TextField
          type="number"
          inputRef={numberRef}
          label={title}
          InputProps={{
            inputProps: { min: 0 },
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={() => onClose()}
          >
            취소
          </Button>
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={() => {
              if (numberRef && numberRef.current) {
                const intValue = parseInt(numberRef.current.value, 10) || null;
                onOK(intValue);
              }
            }}
          >
            적용
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

NumberInputModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired,

  defaultNumber: PropTypes.number,
  title: PropTypes.string,
  context: PropTypes.any,
};

NumberInputModal.defaultProps = {
  defaultNumber: null,
  title: null,
  context: null,
};

export default NumberInputModal;
