import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function SettleSearchBar({ getSettleList }) {
  //날짜
  const [date, setDate] = useState(dayjs(new Date()));
  // 검색
  const [division, setDivision] = useState("");
  const [keyword, setKeyword] = useState("");

  const onDivisionChange = (event) => {
    setDivision(event.target.value);
  };

  const onKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  // '검색'버튼 클릭시 푸시리스트 api get 요청
  const onSearchBtnClick = () => {
    const sendDate = date.$d;
    getSettleList(sendDate, division, keyword);
  };

  // '초기화'버튼과 달력내부 'X'버튼 클릭시 초기화
  const onCleanBtnClick = () => {
    setDate(dayjs(new Date()));
    setKeyword("");
  };

  return (
    <SearchContainer>
      <Inner1>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Text>계약기간</Text>
          <DatePicker
            views={["year", "month"]}
            minDate={dayjs("2022-01-01")}
            maxDate={dayjs("2029-12-31")}
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
        </LocalizationProvider>
      </Inner1>
      <Inner1>
        <Text>검색 구분</Text>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="select-search-label">Search</InputLabel>
          <Select
            labelId="select-search-label"
            id="select-search"
            label="Search"
            value={division}
            onChange={onDivisionChange}
          >
            <MenuItem value={"cpName"}>CP/작가명</MenuItem>
            <MenuItem value={"comRegisterNum"}>사업자등록번호</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Keyword" value={keyword} onChange={onKeywordChange} />
      </Inner1>
      <Inner2>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onSearchBtnClick}
        >
          검색
        </Button>
        <Button
          color="orange"
          appearance="subtle"
          style={{ border: "1px solid #e0e0e0", margin: "0 5px" }}
          onClick={onCleanBtnClick}
        >
          초기화
        </Button>
      </Inner2>
    </SearchContainer>
  );
}

const SearchContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});
const Inner1 = styled("div")({
  position: "relative",
  width: "100%",
  marginBottom: "20px",
  border: "1px solid #e0e0e0",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});
const Inner2 = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Text = styled("p")({
  border: "1px solid black",
  width: "100px",
  height: "37px",
  marginRight: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#bdbdbd",
  color: "black",
});

SettleSearchBar.propTypes = {
  getSettleList: PropTypes.func.isRequired,
};

export default SettleSearchBar;
