import React, { useState } from "react";
import AdminRoot from "../components/frame/AdminRoot";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
import AddTokenModal from "../components/modal/AddTokenModal";
import RegisterTokenSearchBar from "../components/payments/RegisterTokenSearchBar";
import { useEffect } from "react";
import { paymentsApi } from "../api/payments";
import toast from "react-hot-toast";
import RegisterTokenList from "../components/payments/RegisterTokenList";

const RegisterTokens = () => {
  const [loading, setLoading] = useState(true);
  const [openTokenRegister, setOpenTokenRegister] = useState(false);
  const [searchedList, setSearchedList] = useState([]);

  const getList = async (startDate, endDate, division, keyword) => {
    try {
      setLoading(true);
      const { data } = await paymentsApi.getAdminTokenList(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        division,
        keyword
      );
      setSearchedList(data);
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    const { status, data } = await paymentsApi.getAdminTokenList(
      undefined,
      undefined,
      undefined,
      undefined
    );
    if (status === 200) {
      setSearchedList(data);
    } else {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <AdminRoot>
          <Title>토큰 지급</Title>
          <RegisterTokenSearchBar getList={getList} />
        </AdminRoot>
      ) : (
        <AdminRoot>
          <Title>토큰 지급</Title>
          <RegisterTokenSearchBar getList={getList} />
          <Button
            appearance="primary"
            onClick={() => {
              setOpenTokenRegister(true);
            }}
            style={{
              margin: "0 5px",
              backgroundColor: "#000000",
              textColor: "#FFFFFF",
              width: "120px",
            }}
          >
            토큰 등록
          </Button>
          <RegisterTokenList searchedList={searchedList} />
          <AddTokenModal
            open={openTokenRegister}
            handleClose={() => setOpenTokenRegister(false)}
            getData={getData}
          />
        </AdminRoot>
      )}
    </>
  );
};

const Title = styled("div")({
  marginBottom: "20px",
});

export default RegisterTokens;
