import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import ContentsModal from "./Modal/ContentsModal";

function TableOfContents({ apiResults }) {
  //  state
  const [openContentsModal, setOpenContentsModal] = React.useState(false);
  const [contentInfo, setContentInfo] = React.useState({
    id: undefined,
    title: undefined,
  });

  // 테이블 오름차순/내림차순 관련 참수들
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // 테이블 헤더 설정
  const headCells = [
    {
      id: "no",
      numeric: false,
      disablePadding: false,
      label: "No",
    },
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "작품명",
    },
    {
      id: "detail",
      numeric: true,
      disablePadding: false,
      label: "상세보기",
    },
    {
      id: "viewCount",
      numeric: true,
      disablePadding: false,
      label: "조회수",
    },
    {
      id: "likeCount",
      numeric: true,
      disablePadding: false,
      label: "좋아요 수",
    },
    {
      id: "replyCount",
      numeric: true,
      disablePadding: false,
      label: "댓글 수",
    },
  ];
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  // 테이블 바디 설정

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = apiResults.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - apiResults.length) : 0;

  // '상세보기' 버튼 클릭시 모달창 오픈과 api 호출
  const onDetailBtnClick = (id, title) => {
    setContentInfo({ id: Number(id), title: title });
    setOpenContentsModal(true);
  };
  const onCloseContentsModal = () => {
    setOpenContentsModal(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={apiResults.length}
            />
            <TableBody>
              {stableSort(apiResults, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index} // 일부 작품 name등이 중복되어 key로는 index가 적합
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="center"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell padding="normal" align="center">
                        {row.name}
                      </TableCell>
                      <TableCell
                        padding="normal"
                        align="center"
                        sx={{
                          cursor: "pointer",
                          "&:hover": { textDecoration: "underline" },
                        }}
                        id={row.id}
                        onClick={(e) => {
                          onDetailBtnClick(e.target.id, row.name);
                        }}
                      >
                        상세보기
                      </TableCell>
                      <TableCell padding="normal" align="center">
                        {row.viewCount}
                      </TableCell>
                      <TableCell padding="normal" align="center">
                        {row.likeCount}
                      </TableCell>
                      <TableCell padding="normal" align="center">
                        {row.replyCount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 40]}
          component="div"
          count={apiResults.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ContentsModal
        openContentsModal={openContentsModal}
        setOpenContentsModal={setOpenContentsModal}
        onCloseContentsModal={onCloseContentsModal}
        contentId={contentInfo.id}
        contentTitle={contentInfo.title}
      />
    </Box>
  );
}

TableOfContents.defaultProps = {
  apiResults: [],
};

TableOfContents.propTypes = {
  apiResults: PropTypes.array,
};

export default TableOfContents;
