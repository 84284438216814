import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import TablePaginationActions from "../push/TablePaginationActions";
import Loading from "../common/Loading";
import PropTypes from "prop-types";
import UsersCancelDetailModal from "../modal/UsersCancelDetailModal";
import UseTokenCancelModal from "../modal/UseTokenCancelModal";

function UseTokenList({ loading, list, isCanceled }) {
  const history = useHistory();
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dataLength = list ? list.length : 0;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //modal
  const [openCancelDetail, setOpenCancelDetail] = useState(false);
  const [cancelDetailData, setCancelDetailData] = useState({});
  const [openNormalDetail, setOpenNormalDetail] = useState(false);
  const [normalDetailData, setNormalDetailData] = useState({});

  //누적금액
  const sumArr = [];
  let sumVal = 0;

  const calculateSum = (list) => {
    for (let i = dataLength - 1; i >= 0; i--) {
      if (list[i]["holdTokens.tknClCd"] === "PAY") {
        const tkn =
          list[i]["payments.payClCd"] === "0" && list[i].useType === "STORY"
            ? list[i].useToken
            : 0;
        //sumVal += tkn * list[i]["payments.payPerAmt"];
        sumVal += tkn * 120;
        sumArr.push(sumVal);
      } else {
        sumVal += 0;
        sumArr.push(sumVal);
      }
    }
  };

  const handleListButtonClick = () => {
    history.push("/useTokens/list");
  };

  return (
    <>
      {loading ? (
        <div style={{ height: "300px" }}>
          <Loading />
        </div>
      ) : (
        <>
          {calculateSum(list)}
          <div>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontSize: "16px",
                margin: "0px 12px",
                padding: "20px 20px",
                fontWeight: 500,
                letterSpacing: "-1px",
                lineHeight: "1.6",
              }}
            >
              사용 내역
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontSize: "15px",
                margin: "0px 12px",
                paddingRight: "20px",
                fontWeight: 500,
                letterSpacing: "-1px",
                lineHeight: "1.6",
                textAlign: "right",
                marginBottom: "12px",
              }}
            >
              전체 <span style={{ color: "orange" }}>{dataLength}</span> 건
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#e0e0e0",
                      height: "60px",
                    }}
                  >
                    <TableCell
                      sx={{ width: "5%", fontWeight: "bold", fontSize: "15px" }}
                      align="center"
                    >
                      NO
                    </TableCell>
                    <TableCell
                      sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                      align="center"
                    >
                      주문번호
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "8%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      CP
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      작품명
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "14%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      회차
                    </TableCell>
                    <TableCell
                      sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                      align="center"
                    >
                      토큰사용
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "8%",
                        wordBreak: "keep-all",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      사용금액
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "8%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      토큰종류
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      차감/취소일시
                    </TableCell>
                    <TableCell
                      sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                      align="center"
                    >
                      진입시간
                    </TableCell>
                    <TableCell
                      sx={{ width: "8%", fontWeight: "bold", fontSize: "15px" }}
                      align="center"
                    >
                      종료시간
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      누적사용금액
                    </TableCell>
                    <TableCell
                      sx={{ width: "4%", fontWeight: "bold", fontSize: "15px" }}
                      align="center"
                    >
                      비고
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? list.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : list
                  ).map((useToken, index) => (
                    <TableRow key={useToken.id}>
                      <TableCell align="center">
                        {dataLength - index - page * rowsPerPage}
                      </TableCell>
                      <TableCell align="center">{useToken.groupId}</TableCell>
                      <TableCell align="center">
                        {useToken["series.cpCompany.name"]
                          ? useToken["series.cpCompany.name"]
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {useToken.seriesId ? useToken["series.name"] : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {useToken.storyId ? useToken["stories.name"] : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {useToken["payments.payClCd"] === "0" &&
                        useToken.useType === "STORY"
                          ? useToken.useToken
                          : 0}
                      </TableCell>
                      <TableCell align="center">
                        {/* {useToken.holdTokens.tknClCd === "PAY"
                          ? useToken.useToken * useToken.payments.payPerAmt
                          : "-"} */}
                        {useToken["payments.payClCd"] === "0" &&
                        useToken.useType === "STORY"
                          ? useToken["holdTokens.tknClCd"] === "PAY"
                            ? // ? useToken.useToken * useToken["payments.payPerAmt"]
                              useToken.useToken * 120
                            : "-"
                          : useToken["holdTokens.tknClCd"] === "PAY"
                          ? 0
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {useToken["holdTokens.tknClCd"] === "PAY"
                          ? "구매"
                          : "이벤트"}
                      </TableCell>
                      <TableCell align="center">
                        {/* {useToken.payments
                          ? useToken.payments.payClCd === "0" &&
                            useToken.useType === "STORY"
                            ? moment(useToken.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : moment(useToken.payments.updatedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                          : ""} */}
                        {useToken["payments.payClCd"] === "0" &&
                        useToken.useType === "STORY"
                          ? moment(useToken.createdAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : useToken["payments.payClCd"] === "1"
                          ? moment(useToken["payments.updatedAt"]).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : moment(useToken.updatedAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                      </TableCell>
                      <TableCell align="center">
                        {useToken.useType === "STORY"
                          ? useToken["payments.payClCd"] === "0"
                            ? useToken.startedAt
                              ? moment(useToken.startedAt)
                                  .subtract(9, "hour")
                                  .format("MM-DD HH:mm:ss")
                              : ""
                            : ""
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {useToken.useType === "STORY"
                          ? useToken["payments.payClCd"] === "0"
                            ? useToken.closedAt
                              ? moment(useToken.closedAt)
                                  .subtract(9, "hour")
                                  .format("MM-DD HH:mm:ss")
                              : ""
                            : ""
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {sumArr.length === 0
                          ? "-"
                          : sumArr[dataLength - index - page * rowsPerPage - 1]}
                      </TableCell>
                      <TableCell align="center">
                        {useToken["payments.payClCd"] === "0" &&
                        useToken.useType === "STORY" ? (
                          <span
                            style={{ textDecoration: "underline" }}
                            onClick={() => {
                              setOpenNormalDetail(true);
                              setNormalDetailData(useToken);
                            }}
                          >
                            정상
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              setOpenCancelDetail(true);
                              setCancelDetailData(useToken);
                            }}
                          >
                            취소
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={11} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={12}
                      count={list.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#e0e0e0", color: "black" }}
                        onClick={handleListButtonClick}
                      >
                        목록
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <UsersCancelDetailModal
              open={openCancelDetail}
              handleClose={() => setOpenCancelDetail(false)}
              detail={cancelDetailData}
            />
            <UseTokenCancelModal
              open={openNormalDetail}
              handleClose={() => {
                setOpenNormalDetail(false);
              }}
              detail={normalDetailData}
              isCanceled={isCanceled}
            />
          </div>
        </>
      )}
    </>
  );
}

UseTokenList.defaultProps = {
  list: {
    groupId: "",
    cpName: "",
    seriesName: "",
    storiesName: "",
    useToken: "",
    tknClCd: "",
    createdAt: "",
  },
};

UseTokenList.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.string,
      cpName: PropTypes.string,
      seriesName: PropTypes.string,
      storiesName: PropTypes.string,
      useToken: PropTypes.number,
      tknClCd: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
};

export default UseTokenList;
