import React, { useState } from "react";
import { Button, styled } from "@material-ui/core";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import toast from "react-hot-toast";
import TablePaginationActions from "../push/TablePaginationActions";
import ReplyAdminHideModal from "./Modal/ReplyAdminHideModal";
import ReplyAdminHideCancelModal from "./Modal/ReplyAdminHideCancelModal";

const TableOfReply = ({ apiResults, isAdminHide }) => {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - apiResults.length) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    setCheckItems([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCheckItems([]);
  };

  //Check box
  const [checkItems, setCheckItems] = useState([]);

  const resetCheckItems = () => {
    setCheckItems([]);
  };

  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      const startRow = page * rowsPerPage;
      const endRow = (page + 1) * rowsPerPage;
      const idArray = [];
      for (let i = startRow; i < endRow; i++) {
        idArray.push(apiResults[i].id);
      }
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  // Modal
  const [openReplyAdminHide, setOpenReplyAdminHide] = useState(false);
  const [openReplyAdminHideCancel, setOpenReplyAdminHideCancel] =
    useState(false);

  return (
    <ListContainer>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#e0e0e0",
              height: "60px",
            }}
          >
            <TableRow>
              <TableCell padding="checkbox" align="center">
                <Checkbox
                  color="primary"
                  onChange={(e) => handleAllCheck(e.target.checked)}
                  checked={checkItems.length === rowsPerPage ? true : false}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                align="center"
              >
                No
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                align="center"
              >
                숨김
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                align="center"
              >
                작품명
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                align="center"
              >
                회차명
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                align="center"
              >
                필명
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                align="center"
              >
                댓글
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                align="center"
              >
                작성일
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? apiResults.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : apiResults
            ).map((data, index) => (
              <TableRow key={index} sx={{ height: "50px" }}>
                <TableCell align="center">
                  <Checkbox
                    color="primary"
                    onChange={(e) =>
                      handleSingleCheck(e.target.checked, data.id)
                    }
                    checked={checkItems.includes(data.id) ? true : false}
                  />
                </TableCell>
                <TableCell align="center">{data.id}</TableCell>
                <TableCell align="center">
                  {data.adminHide === false ? "N" : "Y"}
                </TableCell>
                <TableCell align="center">
                  {data.story?.series?.name ?? ""}
                </TableCell>
                <TableCell align="center">{data.story?.name ?? ""}</TableCell>
                <TableCell align="center">{data.writer.nickname}</TableCell>
                <TableCell align="center">{data.content}</TableCell>
                <TableCell align="center">
                  {moment(data.createdAt).format("YYYY.MM.DD")}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
              <TableCell colSpan={4}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (checkItems.length === 0) {
                      toast.error("숨김처리할 항목을 선택해 주세요", {
                        style: {
                          borderRadius: "10px",
                          background: "#333",
                          color: "#fff",
                        },
                      });
                    } else {
                      setOpenReplyAdminHide(true);
                    }
                  }}
                >
                  관리자 숨김
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    if (checkItems.length === 0) {
                      toast.error("숨김 취소처리할 항목을 선택해 주세요", {
                        style: {
                          borderRadius: "10px",
                          background: "#333",
                          color: "#fff",
                        },
                      });
                    } else {
                      setOpenReplyAdminHideCancel(true);
                    }
                  }}
                >
                  관리자 숨김 취소
                </Button>
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={4}
                count={apiResults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ReplyAdminHideModal
        open={openReplyAdminHide}
        checkItems={checkItems}
        handleClose={(result) => {
          setOpenReplyAdminHide(false);
          // if (result) {
          //   setReloadCount(reloadCount + 1);
          // }
        }}
        isAdminHide={isAdminHide}
        resetCheckItems={resetCheckItems}
      />
      <ReplyAdminHideCancelModal
        open={openReplyAdminHideCancel}
        checkItems={checkItems}
        handleClose={(result) => {
          setOpenReplyAdminHideCancel(false);
          // if (result) {
          //   setReloadCount(reloadCount + 1);
          // }
        }}
        isAdminHide={isAdminHide}
        resetCheckItems={resetCheckItems}
      />
    </ListContainer>
  );
};

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});

export default TableOfReply;
