import moment from "moment";

// DateRangePicker에서 날짜 얻어 String으로 변환
const convertDate = (newDate, newHour) => {
  const date = moment(newDate).format("YYYY-MM-DD");
  const hour = moment(newHour).format("HH:mm");
  return moment(`${date} ${hour}`, "YYYY-MM-DD HH:mm");
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 750,
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: "10px",
  p: 4,
};

export { convertDate, modalStyle };
