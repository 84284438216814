import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { styled } from "@material-ui/core";
import { paymentsApi } from "../api/payments";
import AdminRoot from "../components/frame/AdminRoot";
import PaymentList from "../components/payments/PaymentList";
import SearchBar from "../components/payments/SearchBar_PaymentList";
import ExcelDownOfPayments from "../components/Statistics/ExcelDown_Payments";

const Payment = () => {
  const [loading, setLoading] = useState(true);
  const [searchedPaymentList, setSearchedPaymentList] = useState([]);

  const getPaymentList = async (startDate, endDate, division, keyword) => {
    try {
      setLoading(true);
      const { data } = await paymentsApi.list(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        division,
        keyword
      );
      setSearchedPaymentList(data);
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { status, data } = await paymentsApi.list(
        undefined,
        undefined,
        undefined,
        undefined
      );
      if (status === 200) {
        setSearchedPaymentList(data);
      } else {
        toast.error("네트워크 에러", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <AdminRoot>
          <Title>결제 내역 / 매출 관리</Title>
          <SearchBar getPaymentList={getPaymentList} />
        </AdminRoot>
      ) : (
        <AdminRoot>
          <Title>결제 내역 / 매출 관리</Title>
          <>
            <SearchBar getPaymentList={getPaymentList} />
            <ExcelDownOfPayments apiResults={searchedPaymentList} />
            <PaymentList searchedPaymentList={searchedPaymentList} />
          </>
        </AdminRoot>
      )}
    </>
  );
};

const Title = styled("div")({
  marginBottom: "20px",
});

export default Payment;
