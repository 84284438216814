import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import PropTypes from "prop-types";

function CpPayDetail({ data }) {
  const payDetail = data;

  return (
    <div>
      <Typography
        sx={{
          fontFamily: "NotoSansKR",
          fontSize: "16px",
          margin: "0px 12px",
          padding: "20px 20px",
          fontWeight: 500,
          letterSpacing: "-1px",
          lineHeight: "1.6",
        }}
      >
        결제 정보
      </Typography>
      <TableContainer>
        <Table
          sx={{
            width: "800px",
            marginBottom: "30px",
          }}
          align="center"
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                  lineHeight: "2.45",
                }}
              >
                CP ID
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                  lineHeight: "2.45",
                }}
              >
                {payDetail.cpId}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                  lineHeight: "2.45",
                }}
              >
                CP사
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                  lineHeight: "2.45",
                }}
              >
                {payDetail.cpName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                  lineHeight: "2.45",
                }}
              >
                작품명
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "15%",
                }}
              >
                {payDetail.seriesName}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                  lineHeight: "2.45",
                }}
              >
                회차명
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  lineHeight: "2.45",
                  width: "30%",
                }}
              >
                {payDetail.storyName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                  lineHeight: "2.45",
                }}
              >
                누적결제
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                  lineHeight: "2.45",
                }}
              >
                {payDetail.tknClCd === "PAY" ? payDetail.token * 120 : "-"}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e0e0e0",
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "20%",
                  lineHeight: "2.45",
                }}
              >
                차감기간
              </TableCell>
              <TableCell
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  fontFamily: "NotoSansKR",
                  width: "30%",
                  lineHeight: "2.45",
                }}
              >
                {moment(payDetail.dt).startOf("month").format("YYYY-MM-DD")} ~{" "}
                {moment(payDetail.dt).endOf("month").format("YYYY-MM-DD")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

CpPayDetail.propTypes = {
  data: PropTypes.object,
};

export default CpPayDetail;
