import React, { forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import moment from "moment";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AdminRoot from "../components/frame/AdminRoot";
import { studioMetaApi } from "../api/studio";
import { StudioSubMenu } from "../define";
import AddCategoryInterActiveModal from "../components/modal/AddCategoryInterActiveModal";
import AddResourceInterActiveModal from "../components/modal/AddResourceInterActiveModal";
import { Button } from "@material-ui/core";
import ChangeCategoryModal from "../components/modal/ChangeCategoryModal";
import ChangeResourceInterActiveModal from "../components/modal/ChangeResourceInterActiveModal";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const materialTableOptions = {
  search: true,
  sorting: true,
  draggable: false,
  showTitle: true,
  actionsColumnIndex: -1,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
  headerStyle: {
    backgroundColor: "#EEE",
    fontWeight: "bold",
    textAlign: "center",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  actionsCellStyle: {
    textAlign: "center",
  },
};

const useStyles = makeStyles(() => ({
  root: {},
}));

const MetaInteractive = () => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [studioSubMenuData, setStudioSubMenuData] = useState([]);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openChangeCategory, setOpenChangeCategory] = useState(false);
  const [openAddResource, setOpenAddResource] = useState(false);
  const [openChangeResource, setOpenChangeResource] = useState(false);
  const [selectedSubMenuId, setSelectedSubMenuId] = useState(1);
  const [selectedResourceId, setSelectedResourceId] = useState(1);
  const [reloadCount, setReloadCount] = useState(0);

  const materialTableColumns = [
    {
      title: "카테고리",
      field: "name",
    },
    {
      title: "리소스 타입",
      render: (rowData) => <>인터렉티브</>,
    },
    { title: `리소스 이름`, field: "resourceName" },
    { title: `비고`, field: "etc" },
    {
      title: `미리보기(이미지)`,
      render: (rowData) => {
        if (rowData.id === "") {
          return (
            <img
              src={rowData?.url}
              alt="preview"
              style={{ maxHeight: "50px" }}
            />
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: `미리보기(효과음)`,
      render: (rowData) => {
        if (rowData.id === "") {
          return (
            <audio controls>
              <source src={rowData?.url2} />
            </audio>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: "공개",
      render: (rowData) => {
        if (rowData.id === "") {
          return <>{rowData.isPublic ? "공개" : "비공개"}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: "등록일",
      render: (rowData) => (
        <>
          {rowData.createdAt
            ? moment(rowData.createdAt).format("YY-MM-DD  HH:mm")
            : ""}
        </>
      ),
    },
    {
      title: "수정",
      render: (rowData) => {
        if (rowData.id === "") {
          return (
            <Button
              variant="contained"
              onClick={() => {
                setSelectedSubMenuId(rowData.studioSubMenuId);
                setSelectedResourceId(rowData.resourceId);
                setOpenChangeResource(true);
              }}
            >
              수정
            </Button>
          );
        } else {
          return (
            <Button
              variant="contained"
              onClick={() => {
                setOpenChangeCategory(true);
                setSelectedSubMenuId(rowData.id);
              }}
            >
              수정
            </Button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    const getRoundData = async () => {
      const { status, data: newData } =
        await studioMetaApi.getListStudioSubMenu(StudioSubMenu.Interactive);
      if (status === 200) {
        const resources = [];
        newData.forEach((item) => {
          item.resources.forEach((item2) => {
            resources.push({
              id: "",
              resourceId: item2.id,
              etc: item2.etc,
              isPublic: item2.isPublic,
              resourceName: item2.name,
              studioSubMenuId: item2.studioSubMenuId,
              createdAt: item2?.createdAt,
              type: item2.type,
              url: item2.url,
              url2: item2.url2,
            });
          });
        });

        setStudioSubMenuData(newData);
        setData([...newData, ...resources]);
      }
    };
    getRoundData();
  }, [reloadCount]);

  return (
    <AdminRoot>
      <div className={classes.root}>
        <MaterialTable
          title="인터렉티브"
          icons={tableIcons}
          columns={materialTableColumns}
          options={materialTableOptions}
          data={data}
          parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.studioSubMenuId)
          }
          actions={[
            {
              icon: AddBox,
              tooltip: "카테고리 추가",
              isFreeAction: true,
              onClick: () => {
                setOpenAddCategory(true);
              },
            },
            {
              icon: AddBox,
              tooltip: "리소스 추가",
              isFreeAction: true,
              onClick: () => {
                setOpenAddResource(true);
              },
            },
          ]}
        />
        <AddCategoryInterActiveModal
          key={openAddCategory}
          open={openAddCategory}
          studioMenuId={StudioSubMenu.Interactive}
          handleClose={(result) => {
            setOpenAddCategory(false);
            if (result) {
              setReloadCount(reloadCount + 1);
            }
          }}
        />
        <ChangeCategoryModal
          key={openChangeCategory}
          open={openChangeCategory}
          studioMenuId={StudioSubMenu.Interactive}
          studioSubMenuId={selectedSubMenuId}
          handleClose={(result) => {
            setOpenChangeCategory(false);
            if (result) {
              setReloadCount(reloadCount + 1);
            }
          }}
        />
        <ChangeResourceInterActiveModal
          key={openChangeResource}
          open={openChangeResource}
          studioMenuId={StudioSubMenu.Interactive}
          studioSubMenuId={selectedSubMenuId}
          resourceId={selectedResourceId}
          handleClose={(result) => {
            setOpenChangeResource(false);
            if (result) {
              setReloadCount(reloadCount + 1);
            }
          }}
        />
        <AddResourceInterActiveModal
          studioMenuId={StudioSubMenu.Interactive}
          items={studioSubMenuData}
          key={openAddResource}
          open={openAddResource}
          handleClose={(result) => {
            setOpenAddResource(false);
            if (result) {
              setReloadCount(reloadCount + 1);
            }
          }}
        />
      </div>
    </AdminRoot>
  );
};
export default MetaInteractive;
