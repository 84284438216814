import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { styled } from "@material-ui/core";
import { Button } from "rsuite";
import { Link } from "react-router-dom";
import { pushApi } from "../../api/push";
import "rsuite/dist/rsuite.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "./TablePaginationActions";
import Paper from "@mui/material/Paper";
import toast from "react-hot-toast";
import Loading from "../common/Loading";
import PushCheckDrawer from "./PushCheckDrawer";

function PushList({ loading, searchedPushList, getData, props }) {
  // state 모음
  const [openDrawer, setOpenDrawer] = useState(false);

  // Drawer창에 리스트 중 특정 푸시의 정보를 넘기기 위한 스테이트
  const [pushInfo, setPushInfo] = useState({
    id: undefined,
    status: undefined,
  });

  // '조회(변경)' 버튼으로 Drawer 열기 / 닫기
  const onOpenCheckDrawer = (event) => {
    setPushInfo({
      id: Number(event.target.name),
      status: Number(event.target.value),
    });
    setOpenDrawer(true);
  };
  const onCloseCheckDrawer = () => setOpenDrawer(false);

  // 테이블 페이지네이션 관련 내용
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - searchedPushList.length)
      : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // push 삭제버튼 이벤트
  const onPushRemove = async (event) => {
    try {
      const { status } = await pushApi.remove(Number(event.target.id));
      if (status === 200) {
        toast.success("push 삭제 되었습니다.");
        getData();
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  // console.log(searchedPushList);
  return (
    <ListContainer>
      <Link to="/push/register">
        <Button style={{ backgroundColor: "black", color: "white" }}>
          푸시 발송 등록
        </Button>
      </Link>
      <Inner>
        {loading ? (
          <div style={{ height: "300px" }}>
            <Loading />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#e0e0e0", height: "60px" }}>
                  <TableCell sx={{ width: "7%" }} align="center">
                    번호
                  </TableCell>
                  <TableCell sx={{ width: "8%" }} align="center">
                    발송처리
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    발송시간
                  </TableCell>
                  <TableCell sx={{ width: "30%" }} align="center">
                    발송제목
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    상태
                  </TableCell>
                  <TableCell sx={{ width: "10%" }} align="center">
                    발송정보
                  </TableCell>
                  <TableCell
                    sx={{ width: "8%", wordBreak: "keep-all" }}
                    align="center"
                  >
                    보낼&nbsp;디바이스
                  </TableCell>
                  <TableCell sx={{ width: "9%" }} align="center">
                    등록자
                  </TableCell>
                  <TableCell sx={{ width: "8%" }} align="center">
                    푸시삭제
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? searchedPushList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : searchedPushList
                ).map((push) => (
                  <TableRow
                    key={push.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{push.id}</TableCell>
                    <TableCell align="center">
                      {push.process === 1 ? "예약발송" : "즉시발송"}
                    </TableCell>
                    <TableCell align="center">
                      {push.process === 1
                        ? moment(push?.pushDate).format("YY-MM-DD  HH:mm")
                        : moment(push?.createdAt).format("YY-MM-DD  HH:mm")}
                    </TableCell>
                    <TableCell align="center">{push.title}</TableCell>
                    <TableCell align="center">
                      {push.status === 1 ? "발송전" : "발송완료"}
                    </TableCell>
                    <TableCell align="center">
                      {push.status === 1 && (
                        <Button
                          name={push.id}
                          value={push.status}
                          onClick={onOpenCheckDrawer}
                        >
                          조회/변경
                        </Button>
                      )}
                      {push.status === 2 && (
                        <Button
                          name={push.id}
                          value={push.status}
                          onClick={onOpenCheckDrawer}
                        >
                          조회
                        </Button>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {push.sendDevice === 1 && "안드로이드"}
                      {push.sendDevice === 2 && "IOS"}
                      {push.sendDevice === 3 && "모두"}
                    </TableCell>
                    <TableCell align="center">{push.user?.name}</TableCell>
                    <TableCell align="center">
                      <Button id={push.id} onClick={onPushRemove}>
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={9}
                    count={searchedPushList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            <PushCheckDrawer
              pushId={pushInfo.id}
              pushStatus={pushInfo.status}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              onCloseCheckDrawer={onCloseCheckDrawer}
              getData={getData}
            />
          </TableContainer>
        )}
      </Inner>
    </ListContainer>
  );
}

const ListContainer = styled("div")({
  position: "relative",
  marginBottom: "20px",
});
const Inner = styled("div")({
  position: "relative",
  width: "100%",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

PushList.defaultProps = {
  searchedPushList: {
    no: "",
    type: "",
    title: "",
    status: "",
    detail: "",
    history: "",
    register: "",
  },
};

PushList.propTypes = {
  loading: PropTypes.bool.isRequired,
  searchedPushList: PropTypes.arrayOf(
    PropTypes.shape({
      no: PropTypes.number,
      type: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.number,
      detail: PropTypes.string,
      history: PropTypes.string,
      register: PropTypes.string,
    })
  ),
  getData: PropTypes.func.isRequired,
};

export default PushList;
