import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "rsuite";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import Loading from "../common/Loading";
import { pushApi } from "../../api/push";

const PromptPush = ({ pushId, setOpenCheckDrawer }) => {
  const [pushDetail, setPushDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const getPushDetail = async () => {
    setLoading(true);
    try {
      const { status, data } = await pushApi.detail(pushId);
      if (status === 200) {
        setPushDetail(data);
      }
    } catch (error) {
      toast.error("네트워크 에러", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  // eslint-disable-next-line
  useEffect(getPushDetail, []);

  // 모달창 닫기 이벤트
  const onCloseModal = () => {
    setOpenCheckDrawer(false);
  };

  return (
    <Container>
      {loading || pushDetail === null ? (
        <Loading />
      ) : (
        <Box>
          <h5 style={{ margin: "100px 0 10px" }}>Push 발송정보</h5>
          <Inner>
            <Cell1>발송대상자</Cell1>
            <Cell2>
              {pushDetail.sendDevice === 1 && "안드로이드"}
              {pushDetail.sendDevice === 2 && "iOS"}
              {pushDetail.sendDevice === 3 && "모두"}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>발송처리</Cell1>
            <Cell2>
              {pushDetail.process === 1 && `예약발송`}
              {pushDetail.sendDevice === 2 && `즉시발송`}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>발송시간</Cell1>
            <Cell2>
              {moment(pushDetail.pushDate).format("YYYY-MM-DD HH:mm")}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>앱푸쉬 동의</Cell1>
            <Cell2>
              <FormControlLabel
                control={
                  <Checkbox
                    name="onlyAgreePush"
                    checked={pushDetail.onlyAgreePush === true ? true : false}
                  />
                }
                label="앱푸쉬 알람 동의자에게만 발송"
                disabled
              />
            </Cell2>
          </Inner>
          <Inner>
            <Cell1>제목</Cell1>
            <Cell2>{pushDetail.title}</Cell2>
          </Inner>
          <Inner>
            <Cell1>메시지</Cell1>
            <Cell2>{pushDetail.content}</Cell2>
          </Inner>
          <Inner>
            <Cell1>이미지</Cell1>
            <Cell2>
              {pushDetail.imgUrl === null ? (
                <div>없음</div>
              ) : (
                <Img src={pushDetail.imgUrl} alt="inserted_image" />
              )}
            </Cell2>
          </Inner>
          <Inner>
            <Cell1 style={{ borderBottom: "1px solid #ccc" }}>링크</Cell1>
            <Cell2 style={{ borderBottom: "1px solid #ccc" }}>
              <span>작품:&nbsp;</span>
              <span id={pushDetail.series?.id}>
                {pushDetail.series?.name ?? "None"}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>회차:&nbsp;</span>
              <span id={pushDetail.story?.id}>
                {pushDetail.story?.name ?? "None"}
              </span>
            </Cell2>
          </Inner>
          <Inner style={{ justifyContent: "flex-end" }}>
            <Button
              color="orange"
              appearance="subtle"
              style={{
                margin: "10px 0",
                width: "90px",
                height: "40px",
                border: "1px solid #e0e0e0",
              }}
              onClick={onCloseModal}
            >
              닫기
            </Button>
          </Inner>
        </Box>
      )}
    </Container>
  );
};

const Container = styled("div")({
  position: "relative",
});
const Inner = styled("div")({
  position: "relative",
  height: "60px",
  display: "flex",
  justifyContent: "flex-start",
});
const Cell1 = styled("div")({
  border: "1px solid #ccc",
  borderRight: 0,
  borderBottom: 0,
  backgroundColor: "lightgray",
  width: "100px",
  height: "100%",
  paddingLeft: "10px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Cell2 = styled("div")({
  border: "1px solid #ccc",
  borderBottom: 0,
  height: "100%",
  paddingRight: "30px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexGrow: 1,
  paddingLeft: "30px",
  overflow: "auto",
  font: '400 14px "Roboto","Helvetica","Arial", sans-serif',
});
const Img = styled("img")({
  border: "1px solid lightgray",
  height: "60px",
});

export default PromptPush;
