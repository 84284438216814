import React from "react";
import SideBar from "./SideBar";
import MainContent from "./MainContent";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AdminAppBar from "./AdminAppBar";
import BottomSnackbar from "./BottomSnackbar";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));

const AdminRoot = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AdminAppBar />
      <SideBar />
      <MainContent>{children}</MainContent>
      <BottomSnackbar />
    </div>
  );
};

export default AdminRoot;

AdminRoot.propTypes = {
  children: PropTypes.node.isRequired,
};
