import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import "rsuite/dist/rsuite.min.css";
import TablePaginationActions from "../push/TablePaginationActions";

function RegisterTokenList({ searchedList }) {
  //PAGING
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dataLength = searchedList.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataLength) : 0;
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#e0e0e0",
              height: "60px",
            }}
          >
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              No
            </TableCell>
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              회원ID
            </TableCell>
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              프로필명
            </TableCell>
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              지급토큰
            </TableCell>
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              유효기간(시작)
            </TableCell>
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              유효기간(종료)
            </TableCell>
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              적요
            </TableCell>
            <TableCell
              sx={{ width: "12%", fontWeight: "bold", fontSize: "15px" }}
              align="center"
            >
              등록일
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? searchedList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : searchedList
          ).map((data, index) => (
            <TableRow key={data.id}>
              <TableCell align="center">
                {dataLength - index - page * rowsPerPage}
              </TableCell>
              <TableCell align="center">{data.userId}</TableCell>
              <TableCell align="center">
                {data.users ? data.users.nickname : ""}
              </TableCell>
              <TableCell align="center">{data.fstToken}</TableCell>
              <TableCell align="center">
                {moment(data.createdAt).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align="center">
                {moment(data.expDd).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align="center">{data.prdCd}</TableCell>
              <TableCell align="center">
                {moment(data.createdAt).format("YYYY-MM-DD")}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={11} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ borderTop: "1px solid #f2f2f2" }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { label: "All", value: -1 }]}
              colSpan={8}
              count={searchedList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

RegisterTokenList.propTypes = {
  searchedList: PropTypes.array.isRequired,
};

export default RegisterTokenList;
